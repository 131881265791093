<!-- Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM -->
<moon-table-page [MSPageTitle]="MSPageTitle"
                 [MSItemList]="MSDocumentCategoryList"
                 [MSDisplayCreateButton]="MSDisplayCreate"
                 [MSDisplayRouteBackButton]="MSDisplayRouteBack"
                 [MSDisplayFindButton]="MSDisplayFind"
                 (MSEventOnClickCreate)="OnClickCreate()"
                >
    <p-table #MSTable
             [columns]="MSTableColumns"
             [rowHover]="true"
             [value]="MSDocumentCategoryList"
             selectionMode="single"
             [loading]="MSLoading"
             styleClass="p-datatable-gridlines p-datatable-striped">
        <ng-template pTemplate="caption">
            <moon-table-header [MSPageTitle]="MSPageTitle"
                               [MSEnableSearch]="MSEnableSearch"
                               [MSDisplayCreateButton]="MSDisplayCreate"
                               [MSDisplayRouteBackButton]="MSDisplayRouteBack"
                               (MSEventOnClickCreate)="OnClickCreate()"
                               (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                               (MSEventOnClickRouteBack)="OnClickGoBack()">
            </moon-table-header>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions' || OnClickItem(rowData)">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'actions'"
                             class="text-center">

                            <moon-custom-dropdown [model]="rowData.actions">
                                <i class="pi pi-ellipsis-v moon-custom-button-icon"></i>
                            </moon-custom-dropdown>
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

