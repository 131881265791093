// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { NgIf }                         from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }            from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Third Party Imports
import { MenuItem }                     from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { MezzLoanGet }                  from '@moon-public/api/response/mezz-loan-get.response';
import { MezzLoanController }           from '@moon-public/api/mezz-loan.controller';
import { PublicRouter }                 from "@moon-public/public.router";
import { MezzLoanFormComponent }        from '@moon-public/mezz-loan/mezz-loan-form/mezz-loan-form.component';
import { MezzLoanTableComponent }       from '@moon-public/mezz-loan/mezz-loan-table/mezz-loan-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { RoutePath }                    from '@moon-shared/constants/route-path';

@Component({
    selector: 'mezz-loan-add-page',
    templateUrl: './mezz-loan-edit-page.component.html',
    styleUrls: ['./mezz-loan-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, MezzLoanTableComponent, MezzLoanFormComponent],
    providers: [MezzLoanController, PublicRouter]
})
export class MezzLoanEditPageComponent implements OnInit {

  public MSMezzLoanID: number | null;
  public MSMezzLoanGet: MezzLoanGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.MezzLoan.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDisplayRouteBackButton: boolean = true;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _mezzLoanController: MezzLoanController,
    private _publicRouter: PublicRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSMezzLoanID){
      await this.getMezzLoanByID(this.MSMezzLoanID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSMezzLoanID){
      this.doRemove(this.MSMezzLoanID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSMezzLoanID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    const editPageBreadcrumb: MenuItem[] = [
      { label: this.MSPageTitle, routerLink: [`${this._publicRouter.MezzLoanRouteLink()}`] }, 
      { label: this.MSMezzLoanID ? RoutePath.Edit : RoutePath.Add, routerLink: null }
    ];
    this._appBreadcrumbService.setItems(editPageBreadcrumb);
  }

  private readRouteParameters() {
    const mezzLoanID = this._activatedRoute.snapshot.paramMap.get(FieldNames.MezzLoanID);
    this.MSMezzLoanID = mezzLoanID ? +mezzLoanID : null;
  }

  private async getMezzLoanByID(mezzLoanID: number) {
    if (mezzLoanID) {
      const apiResult: ApiContentResult<MezzLoanGet> = await this._mezzLoanController.GetByID(mezzLoanID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSMezzLoanGet = apiResult.content;
      }
    }
  }

  public OnClickRouteBack() {
    this._publicRouter.RouteToMezzLoan();
  }

  private async doRemove(mezzLoanID: number) {
    const apiResult: ApiResult = await this._mezzLoanController.Delete(mezzLoanID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._publicRouter.RouteToMezzLoan();
    }
  }
}
