<!-- Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
    <skill-table [MSPageTitle]="MSPageTitle"
                [MSSkillList]="MSSkillList"
                [MSLoading]="MSLoading"
                (MSRefreshEvent)="MSRefreshPage()"
                ></skill-table>
</div>
