// Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM
import { NgIf }                         from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }            from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Moon Imports
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { UserGroupGet }                 from '@moon-maintainer/api/response/user-group-get.response';
import { UserGroupController }          from '@moon-maintainer/api/user-group.controller';
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { UserGroupFormComponent }       from '@moon-maintainer/user-group/user-group-form/user-group-form.component';
import { UserGroupTableComponent }      from '@moon-maintainer/user-group/user-group-table/user-group-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { FieldNames }                   from '@moon-shared/constants/field-names';

@Component({
    selector: 'user-group-add-page',
    templateUrl: './user-group-edit-page.component.html',
    styleUrls: ['./user-group-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, UserGroupTableComponent, UserGroupFormComponent],
    providers: [UserGroupController, MaintainerRouter]
})
export class UserGroupEditPageComponent implements OnInit {

  public MSUserGroupID: number | null;
  public MSUserGroupGet: UserGroupGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.UserGroup.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDisplayRouteBackButton: boolean = true;
  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _userGroupController: UserGroupController,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSUserGroupID){
      await this.getUserGroupByID(this.MSUserGroupID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSUserGroupID){
      this.doRemove(this.MSUserGroupID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSUserGroupID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(this.MSPageTitle, "", this.MSUserGroupID);
  }

  private readRouteParameters() {
    const userGroupID = this._activatedRoute.snapshot.paramMap.get(FieldNames.UserGroupID);
    this.MSUserGroupID = userGroupID ? +userGroupID : null;
  }

  private async getUserGroupByID(userGroupID: number) {
    if (userGroupID) {
      const apiResult: ApiContentResult<UserGroupGet> = await this._userGroupController.GetByID(userGroupID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSUserGroupGet = apiResult.content;
      }
    }
  }

  private async doRemove(userGroupID: number) {
    const apiResult: ApiResult = await this._userGroupController.Delete(userGroupID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToUserGroup();
    }
  }
  
  public OnClickRouteBack() {
    this._maintainerRouter.ToUserGroup();
  }
}
