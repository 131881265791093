import { inject } from '@angular/core';
// MS Imports
import { UserSessionService } from '@moon-core/services/user-session.service';
import { AppRouter } from "../../app.router";

export const CollaboratorRouteGuard = (): boolean => {

    const _appRouter = inject(AppRouter);
    const service = inject(UserSessionService);
    if (service.IsCollaborator()){
        return true;
    } else {
        _appRouter.RouteToDefault();
        return false;
    }
}