import { CommonModule, KeyValue, NgIf }                               from '@angular/common';
import { Component, EventEmitter, Input, Output }                     from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }    from '@angular/forms';
// Third party imports
import { ConfirmationService, MenuItem }                              from 'primeng/api';
import { BadgeModule }                                                from 'primeng/badge';
import { ButtonModule }                                               from 'primeng/button';
import { ConfirmDialogModule }                                        from 'primeng/confirmdialog';
import { DialogModule }                                               from 'primeng/dialog';
import { InputTextModule }                                            from 'primeng/inputtext';
import { InputTextareaModule }                                        from 'primeng/inputtextarea';
import { RippleModule }                                               from 'primeng/ripple';
import { TieredMenuModule }                                           from 'primeng/tieredmenu';
// MS Imports
import { ApiResult }                                                  from '@moon-core/models/api-result';
import { ComponentUtilityService }                                    from '@moon-core/services/component-utility.service';
import { TitleDocumentGet }                                           from '@moon-public/api/response/title-document.response';
import { TitleDocumentController }                                    from '@moon-public/api/title-document.controller';
import { PublicRouter }                                               from '@moon-public/public.router';
import { ChatLogComponent }                                           from '@moon-public/title-summary/chat-log/chat-log.component';
import { ProcessingStatus }                                           from '@moon-public/title-summary/models/processing-status';
import { MoonFormControlComponent }                                   from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MessageSeverity }                                            from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                         from '@moon-shared/services/moon-message.service';

@Component({
  selector: 'moon-title-document',
  standalone: true,
  imports: [
    CommonModule, NgIf, ReactiveFormsModule,
    BadgeModule, ButtonModule, RippleModule, DialogModule, TieredMenuModule, InputTextModule, InputTextareaModule, ConfirmDialogModule,
    ChatLogComponent, MoonFormControlComponent
  ],
  templateUrl: './title-document.component.html',
  styleUrls: ['./title-document.component.scss'],
  providers: [TitleDocumentController]
})
export class TitleDocumentComponent {
  public MSSaving: boolean = false;
  @Input() MSTestMode: boolean = false;
  @Input() public MSApplying: boolean = false;
  @Input() public MSDocumentIndexNumber: number;
  @Input() public MSTitleDocument: TitleDocumentGet;
  @Output() public MSOnTitleDocumentChange = new EventEmitter();
  public MSShowChatLog: boolean = false;
  public MSChatLogID: number;
  public MSPromptButtons: MenuItem[] =
    [
      { label: 'Exception Number', command: () => this.onClickPromptExceptionNumber() },
      { label: 'Summary', command: () => this.onClickPromptSummary() },
    ];
  public MSChatLogButtons: MenuItem[] =
    [
      { label: 'Exception Number', command: () => this.onClickExceptionNumberLog() },
      { label: 'Summary', command: () => this.onClickSummaryLog() },
    ];
  public MSEditAction: boolean = false;
  public MSEditType: boolean = false;
  public MSActionFormGroup: FormGroup<{ recommendedAction: FormControl<string> }>;
  public MSTypeFormGroup: FormGroup<{ exceptionType: FormControl<string> }>;
  constructor(
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
    private _moonMessageService: MoonMessageService,
    private _publicRouter: PublicRouter,
    private _confirmationService: ConfirmationService
  ) { }


  public MSToggleActionEdit() {
    this.MSEditAction = !this.MSEditAction;
    if (this.MSActionFormGroup == null) {
      this.initializeActionFormGroup();
    }
  }

  public MSToggleTypeEdit() {
    this.MSEditType = !this.MSEditType;
    if (this.MSTypeFormGroup == null) {
      this.initializeTypeFormGroup();
    }
  }
  public MSShowPdfButton(document: TitleDocumentGet) {
    return ProcessingStatus.Created.equalsIgnoreCase(document.processingStatus) === false;
  }

  public async MSOnClickPdfReviewForDocument(titleDocumentID: number) {
    this._publicRouter.RouteToDocumentPdfReview(this.MSTitleDocument.titleSummaryID, titleDocumentID);
  }
  public MSShowPromptAndChatLogButton(document: TitleDocumentGet) {
    return ProcessingStatus.Created.equalsIgnoreCase(document.processingStatus) === false &&
      ProcessingStatus.TitleDocumentDownloaded.equalsIgnoreCase(document.processingStatus) === false;
  }

  public onClickExceptionNumberLog() {
    if (this.MSTitleDocument.exceptionChatLogID !== null) {
      this.MSChatLogID = this.MSTitleDocument.exceptionChatLogID;
      this.MSShowChatLog = true;
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Exception Chat Log Not Found.")
    }
  }

  public onClickSummaryLog() {
    if (this.MSTitleDocument.summaryChatLogID !== null) {
      this.MSChatLogID = this.MSTitleDocument.summaryChatLogID;
      this.MSShowChatLog = true;
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Summary Chat Log Not Found.")
    }
  }
  public MSSaveRecommendedAction() {
    this.MSActionFormGroup.markAllAsTouched();
    this.MSActionFormGroup.updateValueAndValidity();
    if (this.MSActionFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid recommended action.")
      return;
    }
    this.doOverrideRecommendedAction();
  }

  public async MSSaveExceptionType() {
    this.MSTypeFormGroup.markAllAsTouched();
    this.MSTypeFormGroup.updateValueAndValidity();

    if (this.MSTypeFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid exception type.")
      return;
    }
    this.doOverrideExceptionType();
  }

  private async onClickPromptSummary() {
    if (this.MSTitleDocument.hasError) {
      this.doPromptSummary();
    }
    else {
      this._confirmationService.confirm({
        message: "This prompt is already completed. Are you sure you want to continue?",
        acceptLabel: "Yes",
        accept: () => this.doPromptSummary(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptSummary() {
    this.MSApplying = true;
    const apiResult: ApiResult = await this._titleDocumentController.PromptSummary(this.MSTitleDocument.titleDocumentID, this.MSTestMode);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt summary successful.");
      this.MSOnTitleDocumentChange.emit();
    }
    this.MSApplying = false;
  }
  private async onClickPromptExceptionNumber() {

    if (this.MSTitleDocument.hasError) {
      this.doPromptExceptionNumber();
    }
    else {
      this._confirmationService.confirm({
        message: "This prompt is already completed. Are you sure you want to continue?",
        acceptLabel: "Yes",
        accept: () => this.doPromptExceptionNumber(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptExceptionNumber() {
    this.MSApplying = true;
    const apiResult: ApiResult = await this._titleDocumentController.PromptExceptionNumber(this.MSTitleDocument.titleDocumentID, this.MSTestMode);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt exception successful.");
      this.MSOnTitleDocumentChange.emit();
    }
    this.MSApplying = false;
  }

  private initializeActionFormGroup() {
    this.MSActionFormGroup = new FormGroup({
      recommendedAction: new FormControl<string>(this.MSTitleDocument.overrideRecommendedAction ?? this.MSTitleDocument.recommendedAction,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(500)]
        })
    });
  }

  private initializeTypeFormGroup() {
    this.MSTypeFormGroup = new FormGroup({
      exceptionType: new FormControl<string>(this.MSTitleDocument.overrideExceptionType ?? this.MSTitleDocument.exceptionType,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(100)]
        })
    });
  }

  private async doOverrideExceptionType() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.OverrideExceptionType(this.MSTitleDocument.titleDocumentID, this.MSTypeFormGroup.getRawValue().exceptionType);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Document type updated successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
  private async doOverrideRecommendedAction() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.OverrideRecommendedAction(this.MSTitleDocument.titleDocumentID, this.MSActionFormGroup.getRawValue().recommendedAction);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Document action updated successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }

  public MSPreserveOriginalOrderInKeyValuePipe = (_a: KeyValue<number,string>, _b: KeyValue<number,string>): number => {
    return 0;
  }
  
}
