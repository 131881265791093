<div
    class="layout-wrapper"
    [ngClass]="{
        'p-input-filled': app.inputStyle === 'filled',
        'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL
    }"
    [class]="
        'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme
    "
>
    <p-toast key="tst"></p-toast>
    <app-topbar></app-topbar>

    <div
        class="menu-wrapper"
        (click)="onMenuClick()"
    >
        <div class="layout-menu-container">
            <app-inline-menu
                *ngIf="
                    app.inlineMenuPosition == 'top' ||
                    app.inlineMenuPosition == 'both'
                "
                key="top"
            ></app-inline-menu>
            <app-menu></app-menu>
            <app-inline-menu
                *ngIf="
                    MSShowInlineMenu &&
                    (app.inlineMenuPosition == 'bottom' ||
                    app.inlineMenuPosition == 'both')
                "
                key="bottom"
            ></app-inline-menu>
        </div>
    </div>

    <div class="layout-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

    </div>

    <app-config></app-config>

    <div
        *ngIf="mobileMenuActive"
        class="layout-mask modal-in"
    ></div>
</div>


<moon-go-to-top-button></moon-go-to-top-button>