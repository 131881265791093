// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { 
    Component, EventEmitter, 
    Input, OnInit, Output,
    ViewChild, ViewEncapsulation }          from "@angular/core";
import { 
    NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                       from "@angular/common";
// Third Party imports
import { ButtonModule }                     from "primeng/button";
import { InputTextModule }                  from "primeng/inputtext";
import { Table, TableModule }               from "primeng/table";
import { 
    FileUploadHandlerEvent, 
    FileUploadModule }                      from "primeng/fileupload";
// Moon imports
import { PublicRouter }                     from "@moon-public/public.router";
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { TableColumn }                      from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }           from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }         from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }      from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MezzLoanFind }                     from "@moon-public/api/response/mezz-loan-find.response";
import { MezzLoanGet }                      from "@moon-public/api/response/mezz-loan-get.response";
import { ApiBlobResult, ApiResult }                        from "@app/moon-core/models/api-result";
import { ComponentUtilityService }          from "@app/moon-core/services/component-utility.service";
import { MoonMessageService }               from "@app/moon-shared/services/moon-message.service";
import { MezzLoanController }               from "@app/moon-public/api/mezz-loan.controller";
import { FieldValues }                      from "@app/moon-shared/constants/field-values";

@Component({
    selector: "mezz-loan-table",
    templateUrl: "./mezz-loan-table.component.html",
    styleUrls: ['./mezz-loan-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        TableModule, ButtonModule, InputTextModule, FileUploadModule,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [PublicRouter]
})

export class MezzLoanTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSMezzLoanList: MezzLoanFind[];
    @Input() public MSLoading = true;
    @Output() public MSEventReloadPage = new EventEmitter();
    @ViewChild("MSTableHeader") MSTableHeader: MoonTableHeaderComponent;

    public MSTableColumns: TableColumn[] = [
        { field: FieldNames.FileName.toCamelCase(), header: FieldNames.FileName.getDisplayName(), class: 'w-20per' },
        { field: FieldNames.ProcessingStatus.toCamelCase(), header: FieldNames.ProcessingStatus.getDisplayName(), class: 'w-20per' },
        { field: FieldNames.LogMessage.toCamelCase(), header: FieldNames.LogMessage.getDisplayName(), class: 'w-15per' },

        // Column PlaceHolder - DO NOT REMOVE
        { field: FieldNames.DownloadActions.toCamelCase(), header: FieldNames.DownloadActions.getDisplayName() },
        { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions.getDisplayName() }
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplayUpload: boolean = true;
    public MSAcceptedFileExtension: string = FieldValues.FileExtension_WORD;
    public MSUploading: boolean = false;

    @ViewChild("MSTable") MSTable: Table;

    constructor(
        private _publicRouter: PublicRouter,
        private _mezzLoanController: MezzLoanController,
        private _componentUtilityService: ComponentUtilityService,
        private _moonMessageService: MoonMessageService) { }

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: MezzLoanGet): void {
        this._publicRouter.RouteToMezzLoanChunk(item.mezzLoanID);
    }

    public OnClickCreate(): void {
        this._publicRouter.RouteToMezzLoanCreate();
    }

    public OnClickFind(): void {
        this._publicRouter.RouteToMezzLoan();
    }

    public async OnUploadFile(event: FileUploadHandlerEvent) {
        this.MSUploading = true;
        this._moonMessageService.toastInfo('Processing. Please wait...');

        if (event.files.length > 0 && event.files[0]) {
            try {
                const apiResult: ApiResult = await this._mezzLoanController.ImportAsync(event.files[0]);
                if (this._componentUtilityService.WasSuccessful(apiResult)) {
                    this._moonMessageService.toastSuccess('Mezz loan uploaded successfully.');
                }
            }
            finally {
                this.MSUploading = false;
                this.MSTableHeader.MSClearFile();
                this.MSEventReloadPage.emit();
            }
        }
    }

    public async OnClickRemergeDocument(item: MezzLoanGet) {
        this._moonMessageService.toastInfo('Processing. Please wait...');
        
        const apiResult = await this._mezzLoanController.Remerge(item.mezzLoanID);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess("Document Remerged Successfully.");
        }
    }

    public async OnClickDownloadOriginalDocument(item: MezzLoanGet) {
        this.doDownloadDocument(item.mezzLoanID, MezzLoanDocumentType.Original)
    }
    
    public OnClickDownloadRevisedDocument(item: MezzLoanGet) {
        this.doDownloadDocument(item.mezzLoanID, MezzLoanDocumentType.Revised)
    }

    public OnClickDownloadComparedDocument(item: MezzLoanGet) {
        this.doDownloadDocument(item.mezzLoanID, MezzLoanDocumentType.Compare)
    }

    private async doDownloadDocument(mezzLoanID: number, documentType: string) {
        this._moonMessageService.toastInfo('Downloading. Please wait...');

        const apiResult: ApiBlobResult = await this._mezzLoanController.GetBlobAsync(mezzLoanID, documentType);
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess("Download completed.");
        };
    }

    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }
}

export class MezzLoanDocumentType {
    public static Compare = 'Compare' as const;
    public static Original = 'Original' as const;
    public static Revised = 'Revised' as const;
}