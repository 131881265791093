<!-- Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRouteBackButton]="true"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                (MSOnClickEventRemove)="MSOnClickRemove()"
                (MSOnClickEventRouteBack)="MSOnClickRouteBack()">
    <ng-container *ngIf="!MSLoading">
        <node-environment-form #MSForm
                       [MSNodeEnvironmentID]="MSNodeEnvironmentID"
                       [MSNodeEnvironmentGet]="MSNodeEnvironmentGet">
        </node-environment-form>
    </ng-container>
</moon-edit-page>
