import { NgClass, NgIf, NgStyle }                   from '@angular/common';
import { Component, OnInit, ViewChild }             from "@angular/core";
import { ActivatedRoute }                           from "@angular/router";
// Third party imports
import { ConfirmationService, MenuItem }            from "primeng/api";
import { ButtonModule }                             from 'primeng/button';
import { ConfirmPopupModule }                       from 'primeng/confirmpopup';
import { DialogService }                            from "primeng/dynamicdialog";
import { ProgressBarModule }                        from 'primeng/progressbar';
import { RippleModule }                             from 'primeng/ripple';
import { TableModule }                              from 'primeng/table';
import { ToastModule }                              from "primeng/toast";
import { ToolbarModule }                            from 'primeng/toolbar';
import { TooltipModule }                            from 'primeng/tooltip';
// Moon Imports
import { AppBreadcrumbService }                     from "@app-layout/services/app.breadcrumb.service";
import { DocumentController }                       from "@moon-collaborator/api/document.controller";
import { FindDocumentParameter }                    from "@moon-collaborator/api/request/find-document.parameter";
import { DocumentFind }                             from "@moon-collaborator/api/response/document-find.response";
import { CollaboratorRouter }                       from "@moon-collaborator/collaborator.router";
import { DocumentBatchOperationTableComponent }     from '@moon-collaborator/document/document-batch-operation/document-batch-operation-table/document-batch-operation-table.component';
import { DocumentBatchProcessService }              from '@moon-collaborator/document/document-batch-operation/services/document-batch-process.service';
import { ApiContentResult }                         from "@moon-core/models/api-result";
import { ComponentUtilityService }                  from "@moon-core/services/component-utility.service";
import { MoonCustomDropdownComponent }              from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }                     from '@moon-shared/components/moon-loading/moon-loading.component';
import { SearchFieldComponent }                     from '@moon-shared/components/search-field/search-field.component';
import { MessageSeverity }                          from '@moon-shared/constants/message-severity';
import { RouteParameter }                           from "@moon-shared/constants/route-parameter";
import { MoonDialogService }                        from "@moon-shared/services/moon-dialog.service";
import { MoonMessageService }                       from '@moon-shared/services/moon-message.service';
import { RoutePath }                                from '@moon-shared/constants/route-path';

@Component({
    selector: "document-batch-operation",
    templateUrl: "./document-batch-operation.component.html",
    styleUrls: ["./document-batch-operation.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        NgStyle, NgClass, 
        ToastModule,
        ConfirmPopupModule,
        ToolbarModule,
        ButtonModule,
        RippleModule,
        TableModule,
        TooltipModule,
        ProgressBarModule,
        MoonLoadingComponent,
        DocumentBatchOperationTableComponent,
        SearchFieldComponent,
        MoonCustomDropdownComponent,
      ],
      providers: [
        DialogService,
        MoonDialogService,
        DocumentController,
        CollaboratorRouter,
        DocumentBatchProcessService,
    ],
})
export class DocumentBatchOperationComponent implements OnInit {
    public MSLoading: boolean;
    public MSItems: DocumentFind[];
    public MSEnvironmentName: string = String.empty;

    @ViewChild("MSTableComponent") MSTableComponent: DocumentBatchOperationTableComponent;

    constructor(

        private _activatedRoute: ActivatedRoute,
        private _appBreadcrumbService: AppBreadcrumbService,
        private _confirmationService: ConfirmationService,
        private _moonMessageService: MoonMessageService,
        private _documentBatchProcessService: DocumentBatchProcessService,
        private _componentUtilityService: ComponentUtilityService,
        private _documentController: DocumentController,    
        private _collaboratorRouter: CollaboratorRouter,
    ) {}

    ngOnInit() {
        this.readRouteParameters();
        this.setBreadCrumb();
        this.loadDocumentList();
    }
    private get _selectedItems() {
        return this.MSTableComponent.GetSelectedItems();
    }
    
    public get MSIsProcessInProgress(): boolean {
        return this._documentBatchProcessService.DisplayProgressBar;
    }

    public get MSDisableTable(): boolean {
        return false;
    }

    private readRouteParameters() {
        this.MSEnvironmentName = this._activatedRoute.snapshot.paramMap.get(RouteParameter.EnvironmentName) ?? String.empty;
    }
    
    public MSOnClickEdit() { 
  
    }

    public MSOnClickRemove(event: Event) {
        if (!this._selectedItems?.length) {
            this._moonMessageService.showToastMessage(MessageSeverity.Info, "Please Select Item.");
            return;
        }

        this._confirmationService.confirm({
            target: event.target as EventTarget,
            key: 'MSDeleteConfirm',
            message: `You are about to remove ${this._selectedItems.length} row(s) from the Document. Please confirm, this action cannot be reversed.`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this._documentBatchProcessService.Remove(
                    this.MSEnvironmentName,
                    this._selectedItems
                );
            },
        });
    }

    public async MSOnClickDownloadWord() {
        if (!this._selectedItems?.length) {
            this._moonMessageService.showToastMessage(MessageSeverity.Info, "Please Select Item.");
            return;
        }

        const documentIDList: Array<number> = [];
        this._selectedItems.forEach((a: DocumentFind) => {
            documentIDList.push(a.documentID);
        });

        this._documentBatchProcessService.DownloadWord(this.MSEnvironmentName, documentIDList);
    }

    private async loadDocumentList() {
        this.MSLoading = true;
    
        const findParameter = new FindDocumentParameter();
        const apiResult: ApiContentResult<DocumentFind[]> =
          await this._documentController.Find(
            this.MSEnvironmentName,
            findParameter
          );
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSItems = apiResult.content;
        }
        this.MSLoading = false;
      }
    

    private setBreadCrumb() {
        const editPageBreadcrumb: MenuItem[] = [
            { label: this.MSEnvironmentName, routerLink: [`${this._collaboratorRouter.EnvironmentRouteLink()}`] }, 
            { label: RoutePath.Documents, routerLink: this._collaboratorRouter.DocumentRouteLink(this.MSEnvironmentName) },
            { label: RoutePath.UpdateAll.getDisplayName(), routerLink: null }
        ];

        this._appBreadcrumbService.setItems(editPageBreadcrumb);    
    }
}
