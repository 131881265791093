import { SimilarDocumentChunk } from "@moon-public/clause-search/api/response/similar-document-chunk.response";

export class DocumentMetaData {
    documentName: string;
    categoryName: string;
    documentNumber: string;
    externalDocumentNumber: string;
    matterNumber: string;
    matterName: string;
    matterType: string;
    lawFirmName: string;
    externalLawFirmNumber: string;
    author: string;
    propertyState: string;
    propertyType: string;
    externalDateUtc: Date;
    constructor(chunk: SimilarDocumentChunk){
        this.lawFirmName = chunk.lawFirmName;
        this.author = chunk.author;
        this.propertyState = chunk.propertyState;
        this.matterName = chunk.matterName;
        this.propertyType = chunk.propertyType;
    }
}
