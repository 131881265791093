<div id="revise">
    <moon-loading *ngIf="MSLoading"></moon-loading>
    <div>
        <form
            *ngIf="!MSLoading"
            [formGroup]="MSReviseForm"
            class="mb-3"
        >
            <div class="card">
                <div class="grid gap-1 m-1">
                    <div class="p-fluid function-dropdown">
                        <moon-form-control
                            [MSInputSize]="12"
                            [MSFormControl]="MSReviseForm.controls.skillName"
                            [MSDisplayLabel]="false"
                            [MSLabel]="'Function'"
                        >
                            <p-dropdown
                                [options]="MSFunctions"
                                [formControl]="MSReviseForm.controls.skillName"
                            ></p-dropdown>
                        </moon-form-control>
                    </div>
                    <button
                        pButton
                        pRipple
                        class="p-button-raised moon-apply-revise-button"
                        label="Revise"
                        title="Revise"
                        (click)="MSApplyChat()"
                    >
                    </button>
                    <span *ngIf="MSReviseForm.value.isTest">Test Mode ON</span>
                </div>

            </div>
            <div class="grid">
                <div
                    id="revise-input-container"
                    class="col"
                >
                    <div class="card">
                        <!-- Text Mode Input -->
                        <ng-container>
                            <div class="moon-card-body grid">
                                <div class="col-12 inline-block">
                                    <moon-form-control
                                        [MSInputSize]="12"
                                        [MSFormControl]="MSReviseForm.controls.inputText"
                                        [MSDisplayLabel]="false"
                                        [MSLabel]="'Input Text'"
                                    >
                                        <span class="p-fluid relative">
                                            <textarea
                                                pInputTextarea
                                                placeholder="Write text"
                                                [formControl]="MSReviseForm.controls.inputText"
                                            ></textarea>
                                            <span class="token-detail">
                                                {{ MSReviseForm.value.inputToken }}
                                            </span>
                                        </span>
                                    </moon-form-control>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div
                    id="revise-output-container"
                    class="col"
                >
                    <div class="card moon-card relative">
                        <div class="absolute moon-output-helper-button">
                            <button
                                pButton
                                pRipple
                                class="p-button-raised moon-apply-revise-button"
                                icon="pi pi-copy"
                                pTooltip="Copy Output to Clipboard"
                                tooltipPosition="left"
                                (click)="MSCopyOutputToClipboard()"
                            >
                            </button>
                            <button
                                pButton
                                pRipple
                                class="p-button-raised moon-apply-revise-button"
                                icon="pi pi-refresh"
                                pTooltip="Clear Output"
                                tooltipPosition="left"
                                (click)="MSClearOutput()"
                            >
                            </button>
                        </div>
                        <div class="p-fluid">
                            <div
                                *ngIf="MSApplying; else skillOutput"
                                class="flex justify-content-center align-items-center h-30rem"
                            >
                                <p-progressSpinner
                                    aria-label="Loading"
                                    styleClass="w-4rem h-4rem"
                                    strokeWidth="4"
                                    animationDuration=".5s"
                                ></p-progressSpinner>
                            </div>
                            <ng-template #skillOutput>
                                <div class="revise-output-card grid gap-3">
                                    <div [ngClass]="MSCheckCondition() ? 'moon-empty-output' : 'moon-output'" class="col-12 white-space-pre-line overflow-y-auto">
                                        {{ MSSkillOutput }}
                                    </div>
                                    <div class="col-12 moon-empty-output white-space-pre-line overflow-y-auto">
                                        <h1 class="text-lg underline">Black Lines</h1>
                                        <ms-black-lines #blackLine
                                            *ngIf="MSSkillOutput"
                                            [MSOriginalText]="MSReviseForm.value.inputText ?? ''"
                                            [MSModifiedText]="MSSkillOutput"
                                        ></ms-black-lines>
                                    </div>
                            </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div
        *ngIf="MSElapsedTimeForSkillDuration"
        class="text-right text-600"
    >
        Response time: {{MSElapsedTimeForSkillDuration / 1000 | number: '1.0-3'}}s
    </div>
</div>