// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
import { 
    Component, EventEmitter, Input, OnInit,
    Output,
    ViewChild, ViewEncapsulation }              from "@angular/core";
import { 
    NgClass, NgFor, NgStyle,       
    NgSwitch, NgSwitchCase,     
    NgSwitchDefault }                           from "@angular/common";
// Third Party imports      
import { ButtonModule }                         from "primeng/button";
import { InputTextModule }                      from "primeng/inputtext";
import { Table, TableModule }                   from "primeng/table";
import { ProgressBarModule }                    from "primeng/progressbar";
import { DropdownChangeEvent, DropdownModule }  from "primeng/dropdown";
// Moon imports     
import { FieldNames }                           from '@moon-shared/constants/field-names';
import { TableColumn }                          from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }               from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }             from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }          from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { PublicRouter }                         from "@moon-public/public.router";
import { MezzLoanChunkFind }                    from "@moon-public/api/response/mezz-loan-chunk-find.response";
import { MezzLoanChunkGet }                     from "@moon-public/api/response/mezz-loan-chunk-get.response";
import { MezzLoanChunkBatchProcessService }     from "@moon-public/mezz-loan-chunk/services/mezz-loan-chunk-batch-process.service";
import { MoonTemplateDirective }                from "@moon-shared/directives/moon-template.directive";

@Component({
    selector: "mezz-loan-chunk-table",
    templateUrl: "./mezz-loan-chunk-table.component.html",
    styleUrls: ['./mezz-loan-chunk-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        TableModule, ButtonModule, InputTextModule,
        MoonTablePageComponent, MoonTableHeaderComponent,
        MoonCustomDropdownComponent, MoonTemplateDirective,
        ProgressBarModule, DropdownModule
    ],
    providers: []
})
export class MezzLoanChunkTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSMezzLoanChunkList: MezzLoanChunkFind[];
    @Input() public MSLoading: boolean = true;
    @Output() public MSEventReloadPage = new EventEmitter();
    public MSScrollHeight = "calc(100vh - 275px)";
    public MSSelectRowNumberOptions: string[] = [
        "Select Required Rows",
        SelectedNumberOfRows.AllRows,
        SelectedNumberOfRows.TwentyRows,
        SelectedNumberOfRows.FiftyRows,
        SelectedNumberOfRows.HundredRows,
        SelectedNumberOfRows.FiveHundredRows,
        SelectedNumberOfRows.ThousandRows,
    ];

    public MSTableColumns: TableColumn[] = [
        { field: FieldNames.Ordinal.toCamelCase(), header: FieldNames.Ordinal.getDisplayName(), class: 'w-5per' },
        { field: FieldNames.Content.toCamelCase(), header: FieldNames.Content.getDisplayName() },
        { field: FieldNames.TokenSize.toCamelCase(), header: FieldNames.TokenSize.getDisplayName(), class: 'w-10per' },
        { field: FieldNames.RevisionStatus.toCamelCase(), header: FieldNames.RevisionStatus.getDisplayName() },
        { field: FieldNames.RevisedContent.toCamelCase(), header: FieldNames.RevisedContent.getDisplayName() },
        // Column PlaceHolder - DO NOT REMOVE
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplayRouteBack: boolean;
    public MSSelectedItems: MezzLoanChunkGet[] = [];

    @ViewChild("MSTable") MSTable: Table;

    constructor(
        private _publicRouter: PublicRouter,
        private _mezzLoanChunkBatchProcessService: MezzLoanChunkBatchProcessService,
    ) { }

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = false;
        this.MSDisplayFind = false;
        this.MSDisplayRouteBack = true;
    }

    public get MSIsProcessInProgress(): boolean {
        return this._mezzLoanChunkBatchProcessService.DisplayProgressBar;
    }

    public MSOnSelectRowNumberChanged(event: DropdownChangeEvent) {
        let selectedNumberOfRows = event.value;
        switch (selectedNumberOfRows) {
            case SelectedNumberOfRows.AllRows:
                this.MSSelectedItems = this.MSMezzLoanChunkList.slice(0, this.MSMezzLoanChunkList.length).map(row => row);
                break;
            case SelectedNumberOfRows.TwentyRows:
                this.MSSelectedItems = this.MSMezzLoanChunkList.slice(0, 20).map(row => row);
                break;
            case SelectedNumberOfRows.FiftyRows:
                this.MSSelectedItems = this.MSMezzLoanChunkList.slice(0, 50).map(row => row);
                break;
            case SelectedNumberOfRows.HundredRows:
                this.MSSelectedItems = this.MSMezzLoanChunkList.slice(0, 100).map(row => row);
                break;
            case SelectedNumberOfRows.FiveHundredRows:
                this.MSSelectedItems = this.MSMezzLoanChunkList.slice(0, 500).map(row => row);
                break;
            case SelectedNumberOfRows.ThousandRows:
                this.MSSelectedItems = this.MSMezzLoanChunkList.slice(0, 1000).map(row => row);
                break;

            default: this.MSSelectedItems = [];
                break;
        }
    }

    public OnClickItem(item: MezzLoanChunkGet): void {
        console.log(item);
    }

    public MSOnClickGoBack(): void {
        this._publicRouter.RouteToMezzLoan();
    }

    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }

    public GetSelectedItems() {
        return this.MSSelectedItems;
    }
}

export class SelectedNumberOfRows {
    public static readonly AllRows = "Select All Rows" as const;
    public static readonly TwentyRows = "Select Top 20 Rows" as const;
    public static readonly FiftyRows = "Select Top 50 Rows" as const;
    public static readonly HundredRows = "Select Top 100 Rows" as const;
    public static readonly FiveHundredRows = "Select Top 500 Rows" as const;
    public static readonly ThousandRows = "Select Top 1000 Rows" as const;
}