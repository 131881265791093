import { Injectable }         from "@angular/core";
// MS Imports
import { ApiContentResult }   from '@moon-core/models/api-result';
import { MoonApiClient }      from '@moon-core/services/moon-api.client';
import { FullTextSearchParameter } from "@moon-public/api/request/full-text-search.parameter";
import { SearchResult }       from "@moon-public/clause-search/api/response/search-result.response";
import { ConstantString }     from '@moon-shared/constants/constant-string';
import { FindDocumentDbListResult } from "@app/moon-public/clause-search/api/response/find-document-db-list-result.response";
import { FindDistinctColumnDataDbListResult } from "@app/moon-public/clause-search/api/response/find-distinct-column-data-db-list-result.response";

@Injectable()
export class SearchController {
  private getBaseUrl(): string {
    return `${ConstantString.Version1}/Search`;
  };

  constructor(private _moonApi: MoonApiClient) { }

  /**
   * POST: /v1/Search/FullText
   */
  public async PostFullTextSearch(parameter: FullTextSearchParameter): Promise<ApiContentResult<SearchResult>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.FullText);
    return this._moonApi.postWithResultAsync(url, parameter);
  }

  /**
   * POST: /v1/Search/Find/AllDocument
   */
    public async FindAllDocument(environmentList: string[]): Promise<ApiContentResult<FindDocumentDbListResult[]>> {
      const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Find, ConstantString.AllDocument);
      return this._moonApi.postWithResultAsync(url, environmentList);
    }

    /**
    * POST: /v1/Search/Find/DistinctColumn/{columnName}
    */
     public async FindDistinctColumnData(environmentList: string[], columnName: string): Promise<ApiContentResult<FindDistinctColumnDataDbListResult[]>> {
       const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Find, 'DistinctColumn', columnName);
       return this._moonApi.postWithResultAsync(url, environmentList);
     }
}

