<!-- Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM -->
<moon-form-page
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    (MSOnClickEventSubmit)="OnClickSubmit()"
    (MSOnClickEventCancel)="OnClickCancel()"
>
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'Template Name'"
            [MSFormControl]="MSParentForm.controls.templateName"
        >
            <input
                type="text"
                formControlName="templateName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Template Type'"
            [MSFormControl]="MSParentForm.controls.templateType"
        >
            <p-dropdown
                [options]="MSTemplateTypeSelectItemList"
                formControlName="templateType"
            ></p-dropdown> 
            <!-- (onChange)="OnChangeTemplateType($event.value)" -->

        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Template Document'"
            [MSFormControl]="MSParentForm.controls.inputFile"
            [MSInputSize]="6"
        >
            <div>
                <div
                    class="col-12 h-full grid"
                    moonDragDrop
                    [allowDrop]="true"
                    allowMultiple="false"
                    [fileType]="['text/csv']"
                    (outputFileDropped)="MSOnFileDrop($event)"
                >
                    <ng-template #fileNotUploaded>
                        <div class="h-full flex flex-column gap-2 justify-content-center moon-file-drop-container">
                            <div>
                                <span class="pi pi-download mr-2"></span> Drop your file here or
                            </div>
                            <div>
                                <input
                                    #fileInput
                                    type="file"
                                    (change)="MSHandleFileInput(fileInput.files)"
                                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                            </div>
                        </div>
                    </ng-template>

                    <ng-container *ngIf="MSParentForm.controls.inputFile.value; else fileNotUploaded">
                        <div class="h-full flex justify-content-evenly align-items-center">
                            <div class="line-break-anywhere">
                                {{ MSParentForm.controls.inputFile.value.name }} -
                                {{
                                MSParentForm.controls.inputFile.value.size / 1024
                                | number : "1.0-2"
                                }}
                                kb
                            </div>
                            <div>
                                <button
                                    pButton
                                    pRipple
                                    icon="pi pi-times"
                                    class="p-button-raised moon-apply-extract-button ml-2"
                                    label="Clear File"
                                    title="Clear File"
                                    (click)="MSClearSelectedFile()"
                                ></button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!MSParentForm.controls.inputFile.value && MSTemplateGet?.blobID">
                        <div class="h-full flex justify-content-evenly align-items-center">
                            <div class="moon-file-name">
                                {{ MSTemplateGet.templateFileName }}
                            </div>
                            <div>
                                <button
                                pButton
                                pRipple
                                pTooltip="Download Template"
                                tooltipPosition="bottom"
                                type="button"
                                icon="pi pi-file-word"
                                (click)="MSOnClickDownloadFile()"
                                class="p-button-raised moon-apply-extract-button"
                              ></button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </moon-form-control>

    </div>
</moon-form-page>