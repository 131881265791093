import { Component, OnInit }        from '@angular/core';
import { CommonModule }             from '@angular/common';
//Third Party Imports
import {TableModule}                from 'primeng/table';
import {DynamicDialogConfig}        from 'primeng/dynamicdialog';
import { ButtonModule }             from 'primeng/button';
import { TooltipModule }            from 'primeng/tooltip';
import { FieldsetModule }           from 'primeng/fieldset';
// Moon imports
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import {FieldNames}                 from '@moon-shared/constants/field-names';
import {TableColumn}                from '@moon-shared/types/table-column.type';
import {SearchLogJson}              from '@moon-maintainer/models/search-log-json.model';
import { DocumentController }       from '@moon-collaborator/api/document.controller';
import {DocumentChunkGet}           from '@moon-collaborator/api/response/document-chunk-get.response';
import {BlackLinesComponent}        from '@moon-shared/components/black-lines/black-lines.component';
import {MoonTruncatePipe}           from '@moon-shared/pipes/moon-truncate.pipe';

@Component({
  selector: 'search-log-dialog',
  standalone: true,
  imports: [CommonModule, TableModule, ButtonModule, TooltipModule, FieldsetModule, 
            BlackLinesComponent, MoonTruncatePipe],
  providers: [DocumentController],
  templateUrl: './search-log-dialog.component.html',
  styleUrls: ['./search-log-dialog.component.scss'],
})
export class SearchLogDialogComponent implements OnInit {
  public MSTableColumns: TableColumn[] = [
    {
      field: FieldNames.EnvironmentName,
      header: FieldNames.EnvironmentName.getDisplayName(),
      class: 'w-600per',
    },
    {
      field: FieldNames.Success,
      header: FieldNames.Success.getDisplayName(),
      class: 'w-10per',
    },
    {
      field: FieldNames.Count,
      header: FieldNames.Count.getDisplayName(),
      class: 'w-15per',
    },
    {
      field: FieldNames.DurationMs,
      header: FieldNames.DurationMs.getDisplayName(),
      class: 'w-15per',
    },
    {
      field: FieldNames.SqlMessage,
      header: FieldNames.SqlMessage.getDisplayName(),
      class: 'w-15per',
    },
    {
      field: FieldNames.SimilarChunks,
      header: FieldNames.SimilarChunks.getDisplayName(),
      class: 'w-15per',
    },
  ];
  public MSSearchResultJson: SearchLogJson[];
  public MSDocumentChunk: DocumentChunkGet;
  public MSDisplayChunk: boolean = false;
  public MSTableLoading: boolean = false;
  public MSChunkEnvironment: string;
  public MSChunkRelevance: number;
  public MSInputData: string;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _documentController: DocumentController,
    private _componentUtilityService: ComponentUtilityService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  public async MSOnClickDocumentChunkID(
    environment: string,
    documentChunkID: number,
    relevance: number
  ): Promise<void> {
    this.MSTableLoading = true;
    this.MSChunkEnvironment = environment;
    this.MSChunkRelevance = relevance;
    try {
      const apiResult: ApiContentResult<DocumentChunkGet> =
        await this._documentController.GetDocumentChunkAsync(
          environment,
          documentChunkID
        );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSDocumentChunk = apiResult.content;
        this.MSDisplayChunk = true;
      }
    } finally {
      this.MSTableLoading = false;
    }
  }

  public MSOnClickCloseButton(): void {
    this.MSDisplayChunk = !this.MSDisplayChunk;
  }

  private getData(): void {
    this.MSSearchResultJson = JSON.parse(this._dialogConfig.data.SearchResultJson);
    this.MSInputData = this._dialogConfig.data.InputContent;
  }

}
