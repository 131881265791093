<div class="pages-body notfound-page flex flex-column">
    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>SESSION TERMINATED</h2>
            </div>
            <div class="pages-detail pb-6 moon-message">
                You have signed out of the application.
                <br />
                <br />
                Please close all the open Moonstone Applications in this browser.
            </div>
            <p-button
                class="moon-login-button mb:col-2-px-3"
                (onClick)="MSOnClickGoBackToLogin()"
                label="GO TO LOGIN PAGE"
            ></p-button>
        </div>
    </div>
</div>
