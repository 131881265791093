<div class="layout-breadcrumb-container ms-new">
    <!-- <div
    class="layout-breadcrumb-container flex justify-content-between align-items-center shadow-1 moon-breadcrumb"
> -->
    <p-breadcrumb
        [model]="items"
        [home]="home"
        styleClass="layout-breadcrumb pl-4 py-2"
    ></p-breadcrumb>

    <div>
        <button
            *ngIf="MSDisplayButton()"
            pButton
            class="p-button-secondary shadow-1"
            icon="pi pi-history"
            (click)="OnClickHistory()"
        ></button>
    </div>
</div>