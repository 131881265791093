import { KeyValuePipe, NgFor, NgIf }                from '@angular/common';
import { Component, EventEmitter, Input, Output }   from '@angular/core';
import { FormsModule, ReactiveFormsModule }         from '@angular/forms';
// Third party imports
import { ButtonModule }                             from 'primeng/button';
import { CheckboxModule }                           from 'primeng/checkbox';
import { RippleModule }                             from 'primeng/ripple';
import { SliderModule }                             from 'primeng/slider';
// Moon Imports
import { FullTextSearchResultFilter }               from '@app/moon-public/full-text-search/models/full-text-search-result-filter';
import { MoonFormControlComponent }                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { FilterItemComponent }                      from '@app/moon-public/clause-search/filter-result/filter-item/filter-item.component';

@Component({
    selector: 'ms-full-text-filter-result',
    templateUrl: './full-text-filter-result.component.html',
    styleUrls: ['./full-text-filter-result.component.scss'],
    standalone: true,
    imports: [
      NgIf, ButtonModule, RippleModule, NgFor,
      CheckboxModule, FormsModule, ReactiveFormsModule,
      MoonFormControlComponent, SliderModule, KeyValuePipe,
      FilterItemComponent
    ]})
export class FullTextFilterResultComponent {

  @Input() public MSResultFilter: FullTextSearchResultFilter;
  @Output() public MSResultFilterChangedEvent = new EventEmitter<FullTextSearchResultFilter>();
  @Output() public MSResultFilterClearedEvent = new EventEmitter<undefined>();

  public MSOnFilterChanged(): void {
    this.MSResultFilterChangedEvent.emit(this.MSResultFilter);
  }
  public MSOnFilterCleared(): void {
    this.MSResultFilterClearedEvent.emit();
  }
}
