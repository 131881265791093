import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRouter } from '@app/app.router';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'moon-post-logout',
    templateUrl: './moon-post-logout.component.html',
    styleUrls: ['./moon-post-logout.component.scss'],
    standalone: true,
    imports: [ButtonModule, RouterLink]
})
export class MoonPostLogoutComponent {

  constructor(private _appRouter: AppRouter) { }

  public MSOnClickGoBackToLogin(){
    this._appRouter.RouteToLogin();
  }
}
