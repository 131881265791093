import { AsyncPipe, NgClass, NgFor, NgIf }     from '@angular/common'
import { ChangeDetectorRef, Component, OnInit }            from '@angular/core'
import {
    FormControl,
    FormGroup, FormsModule,
    ReactiveFormsModule,
    Validators
}                                       from '@angular/forms'
// Third party imports
import { AppBreadcrumbService }         from '@app-layout/services/app.breadcrumb.service'
import { ButtonModule }                 from 'primeng/button'
import { DividerModule }                from 'primeng/divider'
import { InputNumberModule }            from "primeng/inputnumber"
import { InputSwitchModule }            from 'primeng/inputswitch'
import { InputTextareaModule }          from 'primeng/inputtextarea'
import { MultiSelectModule }            from 'primeng/multiselect'
import { RippleModule }                 from 'primeng/ripple'
import { SelectItem }                   from 'primeng/api'
import { PanelModule }                  from 'primeng/panel';
import { InputTextModule }              from 'primeng/inputtext'
import { CheckboxChangeEvent, CheckboxModule }  from 'primeng/checkbox'
import { DropdownModule }               from 'primeng/dropdown'
import { AutoCompleteModule }           from 'primeng/autocomplete'
import { ProgressSpinnerModule }        from 'primeng/progressspinner'
import { TagModule }                    from 'primeng/tag'
// MS Imports
import { DocumentCategoryController }   from '@moon-collaborator/api/document-category.controller'
import { DocumentController }           from '@moon-collaborator/api/document.controller'
import { EnvironmentApiService }        from '@moon-core/api/environment-api.service'
import { EnvironmentGet }               from '@moon-core/api/response/environment-get.response'
import { ApiContentResult }             from '@moon-core/models/api-result'
import { UserSessionService }           from '@moon-core/services/user-session.service'
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service'
import { FullTextSearchParameter }      from '@moon-public/api/request/full-text-search.parameter'
import { DbListResult }                 from '@moon-public/clause-search/api/response/db-list-result.response'
import { SearchResult }                 from '@moon-public/clause-search/api/response/search-result.response'
import { SearchController }             from '@moon-public/api/search.controller'
import { FullTextSearchParameterForm }  from '@moon-public/full-text-search/models/full-text-search-parameter.form'
import { FullTextSearchResultFilter }   from '@moon-public/full-text-search/models/full-text-search-result-filter'
import { SimilarChunk }                 from '@moon-public/clause-search/models/similar-chunk'
import { FullTextSearchDataViewComponent } from '@moon-public/full-text-search/full-text-search-data-view/full-text-search-data-view.component'
import { MoonFormControlComponent }     from '@moon-shared/components/moon-form-control/moon-form-control.component'
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component'
import { MessageSeverity }              from '@moon-shared/constants/message-severity'
import { MoonMessageService }           from '@moon-shared/services/moon-message.service'
import { PerformanceTimer }             from '@moon-shared/helper/performance-timer/performance-timer'
import { FieldNames }                   from '@moon-shared/constants/field-names'
import { SimilarDocumentChunk }         from '@moon-public/clause-search/api/response/similar-document-chunk.response'
import { BlackLinesMode }               from '@moon-shared/types/black-lines-algorithm.type'
import { DMP_IsExactMatch }             from '@moon-shared/helper/diffmatchpatch/diffmatchpatch'
import { FieldValues }                  from '@moon-shared/constants/field-values';
import { SameDocumentChunk }            from '@app/moon-public/full-text-search/models/same-document-chunk'

export type FullTextSearchFormType = FormGroup<FullTextSearchParameterForm>;

@Component({
    selector: 'ms-full-text-search',
    templateUrl: 'full-text-search.component.html',
    styleUrls: ['full-text-search.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe, MoonFormControlComponent, MoonLoadingComponent, NgClass, NgIf,
        FullTextSearchDataViewComponent, ButtonModule, DividerModule, FormsModule, InputNumberModule, InputSwitchModule,
        InputTextareaModule, MultiSelectModule, ReactiveFormsModule, RippleModule,
        InputTextModule, DropdownModule, AutoCompleteModule,
        PanelModule, CheckboxModule, ProgressSpinnerModule, NgFor, TagModule
    ],
    providers: [SearchController, DocumentCategoryController, DocumentController]
})
export class FullTextSearchComponent implements OnInit {
    public MSDefaultBlacklineAlgorithm: BlackLinesMode = "Word Mode";
    public MSEnvironmentList: EnvironmentGet[] = [];
    public MSSearchChunkList: SimilarChunk[] = [];
    public MSSearchResultFilter: FullTextSearchResultFilter;
    public MSShowAdvanceSettings: boolean = false;
    public MSShowDocumentResultSection: boolean = false
    public MSTimeElapsedForResult: number | null = null;
    public MSLoading: boolean = true;
    public MSLoadingMessage: string = 'Loading...';
    // public MSLoadingDocumentName: boolean = true;
    public MSCollapseSearch: boolean = false;
    public MSTotalExactMatch: number = 0;
    public MSOriginalSearchText: string;
    public MSMatterNameList: SelectItem[] = []
    public MSLawFirmNameList: SelectItem[] = []
    public MSAuthorNameList: SelectItem[] = []
    public MSPropertyStateList: SelectItem[] = []
    public MSDocumentNameList: SelectItem[] = []
    public MSFilterDocumentNameList: SelectItem[] = []
    public MSSearchDocumentList: SameDocumentChunk[] = [];
    public MSFreeTextSearched: string = String.empty;
    public MSContentSearchList: string[] = [];

    public MSSearchForm: FullTextSearchFormType = new FormGroup<FullTextSearchParameterForm>({
        environmentList: new FormControl<string[]>([], { nonNullable: true }),
        containsSearch: new FormControl<string | null>(null, { validators: Validators.required }),
        containsSearch1: new FormControl<string | null>(null),
        containsSearch2: new FormControl<string | null>(null),
        containsSearch3: new FormControl<string | null>(null),
        containsSearch4: new FormControl<string | null>(null),
        containsSearch5: new FormControl<string | null>(null),
        freeTextSearch: new FormControl<string | null>(null),
        documentName: new FormControl<string | null>(null),
        documentDateFrom: new FormControl<Date | null>(null),
        documentDateTo: new FormControl<Date | null>(null),
        lawFirmName: new FormControl<string | null>(null),
        author: new FormControl<string | null>(null),
        propertyState: new FormControl<string | null>(null),
        matterName: new FormControl<string | null>(null),
        includeExactMatches: new FormControl(false, { nonNullable: true }),
        searchWithMeaning: new FormControl(false, { nonNullable: true }),
        document: new FormControl<SelectItem | null>(null),
        // containsSearchList: new FormControl<string[] | null>(null),
    });

    public MSIncludeExactMatches: boolean = false;

    public MSDisplayUseMeaning: boolean = false;
    constructor(
        private _appBreadcrumbService: AppBreadcrumbService,
        private _componentUtilityService: ComponentUtilityService,
        // private _documentCategoryController: DocumentCategoryController,
        private _environmentApiService: EnvironmentApiService,
        private _searchApiService: SearchController,
        private _moonMessageService: MoonMessageService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _userSessionService: UserSessionService
    ) { }

    async ngOnInit() {
        this.setDefaultValue();
        await this.loadEnvironmentList();
        this.setBreadcrumb();
    }

    public MSTriggerExpandCollapse(): void {
        this.MSCollapseSearch = !this.MSCollapseSearch;
    }
    private setBreadcrumb() {
        this._appBreadcrumbService.setItems([
            { label: "Full Text Search", routerLink: null },
        ]);
    }

    private setDefaultValue(): void {
        this.MSDisplayUseMeaning = this._userSessionService.GetCurrentUserSession.userType === FieldValues.MST;
    }

    private async loadEnvironmentList() {
        const apiResult: ApiContentResult<EnvironmentGet[]> = await this._environmentApiService.GetEnvironmentList();
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            this.MSEnvironmentList = apiResult.content;
            this.setEnvironmentIfSingle();
            // await this.setSearchFieldDropdown();
            this.MSLoading = false;
        };
    }

    // private async setSearchFieldDropdown() {
    //     let environmentList: string[] = [];
    //     const selectedEnvironmentNameList: string[] = this.MSSearchForm.getRawValue().environmentList;

    //     if (selectedEnvironmentNameList.length)
    //         environmentList = selectedEnvironmentNameList;
    //     else
    //         environmentList = this.MSEnvironmentList.map(env => env.environmentName);

    //     this.loadDocumentlist(environmentList);
    // }

    // private async loadDocumentlist(environmentList: string[]) {
    //     this.MSLoadingDocumentName = true;
    //     const apiResult: ApiContentResult<FindDocumentDbListResult[]> = await this._searchApiService.FindAllDocument(environmentList);
    //     if (this._componentUtilityService.WasSuccessful(apiResult)) {
    //         this.manageDocumentDropdownData(apiResult);
    //         this.MSLoadingDocumentName = false;
    //     }
    // }

    // private manageDocumentDropdownData(apiResult: ApiContentResult<FindDocumentDbListResult[]>) {
    //     let documentMetadataList: DocumentFind[] = [];
    //     const arrayResult = apiResult.content;
    //     arrayResult.forEach((result: FindDocumentDbListResult) => {
    //         documentMetadataList.push(...result.content)
    //     });

    //     this.setDocumentDropdown(documentMetadataList)
    // }

    private setEnvironmentIfSingle() {
        if (this.MSEnvironmentList && this.MSEnvironmentList.length === 1) {
            let environment: EnvironmentGet | undefined = this.MSEnvironmentList[0];

            if (environment !== undefined) {
                const environmentNameArray: string[] = [environment.environmentName];
                this.MSSearchForm.controls.environmentList.setValue(environmentNameArray);
            } else {
                this._moonMessageService.showToastMessage(MessageSeverity.Info, "Environment is undefined");
            }
        }
    }

    public async MSOnClickSearch() {
        this.doCheckAndSetContentSearchValue();
        this.doCheckAndResetFieldsAndValidators();

        this.MSSearchForm.markAllAsTouched();
        if (this.MSSearchForm.invalid)
            return;

        this.MSSearchChunkList = [];
        this.MSLoading = true;
        const searchForm: FullTextSearchParameter = this.MSSearchForm.getRawValue();
        this.setContentSearchSQLFormat(searchForm);
        const performanceTimer: PerformanceTimer = PerformanceTimer.start();
        if (this.MSLoading) {
            const apiResult: ApiContentResult<SearchResult> =
                await this._searchApiService.PostFullTextSearch(searchForm);
            if (this._componentUtilityService.WasSuccessful(apiResult)) {
                const searchResult: SearchResult = apiResult.content;
                this.processSearchResult(searchResult.searchResults, this.MSSearchForm);
            }
        }
        this.MSTimeElapsedForResult = performanceTimer.getElapsedTime();
        this.MSShowDocumentResultSection = true;
        this.MSLoading = false;
        this.MSCollapseSearch = true;
    }

    private doCheckAndSetContentSearchValue() {
        const contentSearch = this.MSSearchForm.getRawValue().containsSearch;
        if (this.MSContentSearchList.length == 0 && contentSearch != null && contentSearch != String.empty) {
            this.MSContentSearchList.push(contentSearch);
        }
    }

    public processSearchResult(searchResults: DbListResult[], searchForm: FullTextSearchFormType) {
        this.MSLoadingMessage = 'Processing result';
        this._changeDetectorRef.detectChanges();

        this.MSSearchResultFilter = new FullTextSearchResultFilter();
        this.MSTotalExactMatch = 0;
        this.MSIncludeExactMatches = this.MSSearchForm.getRawValue().includeExactMatches;

        for (const dbResult of searchResults) {

            if (dbResult.success) {
                const matches: SimilarChunk[] = dbResult.content
                    .map(chunk => {
                        const item = new SimilarChunk(chunk);
                        item.environmentName = dbResult.environmentName;
                        item.exactMatch = this.isExactMatch(chunk, searchForm);

                        if (item.exactMatch)
                            this.MSTotalExactMatch++;
                        if (this.MSIncludeExactMatches || !item.exactMatch)
                            this.MSSearchResultFilter.MSAddSimilarChunkToFilter(item);
                        return item;
                    });
                this.addAllMatchesToResult(matches);
            }
            else {
                this._moonMessageService.showMessages(MessageSeverity.Error, dbResult.message);
            }
        }
        this.MSSearchChunkList.orderByDescending(FieldNames.savedAsFavorite, FieldNames.relevance);
        this.MSFreeTextSearched = searchForm.value.freeTextSearch as string;
        this.MSSearchDocumentList = this.groupSimilarDocumentChunk(this.MSSearchChunkList, this.MSContentSearchList as string[]); // searchForm.value.containsSearchList 
    }

    // public async MSOnEnvironmentsChanged() {
    //     await this.setSearchFieldDropdown();
    // }

    private doCheckAndResetFieldsAndValidators() {
        const searchWithMeaning: boolean = this.MSSearchForm.getRawValue().searchWithMeaning;
        if (searchWithMeaning) {
            // this.MSSearchForm.controls.containsSearchList.setValue(null)
            this.MSSearchForm.controls.containsSearch.setValue(null)
            this.MSSearchForm.controls.containsSearch1.setValue(null)
            this.MSSearchForm.controls.containsSearch2.setValue(null)
            this.MSSearchForm.controls.containsSearch2.setValue(null)
            this.MSSearchForm.controls.containsSearch3.setValue(null)
            this.MSSearchForm.controls.containsSearch4.setValue(null)
            this.MSSearchForm.controls.containsSearch5.setValue(null)
        } else {
            this.MSSearchForm.controls.freeTextSearch.setValue(null);
            this.MSSearchForm.controls.containsSearch.setValue(null);
            this.MSSearchForm.controls.containsSearch1.setValue(this.searchTextSQLFormat(this.MSContentSearchList[0] ?? null));
            this.MSSearchForm.controls.containsSearch2.setValue(this.searchTextSQLFormat(this.MSContentSearchList[1] ?? null));
            this.MSSearchForm.controls.containsSearch3.setValue(this.searchTextSQLFormat(this.MSContentSearchList[2] ?? null));
            this.MSSearchForm.controls.containsSearch4.setValue(this.searchTextSQLFormat(this.MSContentSearchList[3] ?? null));
            this.MSSearchForm.controls.containsSearch5.setValue(this.searchTextSQLFormat(this.MSContentSearchList[4] ?? null));
        }

        if (!this.MSContentSearchList.length && !searchWithMeaning) {
            this.updateContainsSearchValidators(true);
        } else {
            this.updateContainsSearchValidators(false);
        }
    }

    private setContentSearchSQLFormat(searchForm: FullTextSearchParameter) {
        let freeTextSearch = searchForm.freeTextSearch;
        if (freeTextSearch) searchForm.freeTextSearch = '"' + freeTextSearch + '"';
    }

    private searchTextSQLFormat(searchText: string | null): string | null {
        if (!searchText) return null;

        const wordCount: number = searchText.trim().split(/\s+/).length;
        if (wordCount > 1) searchText = '"' + searchText + '"';

        return searchText;
    }

    private addAllMatchesToResult(matches: SimilarChunk[]) {
        if (this.MSIncludeExactMatches) {
            this.MSSearchChunkList.push(...matches)
        }
        else {
            this.MSSearchChunkList.push(...matches.filter(similarChunk => !similarChunk.exactMatch));
        }
    }

    private isExactMatch(chunk: SimilarDocumentChunk, searchForm: FullTextSearchFormType) {
        this.MSOriginalSearchText = (searchForm.value.containsSearch ?? searchForm.value.freeTextSearch) ?? String.empty;
        if (chunk.relevance > 0.97) {
            return DMP_IsExactMatch(this.MSOriginalSearchText ?? String.empty, chunk.content, this.MSDefaultBlacklineAlgorithm)
        }
        return false;
    }

    public MSOnSearchWithMeaningChanged(event: CheckboxChangeEvent) {
        if (event.checked) {
            this.MSSearchForm.controls.freeTextSearch.setValue(this.MSSearchForm.controls.containsSearch.value)

            this.MSSearchForm.controls.containsSearch.reset();
            this.MSSearchForm.controls.containsSearch.clearValidators();

        } else {
            this.MSSearchForm.controls.containsSearch.reset();
            // this.MSSearchForm.controls.containsSearch.addValidators([Validators.required, Validators.minLength(1)]);

            this.MSSearchForm.controls.containsSearch.setValue(this.MSSearchForm.controls.freeTextSearch.value)
        }

        this.MSSearchForm.controls.searchWithMeaning.setValue(event.checked);
    }

    // private setDocumentDropdown(documentMetadataList: DocumentFind[]) {
    //     this.MSDocumentNameList = [{ label: '', value: null }]
    //     const distinctDocumentNames = Array.from(new Set(documentMetadataList.map(obj => obj.documentName))).filter(document => document.trim() !== "");
    //     const documentNameList = distinctDocumentNames.map(documentName => ({ label: documentName, value: documentName }));
    //     this.MSDocumentNameList.push(...documentNameList);
    //     this.MSFilterDocumentNameList = this.MSDocumentNameList;
    // }

    // public MSSetCategory() {
    //     const selectedValue =
    //         this.MSSearchForm.controls.document.value;
    //     if (typeof selectedValue === "object" && selectedValue) {
    //         this.MSSearchForm.controls.document.setValue(
    //             selectedValue.value
    //         );
    //     } else {
    //         this.MSSearchForm.controls.document.setValue(selectedValue);
    //     }
    // }

    // public MSFindCategory(event: any) {
    //     this.MSFilterDocumentNameList = this.MSDocumentNameList.filter((p: any) => {
    //         return (
    //             p.label
    //                 .toLocaleLowerCase()
    //                 .indexOf(event.query.toLocaleLowerCase()) !== -1
    //         );
    //     });
    // }

    private groupSimilarDocumentChunk(matches: SimilarChunk[], searchedTextList: string[]) {
        let searchDocumentResult: SameDocumentChunk[] = [];
        let _docArrayMap: { [key: string]: SimilarChunk[] } | any = {};

        matches.forEach(item => {
            if (!_docArrayMap[item.documentID]) {
                _docArrayMap[item.documentID] = [];
            }
            _docArrayMap[item.documentID].push(item);
        });

        Object.keys(_docArrayMap).forEach(key => {
            let chunkList = _docArrayMap[key];
            const initialDocument: SameDocumentChunk = this.getSameDocumentChunk(_docArrayMap[key][0]);

            let countNumberOfSearchText: number = 0;
            chunkList.forEach((chunk: SimilarChunk) => {
                initialDocument.noOfMatchingSearchText += this.getMatchingNumberOfSearchedText(countNumberOfSearchText, searchedTextList, chunk.content);
                initialDocument.documentChunkList.push({ documentChunkID: chunk.documentChunkID, content: chunk.content })
            });

            searchDocumentResult.push(initialDocument);
        });
        return searchDocumentResult;
    }

    private getMatchingNumberOfSearchedText(countNumber: number, searchedTextList: string[], chunkContent: string) {
        if (!searchedTextList || searchedTextList.length === 0) {
            return 0;
        }

        const escapedSearchTerms = searchedTextList.map(term => term.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'));
        const joinedSearchTerms = escapedSearchTerms.join('|');
        const regex = new RegExp(`\\b(${joinedSearchTerms})\\b`, 'gi');

        const matches = chunkContent.match(regex);

        if (matches !== null) {
            countNumber = matches.length;
        }

        return countNumber;
    }

    private getSameDocumentChunk(similarChunk: SimilarChunk): SameDocumentChunk {
        return {
            categoryName: similarChunk.categoryName,
            documentChunkList: [],
            documentID: similarChunk.documentID,
            documentChunkID: similarChunk.documentChunkID,
            documentName: similarChunk.documentName,
            environmentName: similarChunk.environmentName,
            content: similarChunk.content,
            ordinal: similarChunk.ordinal,
            year: similarChunk.year,
            documentMetaData: similarChunk.documentMetaData,
            totalMatchingChunk: 0,
            hideInResult: false,
            matchingDocuments: [],
            noOfMatchingSearchText: 0,
        }
    }

    public MSOnEnterClickContainsSearch() {
        let searchedInputText: string | null = this.MSSearchForm.getRawValue().containsSearch;

        if (searchedInputText) {
            if (this.MSContentSearchList.length > 4) {
                this._moonMessageService.showToastMessage(MessageSeverity.Info, 'Search Text exceed the maximum search of 5 searches.');
                return;
            }

            if (!this.MSContentSearchList.some(item => item.toLowerCase() === searchedInputText?.toLowerCase())) {
                this.MSContentSearchList.push(searchedInputText);
                this.MSSearchForm.controls.containsSearch.setValue(null);
                this.updateContainsSearchValidators(false);
            } else {
                this._moonMessageService.showToastMessage(MessageSeverity.Info, 'String already exists to search.');
            }
        }
    }

    public MSClearSearchText(searchInputText: string) {
        this.MSContentSearchList = this.MSContentSearchList.filter(item => item !== searchInputText);

        if (!this.MSContentSearchList.length) {
            this.updateContainsSearchValidators(true);
        }
    }

    private updateContainsSearchValidators(enableValidation: boolean) {
        if (enableValidation) {
            this.MSSearchForm.controls.containsSearch.reset();
            this.MSSearchForm.controls.containsSearch.addValidators([Validators.required, Validators.minLength(1)]);
            this.MSSearchForm.controls.containsSearch.updateValueAndValidity();
        } else {
            this.MSSearchForm.controls.containsSearch.clearValidators();
            this.MSSearchForm.controls.containsSearch.updateValueAndValidity();
        }
    }

    public MSClearSearchTextbox() {
        this.MSSearchForm.controls.containsSearch.setValue(null);
    }

    public get MSSearchWithMeaning(): boolean {
        return this.MSSearchForm.getRawValue().searchWithMeaning;
    }
}