<!-- Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM -->
<div class="moon-copy">
    <span class="ms-label-token">Total Tokens: {{ MSTotalTokens }}</span>
    <button
        *ngIf="MSSkillID"
        pButton
        pRipple
        type="button"
        (click)="OnShowExamplesClick()"
        pTooltip="Show Skill Examples"
        tooltipPosition="top"
        icon="pi pi-list"
    ></button>

    <button
        pButton
        pRipple
        class="p-button-raised"
        icon="pi pi-copy"
        pTooltip="Copy System Message to Clipboard"
        tooltipPosition="left"
        (click)="OnClickCopyMessage($event)"
    ></button>
</div>
<moon-form-page
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    (MSOnClickEventSubmit)="OnClickSubmit()"
    (MSOnClickEventCancel)="OnClickCancel()"
>
    <div
        [formGroup]="MSParentForm"
        class="grid col-12"
    >
        <div class="col-4">
            <moon-form-control
                [MSLabel]="'Skill Name'"
                [MSFormControl]="MSParentForm.controls.skillName"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="text"
                    formControlName="skillName"
                    pInputText
                />
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Notes'"
                [MSFormControl]="MSParentForm.controls.notes"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                    <textarea
                        id="notes"
                        rows="4"
                        pInputTextarea
                        [formControl]="MSParentForm.controls.notes"
                    ></textarea>
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Skill Type'"
                [MSFormControl]="MSParentForm.controls.skillType"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="text"
                    formControlName="skillType"
                    pInputText
                />
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Skill Sub-Type'"
                [MSFormControl]="MSParentForm.controls.skillSubType"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="text"
                    formControlName="skillSubType"
                    pInputText
                />
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Skill Status'"
                [MSFormControl]="MSParentForm.controls.skillStatus"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="text"
                    formControlName="skillStatus"
                    pInputText
                />
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Model Type'"
                [MSFormControl]="MSParentForm.controls.modelType"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="text"
                    formControlName="modelType"
                    pInputText
                />
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Max Output Tokens'"
                [MSFormControl]="MSParentForm.controls.maxOutputTokens"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="number"
                    formControlName="maxOutputTokens"
                    pInputText
                />
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Stop Sequence'"
                [MSFormControl]="MSParentForm.controls.stopSequence"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="text"
                    formControlName="stopSequence"
                    pInputText
                />
            </moon-form-control>

            <p-fieldset legend="Controls">
                <moon-form-control
                    [MSLabel]="'Temperature'"
                    [MSFormControl]="MSParentForm.controls.temperature"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="1"
                            [step]="0.025"
                            formControlName="temperature"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('temperature', $event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            #Temp
                            class="w-8rem"
                            type="number"
                            formControlName="temperature"
                            [step]="0.1"
                            [min]="0"
                            [max]="1"
                            (onInput)="MSSetFormControlValue('temperature', $event.value)"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Top P'"
                    [MSFormControl]="MSParentForm.controls.topP"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="1"
                            [step]="0.025"
                            formControlName="topP"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('topP', $event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            class="w-8rem"
                            type="number"
                            formControlName="topP"
                            [step]="0.1"
                            [min]="0"
                            [max]="1"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                            (onInput)="MSSetFormControlValue('topP', $event.value)"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Frequency Penalty'"
                    [MSFormControl]="MSParentForm.controls.frequencyPenalty"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="2"
                            [step]="0.025"
                            formControlName="frequencyPenalty"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('frequencyPenalty',$event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            class="w-8rem"
                            type="number"
                            formControlName="frequencyPenalty"
                            [step]="0.1"
                            [min]="0"
                            [max]="2"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                            (onInput)="MSSetFormControlValue('frequencyPenalty', $event.value)"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Presence Penalty'"
                    [MSFormControl]="MSParentForm.controls.presencePenalty"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="2"
                            [step]="0.025"
                            formControlName="presencePenalty"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('presencePenalty',$event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            class="w-8rem"
                            type="number"
                            formControlName="presencePenalty"
                            [step]="0.1"
                            [min]="0"
                            [max]="2"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                            (onInput)="MSSetFormControlValue('presencePenalty', $event.value)"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Template'"
                    [MSFormControl]="MSParentForm.controls.templateID"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <p-dropdown
                        [options]="MSTemplateSelectItem"
                        formControlName="templateID"
                        optionLabel="label"
                        optionValue="value"
                    ></p-dropdown>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Input Format'"
                    [MSFormControl]="MSParentForm.controls.inputFormat"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                <div class="flex gap-2">
                    <div
                        *ngFor="let inputFormat of MSInputTypeRadioOptions"
                        class="flex align-items-center"
                    >
                        <p-radioButton
                            name="inputFormat"
                            [value]="inputFormat.value"
                            formControlName="inputFormat"
                            [inputId]="inputFormat.label"
                            (onClick)="OnInputClick($event)"
                        ></p-radioButton>
                        <label
                            [for]="inputFormat.label"
                            class="ml-2"
                        >{{inputFormat.label}}</label>
                    </div>
                </div>
                </moon-form-control>

                <!-- <moon-form-control
                    [MSLabel]="'Chat Output Format'"
                    [MSFormControl]="MSParentForm.controls.chatOutputFormat "
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                <div class="flex gap-2">
                    <div
                        *ngFor="let outputFormat of MSChatOutputRadioOptions"
                        class="flex align-items-center"
                    >
                        <p-radioButton
                            name="chatOutputFormat"
                            [value]="outputFormat.value"
                            formControlName="chatOutputFormat"
                            [inputId]="outputFormat.label"
                        ></p-radioButton>
                        <label
                            [for]="outputFormat.label"
                            class="ml-2"
                        >{{outputFormat.label}}</label>
                    </div>
                </div>
                </moon-form-control> -->

                <moon-form-control
                    [MSLabel]="'File Output Format'"
                    [MSFormControl]="MSParentForm.controls.fileOutputFormat "
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                <div class="flex gap-2">
                    <div
                        *ngFor="let fileOutputFormat of MSFileOutputRadioOptions"
                        class="flex align-items-center"
                    >
                        <p-radioButton
                            name="fileOutputFormat"
                            [value]="fileOutputFormat.value"
                            formControlName="fileOutputFormat"
                            [inputId]="fileOutputFormat.label"
                        ></p-radioButton>
                        <label
                            [for]="fileOutputFormat.label"
                            class="ml-2"
                        >{{fileOutputFormat.label}}</label>
                    </div>
                </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Multiple File Processing'"
                    [MSFormControl]="MSParentForm.controls.multipleFileProcessing"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <p-dropdown
                        [options]="MSMultipleFileProcessing"
                        formControlName="multipleFileProcessing"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select an item"
                    ></p-dropdown>
                </moon-form-control>
            </p-fieldset>
        </div>

        <div class="col-8 system-message p-float-label">
            <moon-form-control
                [MSFormControl]="MSParentForm.controls.systemMessage"
                [MSLabelSize]="0"
                [MSInputSize]="12"
            >
                <div
                    class="grow-wrap"
                    [attr.textarea-value]="sysMessage.value"
                >
                    <textarea
                        id="inputtext"
                        #sysMessage
                        pInputTextarea
                        [formControl]="MSParentForm.controls.systemMessage"
                    ></textarea>
                    <label
                        for="inputtext"
                        class="moon-float-label"
                    >System Message</label>
                </div>
            </moon-form-control>
        </div>
        <!-- Form PlaceHolder - DO NOT REMOVE -->
    </div>
</moon-form-page>

<div
    class="moon-test-panel"
    *ngIf="MSSkillID"
>
    <hr />
    <span class="moon-test-label">Skill Test</span>
    <moon-skill-test
        [MSDisableForSkillEdit]="true"
        [MSSkillGet]="MSSkillGet"
    ></moon-skill-test>
</div>