// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
import { NgIf }                                         from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }                            from '@angular/core';
import { ActivatedRoute }                               from '@angular/router';
// Thrid Party Imports
import { MenuItem }                                     from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }                         from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }                  from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';
import { NodeEnvironmentUserGroupMapGet }               from '@moon-maintainer/api/response/node-environment-user-group-map-get.response';
import { NodeEnvironmentUserGroupMapController }        from '@moon-maintainer/api/node-environment-user-group-map.controller';
import { NodeEnvironmentUserGroupMapFormComponent }     from '@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map-form/node-environment-user-group-map-form.component';
import { NodeEnvironmentUserGroupMapTableComponent }    from '@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map-table/node-environment-user-group-map-table.component';
import { NodeEnvironmentController }                    from '@moon-maintainer/api/node-environment.controller';
import { MaintainerRouter }                             from '@moon-maintainer/maintainer.router';
import { MoonLoadingComponent }                         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }                        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }                               from '@moon-shared/constants/constant-string';
import { RouteParameter }                               from '@moon-shared/constants/route-parameter';
import { RoutePath }                                    from '@moon-shared/constants/route-path';

@Component({
    selector: 'node-environment-user-group-map-add-page',
    templateUrl: './node-environment-user-group-map-edit-page.component.html',
    styleUrls: ['./node-environment-user-group-map-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, NodeEnvironmentUserGroupMapTableComponent, NodeEnvironmentUserGroupMapFormComponent],
    providers: [NodeEnvironmentUserGroupMapController, MaintainerRouter, NodeEnvironmentController]
})
export class NodeEnvironmentUserGroupMapEditPageComponent implements OnInit {

  public MSNodeEnvironmentUserGroupMapID: number | null;
  public MSNodeEnvironmentUserGroupMapGet: NodeEnvironmentUserGroupMapGet;
  public MSNodeEnvironmentUserGroupMapList: NodeEnvironmentUserGroupMapGet[];
  public MSLoading = true;
  public MSPageTitle = ConstantString.NodeEnvironmentUserGroupMap.getDisplayName();
  public MSEnvironmentName = String.empty;
  private _nodeEnvironmentID: number;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _nodeEnvironmentController: NodeEnvironmentController,
    private _nodeEnvironmentUserGroupMapController: NodeEnvironmentUserGroupMapController,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSNodeEnvironmentUserGroupMapID){
      await this.getNodeEnvironmentUserGroupMapByID(this.MSNodeEnvironmentUserGroupMapID);
    }
    this.loadNodeEnvironmentUserGroupMapList();
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSNodeEnvironmentUserGroupMapID){
      this.doRemove(this.MSNodeEnvironmentUserGroupMapID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSNodeEnvironmentUserGroupMapID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.NodeEnvironment.getDisplayName(), routerLink: this._maintainerRouter.NodeEnvironmentRouteLink() }, 
      { label: RoutePath.UserGroupMap.getDisplayName(), routerLink: this._maintainerRouter.NodeEnvironmentUserGroupMapRouteLink(this._nodeEnvironmentID) },
      { label: this.MSNodeEnvironmentUserGroupMapID ? RoutePath.Edit : RoutePath.Add, routerLink: null }
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }

  private readRouteParameters() {
    const nodeEnvironmentUserGroupMapID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.NodeEnvironmentUserGroupMapID);
    this.MSNodeEnvironmentUserGroupMapID = nodeEnvironmentUserGroupMapID ? +nodeEnvironmentUserGroupMapID : null;

    const nodeEnvironmentID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.NodeEnvironmentID);
    this._nodeEnvironmentID = +nodeEnvironmentID!
  }

  private async getNodeEnvironmentUserGroupMapByID(nodeEnvironmentUserGroupMapID: number) {
    if (nodeEnvironmentUserGroupMapID) {
      const apiResult: ApiContentResult<NodeEnvironmentUserGroupMapGet> = await this._nodeEnvironmentUserGroupMapController.GetByID(nodeEnvironmentUserGroupMapID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSNodeEnvironmentUserGroupMapGet = apiResult.content;
      }
    }
  }

  private async loadNodeEnvironmentUserGroupMapList() {
    if (!this._nodeEnvironmentID) {
      return;
    }

    this.MSLoading = true;    
    const apiResult: ApiContentResult<NodeEnvironmentUserGroupMapGet[]> = await this._nodeEnvironmentController.GetUserGroupMapList(this._nodeEnvironmentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSNodeEnvironmentUserGroupMapList = apiResult.content;
    };
    this.MSLoading = false;  
  }

  private async doRemove(nodeEnvironmentUserGroupMapID: number) {
    const apiResult: ApiResult = await this._nodeEnvironmentUserGroupMapController.Delete(nodeEnvironmentUserGroupMapID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToNodeEnvironmentUserGroupMap(this._nodeEnvironmentID);
    }
  }
}
