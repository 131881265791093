<!-- Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div 
    class="card moon-card" 
    *ngIf="!MSLoading"
    >
  <template-table
        [MSPageTitle]="MSPageTitle"
        [MSTemplateList]="MSTemplateList"
        [MSLoading]="MSLoading"
        (MSRefreshEvent)="MSRefreshPage()"
    ></template-table>
</div>