// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
// Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM
// Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM
// Moon Auto Generated (1.0.0) at 11/29/2023 8:32:40 PM
// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:57 PM
import { MenuItem } from 'primeng/api';
import { RoutePath } from '@app/moon-shared/constants/route-path';

export const MaintainerMenuItems: MenuItem = {
    label: "Maintenance",
    items: [
        // Placeholder Start
        { label: RoutePath.BlacklineUtility.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.BlacklineUtility] },
        { label: RoutePath.NodeEnvironment.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.NodeEnvironment] },
        { label: RoutePath.Skill.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.Skill] },
        { label: RoutePath.UserGroup.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.UserGroup] },
        { label: RoutePath.UserInfo.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.UserInfo] },
        { label: RoutePath.Template.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.Template] },
        // Placeholder End
    ]
};
