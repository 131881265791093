// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
import { MenuItem } from 'primeng/api';
import { RoutePath } from '@app/moon-shared/constants/route-path';

export const PublicMenuItems: MenuItem = {
  label: "Apps",
  items: [
      // Placeholder Start
      { label: RoutePath.ClauseSearch.getDisplayName(), icon: "pi pi-search-minus", routerLink: [RoutePath.ClauseSearch] },
      { label: RoutePath.FullTextSearch.getDisplayName(), icon: "pi pi-search", routerLink: [RoutePath.FullTextSearch] },
      // Placeholder End
  ]
};
