<!-- Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
    <title-summary-table [MSPageTitle]="MSPageTitle"
                [MSTitleSummaryList]="MSTitleSummaryList"
                [MSLoading]="MSLoading"
                (MSOnEmitReload)="MSOnEmitReload()"
                ></title-summary-table>
</div>
