<moon-loading *ngIf="MSLoading"></moon-loading>
<div class="clearfix"></div>

<ng-container *ngIf="!MSLoading && MSTitleSummary as titleSummary">
    <div id="title-summary">
        <ng-template #loading>
            <moon-loading></moon-loading>
        </ng-template>
        <!-- Show list if there is any title summary already added else show drop files input -->
        <p-toolbar styleClass="mb-1 gap-2">
            <ng-template pTemplate="left">
                <div class="mb-2 w-full flex">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-arrow-left"
                        title="Go back"
                        class="h-min"
                        (click)="MSOnClickGoBack()"
                        class="p-button-raised moon-button"
                    ></button>
                    <button
                        pButton
                        pRipple
                        title="Refresh"
                        class="p-button-raised moon-button"
                        icon="pi pi-refresh"
                        type="button"
                        (click)="MSOnRefreshClick()"
                    ></button>
                    <button
                        *ngIf="MSShowContinueButton"
                        pButton
                        pRipple
                        type="button"
                        label="Continue"
                        class="p-button-raised"
                        title="Continue processing title summary"
                        (click)="MSOnContinueClick()"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Rerun all prompts"
                        class="p-button-raised"
                        title="Rerun all prompts"
                        (click)="MSOnRerunAllPrompts()"
                    ></button>
                    <button
                        *ngIf="MSShowMarkAsComplete"
                        pButton
                        pRipple
                        type="button"
                        label="Mark as Complete"
                        class="p-button-raised"
                        title="Mark as Complete"
                        (click)="MSOnMarkAsComplete()"
                    ></button>
                    <button
                        #btn
                        type="button"
                        pButton
                        label="Download"
                        icon="pi pi-angle-down"
                        iconPos="right"
                        [disabled]="MSApplying"
                        (click)="chatDropdown.toggle($event)"
                    ></button>
                    <p-tieredMenu
                        #chatDropdown
                        styleClass="w-15rem"
                        [model]="MSDownloadButtons"
                        [popup]="true"
                    ></p-tieredMenu>
                </div>
            </ng-template>
        </p-toolbar>

        <div class="card">
            <div
                class="py-2"
                [ngClass]="titleSummary.hasError ? 'border-top-1 border-bottom-1 border-red-500' : ''"
            >
                <div class="flex flex-1 gap-3 mb-2 text-lg align-items-center">
                    <div class="flex flex-column">
                        <div class="flex gap-1">
                            <div>{{titleSummary.matterName}} : {{titleSummary.fileName}}</div>
                            <div *ngIf="titleSummary.hasError">
                                <p-badge
                                    severity="danger"
                                    value="Error"
                                ></p-badge>
                            </div>
                        </div>
                        <div>
                            <span class="text-sm mr-2">
                                Status: {{titleSummary.status}}
                            </span>
                            <span class="text-sm mr-2">
                                Last completed step: {{titleSummary.processingStatus}}
                            </span>
                            <span class="text-sm mr-2">
                                Type: {{titleSummary.summaryType}}
                            </span>
                            <span class="text-sm mr-2">
                                Exception pages: {{titleSummary.exceptionPagesCsv}}
                            </span>
                            <span
                                *ngIf="titleSummary.fileSize"
                                class="text-sm mr-2"
                            >
                                Size - {{titleSummary.fileSize / 1024 | number:'1.0-2'}} kb
                            </span>
                        </div>
                        <div
                            *ngIf="titleSummary.logMessage"
                            class="text-sm mt-1"
                        >
                            <span class="font-bold">Log Messages</span>: {{titleSummary.logMessage}}
                        </div>
                    </div>

                    <div class="ml-auto flex">
                        <button
                            pButton
                            class="moon-action-button"
                            icon="pi pi-file-pdf"
                            label="Pdf"
                            [disabled]="MSApplying"
                            (click)="MSOnClickPdfReviewForSummary(titleSummary.titleSummaryID, titleSummary.exceptionPagesCsv, titleSummary.chatLogID)"
                        ></button>
                        <button
                            type="button"
                            pButton
                            label="Prompt"
                            iconPos="right"
                            [disabled]="MSApplying"
                            (click)="MSOnClickPromptSummary()"
                        ></button>
                        <button
                            pButton
                            class="moon-action-button"
                            label="Log"
                            icon="pi pi-list"
                            [disabled]="MSApplying"
                            (click)="MSOnClickChatLogForSummary(titleSummary)"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="titleSummary.titleExceptionGetList?.length"
            class="card"
        >
            <div
                *ngFor="let titleException of titleSummary.titleExceptionGetList"
                class="py-2"
            >
                <moon-title-exception
                    [MSTitleException]="titleException"
                    (MSOnTitleExceptionChange)="MSLoadTitleSummary()"
                ></moon-title-exception>
                <ul
                    *ngFor="let document of titleException.titleDocumentGetList; let i=index"
                    class="list-none p-0 m-0 moon-document-grid"
                >
                    <moon-title-document
                        [MSTestMode]="MSTestMode"
                        [MSApplying]="MSApplying"
                        [MSTitleDocument]="document"
                        [MSDocumentIndexNumber]="i"
                        (MSOnTitleDocumentChange)="MSLoadTitleSummary()"
                    ></moon-title-document>
                </ul>
            </div>
        </div>
    </div>

</ng-container>

<!-- Chat Log Viewer -->
<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw"
    position="top"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSChatLogID"
    ></moon-chat-log>
</p-dialog>

<p-confirmDialog></p-confirmDialog>