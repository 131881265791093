import { Injectable } from "@angular/core";
import { ApiBlobResult, ApiContentResult } from "@moon-core/models/api-result";
// MS Imports
import { MoonApiClient } from '@moon-core/services/moon-api.client';
import { ChatResult } from "@moon-public/api/response/chat-result.response";
import { ConstantString } from "@app/moon-shared/constants/constant-string";

@Injectable()
export class SummarizeController {
    
  constructor(private _moonApi: MoonApiClient) { }

  private getBaseUrl(): string {
    return this._moonApi.getPublicUrl(ConstantString.Summarize);
  };

  public async Summarize(chatParameter: FormData
    ): Promise<ApiContentResult<ChatResult>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl());
    return this._moonApi.postWithResultAsync(url, chatParameter);
  }
  public async SummarizeAndDownload(chatParameter: FormData
    ): Promise<ApiBlobResult> {
    
      
    const url = this._moonApi.combineUrl(this.getBaseUrl());
    return this._moonApi.getBlobWithPostAsync(url, chatParameter);
  }
  public async SummarizeFile(chatParameter: FormData
    ): Promise<ApiContentResult<ChatResult>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.FileFunction);
    return this._moonApi.postWithResultAsync(url, chatParameter);
  }
  public async SummarizeFileAndDownload(chatParameter: FormData
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.FileFunction);
    return this._moonApi.getBlobWithPostAsync(url, chatParameter);
  }
  public async CascadeSummarizeFile(chatParameter: FormData
    ): Promise<ApiContentResult<ChatResult>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.CascadeFileFunction);
    return this._moonApi.postWithResultAsync(url, chatParameter);
  }
  public async CascadeSummarizeFileAndDownload(chatParameter: FormData
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.CascadeFileFunction);
    return this._moonApi.getBlobWithPostAsync(url, chatParameter);
  }
}
