import { inject } from '@angular/core';
// MS Imports
import { AppRouter } from "../../app.router";
import { UserSessionService } from '@moon-core/services/user-session.service';

export const PreviewRouteGuard = (): boolean => {

    const _appRouter = inject(AppRouter);
    const userSessionService = inject(UserSessionService);

    if (userSessionService.IsPreviewAvailable()){
        return true;
    } else {
        _appRouter.RouteToDefault();
        return false;
    }
}
