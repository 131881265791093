import { AsyncPipe, DatePipe, DecimalPipe, KeyValuePipe, NgClass, NgFor, NgIf, SlicePipe }  from '@angular/common';
import { Component, OnInit }                                                  from '@angular/core';
import { FormsModule }                                                        from '@angular/forms';
import { ActivatedRoute }                                                     from '@angular/router';
// Third party imports
import { ConfirmationService, MenuItem, SharedModule }                        from 'primeng/api';
import { BadgeModule }                                                        from 'primeng/badge';
import { ButtonModule }                                                       from 'primeng/button';
import { ConfirmDialogModule }                                                from 'primeng/confirmdialog';
import { DialogModule }                                                       from 'primeng/dialog';
import { InputNumberModule }                                                  from 'primeng/inputnumber';
import { InputTextModule }                                                    from 'primeng/inputtext';
import { ProgressSpinnerModule }                                              from 'primeng/progressspinner';
import { RippleModule }                                                       from 'primeng/ripple';
import { SplitButtonModule }                                                  from 'primeng/splitbutton';
import { TabMenuModule }                                                      from 'primeng/tabmenu';
import { ToolbarModule }                                                      from 'primeng/toolbar';
// MS Imports
import { AppBreadcrumbService }                                               from '@app-layout/services/app.breadcrumb.service';
import { ApiBlobResult, ApiContentResult, ApiResult }                         from '@moon-core/models/api-result';
import { ComponentUtilityService }                                            from '@moon-core/services/component-utility.service';
import { ChatLogComponent }                                                   from '@moon-public//title-summary/chat-log/chat-log.component';
import { TitleDocumentGet }                                                   from '@moon-public/api/response/title-document.response';
import { TitleSummaryGet }                                                    from '@moon-public/api/response/title-summary-get.response';
import { TitleSummaryFull }                                                   from '@moon-public/api/response/title-summary-full.response';
import { TitleSummaryController }                                             from '@moon-public/api/title-summary.controller';
import { PublicRouter }                                                       from '@moon-public/public.router';
import { ProcessingStatus }                                                   from '@moon-public/title-summary/models/processing-status';
import { TitleDocumentComponent }                                             from '@moon-public/title-summary/title-summary-detail/title-document/title-document.component';
import { TitleExceptionComponent }                                            from '@moon-public/title-summary/title-summary-detail/title-exception/title-exception.component';
import { MoonLoadingComponent }                                               from '@moon-shared/components/moon-loading/moon-loading.component';
import { DefaultValues }                                                      from '@moon-shared/constants/default-values';
import { MessageSeverity }                                                    from '@moon-shared/constants/message-severity';
import { RouteParameter }                                                     from '@moon-shared/constants/route-parameter';
import { RoutePath }                                                          from '@moon-shared/constants/route-path';
import { MoonMessageService }                                                 from '@moon-shared/services/moon-message.service';
import { TieredMenuModule } from 'primeng/tieredmenu';

@Component({
  selector: 'app-title-summary-detail',
  templateUrl: './title-summary-detail.component.html',
  styleUrls: ['./title-summary-detail.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgFor, DecimalPipe, DatePipe, KeyValuePipe, NgClass, FormsModule,
    ToolbarModule, SharedModule, ButtonModule, RippleModule, ConfirmDialogModule, AsyncPipe, SlicePipe,
    BadgeModule, SplitButtonModule, DialogModule, InputNumberModule, InputTextModule, ProgressSpinnerModule, TabMenuModule, TieredMenuModule,
    MoonLoadingComponent, ChatLogComponent, TitleDocumentComponent, TitleExceptionComponent
  ],
  providers: [TitleSummaryController, PublicRouter, ConfirmationService]
})
export class TitleSummaryDetailComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSShowChatLog: boolean = false;
  public MSChatLogID: number | null;
  public MSTitleSummaryID: number;
  public MSSelectedTitleDocument: TitleDocumentGet;
  public MSTitleSummary: TitleSummaryFull;
  public MSTestMode: boolean = false;
  public MSApplying: boolean = false;
  public MSShowContinueButton: boolean = false;
  public MSDownloadButtons: MenuItem[] = [];
  public MSShowMarkAsComplete: boolean = false;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _publicRouter: PublicRouter,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
    private _moonMessageService: MoonMessageService,
    private _confirmationService: ConfirmationService
  ) {
  }

  async ngOnInit() {
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
    this.setBreadcrumb();
    this.MSLoadTitleSummary();
  }

  public MSLoadTitleSummary() {
    this.loadTitleSummaryByID(this.MSTitleSummaryID);
  }

  private async loadTitleSummaryByID(titleSummaryID: number) {
    this.MSLoading = true;
    try {
      const apiResult: ApiContentResult<TitleSummaryFull> = await this._titleSummaryController.GetFullByID(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleSummary = apiResult.content;
        this.setHasErrorAndTryParseDocumentSummary();
        this.showHideContinueButton();
        this.showHideDownloadButtons();
        this.showHideMarkAsCompleteButton();
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  private showHideContinueButton() {
    let allowContinueButtonStatuses: string[] = [
      ProcessingStatus.Created,
      ProcessingStatus.TitleDocumentDownloaded,
    ];
    this.MSShowContinueButton = allowContinueButtonStatuses.includes(this.MSTitleSummary.processingStatus);
  }

  private showHideMarkAsCompleteButton() {
    let allowMarkAsCompleteStatuses: string[] = [
      ProcessingStatus.PromptTitleSummary,
      ProcessingStatus.PromptTitleDocument,
      ProcessingStatus.PromptTitleException,
      ProcessingStatus.Complete
    ];

    if (allowMarkAsCompleteStatuses.includes(this.MSTitleSummary.processingStatus)) {
      this.MSShowMarkAsComplete = true;
    }
    else {
      this.MSShowMarkAsComplete = false;
    }
  }

  private showHideDownloadButtons() {
    this.MSDownloadButtons = [{ label: 'Title Commitment', command: () => this.OnDownloadTitleCommitmentClick() }];

    if (this.showDownloadWord()) {
      this.MSDownloadButtons.push({ label: 'Summary', command: () => this.OnDownloadWordSummaryClick() });
    }
    if (this.showExportPdf()) {
      this.MSDownloadButtons.push({ label: 'Exception PDFs', command: () => this.OnExportPdfClick() });
    }
    if (this.showExportOcr()) {
      this.MSDownloadButtons.push({ label: 'Exception OCR Text', command: () => this.OnExportOcrClick() });
    }
  }
  private setHasErrorAndTryParseDocumentSummary() {

    Object.safelyDefineProperty(this.MSTitleSummary, "hasError", !String.isNullOrWhiteSpace(this.MSTitleSummary.logMessage));

    this.MSTitleSummary.titleExceptionGetList.forEach(exception => {

      Object.safelyDefineProperty(exception, "hasError", !String.isNullOrWhiteSpace(exception.logMessage));

      exception.titleDocumentGetList?.forEach(doc => {
        this.parseDocumentSummaryIfValid(doc);
        Object.safelyDefineProperty(doc, "hasError", !String.isNullOrWhiteSpace(doc.logMessage));
      });
    });
  }

 
  private parseDocumentSummaryIfValid(document: TitleDocumentGet) {

    if (String.isNullOrWhiteSpace(document.summaryText))
      return;

    try {
      let parsedValue = JSON.parse(document.summaryText);
      Object.safelyDeleteProperty(parsedValue, "Type");
      Object.safelyDeleteProperty(parsedValue, "Action");
      Object.safelyDefineProperty(document, "summaryObject", parsedValue);
    }
    catch (_: unknown) { }
  }

  private setBreadcrumb(titleName?: string) {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.TitleReview.getDisplayName(), routerLink: [`${this._publicRouter.TitleSummaryRouteLink()}`] },
      { label: titleName ?? RoutePath.Detail, routerLink: null },
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
  public async MSOnRefreshClick() {
    await this.loadTitleSummaryByID(this.MSTitleSummaryID);
  }

  public MSOnClickGoBack() {
    this._publicRouter.RouteToTitleSummary();
  };

  // Title Summary Start

  public MSOnClickPromptSummary() {
    if (this.MSTitleSummary.hasError) {
      this.doPromptSummary();
    }
    else {
      this._confirmationService.confirm({
        message: "Are you sure you want to perform this prompt?",
        acceptLabel: "Yes",
        accept: () => this.doPromptSummary(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }


  private async doPromptSummary() {
    this.MSApplying = true;
    const apiResult: ApiResult = await this._titleSummaryController.PromptTitleSummary(this.MSTitleSummary.titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt title summary successful.");
      this.MSLoadTitleSummary();
    }
    this.MSApplying = false;
  }

    
  public async MSOnClickChatLogForSummary(titleSummaryFull: TitleSummaryFull) {
    this.MSChatLogID = titleSummaryFull.chatLogID;
    if (this.MSChatLogID !== null) {
      this.MSShowChatLog = true;
    } else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No Chat Log found.");
    }
  }

  public async MSOnClickPdfReviewForSummary(titleSummaryID: number, exceptionPagesCsv: string, titleSummaryChatLogID: number | null) {
    this._publicRouter.RouteToSummaryPdfReview(titleSummaryID, exceptionPagesCsv, titleSummaryChatLogID);
  }

  // Title Summary End


  // Moved from Title Summary
  private showDownloadWord(): boolean {
    const allowDownloadStatuses: string[] = [
      ProcessingStatus.Complete,
      ProcessingStatus.PromptTitleSummary,
      ProcessingStatus.PromptTitleDocument,
      ProcessingStatus.PromptTitleException
    ];
    if (allowDownloadStatuses.includes(this.MSTitleSummary.processingStatus))
      return true;
    return false;
  }
  private showExportPdf(): boolean {
    if (ProcessingStatus.Created.equalsIgnoreCase(this.MSTitleSummary.processingStatus))
      return false;
    return true;
  }
  private showExportOcr(): boolean {
    if (ProcessingStatus.Created.equalsIgnoreCase(this.MSTitleSummary.processingStatus))
      return false;
    if (ProcessingStatus.TitleDocumentDownloaded.equalsIgnoreCase(this.MSTitleSummary.processingStatus))
      return false;
    return true;
  }
  public async MSOnContinueClick(): Promise<void> {
      this._confirmationService.confirm({
        message: "This process has already started. Are you sure you want to continue?",
        acceptLabel: "Yes",
        accept: () => this.doContinueProcessingException(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
  }

  private async doContinueProcessingException() {
    this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait...");

    if (ProcessingStatus.Created.equalsIgnoreCase(this.MSTitleSummary.processingStatus)) {
      await this.downloadPdfs(this.MSTitleSummary);
    }
    else if (ProcessingStatus.TitleDocumentDownloaded.equalsIgnoreCase(this.MSTitleSummary.processingStatus)) {
      await this.ocrPdfs(this.MSTitleSummary);
    }
    this.loadTitleSummaryByID(this.MSTitleSummaryID);
  }

  public async MSOnMarkAsComplete(){
      this._confirmationService.confirm({
        message: "This process has already started. Are you sure you want to continue?",
        acceptLabel: "Yes",
        accept: () => this.doMarkAsComplete(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
  }
  private async doMarkAsComplete() {
    try {
      this.MSLoading = true;
      const promptAllResult: ApiResult = await this._titleSummaryController.Complete(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(promptAllResult)) {
        this._moonMessageService.toastSuccess("Title review marked as complete.");
      }
    } finally {
      this.loadTitleSummaryByID(this.MSTitleSummaryID);
    }
  }

  public async MSOnRerunAllPrompts() {
    this._confirmationService.confirm({
      message: "This process has already started. Are you sure you want to continue?",
      acceptLabel: "Yes",
      accept: () => this.doRerunAllPrompts(),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }

  private async doRerunAllPrompts() {
    try {
      this.MSLoading = true;
      const promptAllResult: ApiResult = await this._titleSummaryController.PromptAll(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(promptAllResult)) {
        this._moonMessageService.toastSuccess("Prompt successful.");
        this.loadTitleSummaryByID(this.MSTitleSummaryID);
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnDownloadWordSummaryClick(): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.GetDocxBlob(this.MSTitleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download Word summary successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnDownloadTitleCommitmentClick() {
    try {
      this.MSLoading = true;

      const apiResult: ApiBlobResult = await this._titleSummaryController.GetPdfBlob(this.MSTitleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Title commitment downloaded successful.");
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  public async OnExportPdfClick(): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportPdf(this.MSTitleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export pdf successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnExportOcrClick(): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportOcr(this.MSTitleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export ocr successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }
  private async ocrPdfs(titleSummaryGet: TitleSummaryGet) {
    try {
      this.MSLoading = true;
      const apiResult: ApiResult = await this._titleSummaryController.OcrPdfs(titleSummaryGet.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Ocr pdfs successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private async downloadPdfs(titleSummaryGet: TitleSummaryGet) {
    try {
      this.MSLoading = true;
      const apiResult: ApiResult = await this._titleSummaryController.DownloadPdfs(titleSummaryGet.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download pdfs successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
