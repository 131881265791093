import { Route }                        from "@angular/router";
import { MsalGuard }                    from "@azure/msal-angular";
//Naya Imports
import { MaintainerRouteGuard }         from "@moon-core/guards/maintainer-route.guard";
import { UserSessionGuard }             from "@moon-core/guards/user-session.guard";
import { ChatLogComponent }             from "@moon-maintainer/chat-log/chat-log.component";
import { SearchLogComponent }           from "@moon-maintainer/search-log/search-log.component";
import { HistoryComponent }             from '@moon-maintainer/history/history.component';
import { RoutePath }                    from "@moon-shared/constants/route-path";

export const HistoryRoutes: Route[] = [
    {
        path: RoutePath.ChatLog, component: ChatLogComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },
    
    {
        path: RoutePath.SearchLog, component: SearchLogComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },
    
    {
        path: RoutePath.History, component: HistoryComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },
    
];