// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { Injectable }   from "@angular/core";
import { Router }       from "@angular/router";
import { RoutePath }    from '@moon-shared/constants/route-path';

@Injectable()
export class PublicRouter {
    constructor(private _router: Router) { }

    public NavigateToSearch() {
        this._router.navigate([RoutePath.ClauseSearch]);
    }
    
    // TitleSummary Start
    public TitleSummaryRouteLink() {
        return RoutePath.TitleReview;
    }

    public RouteToTitleSummary(...command: (string | number)[]) {
        this._router.navigate([RoutePath.TitleReview, ...command]);
    }

    public RouteToTitleSummaryDetail(titleSummaryID: number) {
        this.RouteToTitleSummary(titleSummaryID, RoutePath.Detail);
    }
    
    public RouteToTitleSummaryAdd(): void {
        this.RouteToTitleSummary(RoutePath.Add);
    }

    public RouteToTitleSummaryEdit(titleSummaryID: number) {
        this.RouteToTitleSummary(titleSummaryID, RoutePath.Edit);
    }
    
    public RouteToDocumentPdfReview(titleSummaryID: number, titleDocumentID: number) {
        this.RouteToTitleSummary(titleSummaryID, RoutePath.Documents, titleDocumentID, RoutePath.PdfReview);
    }
    
    public RouteToSummaryPdfReview(titleSummaryID: number, exceptionPagesCsv: string, titleSummaryChatLogID: number | null) {
        
        this._router.navigate([RoutePath.TitleReview, titleSummaryID, RoutePath.PdfReview],
            {
                state: {
                    exceptionPagesCsv: exceptionPagesCsv,
                    chatLogID: titleSummaryChatLogID
                }
            });
    }

    // TitleSummary End


    // MezzLoan Start
    public MezzLoanRouteLink() {
        return RoutePath.MezzLoan;
    }

    public RouteToMezzLoan(...command: (string | number)[]) {
        this._router.navigate([RoutePath.MezzLoan, ...command]);
    }

    public RouteToMezzLoanCreate(): void {
        this.RouteToMezzLoan(RoutePath.Add);
    }
    
    public RouteToMezzLoanDetail(mezzLoanID: number) {
        this.RouteToMezzLoan(mezzLoanID, RoutePath.Revision);
    }
    
    public RouteToMezzLoanEdit(mezzLoanID: number) {
        this.RouteToMezzLoan(mezzLoanID, RoutePath.Edit);
    }
    
    public RouteToMezzLoanChunk(mezzLoanID: number) {
        this.RouteToMezzLoan(mezzLoanID, RoutePath.Chunk);
    }

    public RouteToMezzLoanRevision(mezzLoanID: number) {
        this.RouteToMezzLoan(mezzLoanID, RoutePath.Revision);
    }
    // MezzLoan End

    // Placeholder Router
}
