<div class="moon-inputfield grid align-items-center">
    <!-- LABEL -->
    <div
        *ngIf="MSDisplayLabel"
        class="col-12 md:col-{{ MSLabelSize || 2 }} md:text-right"
    >
        <label [for]="MSLabel">
            {{ MSIsRequired || MSHasRequiredValidator ? "*" : "" }} {{ MSLabel }}
            <ng-container *ngIf="MSSecondLabel">
                <span class="text-color-secondary">{{ MSSecondLabel }}</span>
            </ng-container>
            <span
                *ngIf="MSHint"
                [pTooltip]="MSHint"
                tooltipPosition="bottom"
            >
                <i class="pi pi-info-circle"></i></span>

        </label>
    </div>
    <!-- INPUT FIELD -->
    <div class="col-12 md:col-{{ MSInputSize || 4 }}">
        <ng-content></ng-content>
    </div>
    <!-- MESSAGE -->
    <div
        class="col-12 md:col-{{ 12 - (MSInputSize || 4) - (MSLabelSize || 2) }}"
        [class]="MSErrorMessageStyleClass"
        *ngIf="MSDisplayErrMsg && MSFormControl && MSFormControl.invalid && (MSFormControl.dirty ||
        MSFormControl.touched || MSFormControl.root?.submitting)"
    >
        <div class="p-message p-message-error">
            <div *ngIf="MSFormControl.errors?.required">
                {{ MSLabel || MSFormControl }} is required.
            </div>
            <div *ngIf="MSFormControl.errors?.list">
                Select item from the list.
            </div>
            <div *ngIf="MSFormControl.errors?.email">
                {{ MSLabel || MSFormControl }} has invalid format.
            </div>
            <div *ngIf="MSFormControl.errors?.pattern">
                {{ MSLabel || MSFormControl }} has invalid characters.
            </div>
            <div *ngIf="MSFormControl.errors?.JSONValidation">
                {{ MSLabel || MSFormControl }} has invalid JSON input.
            </div>
            <div *ngIf="MSFormControl.errors?.min">
                {{ MSLabel || MSFormControl }} is less than {{ validators().min?.min }}.
            </div>
            <div *ngIf="MSFormControl.errors?.max">
                {{ MSLabel || MSFormControl }} is greater than {{ validators().max?.max }}.
            </div>
            <div *ngIf="MSFormControl.errors?.maxlength">
                {{ MSLabel || MSFormControl }} must be less than
                {{ validators().maxlength?.requiredLength }} characters
                long.
            </div>
            <div *ngIf="MSFormControl.errors?.serverError">
                {{ MSFormControl.errors?.serverError }}
            </div>
            <div *ngIf="MSFormControl.errors?.hasEmptyContent">
                File content cannot be empty.
            </div>
            <div *ngIf="MSFormControl.errors?.invalidFileType">
                Invalid File Type.
            </div>
            <div *ngIf="MSFormControl.errors?.invalidData">
                {{MSFormControl.errors?.invalidData ?? 'Invalid Data.'}}
            </div>
        </div>
    </div>
</div>