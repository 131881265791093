<input
    pInputText
    type="text"
    placeholder="Search"
    [(ngModel)]="MSSearchTerm"
    class="moon-search"
    (input)="OnSearchFieldInputChange()"
/>
<i
    class="btn-clear"
    [ngClass]="{ 'pi pi-times': HasSearchFieldValue() }"
    (click)="OnButtonClearClick()"
></i>
