import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
// Third party imports
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Moon imports
import { TitleSummaryFind } from '@moon-public/api/response/title-summary-find.response';
import { MessageSeverity } from '@moon-shared/constants/message-severity';
import { MoonMessageService } from '@moon-shared/services/moon-message.service';
import { MoonFormControlComponent } from '@app/moon-shared/components/moon-form-control/moon-form-control.component';
import { InputTextModule } from 'primeng/inputtext';
import { TitleSummaryStatus } from '@app/moon-public/title-summary/models/processing-status';
import { DropdownModule } from 'primeng/dropdown';
import { MoonFormPageComponent } from '@app/moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TitleSummaryController } from '@app/moon-public/api/title-summary.controller';
import { ComponentUtilityService } from '@app/moon-core/services/component-utility.service';
export type CloseTitleSummaryEditDynamicDialogData = 'cancel' | 'success';

@Component({
  selector: 'moon-title-review-edit-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    InputTextModule, DropdownModule, ProgressBarModule, ButtonModule, RippleModule,
    MoonFormControlComponent, MoonFormPageComponent],
  templateUrl: './title-review-edit-modal.component.html',
  styleUrls: ['./title-review-edit-modal.component.scss']
})
export class TitleReviewEditModalComponent {
  public MSTitleSummary!: TitleSummaryFind;
  public MSStatusOptions = [TitleSummaryStatus.OPEN, TitleSummaryStatus.CLOSED];
  public MSTitleReviewEditForm!: FormGroup<{
    status: FormControl<string>;
    matterName: FormControl<string>;
  }>;
  public MSIsFormSubmitting: boolean = false;
  constructor(
    private _dynamicDialogConfig: DynamicDialogConfig<TitleSummaryFind>,
    private _dynamicDialogRef: DynamicDialogRef,
    private _moonMessageService: MoonMessageService,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
  ) { }
  ngOnInit() {
    const data: TitleSummaryFind | undefined = this._dynamicDialogConfig.data;

    if (data == null) {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No documents found.");
      this._dynamicDialogRef.close();
      return;
    }

    this.MSTitleSummary = data;
    this.MSTitleReviewEditForm = new FormGroup({
      status: new FormControl(this.MSTitleSummary.status, { nonNullable: true, validators: Validators.required }),
      matterName: new FormControl(this.MSTitleSummary.matterName, { nonNullable: true, validators: Validators.required }),
    });
  }


  public MSOnClickCancel() {
    const data: CloseTitleSummaryEditDynamicDialogData = 'cancel';
    this._dynamicDialogRef.close(data);
  }
  public async MSOnClickSubmit() {

    if (this._componentUtilityService.IsFormValid(this.MSTitleReviewEditForm)) {
      this.MSIsFormSubmitting = true;

      try {
        const formValue = this.MSTitleReviewEditForm.getRawValue();
        this.MSTitleSummary = { ...this.MSTitleSummary, status: formValue.status, matterName: formValue.matterName };

        const apiResult = await this._titleSummaryController.Update(this.MSTitleSummary.titleSummaryID, this.MSTitleSummary);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess("Title review updated.");
          const data: CloseTitleSummaryEditDynamicDialogData = 'success';
          this._dynamicDialogRef.close(data);
        }
      }
      finally {
        this.MSIsFormSubmitting = false;
      }
    }
  }

}