// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
import { NgIf }                         from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }            from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Third Party Imports
import { ConfirmationService }          from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { TemplateGet }                  from '@moon-maintainer/api/response/template-get.response';
import { TemplateController }           from '@moon-maintainer/api/template.controller';
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { TemplateFormComponent }        from '@moon-maintainer/template/template-form/template-form.component';
import { TemplateTableComponent }       from '@moon-maintainer/template/template-table/template-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { FieldNames }                   from '@moon-shared/constants/field-names';

@Component({
    selector: 'template-add-page',
    templateUrl: './template-edit-page.component.html',
    styleUrls: ['./template-edit-page.component.css'],
    standalone: true,
    imports: [
      NgIf,
      MoonLoadingComponent, MoonEditPageComponent, 
      TemplateTableComponent, TemplateFormComponent
    ],
    providers: [TemplateController, MaintainerRouter]
})
export class TemplateEditPageComponent implements OnInit {

  public MSTemplateID: number | null;
  public MSTemplateGet: TemplateGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.Template;
  public MSEnvironmentName = String.empty;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _templateController: TemplateController,
    private _maintainerRouter: MaintainerRouter,
    private _confirmationService: ConfirmationService,
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSTemplateID){
      await this.getTemplateByID(this.MSTemplateID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSTemplateID){
      this._confirmationService.confirm({
        message: `Are you sure you want to remove ${this.MSTemplateGet.templateName}`,
        accept: () => {
            this.doRemove();
        },
      });
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSTemplateID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(this.MSPageTitle, ConstantString.Template, this.MSTemplateID);
  }

  private readRouteParameters() {
    const templateID = this._activatedRoute.snapshot.paramMap.get(FieldNames.TemplateID);
    this.MSTemplateID = templateID ? +templateID : null;
  }

  private async getTemplateByID(templateID: number) {
    if (templateID) {
      const apiResult: ApiContentResult<TemplateGet> = await this._templateController.GetByID(templateID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTemplateGet = apiResult.content;
      }
    }
  }

  private async doRemove() {
    const apiResult: ApiResult = await this._templateController.Delete(this.MSTemplateID ?? 0);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToTemplate();
    }
  }

  public MSOnClickGoBack() {
    this._maintainerRouter.ToTemplate();
  }
}
