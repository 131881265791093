// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import { Route }                                            from "@angular/router";
import { MsalGuard }                                        from "@azure/msal-angular";
// MS Imports
import { MaintainerRouteGuard }                             from "@moon-core/guards/maintainer-route.guard";
import { UserSessionGuard }                                 from "@moon-core/guards/user-session.guard";
import { BlackLineUtilityComponent }                        from "@moon-maintainer/black-line-utility/black-line-utility.component";
import { ChatLogComponent }                                 from "@moon-maintainer/chat-log/chat-log.component";
import { NodeEnvironmentUserGroupMapEditPageComponent }     from "@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map-edit-page/node-environment-user-group-map-edit-page.component";
import { NodeEnvironmentUserGroupMapComponent }             from "@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map.component";
import { NodeEnvironmentEditPageComponent }                 from "@moon-maintainer/node-environment/node-environment-edit-page/node-environment-edit-page.component";
import { NodeEnvironmentComponent }                         from "@moon-maintainer/node-environment/node-environment.component";
import { SearchLogComponent }                               from "@moon-maintainer/search-log/search-log.component";
import { SkillExampleEditPageComponent }                    from "@moon-maintainer/skill-example/skill-example-edit-page/skill-example-edit-page.component";
import { SkillExampleComponent }                            from "@moon-maintainer/skill-example/skill-example.component";
import { SkillEditPageComponent }                           from "@moon-maintainer/skill/skill-edit-page/skill-edit-page.component";
import { SkillTestComponent }                               from "@moon-maintainer/skill/skill-test/skill-test.component";
import { SkillComponent }                                   from "@moon-maintainer/skill/skill.component";
import { UserGroupEditPageComponent }                       from '@moon-maintainer/user-group/user-group-edit-page/user-group-edit-page.component';
import { UserGroupComponent }                               from '@moon-maintainer/user-group/user-group.component';
import { UserInfoEditPageComponent }                        from "@moon-maintainer/user-info/user-info-edit-page/user-info-edit-page.component";
import { UserInfoComponent }                                from "@moon-maintainer/user-info/user-info.component";
import { UserSessionComponent }                             from '@moon-maintainer/user-session/user-session.component';
import { HistoryComponent }                                 from "@moon-maintainer/history/history.component";
import { TemplateEditPageComponent }                        from '@moon-maintainer/template/template-edit-page/template-edit-page.component';
import { RouteParameter }                                   from "@moon-shared/constants/route-parameter";
import { RoutePath }                                        from "@moon-shared/constants/route-path";
import { TemplateComponent }                               from '@moon-maintainer/template/template.component';

export const MaintainerRoutes: Route[] = [
    {
        path: RoutePath.ChatLog, component: ChatLogComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },

    {
        path: RoutePath.SearchLog, component: SearchLogComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },

    {
        path: RoutePath.History, component: HistoryComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },

    {
        path: RoutePath.UserInfo,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: UserInfoComponent },
            { path: RoutePath.Add, component: UserInfoEditPageComponent },
            { path: String.createRoute(RouteParameter.UserInfoID.toRouteParam(), RoutePath.Edit), component: UserInfoEditPageComponent }],
    },

    {
        path: RoutePath.NodeEnvironment,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: NodeEnvironmentComponent },
            { path: RoutePath.Add, component: NodeEnvironmentEditPageComponent },
            { path: String.createRoute(RouteParameter.NodeEnvironmentID.toRouteParam(), RoutePath.Edit), component: NodeEnvironmentEditPageComponent },
            {
                path: String.createRoute(RouteParameter.NodeEnvironmentID.toRouteParam(), RoutePath.NodeEnvironmentUserGroupMap),
                children: [
                    { path: RoutePath.Empty, component: NodeEnvironmentUserGroupMapComponent },
                    { path: RoutePath.Add, component: NodeEnvironmentUserGroupMapEditPageComponent },
                    { path: String.createRoute(RouteParameter.NodeEnvironmentUserGroupMapID.toRouteParam(), RoutePath.Edit), component: NodeEnvironmentUserGroupMapEditPageComponent },
                ]
            }
        ]
    },

    {
        path: RoutePath.Skill,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: SkillComponent },
            { path: RoutePath.Add, component: SkillEditPageComponent },
            { path: String.createRoute(RouteParameter.SkillID.toRouteParam(), RoutePath.Edit), component: SkillEditPageComponent },
            { path: String.createRoute(RoutePath.Test), component: SkillTestComponent },
            {
                path: String.createRoute(RouteParameter.SkillID.toRouteParam(), RoutePath.SkillExample),
                children: [
                    { path: RoutePath.Empty, component: SkillExampleComponent },
                    { path: RoutePath.Add, component: SkillExampleEditPageComponent },
                    { path: String.createRoute(RouteParameter.SkillExampleID.toRouteParam(), RoutePath.Edit), component: SkillExampleEditPageComponent },
                ]
            },
        ]
    },

    {
        path: RoutePath.UserGroup,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: UserGroupComponent },
            { path: RoutePath.Add, component: UserGroupEditPageComponent },
            { path: String.createRoute(RouteParameter.UserGroupID.toRouteParam(), RoutePath.Edit), component: UserGroupEditPageComponent },
        ]
    },
    {
        path: RoutePath.UserSessionLog,
        component: UserSessionComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],

    },
    {
        path: RoutePath.BlacklineUtility,
        component: BlackLineUtilityComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },
    {
        path: RoutePath.Template,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: TemplateComponent },
            { path: RoutePath.Add, component: TemplateEditPageComponent },
            { path: String.createRoute(RouteParameter.TemplateID.toRouteParam(), RoutePath.Edit), component: TemplateEditPageComponent },
        ]
    },
    // Placeholder for route
];
