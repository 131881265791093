import { Clipboard }                    from '@angular/cdk/clipboard';
import { CommonModule }                 from '@angular/common';
import { Component, Input, OnInit }     from '@angular/core';
// Third party imports
import { ButtonModule }                 from 'primeng/button';
import { RippleModule }                 from 'primeng/ripple';
// MS Imports
import { ApiContentResult }             from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { UserSessionService }           from '@moon-core/services/user-session.service';
import { ChatLogController }            from '@moon-maintainer/api/chat-log.controller';
import { ChatLogDataGet }               from '@moon-maintainer/api/response/chat-log-data-get.response';
import { ChatLogGet }                   from '@moon-maintainer/api/response/chat-log-get.response';
import { MaintainerRouter }             from '@moon-maintainer/maintainer.router';
import { FieldsetModule } from 'primeng/fieldset';

@Component({
  selector: 'moon-chat-log',
  standalone: true,
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.scss'],
  imports: [CommonModule, ButtonModule, RippleModule, FieldsetModule],
  providers: [ChatLogController, MaintainerRouter]
})
export class ChatLogComponent implements OnInit {

  @Input() public MSChatLogID: number | null;
  public MSChatLog: ChatLogGet | null;
  public MSChatLogData: ChatLogDataGet | null;
  public MSIsCurrentUserMaintainer: boolean = false;

  constructor(
    private _componentUtilityService: ComponentUtilityService,
    private _chatLogController: ChatLogController,
    private _clipboard: Clipboard,
    private _userSessionService: UserSessionService,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.MSIsCurrentUserMaintainer = this._userSessionService.IsMaintainer();
    if (this.MSChatLogID) {
      await this.LoadChatLogData(this.MSChatLogID);
    }
  }

  public MSGoToTest(): void {
    this._maintainerRouter.ToSkillTest();
  }


  public MSCopyInputToClipboard(): void {
    if (this.MSChatLogData?.inputData)
      this._clipboard.copy(this.MSChatLogData.inputData);
  }

  private async LoadChatLogData(chatLogID: number) {

    const getLogResult: ApiContentResult<ChatLogGet> = await this._chatLogController.GetByID(chatLogID);
    if (this._componentUtilityService.WasSuccessful(getLogResult)) {
      this.MSChatLog = getLogResult.content;

      const getLogDataResult: ApiContentResult<ChatLogDataGet> = await this._chatLogController.GetChatLogData(chatLogID);
      if (this._componentUtilityService.WasSuccessful(getLogDataResult)) {
        this.MSChatLogData = getLogDataResult.content;
      }
    }
  }

}
