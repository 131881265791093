// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
// Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM
// Moon Auto Generated (1.0.0) at 12/18/2023 10:25:43 AM
// Moon Auto Generated (1.2.0) at 12/22/2023 6:21:39 AM
// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
export class ConstantString {    
    
    
    public static readonly AddDocument: string = 'AddDocument';
    public static readonly AllDocument: string = 'AllDocument';
    public static readonly Blob: string = 'Blob';
    public static readonly Category: string = "Category";
    public static readonly ChatLog: string = "ChatLog";
    public static readonly ChatLogData: string = "ChatLogData";
    public static readonly ChatSkill: string = "ChatSkill";
    public static readonly Chunk: string = "Chunk";
    public static readonly ClauseSearch: string = "ClauseSearch";
    public static readonly CollaboratorVersion1: string = '/Collaborator/v1';
    public static readonly Document: string = "Document"; 
    public static readonly DocumentCategories: string = "DocumentCategories";
    public static readonly DocumentCategory: string = "DocumentCategory";
    public static readonly DocumentChunk: string = "DocumentChunk";
    public static readonly DocumentMetadata: string = "DocumentMetadata";
    public static readonly Download: string = "Download";    
    public static readonly Environment: string = "Environment";
    public static readonly Export: string = "Export";
    public static readonly CascadeFileFunction = "CascadeFileFunction" as const;
    public static readonly FileFunction = "FileFunction" as const;
    public static readonly FileSummary: string = "FileSummary";
    public static readonly Find: string = "Find";
    public static readonly FullText: string = "FullText";
    public static readonly Function: string = "Function";
    public static readonly Import: string = "Import";
    public static readonly MaintainerVersion1: string = '/Maintainer/v1';
    public static readonly Metadata: string = "Metadata";
    public static readonly MezzLoan: string = "MezzLoan";
    public static readonly MezzLoanChunk: string = "MezzLoanChunk";
    public static readonly History: string = "History";
    public static readonly NodeEnvironment: string = "NodeEnvironment";
    public static readonly NodeEnvironmentUserGroupMap: string = "NodeEnvironmentUserGroupMap";
    public static readonly PublicVersion1: string = "/Public/v1";
    public static readonly Remerge: string = "Remerge";
    public static readonly Revise: string = "Revise";
    public static readonly Revision: string = "Revision";
    public static readonly SameChunk = 'SameChunk' as const;
    public static readonly Search = 'Search' as const;
    public static readonly SearchLog: string = "SearchLog";
    public static readonly SearchRating: string = "SearchRating";
    public static readonly Skill: string = 'Skill';
    public static readonly Skills: string = 'Skills';
    public static readonly SkillExample: string = "SkillExample";
    public static readonly SkillTest: string = 'SkillTest';
    public static readonly Summarize: string = "Summarize";
    public static readonly TitleException: string = "TitleException";
    public static readonly Template: string = "Template";
    public static readonly TitleSummary: string = "TitleSummary";
    public static readonly Update: string = 'Update';    
    public static readonly UpdateAll: string = 'UpdateAll';    
    public static readonly UpdateFavorite = 'UpdateFavorite' as const;
    public static readonly UserGroup: string = "UserGroup";
    public static readonly UserInfo: string = "UserInfo";
    public static readonly UserOption: string = "UserOption";
    public static readonly UserOptionMap: string = "UserOptionMap";
    public static readonly UserSessionLog: string = "UserSessionLog";
    public static readonly Vector: string = "Vector";    
    public static readonly Version1: string = '/v1';    
    
}
