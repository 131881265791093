// Moon Auto Generated (1.5.0) at 2/21/2024 10:18:48 AM
export class RoutePath {
    public static readonly Add: string = 'Add';
    public static readonly BlacklineUtility = 'BlacklineUtility' as const;
    public static readonly Blob: string = 'Blob';
    public static readonly ChatLog: string = 'ChatLog';
    public static readonly Chunk: string = 'Chunk';
    public static readonly ClauseSearch: string = 'ClauseSearch';
    public static readonly Complete = 'Complete' as const;
    public static readonly ConnectUser: string = 'ConnectUser';
    public static readonly Detail: string = 'Detail';
    public static readonly DisclaimerAgreement: string = 'DisclaimerAgreement';
    public static readonly DocumentCategory: string = 'DocumentCategory';
    public static readonly Documents: string = 'Documents';
    public static readonly DocxBlob: string = 'DocxBlob';
    public static readonly Download: string = 'Download';
    public static readonly Edit: string = 'Edit';
    public static readonly Empty: string = '';
    public static readonly Environment: string = 'Environment';
    public static readonly Error: string = 'Error';
    public static readonly ExportOcr: string = 'ExportOcr';
    public static readonly ExportPdf: string = 'ExportPdf';
    public static readonly External: string = 'External';
    public static readonly Find: string = 'Find';
    public static readonly Full: string = 'Full';
    public static readonly FullTextSearch: string = 'FullTextSearch';
    public static readonly Login: string = 'Login';
    public static readonly MezzLoan: string = "MezzLoan";
    public static readonly History: string = "History";
    public static readonly NodeEnvironment: string = 'NodeEnvironment';
    public static readonly NodeEnvironmentUserGroupMap: string = 'NodeEnvironmentUserGroupMap';
    public static readonly Ocr: string = 'Ocr';
    public static readonly OcrSkillData: string = 'OcrSkillData';
    public static readonly OverrideExceptionNumber = 'OverrideExceptionNumber' as const;
    public static readonly OverrideExceptionType = 'OverrideExceptionType' as const;
    public static readonly OverrideRecommendedAction = 'OverrideRecommendedAction' as const;
    public static readonly PdfBlob: string = 'PdfBlob';
    public static readonly PdfReview = 'PdfReview' as const;    
    public static readonly PostLogout: string = 'PostLogout';
    public static readonly Process: string = 'Process';
    public static readonly PromptAll: string = 'PromptAll';
    public static readonly PromptExceptionNumber: string = 'PromptExceptionNumber';
    public static readonly PromptExceptionTypeAndAction = 'PromptExceptionTypeAndAction' as const;
    public static readonly PromptSummary: string = 'PromptSummary';
    public static readonly PromptTitleDocument: string = 'PromptTitleDocument';
    public static readonly PromptTitleException: string = 'PromptTitleException';
    public static readonly PromptTitleSummary: string = 'PromptTitleSummary';
    public static readonly PromptTitleSummaryReviseOcrText: string = 'PromptTitleSummaryReviseOcrText';
    public static readonly PromptTitleDocumentOcrText: string = 'PromptTitleDocumentOcrText';
    public static readonly PromptTypeAndAction: string = 'PromptTypeAndAction';
    public static readonly Revise: string = 'Revise';
    public static readonly Revision: string = 'Revision';
    public static readonly SearchLog: string = 'SearchLog';
    public static readonly Skill: string = 'Skill';
    public static readonly SkillExample: string = 'SkillExample';
    public static readonly Summarize: string = 'Summarize';
    public static readonly Template: string = "Template";
    public static readonly Test: string = 'Test';
    public static readonly TitleDocument: string = 'TitleDocument';
    public static readonly TitleDocumentMapList = 'TitleDocumentMapList' as const;
    public static readonly TitleException: string = 'TitleException';
    public static readonly TitleExceptionMapList = 'TitleExceptionMapList' as const;
    public static readonly TitleReview: string = 'TitleReview';
    public static readonly UpdateAll: string = 'UpdateAll';
    public static readonly Upload: string = 'Upload';
    public static readonly UserGroup: string = "UserGroup";
    public static readonly UserGroupMap: string = "UserGroupMap";
    public static readonly UserInfo = 'UserInfo' as const;
    public static readonly UserSessionLog: string = "UserSessionLog";    
}
