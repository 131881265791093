import {Injectable}                     from '@angular/core';
//Naya Imports
import {MoonApiClient}                  from '@moon-core/services/moon-api.client';
import {ConstantString}                 from '@moon-shared/constants/constant-string';
import {ApiContentResult}               from '@moon-core/models/api-result';
import {HistoryGet}                     from '@moon-maintainer/api/response/history-get.response';
@Injectable()
export class HistoryController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.History);
  };

  constructor(private _moonApi: MoonApiClient) { }

    
/**
 *   GET: Maintainer/v1/History
 */
public async GetList(
  ): Promise<ApiContentResult<HistoryGet[]>> {
  
    const url = this._moonApi.combineUrl(this.getBaseUrl());
    return this._moonApi.getAsync<HistoryGet[]>(url);
}

/**
 *   GET: Maintainer/v1/History/EntityType/{entityType}/EntityID/{entityID}
 */
public async GetHistoryByEntityID(entityType: string, entityID: number
  ): Promise<ApiContentResult<HistoryGet[]>> {
  
    const url = this._moonApi.combineUrl(this.getBaseUrl(), "EntityType", entityType, "EntityID", entityID);
    return this._moonApi.getAsync<HistoryGet[]>(url) ;
}

/**
 *   GET: Maintainer/v1/History/EntityType/{entityType}
 */
public async GetHistoryByEntityType(entityType: string): Promise<ApiContentResult<HistoryGet[]>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), "EntityType", entityType);
    return this._moonApi.getAsync<HistoryGet[]>(url) ;
}

/**
 *   GET: Maintainer/v1/History/CurrentPage/{currentPage}/PageSize/{pageSize}
 */
// public async GetAllList(currentPage: number, itemsPerPage: number
//   ): Promise<ApiContentResult<HistoryGet[]>> {
  
//     const url = this._moonApi.combineUrl(this.getBaseUrl(), "CurrentPage", currentPage, "PageSize", itemsPerPage);
//     return this._moonApi.getAsync<HistoryGet[]>(url);
// }
  
}