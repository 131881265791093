<!-- Moon Auto Generated (1.0.0) at 11/22/2023 10:52:50 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>
<div class="clearfix"></div>

<div
  class="summary-main"
  *ngIf="!MSLoading"
>
  <moon-form-sticky-navbar>
    <p-toolbar>
      <div class="p-toolbar-group-start">
        <button
          pButton
          pRipple
          pTooltip="Go Back"
          tooltipPosition="bottom"
          type="button"
          icon="pi pi-arrow-left"
          (click)="MSOnClickGoBack()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSIsProcessInProgress"
        ></button>
        <button
          pButton
          pRipple
          pTooltip="Refresh"
          tooltipPosition="bottom"
          type="button"
          icon="pi pi-refresh"
          (click)="MSOnRefreshClick()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSIsProcessInProgress"
        ></button>

        <i class="pi pi-circle-fill toolbar-separator"></i>

        <button
          pButton
          pRipple
          pTooltip="Upload Document"
          tooltipPosition="top"
          label="Upload"
          type="button"
          icon="pi pi-upload"
          (click)="MSOnClickUploadDocument()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSIsProcessInProgress"
        ></button>

        <button
          pButton
          pRipple
          pTooltip="Chunk"
          tooltipPosition="top"
          label="Chunk"
          type="button"
          icon="pi pi-book"
          pTooltip="Chunk"
          (click)="MSOnClickChunk()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSDoDisableActionButton"
        ></button>

        <button
          pButton
          pRipple
          pTooltip="Vector"
          tooltipPosition="top"
          type="button"
          icon="pi pi-inbox"
          label="Vectorize"
          (click)="MSOnClickVectorAsCSV()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSDoDisableActionButton"
        ></button>

        <i class="pi pi-circle-fill toolbar-separator"></i>

        <!-- <button
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          label="Update All"
          (click)="MSOnClickUpdateAll()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSIsProcessInProgress"
        ></button> -->

        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-list"
          label="Categories"
          pTooltip="View Document Categories"
          tooltipPosition="top"
          (click)="MSOnClickDocumentCategory()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSIsProcessInProgress"
        ></button>

        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-file-export"
          pTooltip="Export Document with Metadata"
          tooltipPosition="top"
          (click)="MSOnClickDocumentExport()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [disabled]="MSIsProcessInProgress"
          [loading]="MSDownloadInProgressZip"
          loadingIcon="pi pi-spin pi-spinner"
        ></button>

        <i class="pi pi-circle-fill toolbar-separator"></i>
        
        <p-fileUpload #MSFileUpload
          mode="basic" accept=".csv" [maxFileSize]="1000000" 
          [auto]="true" 
          (uploadHandler)="MSOnClickMetadataImport($event, MSFileUpload)" 
          [customUpload]="true"
          pTooltip="Metadata Import"
          [disabled]="MSIsProcessInProgress"
          class="p-button-raised mr-2 mb-2 moon-button"
        ></p-fileUpload>

        <button
          pButton
          pRipple
          pTooltip="Metadata Export"
          tooltipPosition="top"
          type="button"
          icon="pi pi-file-export"
          (click)="MSOnClickMetadataExport()"
          class="p-button-raised mr-2 mb-2 moon-button"
          [loading]="MSDownloadInProgress"
          loadingIcon="pi pi-spin pi-spinner"
          [disabled]="MSIsProcessInProgress"
        >
        </button>

      </div>

      <div class="p-toolbar-group-end">
        <moon-search-field
          [MSSearchTerm]="MSSearchTerm"
          (MSSearchTermOutput)="MSOnChangeSearchField($event)"
        ></moon-search-field>
      </div>
    </p-toolbar>
  </moon-form-sticky-navbar>

  <div
    class="moon-progress-bar"
    *ngIf="MSIsProcessInProgress"
  >
    <p class="moon-progress-message">{{ MSProcessMessage }}</p>
    <p-progressBar
      mode="indeterminate"
      styleClass="h-1px"
    ></p-progressBar>
  </div>

  <div
    class="card moon-card"
    *ngIf="!MSLoading"
  >
    <document-table
      #MSDocumentTable
      [MSSearchTerm]="MSSearchTerm"
      [MSPageTitle]="MSPageTitle"
      [MSEnvironmentName]="MSEnvironmentName"
      [MSDocumentList]="MSDocumentList"
      [MSLoading]="MSLoading"
    ></document-table>
  </div>
</div>