// Moon Auto Generated (1.0.0) at 11/27/2023 10:27:34 AM
import { Component, Input, OnInit,
    ViewChild, ViewEncapsulation }      from "@angular/core";
import { DatePipe, NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { Table, TableModule }           from "primeng/table";
import { DialogService, 
    DynamicDialogRef }                  from "primeng/dynamicdialog";
// Moon imports
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { SearchLogFind }                from "@moon-maintainer/api/response/search-log-find.response";
import { SearchLogGet }                 from "@moon-maintainer/api/response/search-log-get.response";
import { MaintainerRouter }             from '@moon-maintainer/maintainer.router';
import {SearchLogDialogComponent}       from '@moon-maintainer/search-log/search-log-dialog/search-log-dialog.component';

@Component({
    selector: "search-log-table",
    templateUrl: "./search-log-table.component.html",
    styleUrls: ['./search-log-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
              TableModule, DatePipe,
              MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter, DialogService]
})
export class SearchLogTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSSearchLogList: SearchLogFind[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.Content.toCamelCase(), header: FieldNames.Content.getDisplayName(), class: 'w-600per'},
		{field: FieldNames.DurationInMs.toCamelCase(), header: FieldNames.DurationInMs.getDisplayName(), class: 'w-10per'},
        {field: FieldNames.LastUpdatedBy.toCamelCase(), header: FieldNames.LastUpdatedBy.getDisplayName(), class: 'w-15per'},
        {field: FieldNames.LastUpdatedAtUtc.toCamelCase(), header: FieldNames.LastUpdatedAtUtc.getDisplayName(), class: 'w-15per'},
        // Column PlaceHolder - DO NOT REMOVE
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public Ref: DynamicDialogRef | undefined;

    @ViewChild("MSTable") MSTable: Table;

    constructor(private _maintainerRouter: MaintainerRouter,
        public dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = false;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: SearchLogGet): void {
        this.Ref = this.dialogService.open(SearchLogDialogComponent, {
            header: 'Search Result',
            width: '70%',
            data: {
                InputContent: item.content,
                SearchResultJson: item.searchResultJson
            }
        });
        // console.log('OnClickItem not implemented', item);
    }

    public OnClickCreate(): void {
    }

    public OnClickFind(): void {
        this._maintainerRouter.ToSearchLog();
    }

    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }
}
