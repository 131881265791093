import { CommonModule }                                               from '@angular/common';
import { Component, EventEmitter, Input, Output }                     from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }    from '@angular/forms';
// Third party imports
import { ConfirmationService }                                        from 'primeng/api';
import { BadgeModule }                                                from 'primeng/badge';
import { ButtonModule }                                               from 'primeng/button';
import { DialogModule }                                               from 'primeng/dialog';
import { InputTextModule }                                            from 'primeng/inputtext';
import { InputTextareaModule }                                        from 'primeng/inputtextarea';
import { RippleModule }                                               from 'primeng/ripple';
// Moon Imports
import { ApiResult }                                                  from '@moon-core/models/api-result';
import { ComponentUtilityService }                                    from '@moon-core/services/component-utility.service';
import { TitleExceptionGet }                                          from '@moon-public/api/response/title-exception.response';
import { TitleExceptionController }                                   from '@moon-public/api/title-exception.controller';
import { ChatLogComponent }                                           from '@moon-public/title-summary/chat-log/chat-log.component';
import { MoonFormControlComponent }                                   from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MessageSeverity }                                            from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                         from '@moon-shared/services/moon-message.service';

@Component({
  selector: 'moon-title-exception',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, RippleModule, BadgeModule, InputTextModule, InputTextareaModule, DialogModule,
    ChatLogComponent, MoonFormControlComponent,
  ],
  templateUrl: './title-exception.component.html',
  styleUrls: ['./title-exception.component.scss'],
  providers: [TitleExceptionController]
})
export class TitleExceptionComponent {
  public MSSaving: boolean = false;
  @Input() public MSTitleException: TitleExceptionGet;
  public MSEditAction: boolean = false;
  public MSEditType: boolean = false;
  public MSActionFormGroup: FormGroup<{ recommendedAction: FormControl<string> }>;
  public MSTypeFormGroup: FormGroup<{ exceptionType: FormControl<string> }>;
  @Output() public MSOnTitleExceptionChange = new EventEmitter();
  public MSShowChatLog: boolean = false;
  public MSChatLogID: number;

  constructor(
    private _moonMessageService: MoonMessageService,
    private _titleExceptionController: TitleExceptionController,
    private _componentUtilityService: ComponentUtilityService,
    private _confirmationService: ConfirmationService
  ) { }

  public async MSOnClickChatLog(){
    if (this.MSTitleException.chatLogID !== null) {
      this.MSChatLogID = this.MSTitleException.chatLogID;
      this.MSShowChatLog = true;
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Exception Chat Log Not Found.")
    }
  }
  public async MSOnClickPromptException() {

    if (this.MSTitleException.hasError) {
      this.doPromptException();
    }
    else {
      this._confirmationService.confirm({
        message: "This prompt is already completed. Are you sure you want to continue?",
        acceptLabel: "Yes",
        accept: () => this.doPromptException(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptException() {
    this.MSSaving = true;
    const apiResult: ApiResult = await this._titleExceptionController.PromptExceptionTypeAndAction(this.MSTitleException.titleExceptionID, false);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt exception successful.");
      this.MSOnTitleExceptionChange.emit();
    }
    this.MSSaving = false;
  }


  public MSToggleActionEdit() {
    this.MSEditAction = !this.MSEditAction;
    if (this.MSActionFormGroup == null) {
      this.initializeActionFormGroup();
    }
  }

  public MSToggleTypeEdit() {
    this.MSEditType = !this.MSEditType;
    if (this.MSTypeFormGroup == null) {
      this.initializeTypeFormGroup();
    }
  }

  public MSSaveRecommendedAction() {
    this.MSActionFormGroup.markAllAsTouched();
    this.MSActionFormGroup.updateValueAndValidity();
    if (this.MSActionFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid recommended action.")
      return;
    }
    this.doOverrideRecommendedAction();
  }

  public async MSSaveExceptionType() {
    this.MSTypeFormGroup.markAllAsTouched();
    this.MSTypeFormGroup.updateValueAndValidity();

    if (this.MSTypeFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid exception type.")
      return;
    }
    this.doOverrideExceptionType();
  }

  private initializeActionFormGroup() {
    this.MSActionFormGroup = new FormGroup({
      recommendedAction: new FormControl<string>(this.MSTitleException.overrideExceptionType ?? this.MSTitleException.recommendedAction,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(500)]
        })
    });
  }

  private initializeTypeFormGroup() {
    this.MSTypeFormGroup = new FormGroup({
      exceptionType: new FormControl<string>(this.MSTitleException.overrideExceptionType ?? this.MSTitleException.exceptionType,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(100)]
        })
    });
  }

  private async doOverrideExceptionType() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleExceptionController.OverrideExceptionType(this.MSTitleException.titleExceptionID, this.MSTypeFormGroup.getRawValue().exceptionType );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Exception type updated successfully.");
        this.MSOnTitleExceptionChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
  private async doOverrideRecommendedAction() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleExceptionController.OverrideRecommendedAction(this.MSTitleException.titleExceptionID, this.MSActionFormGroup.getRawValue().recommendedAction );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Recommended action updated successfully.");
        this.MSOnTitleExceptionChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
}
