import { Injectable }                                   from "@angular/core";
// MS Imports
import { ApiContentResult, IApiResult }                 from "@moon-core/models/api-result";
import { FlatUserOption, ValidUserOptionValueTypes }    from "@moon-core/models/flat-user-option";
import { ComponentUtilityService }                      from "@moon-core/services/component-utility.service";
import { MoonApplicationInsight }                       from "@moon-core/services/moon-application-insight.service";
import { UserSessionService }                           from "@moon-core/services/user-session.service";
import { UserOptionController }                         from "@moon-maintainer/api/user-option.controller";
import { UserOptionGet }                                from "@moon-public/api/response/user-option-get.response";
import { UserOptionMapGet }                             from "@moon-public/api/response/user-option-map-get.response";
import { UserOptionMapController }                      from "@moon-public/api/user-option-map.controller";

@Injectable()
export class UserOptionService {
    private _userOptionMap: UserOptionMapGet[];
    private _flatUserOption: FlatUserOption;
    public get MSUserOption(): FlatUserOption {
        return this._flatUserOption;
    }

    constructor(
        private _componentUtilityService: ComponentUtilityService,
        private _moonApplicationInsights: MoonApplicationInsight,
        private _userOptionMapController: UserOptionMapController,
        private _userOptionController: UserOptionController,
        private _userSessionService: UserSessionService
    ) {
    }

    public async SetUserOptionMapList() {
        const apiResult = await this._userOptionMapController.GetList();
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            this._userOptionMap = apiResult.content;
            this._flatUserOption = new FlatUserOption(this._userOptionMap);
        } else {
            this.logEvent(`Loading User Option Map List FAILED.`);
            throw new Error(apiResult.message);
        }
    }
    /** @param newValue latest unparsed value */
    public async MSCreateOrUpdateUserOptionMap(optionName: keyof FlatUserOption, newValue: ValidUserOptionValueTypes): Promise<void | IApiResult> {
        const oldValue: string | undefined = this.getUserOptionMap(optionName)?.optionValue
        const newValueStrigified = JSON.stringify(newValue);

        if (newValueStrigified === oldValue) {
            return Promise.resolve();
        }

        let userOptionMapBody = this.getUserOptionMap(optionName);

        if (!userOptionMapBody) {
            const userOptionMap = {
                optionValue: newValueStrigified,
                userInfoID: this._userSessionService.GetCurrentUserSession.userInfoID,
                userOptionID: await this.getUserOptionID(optionName)
            };
            // get user option from server;
            const apiResult = await this._userOptionMapController.Create(userOptionMap);
            return apiResult;

        }

        const userOptionMap = {
            optionValue: newValueStrigified,
            userInfoID: this._userSessionService.GetCurrentUserSession.userInfoID,
            userOptionID: userOptionMapBody.userOptionID
        };
        const apiResult: IApiResult = userOptionMapBody?.userOptionMapID
            ? await this._userOptionMapController.Update(userOptionMapBody.userOptionMapID, userOptionMap)
            : await this._userOptionMapController.Create(userOptionMap);
        return apiResult;

    }

    private getUserOptionMap(optionName: keyof FlatUserOption) {
        return this._userOptionMap.find(a => optionName.equalsIgnoreCase(a.optionName));
    }
    private async getUserOptionID(userOptionName: keyof FlatUserOption): Promise<number> {
        const apiGetResult: ApiContentResult<UserOptionGet> = await this._userOptionController.GetByName(userOptionName);
        if (this._componentUtilityService.WasSuccessful(apiGetResult)) {
            return apiGetResult.content.userOptionID;
        }
        return Promise.reject(apiGetResult);
    }

   

    private logEvent(event: string) {
        this._moonApplicationInsights.logEvent(event);
    }
}