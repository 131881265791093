import { EventEmitter, Input, Output, Component }         from '@angular/core';
import { NgIf }                                           from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { RippleModule }                                   from 'primeng/ripple';
import { ButtonModule }                                   from 'primeng/button';
import { FormStickyNavbarComponent }                      from '@moon-shared/components/form-sticky-navbar/form-sticky-navbar.component';
import { FormStickyFooterComponent }                      from '@moon-shared/components/form-sticky-footer/form-sticky-footer.component';
@Component({
    selector: 'moon-form-page',
    templateUrl: './moon-form-page.component.html',
    styleUrls: ['./moon-form-page.component.scss'],
    standalone: true,
    imports: [
      FormsModule, ReactiveFormsModule, NgIf, ButtonModule, RippleModule, 
      FormStickyNavbarComponent, FormStickyFooterComponent]
})
export class MoonFormPageComponent {

  @Input() public MSParentForm: FormGroup;
  @Input() public MSIsFormSaving: boolean;
  @Input() public MSDisplaySaveButton: boolean = true;
  @Output() public MSOnClickEventSubmit = new EventEmitter();
  @Output() public MSOnClickEventCancel = new EventEmitter();

  constructor() { }

  public OnClickSubmit() {
    this.MSOnClickEventSubmit.emit();
  }

  public OnClickCancel() {
    this.MSOnClickEventCancel.emit();
  }

}
