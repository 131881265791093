<!-- Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM -->
<moon-form-page [MSParentForm]="MSParentForm"
                [MSIsFormSaving]="MSIsFormSubmitting"
                (MSOnClickEventSubmit)="OnClickSubmit()"
                (MSOnClickEventCancel)="OnClickCancel()">
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'User Group'"
            [MSFormControl]="MSParentForm.controls.userGroupSelectItem">
            <p-autoComplete
                formControlName="userGroupSelectItem"
                field="label"
                [suggestions]="MSFilteredUserGroupSelectItem"
                [readonly]="MSDisableUserGroup"
                [dropdown]="!MSDisableUserGroup"
                [forceSelection]="true"
                (onSelect)="MSSetSelectedUserGroup($event.value)"
                (completeMethod)="MSFilterUserGroup($event)"
            ></p-autoComplete>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Node Environment'"
            [MSFormControl]="MSParentForm.controls.nodeEnvironmentSelectItem">
            <p-autoComplete
                formControlName="nodeEnvironmentSelectItem"
                field="label"
                [suggestions]="MSFilteredNodeEnvironmentSelectItem"
                [readonly]="MSDisableEnvironment"
                [dropdown]="!MSDisableEnvironment"
                [forceSelection]="true"
                (onSelect)="MSSetSelectedNodeEnvironment($event.value)"
                (completeMethod)="MSFilterEnvironment($event)"
            ></p-autoComplete>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Sort Key'"
            [MSFormControl]="MSParentForm.controls.sortKey">
            <input
                type="number"
                formControlName="sortKey"
                pInputText
            />
        </moon-form-control>

        <!-- <moon-form-control
            [MSLabel]="'Status'"
            [MSFormControl]="MSParentForm.controls.status"
        >
            <input
                type="text"
                formControlName="status"
                pInputText
            />
        </moon-form-control> -->

        <moon-form-control
        [MSLabel]="'User Status'"
        [MSFormControl]="MSParentForm.controls.statusSelectItem"
    >
        <p-dropdown
            formControlName="statusSelectItem"
            [options]="MSUserGroupStatusSelectItem"
            optionLabel="label"
            (onChange)="OnChangeUserGroupStatus($event.value)"
        ></p-dropdown>
    </moon-form-control>

    </div>
</moon-form-page>
