<!-- Moon Auto Generated (1.0.0) at 11/26/2023 11:22:45 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
    <chat-log-table [MSPageTitle]="MSPageTitle"
                [MSChatLogList]="MSChatLogList"
                [MSLoading]="MSLoading"
                [(MSFindChatLogParameter)]="MSFindChatLogParameter"
                (MSDateChangedEvent)="MSOnDateChanged()"
                ></chat-log-table>
</div>
