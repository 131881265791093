<div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo">
            <img
                class="moon-logo"
                id="app-logo"
                src="assets/layout/images/logo2x.png"
                alt="ultima-layout"
                (click)="OnMoonLogoClick()"
            />
        </a>

        <a
            class="layout-menu-button shadow-6"
            pTooltip="Toggle Menu ( Ctrl + [ )"
            (click)="appMain.onMenuButtonClick($event)"
            pRipple
        >
            <i class="pi pi-chevron-right"></i>
        </a>

        <a
            class="layout-topbar-mobile-button"
            (click)="appMain.onTopbarMobileButtonClick($event)"
            pRipple
        >
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div
        class="layout-topbar-right"
        [ngClass]="{
            'layout-topbar-mobile-active': appMain.mobileTopbarActive
        }"
    >
        <div class="layout-topbar-actions-left">
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item layout-topbar-action moon-user-connection">
                    <user-connection-info></user-connection-info>
                </li>
                
                <li class="layout-topbar-item">
                    <a
                        class="layout-topbar-action flex p-dir-row justify-content-center align-items-center px-2 moon-logout"
                        (click)="MSLogout()"
                        pRipple
                    >
                        <i
                            class="pi pi-power-off"
                            [ngClass]="{
                                'mr-2': !app.isRTL,
                                'ml-2': app.isRTL
                            }"
                        ></i>
                        <span>Logout</span>
                    </a>
                </li>
                <li class="moon-custom-dropdown-li">
                    <moon-custom-dropdown [model]="MSLogoutMenuItems">
                        .
                    </moon-custom-dropdown>
                </li>
            </ul>
        </div>
    </div>
</div>