<!-- Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSMezzLoanList"
    [MSDisplayCreateButton]="false"
    [MSDisplayFindButton]="MSDisplayFind"
    [MSDisplayUploadButton]="MSDisplayUpload"
    [MSAcceptedFileExtension]="MSAcceptedFileExtension"
    (MSEventOnClickCreate)="OnClickCreate()"
    (MSEventOnUploadFile)="OnUploadFile($event)"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [rowHover]="true"
        [value]="MSMezzLoanList"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-striped"
        [loading]="MSUploading"
        [scrollable]="true"
    >
        <ng-template pTemplate="caption">
            <moon-table-header
                #MSTableHeader
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="MSDisplayCreate"
                [MSDisplayFindButton]="MSDisplayFind"
                [MSDisplayUploadButton]="MSDisplayUpload"
                [MSAcceptedFileExtension]="MSAcceptedFileExtension"
                (MSEventOnClickCreate)="OnClickCreate()"
                (MSEventOnSearchInput)="OnSearchInput($event)"
                (MSEventOnUploadFile)="OnUploadFile($event)"
            >
            </moon-table-header>

        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'downloadActions' || col.field !== 'actions'}"
                >
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'downloadActions' || col.field === 'actions',
                    clickable: col.field !== 'downloadActions' || col.field !== 'actions'
                }"
                    (click)="col.field === 'downloadActions' || col.field === 'actions' || OnClickItem(rowData)"
                >
                    <div [ngSwitch]="col.field">
                        <div
                            *ngSwitchCase="'downloadActions'"
                            class="text-center"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                class="moon-button-margin"
                                (click)="OnClickDownloadOriginalDocument(rowData)"
                                label="Original"
                                icon="pi pi-file-word"
                                pTooltip="Download Original Document"
                                tooltipPosition="top"
                            ></button>
                            <button
                                pButton
                                pRipple
                                type="button"
                                class="moon-button-margin"
                                (click)="OnClickDownloadRevisedDocument(rowData)"
                                label="Revised"
                                icon="pi pi-file-word"
                                pTooltip="Download Revised Document"
                                tooltipPosition="top"
                            ></button>
                            <button
                                pButton
                                pRipple
                                type="button"
                                class="moon-button-margin"
                                (click)="OnClickDownloadComparedDocument(rowData)"
                                label="Compared"
                                icon="pi pi-file-word"
                                pTooltip="Download Compared Document"
                                tooltipPosition="top"
                            ></button>
                        </div>
                        <div
                            *ngSwitchCase="'actions'"
                            class="text-center"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                (click)="OnClickRemergeDocument(rowData)"
                                label="Remerge Document"
                                icon="pi pi-bolt"
                                pTooltip="Remerge"
                                tooltipPosition="top"
                            ></button>
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>