// Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM
import { NgIf }                             from '@angular/common';
import { Component, OnInit }                from '@angular/core';
import { ActivatedRoute, Router }           from '@angular/router';
//Third Party Imports
import { TooltipModule }                    from 'primeng/tooltip';
import { ConfirmationService, MenuItem, PrimeIcons } from 'primeng/api';
// Moon Imports
import { ApiContentResult, ApiResult }      from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { MoonLoadingComponent }             from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { SkillExampleGet }                  from "@moon-maintainer/api/response/skill-example-get.response";
import { SkillController }                  from '@moon-maintainer/api/skill.controller';
import { SkillExampleTableComponent }       from '@moon-maintainer/skill-example/skill-example-table/skill-example-table.component';
import { SkillExampleController }           from '@moon-maintainer/api/skill-example.controller';
import { RouteParameter }                   from '@moon-shared/constants/route-parameter';
import {MaintainerRouter}                   from '@moon-maintainer/maintainer.router';
import { AppBreadcrumbService }             from '@app-layout/services/app.breadcrumb.service';
import { RoutePath }                        from '@moon-shared/constants/route-path';
import { SkillGet }                         from '@moon-maintainer/api/response/skill-get.response';
import { HistoryService }                   from '@moon-shared/services/history.service';

@Component({
  selector: 'skill-example',
  templateUrl: './skill-example.component.html',
  styleUrls: ['./skill-example.component.css'],
  standalone: true,
  imports: [
    NgIf,
    MoonLoadingComponent,
    SkillExampleTableComponent,
    TooltipModule 
  ],
  providers: [
    SkillController,
    SkillExampleController,
    MaintainerRouter,
  ]
})
export class SkillExampleComponent implements OnInit {

  public MSSkillExampleList: SkillExampleGet[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.SkillExample.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSSkillID: number | null;
  public MSNavigatedFromEdit: boolean = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _skillController: SkillController,
    private _maintainerRouter: MaintainerRouter,
    private _confirmationService: ConfirmationService,
    private _skillExampleController: SkillExampleController,
    private _router: Router,
    private _historyService: HistoryService

  ) { 
    const navigation = this._router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {navigatedFromEdit: boolean};
      this.MSNavigatedFromEdit = state?.navigatedFromEdit;
    }
  }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSSkillID){
      this.setHistoryEntityValues(true, ConstantString.SkillExample, null);
      await this.loadSkillExampleList(this.MSSkillID);
      this.getSkill(this.MSSkillID);
    }
  }
  
  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Skill, routerLink: this._maintainerRouter.SkillRouteLink() }, 
      { label: RoutePath.SkillExample.getDisplayName(), routerLink: null }
    ];
    
    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }

  private readRouteParameters() {
    const skillID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.SkillID);
    this.MSSkillID = skillID ? +skillID : null;
  }

  private async loadSkillExampleList(skillID: number) {
    this.MSLoading = true;
    
    const apiResult: ApiContentResult<SkillExampleGet[]> = await this._skillController.GetSkillExampleList(skillID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.onGetListSuccess(apiResult.content);
    };
    this.MSLoading = false;  
  }

  private onGetListSuccess(skillExampleList: SkillExampleGet[]) {
    if (skillExampleList === null) {
        this.MSLoading = true;
        return;
    }

    this.MSSkillExampleList = skillExampleList.map((item: SkillExampleGet) => {
        item.actions = this.getActions(this.MSSkillID ?? 0 , item);
        return item;
    });
    this.MSLoading = false;
  } 

  private getActions(skillID: number, item: SkillExampleGet) {
    return [
        {
            icon: PrimeIcons.PENCIL,
            label: 'Edit Item',
            command: () => {
                this._maintainerRouter.ToSkillExampleEdit(skillID, item.skillExampleID);
            }
        },
        {
            icon: PrimeIcons.TRASH,
            label: 'Delete Item',
            command: () => {
                this._confirmationService.confirm({
                    message: `Are you sure you want to remove`,
                    accept: () => {
                        this.doRemove(skillID, item.skillExampleID);
                    },
                });
            
            }
        }
    ];
}

private async doRemove(skillID: number, skillExampleID: number) {
  const apiResult: ApiResult = await this._skillExampleController.Delete(skillExampleID);
  if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.loadSkillExampleList(skillID);
  }
}

  private async getSkill(skillID: number) {
      const apiResult: ApiContentResult<SkillGet> = await this._skillController.GetByID(skillID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSPageTitle += `: ${apiResult.content.skillName}`;
    }
  }
  
  private setHistoryEntityValues(showButton: boolean, entityType: string | null, entityID: number | null): void {
    this._historyService.setHistoryEntityValues(showButton, entityType, entityID, null);
  }

  ngOnDestroy(): void {
    this.setHistoryEntityValues(false, null, null);
  }
}
