// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM
import { Component, Input, OnInit,
    ViewChild, ViewEncapsulation }      from "@angular/core";
import { NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { NodeEnvironmentGet }           from "@moon-maintainer/api/response/node-environment-get.response";
import { MaintainerRouter }             from '@moon-maintainer/maintainer.router';

@Component({
    selector: "node-environment-table",
    templateUrl: "./node-environment-table.component.html",
    styleUrls: ['./node-environment-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
              TableModule, ButtonModule, InputTextModule,
              MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter]
})
export class NodeEnvironmentTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSNodeEnvironmentList: NodeEnvironmentGet[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.EnvironmentName.toCamelCase(), header: FieldNames.EnvironmentName.getDisplayName(), class: 'w-25per'},
        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-20per'},
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;

    @ViewChild("MSTable") MSTable: Table;

    constructor(private _maintainerRouter: MaintainerRouter) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: NodeEnvironmentGet): void {
        this._maintainerRouter.ToNodeEnvironmentEdit(item.nodeEnvironmentID);
    }
    
    public OnShowUserGroupsClick(item: NodeEnvironmentGet): void {
        this._maintainerRouter.ToNodeEnvironmentUserGroupMap(item.nodeEnvironmentID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.ToNodeEnvironmentAdd();
    }

    public OnClickFind(): void {
        this._maintainerRouter.ToNodeEnvironment();
    }

    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }
}
