import { inject } from '@angular/core';
// MS Imports
import { AppRouter } from "../../app.router";
import { UserSessionService } from '@moon-core/services/user-session.service';

export const MaintainerRouteGuard = (): boolean => {
    
    const _appRouter = inject(AppRouter);
    const service = inject(UserSessionService);
    if (service.IsMaintainer())
    {
        return true;
    }
    else{
        _appRouter.RouteToDefault();
        return false;
    }
}
