<div class="flex">
    <!-- filter result component -->
    <div
        *ngIf="MSShowFilter"
        id="moon-doc-filter"
        class="card col-12 md:col-3 mr-2"
        [ngClass]="MSShowFilter ? 'fadeinleft animation-duration-100' : 'hidden'"
    >
        <ms-filter-result
            [MSResultFilter]="MSSearchResultFilter"
            (MSResultFilterChangedEvent)="MSOnResultFilterChangedEvent($event)"
            (MSResultFilterClearedEvent)="MSOnResultFilterClearedEvent()"
        ></ms-filter-result>
    </div>

    <!-- display result component -->
    <div
        class="mt-2"
        [ngClass]="MSShowFilter ? 'md:col-9' : 'col-12'"
        id="moon-doc-result-data-view"
    >
        <p-dataView
            #dv
            [value]="MSFilteredSearchChunkList"
            [paginator]="true"
            [rows]="50"
            [showCurrentPageReport]="true"
            emptyMessage="No results found! Can you provide more detail for Clause Search? Or use Full Text Search instead."
        >
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-cneter flex-wrap">
                    <div class="grid gap-5 align-items-center">
                        <div class="grid gap-2">
                            <p-inputSwitch
                                inputId="togglefilter"
                                [(ngModel)]="MSShowFilter"
                            ></p-inputSwitch>
                            <label for="togglefilter">{{MSShowFilter ? 'Hide ' : 'Show '}} Filter</label>
                        </div>
                    </div>
                    <div
                        *ngIf="MSFilteredSearchChunkList"
                        class="mr-2"
                    >
                        <span
                            *ngIf="MSTotalExactMatch"
                            class="mr-2"
                        >
                            <ng-container>
                                {{MSIncludeExactMatches ? 'Includes': 'Excludes'}}
                            </ng-container>
                            {{MSTotalExactMatch }} exact
                            match(s)
                        </span>
                        <span>
                            {{MSShowingOutputContent}}
                            <span
                                *ngIf="MSTimeElapsedForResult"
                                class="text-right text-600"
                            >
                                ({{MSTimeElapsedForResult / 1000 | number: '1.0-3'}}s)
                            </span>
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template
                let-similarChunk
                pTemplate="listItem"
            >
                <div class="col-12">
                    <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                        <div
                            class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                            <div class="flex flex-column align-items-center sm:align-items-start gap-2 w-full">
                                <div
                                    class="flex flex-row-reverse justify-content-between align-items-center flex-wrap w-full">
                                    <div class="align-self-start">
                                        <button
                                            *ngIf="similarChunk.totalMatchingChunk"
                                            pButton
                                            pRipple
                                            class="p-button-outlined cursor-pointer"
                                            label="Show Other Matches ({{similarChunk.totalMatchingChunk}})"
                                            (click)="MSOnClickShowOtherMatches(similarChunk)"
                                        ></button>
                                        <button
                                            *ngIf="MSIsCollaborator"
                                            pButton
                                            pRipple
                                            class="p-button-outlined cursor-pointer"
                                            icon="pi pi-cloud-download"
                                            pTooltip="Download Document"
                                            tooltipPosition="top"
                                            (click)="MSOnClickDownloadDocument(similarChunk)"
                                        ></button>
                                    </div>
                                    <div>
                                        <div class="flex gap-2 text-lg font-bold align-items-center">
                                            {{ similarChunk.documentName }}
                                            <p-badge
                                                pTooltip="Relevance"
                                                value="{{(similarChunk.relevance) * 100 | number:'1.0-0'}}"
                                            ></p-badge>

                                            <ng-container *ngIf="MSIsUserFavoritesAllowed;
                                                then updateFavorite;
                                                else showFavoriteOnly">
                                            </ng-container>

                                            <ng-template #updateFavorite>
                                                <i
                                                    class="text-yellow-400 cursor-pointer"
                                                    [ngClass]="similarChunk.savedAsFavorite === 'Y' ? 'pi pi-star-fill' : 'pi pi-star'"
                                                    (click)="MSOnClickSaveAsFavorite(similarChunk)"
                                                    [pTooltip]="favoriteTooltipContent"
                                                ></i>
                                            </ng-template>
                                            
                                            <ng-template #showFavoriteOnly>
                                                <ng-container *ngIf="similarChunk.savedAsFavorite === 'Y'">
                                                    <i
                                                        class="pi pi-star-fill text-yellow-400"
                                                        [pTooltip]="favoriteTooltipContent"
                                                    ></i>
                                                </ng-container>
                                            </ng-template>

                                            <ng-template #favoriteTooltipContent>
                                                <ng-container
                                                    *ngIf="similarChunk.savedAsFavorite === 'Y'; else markAsFavoriteMessage"
                                                >
                                                    <div class="mb-1">
                                                        Marked by: {{ similarChunk.favoriteLastUpdatedBy}}
                                                    </div>
                                                    <div>
                                                        Notes: {{similarChunk.favoriteNotes}}
                                                    </div>
                                                </ng-container>
                                                <ng-template #markAsFavoriteMessage>Mark as favorite</ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex align-items-center w-full">
                                    <span class="white-space-pre-wrap">{{ similarChunk.content }}</span>
                                </div>
                                <div class="flex align-items-center w-full">
                                    <p-fieldset
                                        legend="Black Line"
                                        styleClass="moon-fieldset"
                                    >
                                        <ng-template pTemplate="content">
                                            <ms-black-lines
                                                [MSOriginalText]="MSOriginalSearchText"
                                                [MSModifiedText]="similarChunk.content"
                                            ></ms-black-lines>
                                        </ng-template>
                                    </p-fieldset>
                                </div>
                                <!-- Source Metadata Tag -->
                                <div class="flex align-items-center flex-wrap gap-1">
                                    <!-- <span *ngIf="similarChunk.documentChunkID">
                                        <p-tag severity="danger" value="DocumentChunkID: {{similarChunk.documentChunkID}}"></p-tag>
                                    </span> -->
                                    <span *ngIf="similarChunk.year">
                                        <p-tag value="Year: {{similarChunk.year}}"></p-tag>
                                    </span>
                                    <ng-container *ngFor="let metadata of similarChunk.documentMetaData | keyvalue">
                                        <span *ngIf="metadata.value !== ''">
                                            <p-tag
                                                value="{{metadata.key?.toString()?.getDisplayName()}}: {{metadata.value}}"
                                            ></p-tag>
                                        </span>
                                    </ng-container>
                                    <span *ngIf="similarChunk.environmentName">
                                        <p-tag value="Environment: {{similarChunk.environmentName}}"></p-tag>
                                    </span>
                                </div>
                                <!-- Additional Info -->
                                <div class="flex align-items-center w-full">
                                    <p-fieldset
                                        class="w-full"
                                        legend="Additional Info"
                                        styleClass="moon-fieldset"
                                        [toggleable]="true"
                                        [collapsed]="true"
                                        (onBeforeToggle)="MSOnAdditionInfoBeforeToggle($event, similarChunk)"
                                    >
                                        <ng-template pTemplate="content">
                                            <div
                                                *ngIf="similarChunk.neighborChunkList; else loadingAdditionalInfo"
                                                class="mb-5"
                                            >
                                                <div
                                                    class="mb-2 pl-2 border-left-1"
                                                    *ngFor="let document of similarChunk.neighborChunkList"
                                                >
                                                    <ng-container
                                                        *ngIf="document.ordinal === similarChunk.ordinal; else additionSourceContent"
                                                    >
                                                        <!-- Black line text -->
                                                        <ms-black-lines
                                                            [MSOriginalText]="MSOriginalSearchText"
                                                            [MSModifiedText]="document.content"
                                                        >
                                                        </ms-black-lines>
                                                    </ng-container>
                                                    <ng-template #additionSourceContent>
                                                        {{document.content}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <ng-template #loadingAdditionalInfo>
                                                <p-skeleton styleClass="mb-2"></p-skeleton>
                                                <p-skeleton styleClass="mb-2"></p-skeleton>
                                                <p-skeleton styleClass="mb-2"></p-skeleton>
                                                <p-skeleton styleClass="mb-2"></p-skeleton>
                                                <p-skeleton styleClass="mb-2"></p-skeleton>
                                            </ng-template>
                                        </ng-template>
                                    </p-fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
</div>