// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
import { Route }                            from "@angular/router";
//Third Party Imports
import { MsalGuard }                        from "@azure/msal-angular";
// MS Imports
import { UserSessionGuard }                 from "@moon-core/guards/user-session.guard";
import { ClauseSearchComponent }            from '@moon-public/clause-search/clause-search.component';
import {FullTextSearchComponent}            from '@moon-public/full-text-search/full-text-search.component';
import { RoutePath }                        from "@moon-shared/constants/route-path";

export const PublicRoutes: Route[] = [
    { path: RoutePath.ClauseSearch, component: ClauseSearchComponent, canActivate: [MsalGuard, UserSessionGuard] },
    { path: RoutePath.FullTextSearch, component: FullTextSearchComponent, canActivate: [MsalGuard, UserSessionGuard] }
    // Placeholder for route
];
