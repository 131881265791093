// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
import { NgIf }                                       from '@angular/common';
import { Component, OnInit }                          from '@angular/core';
import { ActivatedRoute }                             from '@angular/router';
import { MenuItem }                                   from 'primeng/api';
// Moon Imports                 
import { AppBreadcrumbService }                       from '@app-layout/services/app.breadcrumb.service';

import { ApiContentResult }                           from '@moon-core/models/api-result';
import { ComponentUtilityService }                    from '@moon-core/services/component-utility.service';

import { RoutePath }                                  from '@moon-shared/constants/route-path';
import { RouteParameter }                             from '@moon-shared/constants/route-parameter';
import { MoonLoadingComponent }                       from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                             from '@moon-shared/constants/constant-string';

import { MaintainerRouter }                           from '@moon-maintainer/maintainer.router';
import { NodeEnvironmentController }                  from '@moon-maintainer/api/node-environment.controller';
import { NodeEnvironmentUserGroupMapGet }             from "@moon-maintainer/api/response/node-environment-user-group-map-get.response";
import { NodeEnvironmentGet }                         from '@moon-maintainer/api/response/node-environment-get.response';
import { NodeEnvironmentUserGroupMapTableComponent }  from '@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map-table/node-environment-user-group-map-table.component';

@Component({
  selector: 'node-environment-user-group-map',
  templateUrl: './node-environment-user-group-map.component.html',
  styleUrls: ['./node-environment-user-group-map.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, NodeEnvironmentUserGroupMapTableComponent],
  providers: [NodeEnvironmentController, MaintainerRouter]
})
export class NodeEnvironmentUserGroupMapComponent implements OnInit {

  public MSNodeEnvironmentUserGroupMapList: NodeEnvironmentUserGroupMapGet[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.NodeEnvironmentUserGroupMap.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSNodeEnvironmentID: number | null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _nodeEnvironmentController: NodeEnvironmentController,
    private _maintainerRouter: MaintainerRouter,
  ) { }

  ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSNodeEnvironmentID){
      this.loadNodeEnvironmentUserGroupMapList(this.MSNodeEnvironmentID);
      this.getNodeEnvironment(this.MSNodeEnvironmentID);
    }
  }
  
  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.NodeEnvironment.getDisplayName(), routerLink: [`${this._maintainerRouter.NodeEnvironmentRouteLink()}`] }, 
      { label: RoutePath.UserGroupMap.getDisplayName(), routerLink: null }
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
  
  private readRouteParameters() {
    const nodeEnvironmentID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.NodeEnvironmentID);
    this.MSNodeEnvironmentID = nodeEnvironmentID ? +nodeEnvironmentID : null;
  }

  private async loadNodeEnvironmentUserGroupMapList(nodeEnvironmentID: number) {
    this.MSLoading = true;    
    const apiResult: ApiContentResult<NodeEnvironmentUserGroupMapGet[]> = await this._nodeEnvironmentController.GetUserGroupMapList(nodeEnvironmentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSNodeEnvironmentUserGroupMapList = apiResult.content;
    };
    this.MSLoading = false;  
  }

  private async getNodeEnvironment(nodeEnvironmentID: number) {
    const apiResult: ApiContentResult<NodeEnvironmentGet> = await this._nodeEnvironmentController.GetByID(nodeEnvironmentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSPageTitle += `: ${apiResult.content.environmentName}`;
    };
  }
}
