<div class="moon-token-breakdown">
    <div class="mb-1">
        <label
            for="token-usage"
            class="mr-2"
        >
            <i
                class="pi pi-info-circle"
                pTooltip="Show token breakdown"
                (click)="tokenBreakdownOverlay.toggle($event)"
            ></i>
            Token progress indicator
        </label>
        <span id="token-usage">
            {{MSTokenBreakdown.used}}/{{MSTokenBreakdown.limit}}
        </span>
    </div>
    <div>
        <p-progressBar
            styleClass="h-1px"
            [showValue]="false"
            [value]="(MSTokenBreakdown.used/MSTokenBreakdown.limit)*100"
        ></p-progressBar>
    </div>
</div>

<p-overlayPanel #tokenBreakdownOverlay>
    <ng-template pTemplate="content">
        <div class="text-left">
            <h1 class="text-lg">Token Breakdown</h1>
            <p class="line-height-1 text-color-secondary">
                Prompt: {{MSTokenBreakdown.promptTokens}}
            </p>
            <p class="line-height-1 text-color-secondary">
                Input: {{MSTokenBreakdown.inputTokens}}
            </p>
            <p class="line-height-1 text-color-secondary">
                Max Output: {{MSTokenBreakdown.maxOutputTokens}}
            </p>
            <p class="line-height-1 text-color-secondary">
                Limit: {{MSTokenBreakdown.limit}}
            </p>
        </div>
    </ng-template>
</p-overlayPanel>