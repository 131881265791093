<!-- Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <node-environment-table
        [MSPageTitle]="MSPageTitle"
        [MSNodeEnvironmentList]="MSNodeEnvironmentList"
        [MSLoading]="MSLoading"
    ></node-environment-table>
</div>