<!-- Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM -->
<moon-edit-page
  [MSLoading]="MSLoading"
  [MSPageTitle]="MSPageTitle"
  [MSDisplayRemoveButton]="MSDisplayRemoveButton"
  [MSDisplayRouteBackButton]="true"
  (MSOnClickEventRemove)="OnClickRemove()"
  (MSOnClickEventRouteBack)="MSOnClickGoBack()"
>
  <skill-form
    #MSForm
    *ngIf="!MSLoading"
    [MSSkillID]="MSSkillID"
    [MSSkillGet]="MSSkillGet"
  >
  </skill-form>
</moon-edit-page>