// Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM
import { MenuItem } from 'primeng/api';
import { ConstantString } from '@moon-shared/constants/constant-string';
import { RoutePath } from '@app/moon-shared/constants/route-path';

export const CollaboratorMenuItems: MenuItem = {
  label: "Collaborator",
  items: [
    // Placeholder Start
    { label: RoutePath.Documents.getDisplayName(), icon: "pi pi-file", routerLink: [ConstantString.Environment] }
    // Placeholder End
  ]
};
