<!-- Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM -->
<moon-table-page [MSPageTitle]="MSPageTitle"
                 [MSItemList]="MSUserSessionList"
                 [MSDisplayCreateButton]="MSDisplayCreate"
                 [MSDisplayFindButton]="MSDisplayFind"
                 [MSNumberOfFilteredTableRows]="MSNumberOfFilteredValues"
                 >
    <p-table #MSTable
             [columns]="MSTableColumns"
             [rowHover]="true"
             [value]="MSUserSessionList"
             selectionMode="single"
             [loading]="MSLoading"
             (onFilter)="MSOnFilter($event.filteredValue)"
             styleClass="p-datatable-gridlines p-datatable-striped"
             [globalFilterFields]="['preferredUserName', 'connectedAtUtc', 'validUntilUtc','environmentName', 'roleCode']">
        <ng-template pTemplate="caption">
            <moon-table-header [MSPageTitle]="MSPageTitle"
                               [MSEnableSearch]="MSEnableSearch"
                               [MSDisplayCreateButton]="MSDisplayCreate"
                               [MSDisplayFindButton]="MSDisplayFind"
                               (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)">
            </moon-table-header>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns"
                    [class]="col.class"
                    [pSortableColumn]="col.field"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'connectedAtUtc'">
                            {{ col.header }}
                            <p-columnFilter
                              type="date"
                              field="connectedAtUtc"
                              display="menu"
                            >
                            </p-columnFilter> 
                        </div>
                        <span *ngSwitchDefault>
                            {{ col.header }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </span>
                    </div>
                   
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions'">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'actions'"
                             class="text-right">

                            <moon-custom-dropdown [model]="rowData.actions">
                                <i class="pi pi-ellipsis-v"></i>
                            </moon-custom-dropdown>
                        </div>
                        <div *ngSwitchCase="'connectedAtUtc'">
                            {{ rowData[col.field] | date:'short'}}    
                        </div>
                        <div *ngSwitchCase="'validUntilUtc'">
                            {{ rowData[col.field] | date:'short'}}    
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template *ngIf="!MSUserSessionList?.length" pTemplate="emptymessage">
        <tr>
            <td
                [style.flex-basis]="'auto'"
                colspan="6"
                class="moon-text-center"
            >
                No Data found.
            </td>
        </tr>
    </ng-template>
</moon-table-page>

