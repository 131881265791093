// Moon Auto Generated (1.0.0) at 11/29/2023 8:32:40 PM
// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:57 PM
import { MenuItem } from 'primeng/api';
import { RoutePath } from '@app/moon-shared/constants/route-path';

export const HistoryMenuItems: MenuItem = {
    label: "History",
    items: [
        // Placeholder Start
        { label: RoutePath.ChatLog.getDisplayName(), icon: "pi pi-folder-open", routerLink: [RoutePath.ChatLog] },
        { label: RoutePath.SearchLog.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.SearchLog] },
        { label: RoutePath.UserSessionLog.getDisplayName(), icon: "pi pi-history", routerLink: [RoutePath.UserSessionLog] },
        { label: RoutePath.History.getDisplayName(), icon: "pi pi-history", routerLink: [RoutePath.History] },
        // Placeholder End
    ]
};
