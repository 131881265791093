<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.inlineMenuActive[key]}" [ngStyle]="style" [class]="styleClass">
    
    <a class="layout-inline-menu-action flex flex-row align-items-center" [ngClass]="appMain.isHorizontal() ? 'p-3 lg:col-1 py-3' : 'p-3'" (click)="onClick()"
        pTooltip="{{MSAzureUserName}}" [tooltipDisabled]="isTooltipDisabled">
        <span class="flex flex-column" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
            <span class="font-bold">{{MSAzureFullName}}</span>
            <small>{{MSAzureUserName}}</small>
        </span>
        <i class="layout-inline-menu-icon pi pi-angle-down" [ngClass]="{'ml-auto': app.isRTL, 'mr-auto': app.isRTL}"></i>
    </a>

    <ul class="layout-inline-menu-action-panel" [@menu]="!appMain.inlineMenuActive[key] ? isHorizontalActive() ? 'visible' : 'visibleAnimated' : isHorizontalActive() ? 'hidden' : 'hiddenAnimated'">

        <li class="layout-inline-menu-action-item" pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled">
            <a class="flex flex-row align-items-center" (click)="OnClickLogout()" [attr.tabindex]="tabIndex">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </a>
        </li>
    </ul>
</div>
