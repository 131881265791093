<!-- Moon Auto Generated (1.0.0) at 11/22/2023 10:52:50 AM -->

<moon-table-page [MSPageTitle]="''"
                 [MSItemList]="MSDocumentList"
                 [MSDisplayCreateButton]="MSDisplayCreate"
                 [MSDisplaySecondaryButton]="MSDisplaySecondaryButton"
                 [MSDisplayFindButton]="MSDisplayFind">
    <p-table #MSTable
             [columns]="MSTableColumns"
             [rowHover]="true"
             [value]="MSDocumentList"
             selectionMode="single"
             [loading]="MSLoading"
             selectionMode="single"
             [globalFilterFields]="['documentName', 'metadataJson', 'matterName', 'propertyType', 'propertyState', 'lawFirmName', 'categoryName', 'author']"
             styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
             [(selection)]="MSSelectedDocuments"
             dataKey="documentID"
             [paginator]="true"
             [rows]="20"
             [scrollable]="true"
             scrollHeight="flex"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             [tableStyle]="{'min-width': '150rem'}">

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="w-6rem">
                    Actions
                </th>
                <th class="w-4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>

                <th *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }">
                  <div [ngSwitch]="col.field">

                    <div *ngSwitchCase="'isChunked'">
                      {{ col.header }}

                      <p-columnFilter
                        field="isChunked"
                        matchMode="in"
                        display="menu"
                        [showMatchModes]="false"
                        [showOperator]="false"
                        [showAddButton]="false"
                      >
                        <ng-template pTemplate="header">
                          <div class="px-3 pt-3 pb-0">
                            <span class="font-bold">{{ col.header }}</span>
                          </div>
                        </ng-template>
                        <ng-template
                          pTemplate="filter"
                          let-value
                          let-filter="filterCallback"
                        >
                          <p-multiSelect
                            [ngModel]="value"
                            [options]="MSIsChunkedSelectItemList"
                            (onChange)="filter($event.value)"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Any"
                          >
                            <ng-template
                              let-option
                              pTemplate="item"
                            >
                              <div class="inline-block vertical-align-middle">
                                <span class="ml-1 mt-1">{{ option.label }}</span>
                              </div>
                            </ng-template>
                          </p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                  
                    <div *ngSwitchCase="'totalVectors'">
                      {{ col.header }}
                      <p-columnFilter
                        type="numeric"
                        field="totalVectors"
                        display="menu"
                        class="ml-auto"
                      ></p-columnFilter>
                    </div>
                  
                    <span *ngSwitchDefault>
                      {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                    </span>
                  </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr> 
                <td>
                  <div class="text-center">
                    <moon-custom-dropdown [model]="rowData.actions">
                      <i class="pi pi-ellipsis-v text-2xl"></i>
                    </moon-custom-dropdown>
                  </div>
                </td>
                <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td pEditableColumn *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{'actions-column': col.field === 'actions'}">

                    <div [ngSwitch]="col.field">
                      <div *ngSwitchCase="'documentName'" class="editable-column cursor-pointer">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                              <input
                                  type="text"
                                  [(ngModel)]="rowData.documentName"
                                  pInputText
                                  [style]="{ width: '100%' }"
                                  (change)="OnChangeDocumentName(rowData)"
                                  (keydown.enter)="
                                      OnChangeDocumentName(rowData)
                                  "
                              />
                          </ng-template>
                          <ng-template pTemplate="output">
                              {{ rowData.documentName }}
                          </ng-template>
                      </p-cellEditor>          
                    </div>

                    <div *ngSwitchCase="'categoryName'" class="editable-column cursor-pointer">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="w-full"
                                type="text"
                                [(ngModel)]="rowData.categoryName"
                                pInputText
                                (change)="OnChangeMetadataField(rowData)"
                                (keydown.enter)="
                                    OnChangeMetadataField(rowData)
                                "
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ rowData.categoryName }}
                        </ng-template>
                      </p-cellEditor>          
                    </div>

                    <div *ngSwitchCase="'lawFirmName'" class="editable-column cursor-pointer">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="w-full"
                                type="text"
                                [(ngModel)]="rowData.lawFirmName"
                                pInputText
                                (change)="OnChangeMetadataField(rowData)"
                                (keydown.enter)="
                                    OnChangeMetadataField(rowData)
                                "
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ rowData.lawFirmName }}
                        </ng-template>
                      </p-cellEditor>
                    </div>

                    <div *ngSwitchCase="'author'" class="editable-column cursor-pointer">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="w-full"
                                type="text"
                                [(ngModel)]="rowData.author"
                                pInputText
                                (change)="OnChangeMetadataField(rowData)"
                                (keydown.enter)="
                                    OnChangeMetadataField(rowData)
                                "
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ rowData.author }}
                        </ng-template>
                      </p-cellEditor>
                    </div>

                    <div *ngSwitchCase="'propertyState'" class="editable-column cursor-pointer">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="w-full"
                                type="text"
                                [(ngModel)]="rowData.propertyState"
                                pInputText
                                (change)="OnChangeMetadataField(rowData)"
                                (keydown.enter)="
                                    OnChangeMetadataField(rowData)
                                "
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ rowData.propertyState }}
                        </ng-template>
                      </p-cellEditor>
                    </div>

                    <div *ngSwitchCase="'propertyType'" class="editable-column cursor-pointer">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="w-full"
                                type="text"
                                [(ngModel)]="rowData.propertyType"
                                pInputText
                                (change)="OnChangeMetadataField(rowData)"
                                (keydown.enter)="
                                    OnChangeMetadataField(rowData)
                                "
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ rowData.propertyType }}
                        </ng-template>
                      </p-cellEditor>
                    </div>

                    <div *ngSwitchCase="'matterName'" class="editable-column cursor-pointer">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="w-full"
                                type="text"
                                [(ngModel)]="rowData.matterName"
                                pInputText
                                (change)="OnChangeMetadataField(rowData)"
                                (keydown.enter)="
                                    OnChangeMetadataField(rowData)
                                "
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ rowData.matterName }}
                        </ng-template>
                      </p-cellEditor>
                    </div>
                    <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                  </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

