import { CommonModule }                                               from '@angular/common';
import { Component }                                                  from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }    from '@angular/forms';
// Third party imports
import { ButtonModule }                                               from 'primeng/button';
import { DropdownModule }                                             from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef }                      from 'primeng/dynamicdialog';
import { ProgressBarModule }                                          from 'primeng/progressbar';
import { RippleModule }                                               from 'primeng/ripple';
// Moon Imports
import { ApiResult }                                                  from '@moon-core/models/api-result';
import { ComponentUtilityService }                                    from '@moon-core/services/component-utility.service';
import { UpdateFavoriteRequest }                                      from '@moon-public/clause-search/api/request/update-favorite.request';
import { SearchApiService }                                           from '@moon-public/clause-search/api/search-api.service';
import { SimilarChunk }                                               from '@moon-public/clause-search/models/similar-chunk';
import { MoonFormControlComponent }                                   from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }                                      from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { DefaultValues }                                              from '@moon-shared/constants/default-values';
import { MessageSeverity }                                            from '@moon-shared/constants/message-severity';
import { FormStickyFooterService }                                    from '@moon-shared/services/form-sticky-footer.service';
import { MoonMessageService }                                         from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                                from '@moon-shared/types/form-group-of.type';

export interface FavoriteClauseForm {
  favoriteNotes: string;
}

export type CloseFavoriteClauseDynamicDialogData =
  { event: 'cancel' } |
  {
    event: 'success';
    favoriteClauseForm: UpdateFavoriteRequest;
  };

@Component({
  selector: 'moon-modal-wrapper-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MoonFormPageComponent, MoonFormControlComponent, DropdownModule, ProgressBarModule, ButtonModule, RippleModule],
  templateUrl: './favorite-clause-modal.component.html',
  styleUrls: ['./favorite-clause-modal.component.scss'],
  providers: [ComponentUtilityService, SearchApiService, FormStickyFooterService]
})
export class FavoriteClauseModalComponent {
  public MSFavoriteChunkForm: FormGroup<FormGroupOf<FavoriteClauseForm>>;
  public MSIsFormSubmitting: boolean = false;
  public MSSelectedChunk: SimilarChunk;
  public MSShowRemoveButton: boolean = false;
  constructor(
    private _dynamicDialogConfig: DynamicDialogConfig<SimilarChunk>,
    private _dynamicDialogRef: DynamicDialogRef,
    private _moonMessageService: MoonMessageService,
    private _componentUtilityService: ComponentUtilityService,
    private _searchApi: SearchApiService,
  ) { }
  ngOnInit() {
    this.initializeComponent();
  }

  private initializeComponent() {
    const data: SimilarChunk | undefined = this._dynamicDialogConfig.data;

    if (data == null) {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No document provided to add to favorite.");
      this._dynamicDialogRef.close();
      return;
    }

    this.MSSelectedChunk = data;
    this.MSShowRemoveButton = DefaultValues.Y.equalsIgnoreCase(this.MSSelectedChunk.savedAsFavorite);

    this.initializeFormControl();
  }

  private initializeFormControl() {
    this.MSFavoriteChunkForm = new FormGroup<FormGroupOf<FavoriteClauseForm>>(
      {
        favoriteNotes: new FormControl(this.MSSelectedChunk.favoriteNotes, { nonNullable: true, validators: [Validators.maxLength(500)] })
      }
    );
  }

  public MSOnClickCancelFavoriteChunk() {
    const data: CloseFavoriteClauseDynamicDialogData = { event: 'cancel' };
    this._dynamicDialogRef.close(data);
  }

  public async MSOnClickSubmitFavoriteClause() {

    this.MSFavoriteChunkForm.markAllAsTouched();
    this.MSFavoriteChunkForm.updateValueAndValidity();
    if (this.MSFavoriteChunkForm.invalid)
      return;

    try {

      this.MSIsFormSubmitting = true;
      const favoriteChunkForm: UpdateFavoriteRequest = {
        favoriteNotes: this.MSFavoriteChunkForm.getRawValue().favoriteNotes,
        savedAsFavorite: DefaultValues.Y
      }

      const updateResult: ApiResult = await this._searchApi.UpdateFavorite(this.MSSelectedChunk.environmentName, this.MSSelectedChunk.documentChunkID, favoriteChunkForm);

      if (this._componentUtilityService.WasSuccessful(updateResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, 'Clause saved as favorite');

        this.closeDialog(favoriteChunkForm);
      }
    }
    finally {
      this.MSIsFormSubmitting = false;
    }
  }

  public async MSOnClickRemoveFromFavorite() {

    try {
      this.MSIsFormSubmitting = true;
      const favoriteChunkForm: UpdateFavoriteRequest = {
        favoriteNotes: String.empty,
        savedAsFavorite: DefaultValues.N
      }

      const updateResult: ApiResult = await this._searchApi.UpdateFavorite(this.MSSelectedChunk.environmentName, this.MSSelectedChunk.documentChunkID, favoriteChunkForm);

      if (this._componentUtilityService.WasSuccessful(updateResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, 'Clause removed from favorites');
        this.closeDialog(favoriteChunkForm);
      }
    }

    finally {
      this.MSIsFormSubmitting = false;
    }
  }
  private closeDialog(favoriteClauseForm: UpdateFavoriteRequest) {
    const data: CloseFavoriteClauseDynamicDialogData = {
      event: 'success',
      favoriteClauseForm
    };
    this._dynamicDialogRef.close(data);
  }
}
