<div>
    <div *ngIf="!MSDisplayChunk">
        <p-table
            [value]="MSSearchResultJson"
            [columns]="MSTableColumns"
            [scrollable]="true"
            [loading]="MSTableLoading"
            styleClass="p-datatable-gridlines p-datatable-striped"
        >
            <ng-template
                pTemplate="header"
                let-columns
            >
                <tr>
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
            >
                <tr>
                    <td *ngFor="let col of columns">
                        <div [ngSwitch]="col.field">
                            <div
                                *ngSwitchCase="'SimilarChunks'"
                                class="text-center"
                            >
                                <div *ngFor="let data of rowData[col.field]">
                                    <p class="moon-text">
                                        Document Chunk ID: <a
                                            class="moon-anchor"
                                            (click)="MSOnClickDocumentChunkID(rowData.EnvironmentName, data.DocumentChunkID, data.Relevance)"
                                        >{{data.DocumentChunkID}}</a><br />
                                        Relevance: {{data.Relevance | moonTruncate: 2 }}<br />
                                        Lev Similarity: {{data.LevSimilarity}}<br />
                                        Dice Similarity: {{data.DiceSimilarity | moonTruncate: 2 }}
                                    </p>
                                    <hr>
                                </div>
                            </div>
                            <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div
        *ngIf="MSDisplayChunk"
        class="card"
    >
        <div class="grid col-12">
            <div class="col-8">
                <p-fieldset
                    legend="Input"
                    [toggleable]="true"
                    class="moon-text"
                >
                    {{ MSInputData }}
                </p-fieldset>
            </div>

            <div class="col-4">
                <div class="moon-properties">
                    Environment: {{ MSChunkEnvironment }} <br />
                    Document ID: {{ MSDocumentChunk.documentID }} <br />
                    Document Chunk ID: {{ MSDocumentChunk.documentChunkID }} <br />
                    Relevance: {{ MSChunkRelevance | moonTruncate: 2 }}
                </div>
                <div class="moon-close-info">
                    <button
                        pButton
                        icon="pi pi-times"
                        pTooltip="Close Search Detail"
                        tooltipPosition="top"
                        class="moon-right"
                        (click)="MSOnClickCloseButton()"
                    >
                    </button>
                </div>
            </div>

            <div class="col-12">
                <p-fieldset
                    legend="Search Detail"
                    [toggleable]="true"
                    class="moon-text"
                >
                    <ms-black-lines
                        #blackLine
                        [MSOriginalText]="MSInputData"
                        [MSModifiedText]="MSDocumentChunk.content"
                    ></ms-black-lines>
                </p-fieldset>
            </div>
        </div>
    </div>
</div>