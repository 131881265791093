import { NgIf, NgStyle }                from '@angular/common';
import { Component, OnInit }            from '@angular/core';
import { Title }                        from '@angular/platform-browser';
import { MsalBroadcastService }         from '@azure/msal-angular';
import { EventMessage, EventType }      from '@azure/msal-browser';
// Third party imports
import { ButtonModule }                 from 'primeng/button';
import { ProgressBarModule }            from 'primeng/progressbar';
import { filter }                       from 'rxjs';
// Moon Imports
import { AppRouter }                    from '@app/app.router';
import { DiagnosticApiService }         from '@moon-core/api/diagnostic-api.service';
import { DatabaseDto }                  from '@moon-core/api/response/database.response';
import { UserSession }                  from '@moon-core/api/response/user-session.response';
import { ApiContentResult }             from '@moon-core/models/api-result';
import { Diagnostic }                   from '@moon-core/models/diagnostic.model';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { MsalWrapperService }           from '@moon-core/services/msal-wrapper-service';
import { UserOptionService }            from '@moon-core/services/user-option.service';
import { UserSessionService }           from '@moon-core/services/user-session.service';
import { MoonErrorMessageComponent }    from '@moon-shared/components/moon-error-message/moon-error-message.component';
import { DefaultValues }                from '@moon-shared/constants/default-values';
import { MoonstoneApp }                 from 'src/moon-config/moonstone-app';

@Component({
    selector: "app-moon-login",
    templateUrl: "./moon-login.component.html",
    styleUrls: ["./moon-login.component.scss"],
    standalone: true,
    imports: [
        ButtonModule,
        MoonErrorMessageComponent,
        NgIf,
        NgStyle,
        ProgressBarModule,
    ],
    providers: [DiagnosticApiService]
})
export class MoonLoginComponent implements OnInit {

    // Start Template variables
    public MSHasErrors: boolean = false;
    public MSLoading: boolean = false;
    public MSDiagnostic = new Diagnostic();
    public MSDisplayMessage: string = String.empty;
    public MSWebApplicationVersion: string = MoonstoneApp.ApplicationVersion;
    // End Template variables

    constructor(
        private _appRouter: AppRouter,
        private _diagnosticApiService: DiagnosticApiService,
        private _msalBroadcastService: MsalBroadcastService,
        private _msalWrapperService: MsalWrapperService,
        private _titleService: Title,
        private _userSessionService: UserSessionService,
        private _userOptionService: UserOptionService,
        private _componentUtilityService: ComponentUtilityService,
    ) { }

    async ngOnInit(): Promise<void> {
        this._titleService.setTitle(MoonstoneApp.ApplicationName);
        this.loadApiDiagnostic();
    }

    public async MSOnClickLoginButton() {
        if (this._msalWrapperService.IsConnectedToAzure()) {
            if (this._userSessionService.IsConnectedToDatabase()) {
                this._appRouter.RouteToDefault();
            }
            else {
                this.dbConnectUser(DefaultValues.Y)
            }
        }
        else {
            this.doConnectToAzure();
        }
    }

    public async MSOnClickReloadButton() {
        await this.loadApiDiagnostic();
    }

    private async loadApiDiagnostic() {
        this.MSLoading = true;
        try {
            const apiResult: ApiContentResult<DatabaseDto> = await this._diagnosticApiService.GetDatabaseDto(MoonstoneApp.ApplicationName);
            if (this._componentUtilityService.WasSuccessful(apiResult)) {
                this.MSHasErrors = false;
                this.MSDisplayMessage = String.empty;
                this.MSDiagnostic = apiResult.content;
                this._msalWrapperService.MSDiagnostic = apiResult.content;
            }
            else {
                this.MSHasErrors = true;
                this.MSDisplayMessage = apiResult.message;
            }
        }
        finally {
            this.MSLoading = false;
        }
    }

    private doConnectToAzure() {
        this.updateProcess("Authenticating user");

        this._msalWrapperService.ConnectToAzure();

        this._msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            )
            .subscribe((_) => {
                this.dbConnectUser(DefaultValues.Y);
            });
    }

    private async dbConnectUser(forceDisconnect: string) {
        const connectResult: ApiContentResult<UserSession> = await this._userSessionService.ConnectUser(forceDisconnect)
        if (this._componentUtilityService.WasSuccessful(connectResult)) {
            await this._userOptionService.SetUserOptionMapList();
            this._appRouter.RouteToDefault();
        }
        else {
            this.MSHasErrors = true;
            this.MSDisplayMessage = connectResult.message;
        }
    }

    private updateProcess(processMessage: string = "", loading = true) {
        this.MSHasErrors = false;
        this.MSLoading = loading;
        this.MSDisplayMessage = processMessage;
    }
}
