import { CommonModule, NgFor, NgIf, SlicePipe }    from '@angular/common';
import { Component, Input }             from '@angular/core';
import { ReactiveFormsModule }          from '@angular/forms';
import { Clipboard }                    from '@angular/cdk/clipboard';
// Third party imports
import { SharedModule }                 from 'primeng/api';
import { Table, TableModule }           from 'primeng/table';
import { TabViewModule }                from 'primeng/tabview';
import { ButtonModule }                 from 'primeng/button';
import { TooltipModule }                from 'primeng/tooltip';
// Moon Imports
import { ApiContentResult }             from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { ChatResult }                   from '@moon-public/api/response/chat-result.response';
import { DocumentOcr }                  from '@moon-public/api/response/document-ocr.response';
import { TitleDocumentMap }             from '@moon-public/api/response/title-document-map.response';
import { TitleExceptionMap }            from '@moon-public/api/response/title-exception-map.response';
import { TitleSummaryController }       from '@moon-public/api/title-summary.controller';
import { ChatLogComponent }             from '@moon-public/title-summary/chat-log/chat-log.component';
import { DocumentMappingComponent }     from '@moon-public/title-summary/summary-result/document-mapping/document-mapping.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { FieldValues }                  from '@moon-shared/constants/field-values';

@Component({
  selector: 'moon-summary-result',
  standalone: true,
  templateUrl: './summary-result.component.html',
  styleUrls: ['./summary-result.component.scss'],
  imports: [
    CommonModule, ReactiveFormsModule,NgIf, NgFor, SlicePipe,
    TabViewModule, TableModule, SharedModule,
    MoonTableHeaderComponent, ChatLogComponent, ButtonModule, TooltipModule,
    DocumentMappingComponent
  ],
})
export class SummaryResultComponent {
  @Input() public MSTitleSummaryID: number;
  @Input() public MSChatLogID: number | null;
  public MSTitleExceptionMap: TitleExceptionMap[] = [];
  public MSTitleDocumentMap: TitleDocumentMap[] = [];
  public MSOcrContent: string;
  public MSOcrSkillData: ChatResult;
  public MSOcrInProgress: boolean;
  constructor(
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
    private _clipboard: Clipboard
  ) { }
  ngOnInit() {
    this.MSLoadDocumentAndExceptionMaps();
    this.loadOcr(this.MSTitleSummaryID);
  }
  public MSLoadDocumentAndExceptionMaps() {
    this.loadDocumentMaps(this.MSTitleSummaryID);
    this.loadExceptionMaps(this.MSTitleSummaryID);
  }
  private async loadExceptionMaps(titleSummaryID: number) {
    const apiResult: ApiContentResult<TitleExceptionMap[]> = await this._titleSummaryController.GetExceptionMapList(titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSTitleExceptionMap = apiResult.content;
    }
  }
  private async loadDocumentMaps(titleSummaryID: number) {
    const apiResult: ApiContentResult<TitleDocumentMap[]> = await this._titleSummaryController.GetDocumentMapList(titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSTitleDocumentMap = apiResult.content;
    }
  }
  private async loadOcr(titleSummaryID: number) {
    const apiResult: ApiContentResult<DocumentOcr> = await this._titleSummaryController.GetOcr(titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSOcrContent = this.extractOcrContent(apiResult.content);
    }
  }

  public calculateDocumentTotal(exceptionNumber: string) {
    const total = this.MSTitleExceptionMap.filter(d => d.exceptionNumber === exceptionNumber && FieldValues.Y.equalsIgnoreCase(d.hasDocument))?.length ?? 0;
    return total;
  }

  public OnSearchInput(table: Table, searchInput: string): void {
    table.filterGlobal(searchInput, "contains");
  }

  public async OnClickOCRSkill(): Promise<void> {
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiContentResult<ChatResult> = await this._titleSummaryController.GetTitleSummaryOcrText(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSOcrSkillData = apiResult.content;
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }

  public OnClickCopy(e: MouseEvent): void {
    let systemMessage = this.MSOcrSkillData.content;
    if (systemMessage)
        this._clipboard.copy(systemMessage);
    e.stopPropagation();
  }
  private extractOcrContent(documentOcr: DocumentOcr): string {
    const ocrLines: string[] = documentOcr.paragraphs.flatMap(a => a.content);
    return ocrLines?.join('\n\n');
  }

}
