// Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { SkillExample }         from "@moon-maintainer/api/request/skill-example.request";
import { SkillExampleGet }      from "@moon-maintainer/api/response/skill-example-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";

@Injectable()
export class SkillExampleController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.SkillExample);
  };

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/SkillExample
 */
  public async Create(skillExample: SkillExample
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, skillExample);
  }
  
/**
 *   GET: Maintainer/v1/SkillExample/{documentID}
 */
  public async GetByID(skillExampleID: number
    ): Promise<ApiContentResult<SkillExampleGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillExampleID);
    return this._moonApi.getAsync<SkillExampleGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/SkillExample/{documentID}
 */
  public async Update(skillExampleID: number, skillExample: SkillExample
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillExampleID);
    return this._moonApi.putAsync(url, skillExample);
  }
  
/**
 *   DELETE: Maintainer/v1/SkillExample/{documentID}
 */
  public async Delete(skillExampleID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillExampleID);
    return this._moonApi.deleteAsync(url);
  }
}
