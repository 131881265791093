<moon-loading *ngIf="MSLoading"></moon-loading>
<div *ngIf="!MSLoading" class="card moon-card">
    <form [formGroup]="MSSkillTestForm">
        <section id="skill-test">
            <moon-token-breakdown [MSTokenBreakdown]="MSTokenBreakdown"></moon-token-breakdown>
            <div class="flex" *ngIf="!MSDisableForSkillEdit">
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-arrow-left"
                    (click)="MSOnClickGoBack()"
                    class="p-button-rounded p-button-text mr-2"
                    pTooltip="Go Back"
                    tooltipPosition="bottom"
                ></button>
                <h1 class="text-lg m-0 mt-3">Skill Test</h1>
            </div>
            <div class="col-12 p-0 inline-block p-fluid">
                <moon-form-control
                    [MSInputSize]="12"
                    [MSLabelSize]="12"
                    [MSFormControl]="MSSkillTestForm.controls.message"
                    [MSLabel]="'User Message'"
                    [MSSecondLabel]="MSSkillTestForm.value.messageToken + ' tokens'"
                >
                    <textarea
                        rows="8"
                        cols="60"
                        pInputTextarea
                        placeholder="Start writing"
                        [formControl]="MSSkillTestForm.controls.message"
                    ></textarea>
                </moon-form-control>
            </div>
            <div class="flex justify-content-between mb-2">
                <div>
                    <span *ngIf="!MSDisableForSkillEdit">
                        <label for="skill-dropdown">Skill:</label>
                        <p-dropdown
                            id="skill-dropdown"
                            [options]="MSSkillListSelectItem"
                            [(ngModel)]="MSSelectedSkillList"
                            (onChange)="MSOnSkillChange($event)"
                            [ngModelOptions]="{standalone: true}"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Select an item"
                        >                    
                        </p-dropdown>
                    </span>&nbsp;

                    <button
                        pButton
                        pRipple
                        class="p-button-raised h-min"
                        icon="pi pi-telegram"
                        label="Send"
                        (click)="MSApplySkill()"
                        [disabled]="MSSubmitting"
                    >
                    </button>
                    <button
                        pButton
                        pRipple
                        label="Clear"
                        class="p-button-raised h-min"
                        icon="pi pi-times"
                        (click)="MSClearSkillTestForm()"
                        [disabled]="MSSubmitting"
                    >
                    </button>
                </div>
                <div>
                    <p-badge
                        *ngIf="MSTestMode"
                        value="Test mode"
                    ></p-badge>
                    <button
                        pButton
                        pRipple
                        class="p-button-raised ml-auto"
                        icon="pi pi-copy"
                        pTooltip="Copy Output to Clipboard"
                        tooltipPosition="left"
                        (click)="MSCopyOutputToClipboard()"
                    >
                    </button>
                </div>
            </div>
            <div
                *ngIf="MSSkillTesting; else skillOutput"
                class="flex justify-content-center align-items-center h-30rem"
            >
                <p-progressSpinner
                    aria-label="Loading"
                    styleClass="w-4rem h-4rem"
                    strokeWidth="4"
                    animationDuration=".5s"
                ></p-progressSpinner>
            </div>
            <ng-template #skillOutput>
                <div
                    class="flex justify-content-between"
                    *ngIf="MSChatResult"
                >
                    <div class="text-sm text-secondary">{{MSChatResult.replyTokens}} tokens
                        ({{MSChatResult.modelName}})</div>
                    <div class="text-sm text-secondary">Time: {{MSChatResult.durationMs / 1000}}s</div>
                </div>
                <div class="ms-skill-test-output white-space-pre-line">
                    {{MSChatResult?.content ?? 'Assistant Response'}}
                </div>
            </ng-template>

        </section>
    </form>
</div>