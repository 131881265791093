// Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM
import {
    NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                                       from "@angular/common";
import { Component, Input, OnInit, ViewEncapsulation }  from "@angular/core";
// Third Party imports
import { Table, TableModule }                           from "primeng/table";
// Moon imports
import { DocumentCategoryGet }                          from "@moon-collaborator/api/response/document-category-get.response";
import { CollaboratorRouter }                           from '@moon-collaborator/collaborator.router';
import { MoonCustomDropdownComponent }                  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonTableHeaderComponent }                     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }                       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                                   from '@moon-shared/constants/field-names';
import { TableColumn }                                  from '@moon-shared/types/table-column.type';

@Component({
    selector: "document-category-table",
    templateUrl: "./document-category-table.component.html",
    styleUrls: ['./document-category-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
              TableModule,
              MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [CollaboratorRouter]
})
export class DocumentCategoryTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSDocumentCategoryList: DocumentCategoryGet[];
    @Input() public MSLoading = true;
    @Input() public MSEnvironmentName: string = String.empty;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.CategoryName.toCamelCase(), header: FieldNames.CategoryName.getDisplayName(), class: 'w-35per'},
		{field: FieldNames.SearchTableNumber.toCamelCase(), header: FieldNames.SearchTableNumber.getDisplayName(), class: 'w-30per'},
		{field: FieldNames.SortKey.toCamelCase(), header: FieldNames.SortKey.getDisplayName(), class: 'w-30per'},

        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-5sper'}
    ];

    public MSEnableSearch: boolean = true;
    public MSDisplayFind: boolean = true;
    public MSDisplayCreate: boolean = true;
    public MSDisplayRouteBack: boolean = true;

    constructor(private _collaboratorRouter: CollaboratorRouter) {}

    ngOnInit(): void {
    }

    public OnClickGoBack(): void {
        this._collaboratorRouter.ToDocument(this.MSEnvironmentName);
    }

    public OnClickItem(item: DocumentCategoryGet): void {
        this._collaboratorRouter.ToDocumentCategoryEdit(this.MSEnvironmentName, item.documentCategoryID);
    }

    public OnClickCreate(): void {
        this._collaboratorRouter.ToDocumentCategoryAdd(this.MSEnvironmentName);
    }

    public OnSearchInput(documentCategoryTable: Table, searchInput: string): void {
        documentCategoryTable.filterGlobal(searchInput, "contains");
    }
}
