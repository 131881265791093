<!-- Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
    <mezz-loan-table [MSPageTitle]="MSPageTitle"
                [MSMezzLoanList]="MSMezzLoanList"
                [MSLoading]="MSLoading"
                (MSEventReloadPage)="MSReloadPage()"
                ></mezz-loan-table>
</div>
