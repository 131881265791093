// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import { Component, Input, OnInit }             from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule, Validators }      from '@angular/forms';
// Third Party Imports  
import { SelectItem, SharedModule }             from 'primeng/api';
import { InputTextModule }                      from 'primeng/inputtext';
import { DropdownModule }                       from 'primeng/dropdown';
// Moon Imports 
import { IApiResult }                           from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { UserInfo }                             from '@moon-maintainer/api/request/user-info.request';
import { UserInfoGet }                          from "@moon-maintainer/api/response/user-info-get.response";
import { UserInfoController }                   from '@moon-maintainer/api/user-info.controller';
import { MoonFormControlComponent }             from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }                from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                          from '@moon-shared/types/form-group-of.type';
import { MaintainerRouter }                     from '@moon-maintainer/maintainer.router';

@Component({
    selector: 'user-info-form',
    templateUrl: './user-info-form.component.html',
    styleUrls: ['./user-info-form.component.css'],
    standalone: true,
    imports: [ FormsModule, ReactiveFormsModule, SharedModule,
               MoonFormPageComponent, MoonFormControlComponent,
               InputTextModule, DropdownModule,
            ],
    providers: [UserInfoController]
})
export class UserInfoFormComponent implements OnInit {
    @Input() public MSUserInfoGet: UserInfoGet;
    @Input() public MSUserInfoID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<UserInfo>> = new FormGroup<FormGroupOf<UserInfo>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		objectIdentifier: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
		preferredUserName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
		userStatusSelectItem: new FormControl(null, {nonNullable: true, validators: [Validators.required]}),
		userStatus: new FormControl('ACT', {nonNullable: true, validators: [Validators.required]}),
		roleCodeSelectItem: new FormControl(null, {nonNullable: true, validators: [Validators.required]}),
		roleCode: new FormControl('USR', {nonNullable: true, validators: [Validators.required]}),
		firstName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
		lastName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
		emailAddress: new FormControl('', {nonNullable: true}),
		userTypeSelectItem: new FormControl(null, {nonNullable: true, validators: [Validators.required]}),
		userType: new FormControl('USR', {nonNullable: true, validators: [Validators.required]}),
        allowFavoriteSearchResult: new FormControl('N', {nonNullable: true, validators: [Validators.required] }),
    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;
    public MSUserStatusSelectItem: SelectItem[] = [
        {label: 'Active', value: 'ACT'},
        {label: 'Disabled', value: 'DIS'},
        {label: 'Locked', value: 'LCK'},
    ]
	public MSRoleCodeSelectItem: SelectItem[] = [
        {label: 'Super User', value: 'ADM'},
        {label: 'User', value: 'USR'},
    ];
	public MSUserTypeSelectItem: SelectItem[] = [
        {label: 'Master', value: 'MST'},
        {label: 'User', value: 'USR'},
    ];
    // Declare Options PlaceHolder

    constructor(
        private _userInfoController: UserInfoController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSUserInfoGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers
    private doMapGetDataToFormControl(userInfo: UserInfoGet) {
        if (!userInfo) {
            return;
        } 
        this.MSUserInfoGet = userInfo;
        this.MSParentForm.patchValue(userInfo);

        this.setDefaultValue(userInfo);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSUserInfoID
            ? await this._userInfoController.Update(this.MSUserInfoID, this.MSParentForm.getRawValue())
            : await this._userInfoController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        };
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
		//this._configService.getSelectItems(FieldNames.RoleCode, false).subscribe(items => (this.MSRoleCodeSelectItem = items));
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToUserInfo();
    }

    // Dropdown Specific
    private doInitializeFormControls() {
        const userStatus: SelectItem | null = this.MSUserStatusSelectItem.find(a=> a.value == 'ACT') ?? null;
        const roleCode: SelectItem | null = this.MSRoleCodeSelectItem.find(a=> a.value == 'USR') ?? null;
        const userType: SelectItem | null = this.MSUserTypeSelectItem.find(a=> a.value == 'USR') ?? null;

        this.MSParentForm.controls.userStatusSelectItem.setValue(userStatus);
        this.MSParentForm.controls.roleCodeSelectItem.setValue(roleCode);
        this.MSParentForm.controls.userTypeSelectItem.setValue(userType);
    }

    public OnChangeUserStatus(selectItem: SelectItem) {
        this.MSParentForm.controls.userStatus.setValue(selectItem.value);
    }
    public OnChangeUserRole(selectItem: SelectItem) {
        this.MSParentForm.controls.roleCode.setValue(selectItem.value);
    }
    public OnChangeUserType(selectItem: SelectItem) {
        this.MSParentForm.controls.userType.setValue(selectItem.value);
    }

    private setDefaultValue(userInfo: UserInfoGet) {
        const userStatus: SelectItem | null = this.MSUserStatusSelectItem.find(a=> a.value == userInfo.userStatus) ?? null;
        const roleCode: SelectItem | null = this.MSRoleCodeSelectItem.find(a=> a.value == userInfo.roleCode) ?? null;
        const userType: SelectItem | null = this.MSUserTypeSelectItem.find(a=> a.value == userInfo.userType) ?? null;
        
        this.MSParentForm.controls.userStatusSelectItem.setValue(userStatus);
        this.MSParentForm.controls.roleCodeSelectItem.setValue(roleCode);
        this.MSParentForm.controls.userTypeSelectItem.setValue(userType);
    }
}
