// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { NodeEnvironment }                 from "@moon-maintainer/api/request/node-environment.request";
import { NodeEnvironmentGet }              from "@moon-maintainer/api/response/node-environment-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";
import { NodeEnvironmentUserGroupMapGet } from "./response/node-environment-user-group-map-get.response";

@Injectable()
export class NodeEnvironmentController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.NodeEnvironment);
  };

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   GET: Maintainer/v1/NodeEnvironment
 */
  public async GetList(
    ): Promise<ApiContentResult<NodeEnvironmentGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<NodeEnvironmentGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/NodeEnvironment
 */
  public async Create(nodeEnvironment: NodeEnvironment
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, nodeEnvironment);
  }
  
/**
 *   GET: Maintainer/v1/NodeEnvironment/{documentID}
 */
  public async GetByID(nodeEnvironmentID: number
    ): Promise<ApiContentResult<NodeEnvironmentGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentID);
    return this._moonApi.getAsync<NodeEnvironmentGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/NodeEnvironment/{documentID}
 */
  public async Update(nodeEnvironmentID: number, nodeEnvironment: NodeEnvironment
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentID);
    return this._moonApi.putAsync(url, nodeEnvironment);
  }
  
/**
 *   DELETE: Maintainer/v1/NodeEnvironment/{documentID}
 */
  public async Delete(nodeEnvironmentID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentID);
    return this._moonApi.deleteAsync(url);
  }
  
/**
 *   GET: Maintainer/v1/NodeEnvironment/{nodeEnvironmentID}/UserGroupMap
 */
  public async GetUserGroupMapList(nodeEnvironmentID: number
    ): Promise<ApiContentResult<NodeEnvironmentUserGroupMapGet[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentID, 'UserGroupMap');
    return this._moonApi.getAsync<NodeEnvironmentUserGroupMapGet[]>(url);
  }

}
