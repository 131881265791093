<!-- Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM -->
<moon-form-page [MSParentForm]="MSParentForm"
                [MSIsFormSaving]="MSIsFormSubmitting"
                (MSOnClickEventSubmit)="OnClickSubmit()"
                (MSOnClickEventCancel)="OnClickCancel()">
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'Category Name'"
            [MSFormControl]="MSParentForm.controls.categoryName"
        >
            <input
                type="text"
                formControlName="categoryName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Search Table Number'"
            [MSFormControl]="MSParentForm.controls.searchTableNumber"
        >
            <p-dropdown
                [options]="MSSearchTableNumberSelectItem"
                formControlName="searchTableNumber"
            ></p-dropdown>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Sort Key'"
            [MSFormControl]="MSParentForm.controls.sortKey"
        >
            <input
                type="number"
                formControlName="sortKey"
                pInputText
            />
        </moon-form-control>
    </div>
</moon-form-page>
