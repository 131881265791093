import { HttpHeaders }              from "@angular/common/http";
import { MoonApiErrorResponse }     from "@moon-core/moon-errors/moon-api-error-response";
import { ValidationProblemDetail }  from "@moon-core/moon-errors/problem-detail";

export class MoonApiValidationErrorResponse extends MoonApiErrorResponse {
    
    public validationProblemDetail: ValidationProblemDetail;

    constructor(init: {
        error?: any;
        headers?: HttpHeaders;
        status?: number;
        statusText?: string;
        url?: string;
    }) {
        super(init);
    }
}
