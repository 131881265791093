import { AsyncPipe, NgClass, NgIf }             from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup, FormsModule,
    ReactiveFormsModule, Validators
}                                               from '@angular/forms';
// Third party imports
import { AppBreadcrumbService }                 from '@app-layout/services/app.breadcrumb.service';
import { SelectItem }                           from 'primeng/api';
import { ButtonModule }                         from 'primeng/button';
import { CheckboxModule }                       from 'primeng/checkbox';
import { DividerModule }                        from 'primeng/divider';
import { InputNumberModule }                    from "primeng/inputnumber";
import { InputSwitchModule }                    from 'primeng/inputswitch';
import { InputTextareaModule }                  from 'primeng/inputtextarea';
import { MultiSelectModule }                    from 'primeng/multiselect';
import { RippleModule }                         from 'primeng/ripple';

// MS Imports
import { DocumentCategoryController }           from '@moon-collaborator/api/document-category.controller';
import { DocumentController }                   from '@moon-collaborator/api/document.controller';
import { DocumentCategoryGet }                  from '@moon-collaborator/api/response/document-category-get.response';
import { EnvironmentApiService }                from '@moon-core/api/environment-api.service';
import { EnvironmentGet }                       from '@moon-core/api/response/environment-get.response';
import { ApiContentResult, IApiResult }                     from '@moon-core/models/api-result';
import { FlatUserOption, UserOptions }                       from '@moon-core/models/flat-user-option';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { UserOptionService }                    from '@moon-core/services/user-option.service';
import { SearchParameter }                      from '@moon-public/clause-search/api/request/search.parameter';
import { DbListResult }                         from '@moon-public/clause-search/api/response/db-list-result.response';
import { SearchResult }                         from '@moon-public/clause-search/api/response/search-result.response';
import { SimilarDocumentChunk }                 from '@moon-public/clause-search/api/response/similar-document-chunk.response';
import { SearchApiService }                     from '@moon-public/clause-search/api/search-api.service';
import { ClauseSearchDataViewComponent }        from '@moon-public/clause-search/clause-search-data-view/clause-search-data-view.component';
import { SearchParameterForm }                  from '@moon-public/clause-search/models/search-parameter.form';
import { SearchResultFilter }                   from '@moon-public/clause-search/models/search-result-filter';
import { SimilarChunk }                         from '@moon-public/clause-search/models/similar-chunk';
import { MoonFormControlComponent }             from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                       from '@moon-shared/constants/constant-string';
import { MessageSeverity }                      from '@moon-shared/constants/message-severity';
import { DMP_IsExactMatch }                     from "@moon-shared/helper/diffmatchpatch/diffmatchpatch";
import { PerformanceTimer }                     from '@moon-shared/helper/performance-timer/performance-timer';
import { MoonMessageService }                   from '@moon-shared/services/moon-message.service';
import { BlackLinesMode }                       from '@moon-shared/types/black-lines-algorithm.type';
import { FieldNames }                           from '@moon-shared/constants/field-names';
export type SearchFormType = FormGroup<SearchParameterForm>;

@Component({
    selector: 'ms-clause-search',
    templateUrl: 'clause-search.component.html',
    styleUrls: ['clause-search.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe, MoonFormControlComponent, MoonLoadingComponent, NgClass, NgIf,
        ClauseSearchDataViewComponent, ButtonModule, DividerModule, FormsModule,
        InputNumberModule, InputSwitchModule, CheckboxModule,
        InputTextareaModule, MultiSelectModule, ReactiveFormsModule, RippleModule,
    ],
    providers: [SearchApiService, DocumentCategoryController, DocumentController]
})
export class ClauseSearchComponent implements OnInit {
    private readonly DEFAULT_RELEVANCE_VALUE = { MIN: 0.88, MAX: 1.1 };
    public MSDefaultBlacklineAlgorithm: BlackLinesMode = "Word Mode";
    public MSDocumentTypeCategoryListSelectItems: SelectItem[] = [];
    public MSEnvironmentList: EnvironmentGet[] = [];
    public MSLoading: boolean = true;
    public MSLoadingMessage: string = 'Loading...';
    public MSSearchChunkList: SimilarChunk[] = [];
    public MSSearchResultFilter: SearchResultFilter;
    public MSShowAdvanceSettings: boolean = false;
    public MSShowDocumentResultSection: boolean = false
    public MSTimeElapsedForResult: number | null = null;
    public MSTotalExactMatch: number = 0;
    public MSSearchForm: SearchFormType = new FormGroup<SearchParameterForm>({
        environmentList: new FormControl<string[]>([], { nonNullable: true }),
        documentCategoryIDList: new FormControl<number[]>([], { nonNullable: true }),
        isTest: new FormControl(false, { nonNullable: true }),
        content: new FormControl('', { nonNullable: true, validators: Validators.required }),
        minimumRelevance: new FormControl(this.DEFAULT_RELEVANCE_VALUE.MIN, { nonNullable: true, validators: [Validators.min(0), Validators.max(1.1)] }),
        maximumRelevance: new FormControl(this.DEFAULT_RELEVANCE_VALUE.MAX, { nonNullable: true, validators: [Validators.min(0), Validators.max(1.1)] }),
        parametersJson: new FormControl('', { nonNullable: true }),
        includeExactMatches: new FormControl(false, { nonNullable: true })
    });

    public MSIncludeExactMatches: boolean = false;
    public MSDisableDocumentCategory: boolean = false;

    constructor(
        private _appBreadcrumbService: AppBreadcrumbService,
        private _componentUtilityService: ComponentUtilityService,
        private _documentCategoryController: DocumentCategoryController,
        private _environmentApiService: EnvironmentApiService,
        private _clauseSearchApiService: SearchApiService,
        private _moonMessageService: MoonMessageService,
        private _userOptionService: UserOptionService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.setBreadcrumb();
        this.initialise();
    }

    private async initialise() {

        await this.loadEnvironmentList();
        this.setEnvironmentUserOption();

        await this.loadDocumentCategory();
        this.setDocumentTypeUserOption();

    }

    private get _userOption(): FlatUserOption {
        return this._userOptionService.MSUserOption;
    }

    private setBreadcrumb() {
        this._appBreadcrumbService.setItems([
            { label: ConstantString.ClauseSearch.getDisplayName(), routerLink: null },
        ]);
    }

    private async loadEnvironmentList() {
        try {
            const apiResult: ApiContentResult<EnvironmentGet[]> = await this._environmentApiService.GetEnvironmentList();
            if (this._componentUtilityService.WasSuccessful(apiResult)) {
                this.MSEnvironmentList = apiResult.content;
            };
        }
        finally {
            this.MSLoading = false;
        }
    }

    private setEnvironmentUserOption() {
        if (this._userOption.ClauseSearchEnvironment) {
            this.MSSearchForm.controls.environmentList.setValue(this._userOption.ClauseSearchEnvironment);
        } 
        
        if (this.MSEnvironmentList && this.MSEnvironmentList.length === 1) {
            this.setEnvironmentIfSingle();
        }
    }

    private setDocumentTypeUserOption() {
        if (this._userOption.ClauseSearchDocumentType) {
            this.MSSearchForm.controls.documentCategoryIDList.setValue(this._userOption.ClauseSearchDocumentType);
        }
    }

    private setEnvironmentIfSingle() {        
        let environment: EnvironmentGet | undefined = this.MSEnvironmentList[0];

        if (environment !== undefined) {
            const environmentNameArray: string[] = [environment.environmentName];
            this.MSSearchForm.controls.environmentList.setValue(environmentNameArray);
            this.loadDocumentCategory();
        } else {
            console.error("Environment is undefined");
        }        
    }

    public async MSOnEnvironmentsChanged() {
        this.MSDocumentTypeCategoryListSelectItems = [];
        this.MSSearchForm.controls.documentCategoryIDList.reset();
        this.MSSearchForm.controls.documentCategoryIDList.clearValidators();
        this.MSSearchForm.controls.documentCategoryIDList.updateValueAndValidity();
        this.loadDocumentCategory();
    }

    private async loadDocumentCategory() {
        const environmentNameList: string[] = this.MSSearchForm.getRawValue().environmentList;
        if (environmentNameList == null)
            return;

        if (environmentNameList.length > 1)
            return;

        this.MSDisableDocumentCategory = true;
        for await (const environmentName of environmentNameList) {
            const apiResult: ApiContentResult<DocumentCategoryGet[]> =
                await this._documentCategoryController.GetList(environmentName);

            if (this._componentUtilityService.WasSuccessful(apiResult)) {
                this.MSDocumentTypeCategoryListSelectItems = this.doMapCategorySelectItem(apiResult.content);
                this.MSSearchForm.controls.documentCategoryIDList.addValidators([Validators.required, Validators.minLength(1)]);
                this.MSSearchForm.controls.documentCategoryIDList.updateValueAndValidity();
            }
        };
        this.MSDisableDocumentCategory = false;
    }

    private doMapCategorySelectItem(documentCategoryGet: DocumentCategoryGet[]): SelectItem[] {
        if (documentCategoryGet === null) return [];

        const selectItems: SelectItem[] = []

        documentCategoryGet.map((documentCategory: DocumentCategoryGet) => {
            selectItems.push({
                label: documentCategory.categoryName,
                value: documentCategory.documentCategoryID
            })
        });
        return selectItems
    }

    public async MSOnClickSearch() {

        this.MSSearchForm.markAllAsTouched();
        if (this.MSSearchForm.invalid)
            return;

        this.MSSearchChunkList = [];
        this.MSLoading = true;
        this.MSLoadingMessage = 'Searching for results...'

        const searchForm: SearchParameter = this.MSSearchForm.getRawValue();

        const performanceTimer: PerformanceTimer = PerformanceTimer.start();
        if (this.MSLoading) {
            const apiResult: ApiContentResult<SearchResult> =
                await this._clauseSearchApiService.PostSearch(searchForm);
            if (this._componentUtilityService.WasSuccessful(apiResult)) {
                const searchResult: SearchResult = apiResult.content;
                this.processSearchResult(searchResult.searchResults, this.MSSearchForm);
                await this.updateUserOptionIfNecessary();
            }

            this.MSTimeElapsedForResult = performanceTimer.getElapsedTime();
            this.MSShowDocumentResultSection = true;
            this.MSLoading = false;
        }
    }

    public async updateUserOptionIfNecessary() {
        await this.createOrUpdateEnvironmentUserOption();
        await this.createOrUpdateDocumentCategoryOption();
        await this._userOptionService.SetUserOptionMapList();
    }

    private async createOrUpdateEnvironmentUserOption() {
        const selectedEnvironmentList: string[] = this.MSSearchForm.getRawValue().environmentList;

        const environemntUserOptionUpdateResult: void | IApiResult = await this._userOptionService.MSCreateOrUpdateUserOptionMap(UserOptions.ClauseSearchEnvironment, selectedEnvironmentList);
        if (environemntUserOptionUpdateResult) {
            this._componentUtilityService.WasSuccessful(environemntUserOptionUpdateResult);
        }
    }

    private async createOrUpdateDocumentCategoryOption() {
        const selectedDocumentTypeIDList: number[] = this.MSSearchForm.getRawValue().documentCategoryIDList;

        const documentCategoryUserOptionUpdateResult: void | IApiResult = await this._userOptionService.MSCreateOrUpdateUserOptionMap(UserOptions.ClauseSearchDocumentType, selectedDocumentTypeIDList);
        if (documentCategoryUserOptionUpdateResult) {
            this._componentUtilityService.WasSuccessful(documentCategoryUserOptionUpdateResult);
        }
    }

    public processSearchResult(searchResults: DbListResult[], searchForm: SearchFormType) {
        this.MSLoadingMessage = 'Processing result';
        this._changeDetectorRef.detectChanges();

        this.MSSearchResultFilter = new SearchResultFilter();
        this.MSTotalExactMatch = 0;
        this.MSIncludeExactMatches = this.MSSearchForm.getRawValue().includeExactMatches;

        for (const dbResult of searchResults) {

            if (dbResult.success) {
                const matches: SimilarChunk[] = dbResult.content
                    .map(chunk => {
                        const item = new SimilarChunk(chunk);
                        item.environmentName = dbResult.environmentName;
                        item.exactMatch = this.isExactMatch(chunk, searchForm);

                        if (item.exactMatch)
                            this.MSTotalExactMatch++;
                        if (this.MSIncludeExactMatches || !item.exactMatch)
                            this.MSSearchResultFilter.MSAddSimilarChunkToFilter(item);
                        return item;
                    });
                this.addAllMatchesToResult(matches);
            }
            else {
                this._moonMessageService.showMessages(MessageSeverity.Error, dbResult.message);
            }
        }
        this.MSSearchChunkList.orderByDescending(FieldNames.savedAsFavorite, FieldNames.relevance);
    }    
    
    private addAllMatchesToResult(matches: SimilarChunk[]) {
        if (this.MSIncludeExactMatches) {
            this.MSSearchChunkList.push(...matches)
        }
        else {
            this.MSSearchChunkList.push(...matches.filter(similarChunk => !similarChunk.exactMatch));
        }
    }

    private isExactMatch(chunk: SimilarDocumentChunk, searchform: SearchFormType) {
        if (chunk.relevance > 0.97) {
            return DMP_IsExactMatch(searchform.value.content ?? String.empty, chunk.content, this.MSDefaultBlacklineAlgorithm)
        }
        return false;
    }

    // private relevanceFormValidator() {
    //     return (form: AbstractControl<SearchFormType>): { [key: string]: any; } | null => {
    //         const minRelevance = form.get('min')?.value;
    //         const maxRelevance = form.get('max')?.value;

    //         if (minRelevance != null && maxRelevance != null && minRelevance > maxRelevance) {
    //             return { invalidData: 'Min value cannot be greater than max value.' };
    //         }
    //         return null;
    //     };
    // }

    // private scrollToResult() {
    //     this._changeDetectorRef.detectChanges();
    //     this._resultElement?.nativeElement?.scrollIntoView({ block: "nearest", inline: "start", behavior: 'smooth' });
    // }
}