// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import { NgIf }                                                                   from "@angular/common";
import { Component, OnDestroy, OnInit }                                           from "@angular/core";
import { MenuItem, PrimeIcons }                                                             from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef }                   from "primeng/dynamicdialog";
// Moon Imports
import { AppBreadcrumbService }                                                   from '@app-layout/services/app.breadcrumb.service';
import { ApiBlobResult, ApiContentResult }                                                       from '@moon-core/models/api-result';
import { ComponentUtilityService }                                                from '@moon-core/services/component-utility.service';
import { FindTitleSummaryParameter }                                              from "@moon-public/api/request/find-title-summary.parameter";
import { TitleSummaryFind }                                                       from "@moon-public/api/response/title-summary-find.response";
import { TitleSummaryController }                                                 from "@moon-public/api/title-summary.controller";
import { CloseTitleSummaryEditDynamicDialogData, TitleReviewEditModalComponent }  from "@moon-public/title-summary/modals/title-review-edit-modal/title-review-edit-modal.component";
import { ProcessingStatus }                                                       from "@moon-public/title-summary/models/processing-status";
import { TitleSummaryTableComponent }                                             from "@moon-public/title-summary/title-summary-table/title-summary-table.component";
import { MoonLoadingComponent }                                                   from "@moon-shared/components/moon-loading/moon-loading.component";
import { RoutePath }                                                              from "@moon-shared/constants/route-path";
import { MoonMessageService }                                                     from "@moon-shared/services/moon-message.service";

@Component({
  selector: 'title-summary',
  templateUrl: './title-summary.component.html',
  styleUrls: ['./title-summary.component.css'],
  standalone: true,
  imports: [NgIf,
    MoonLoadingComponent, TitleSummaryTableComponent],
  providers: [TitleSummaryController, DialogService]
})
export class TitleSummaryComponent implements OnInit, OnDestroy {

  public MSTitleSummaryList: TitleSummaryFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = RoutePath.TitleReview.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDynamicDialogRef?: DynamicDialogRef;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _titleSummaryController: TitleSummaryController,
    private _dialogService: DialogService,
    private _moonMessageService: MoonMessageService,
  ) { }

  async ngOnInit() {
    this.setBreadcrumb();
    await this.loadTitleSummaryList();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  private async loadTitleSummaryList() {
    try {
      this.MSLoading = true;
      const findParameter = new FindTitleSummaryParameter();
      const apiResult: ApiContentResult<TitleSummaryFind[]> = await this._titleSummaryController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleSummaryList = apiResult.content;
        this.MSTitleSummaryList.map(titleReview => {
          titleReview.actions = this.getActions(titleReview);
          titleReview.lastUpdatedAt = new Date(titleReview.lastUpdatedAtUtc + 'Z');
          return titleReview;
        });
        this.MSTitleSummaryList.orderByDescending("lastUpdatedAt");

      };
    } finally {
      this.MSLoading = false;
    }
  }


  private getActions(titleSummary: TitleSummaryFind): MenuItem[] {
    let actionBtns: MenuItem[] = [
      { icon: PrimeIcons.PENCIL, label: 'Edit', command: () => this.showEditTitleSummaryDialog(titleSummary) },
      { icon: PrimeIcons.DOWNLOAD, label: 'Title Commitment', command: () => this.OnDownloadTitleCommitmentClick(titleSummary) }
    ];

    if (this.showDownloadWord(titleSummary)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: 'Summary', command: () => this.OnDownloadWordSummaryClick(titleSummary) });
    }
    if (this.showExportPdf(titleSummary)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: 'Exception PDFs', command: () => this.OnExportPdfClick(titleSummary) });
    }
    if (this.showExportOcr(titleSummary)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: 'Exception OCR Text', command: () => this.OnExportOcrClick(titleSummary) });
    }
    return actionBtns;
  }

  private showEditTitleSummaryDialog(titleSummary: TitleSummaryFind) {
    const dynamicDialogConfig: DynamicDialogConfig<TitleSummaryFind> = {
      styleClass: 'w-30per moon-modal-wrapper',
      header: 'Edit title review',
      data: titleSummary
    };
    this.MSDynamicDialogRef = this._dialogService.open(TitleReviewEditModalComponent, dynamicDialogConfig);
    this.MSDynamicDialogRef.onClose.subscribe(
      (data: CloseTitleSummaryEditDynamicDialogData) => {
        const successEvent: CloseTitleSummaryEditDynamicDialogData = "success";
        if (successEvent.equalsIgnoreCase(data)) {
          this.loadTitleSummaryList();
        }
      });
  }
  private showDownloadWord(titleSummary: TitleSummaryFind): boolean {
    if (ProcessingStatus.Complete.equalsIgnoreCase(titleSummary.processingStatus))
      return true;
    return false;
  }
  private showExportPdf(titleSummary: TitleSummaryFind): boolean {
    if (ProcessingStatus.Created.equalsIgnoreCase(titleSummary.processingStatus))
      return false;
    return true;
  }
  private showExportOcr(titleSummary: TitleSummaryFind): boolean {
    if (ProcessingStatus.Created.equalsIgnoreCase(titleSummary.processingStatus))
      return false;
    if (ProcessingStatus.TitleDocumentDownloaded.equalsIgnoreCase(titleSummary.processingStatus))
      return false;
    return true;
  }

  public async OnDownloadWordSummaryClick(titleSummary: TitleSummaryFind): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.GetDocxBlob(titleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download Word summary successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnDownloadTitleCommitmentClick(titleSummary: TitleSummaryFind) {
    try {
      this.MSLoading = true;

      const apiResult: ApiBlobResult = await this._titleSummaryController.GetPdfBlob(titleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Title commitment downloaded successful.");
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  public async OnExportPdfClick(titleSummary: TitleSummaryFind): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportPdf(titleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export pdf successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnExportOcrClick(titleSummary: TitleSummaryFind): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportOcr(titleSummary.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export ocr successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }
  public async MSOnEmitReload() {
    this.loadTitleSummaryList();
  }

  ngOnDestroy() {
    this.MSDynamicDialogRef?.close()
  }
}
