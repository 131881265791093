import { CommonModule }                                             from '@angular/common';
import { Component, Input, ViewChild }                              from '@angular/core';
// Third party imports
import { PdfViewerComponent, PdfViewerModule }                      from 'ng2-pdf-viewer';
import { ButtonModule }                                             from 'primeng/button';
import { ProgressSpinnerModule }                                    from 'primeng/progressspinner';
import { RippleModule }                                             from 'primeng/ripple';
// Moon Imports
import { ApiBlobResult }                                            from '@moon-core/models/api-result';
import { ComponentUtilityService }                                  from '@moon-core/services/component-utility.service';
import { TitleDocumentController }                                  from '@moon-public/api/title-document.controller';
import { TitleSummaryController }                                   from '@moon-public/api/title-summary.controller';
import { TitleSummaryPdf }                                          from '@moon-public/title-summary/title-summay-pdf';
import { RoutePath }                                                from '@moon-shared/constants/route-path';

@Component({
  selector: 'app-pdf-display',
  standalone: true,
  templateUrl: './pdf-display.component.html',
  styleUrls: ['./pdf-display.component.scss'],
  imports: [
    CommonModule, PdfViewerModule, ButtonModule, RippleModule,
    ProgressSpinnerModule],
})
export class PdfDisplayComponent {
  @ViewChild('pdfViewer') private _pdfComponent: PdfViewerComponent;
  @Input() public MSEntityType: string;
  @Input() public MSEntityID: number;
  @Input() public MSExceptionPagesCSV: string;
  public MSTitleSummaryPdfBlob: TitleSummaryPdf = new TitleSummaryPdf();
  public MSIsPdfDownloadingAndLoading: boolean = false;
  private blobDownloadResult: ApiBlobResult;

  constructor(
    private _titleSummaryController: TitleSummaryController,
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
  ) { }

  async ngOnInit() {

    if (RoutePath.TitleReview.equalsIgnoreCase(this.MSEntityType)) {
      await this.loadTitleSummaryByID(this.MSEntityID, this.MSExceptionPagesCSV);
    }
    else if (RoutePath.TitleDocument.equalsIgnoreCase(this.MSEntityType)) {
      await this.loadTitleDocumentByID(this.MSEntityID);
    }
  }

  public MSDownloadPdf() {
    this._componentUtilityService.WasDownloadSuccessful(this.blobDownloadResult);
  }
  
  private async loadTitleSummaryByID(titleSummaryID: number, exceptionPagesCsv: string) {
    try {
      this.MSIsPdfDownloadingAndLoading = true;

      const apiResult: ApiBlobResult = await this._titleSummaryController.GetPdfBlob(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.blobDownloadResult = apiResult;
        this.MSTitleSummaryPdfBlob.ReadContentFromBlob(apiResult.content);
        this.setExceptionPages(exceptionPagesCsv);
      }
    }
    finally {
      this.MSIsPdfDownloadingAndLoading = false;
    }
  }

  private async loadTitleDocumentByID(titleDocumentID: number) {
    try {
      this.MSIsPdfDownloadingAndLoading = true;
      const apiResult: ApiBlobResult = await this._titleDocumentController.GetBlob(titleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.blobDownloadResult = apiResult;
        this.MSTitleSummaryPdfBlob.ReadContentFromBlob(apiResult.content);
      }
    }
    finally {
      this.MSIsPdfDownloadingAndLoading = false;
    }
  }

  public MSSearchPdf(stringToSearch: string) {
    this._pdfComponent.eventBus.dispatch('find', {
      query: stringToSearch, type: 'again', caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: true
    });
  }

  private setExceptionPages(exceptionPagesCsv: string) {
    if (!String.isNullOrWhiteSpace(exceptionPagesCsv)) {
      const exceptionPages: number[] = exceptionPagesCsv.split(',').map(a => parseInt(a));
      this.MSTitleSummaryPdfBlob.SetExceptionPages(exceptionPages);
    }
  }

}
