// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }                from '@moon-core/models/api-result';
import { MoonApiClient }            from '@moon-core/services/moon-api.client';
import { UserInfo }                 from "@moon-maintainer/api/request/user-info.request";
import { FindUserInfoParameter }    from "@moon-maintainer/api/request/find-user-info.parameter";
import { UserInfoFind }             from "@moon-maintainer/api/response/user-info-find.response";
import { UserInfoGet }              from "@moon-maintainer/api/response/user-info-get.response";
import { ConstantString }           from "@moon-shared/constants/constant-string";

@Injectable()
export class UserInfoController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.UserInfo);
  };

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/UserInfo/find
 */
  public async Find(findParameter: FindUserInfoParameter
    ): Promise<ApiContentResult<UserInfoFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/UserInfo
 */
  public async GetList(
    ): Promise<ApiContentResult<UserInfoGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<UserInfoGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/UserInfo
 */
  public async Create(userInfo: UserInfo
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, userInfo);
  }
  
/**
 *   GET: Maintainer/v1/UserInfo/{documentID}
 */
  public async GetByID(userInfoID: number
    ): Promise<ApiContentResult<UserInfoGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userInfoID);
    return this._moonApi.getAsync<UserInfoGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/UserInfo/{documentID}
 */
  public async Update(userInfoID: number, userInfo: UserInfo
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userInfoID);
    return this._moonApi.putAsync(url, userInfo);
  }
  
/**
 *   DELETE: Maintainer/v1/UserInfo/{documentID}
 */
  public async Delete(userInfoID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userInfoID);
    return this._moonApi.deleteAsync(url);
  }
}
