// Moon Auto Generated (1.0.0) at 12/18/2023 10:34:38 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }                  from '@moon-core/models/api-result';
import { MoonApiClient }              from '@moon-core/services/moon-api.client';
import { UserGroup }                  from "@moon-maintainer/api/request/user-group.request";
import { FindUserGroupParameter }     from "@moon-maintainer/api/request/find-user-group.parameter";
import { UserGroupFind }              from "@moon-maintainer/api/response/user-group-find.response";
import { UserGroupGet }               from "@moon-maintainer/api/response/user-group-get.response";
import { ConstantString }             from "@moon-shared/constants/constant-string";

@Injectable()
export class UserGroupController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.UserGroup);
  };

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/UserGroup/find
 */
  public async Find(findParameter: FindUserGroupParameter
    ): Promise<ApiContentResult<UserGroupFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/UserGroup
 */
  public async GetList(
    ): Promise<ApiContentResult<UserGroupGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<UserGroupGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/UserGroup
 */
  public async Create(userGroup: UserGroup
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, userGroup);
  }
  
/**
 *   GET: Maintainer/v1/UserGroup/{documentID}
 */
  public async GetByID(userGroupID: number
    ): Promise<ApiContentResult<UserGroupGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userGroupID);
    return this._moonApi.getAsync<UserGroupGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/UserGroup/{documentID}
 */
  public async Update(userGroupID: number, userGroup: UserGroup
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userGroupID);
    return this._moonApi.putAsync(url, userGroup);
  }
  
/**
 *   DELETE: Maintainer/v1/UserGroup/{documentID}
 */
  public async Delete(userGroupID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userGroupID);
    return this._moonApi.deleteAsync(url);
  }
}
