// Moon Auto Generated (1.0.0) at 11/22/2023 10:52:50 AM
import { Injectable }                                   from "@angular/core";
// MS Imports
import { Document }                                     from "@moon-collaborator/api/request/document.request";
import { FindDocumentParameter }                        from "@moon-collaborator/api/request/find-document.parameter";
import { DocumentChunkGet }                             from "@moon-collaborator/api/response/document-chunk-get.response";
import { DocumentFind }                                 from "@moon-collaborator/api/response/document-find.response";
import { DocumentGet }                                  from "@moon-collaborator/api/response/document-get.response";
import { ApiBlobResult, ApiContentResult, ApiResult }   from '@moon-core/models/api-result';
import { MoonApiClient }                                from '@moon-core/services/moon-api.client';
import { ConstantString }                               from "@moon-shared/constants/constant-string";
import { BooleanString }                                from "@moon-shared/types/boolean-string.type";
import { DocumentMetaData }                             from "@app/moon-collaborator/api/response/document-metadata.response";

@Injectable()
export class DocumentController {

  private getBaseUrl(environmentName: string): string {
    return this._moonApi.getCollaboratorUrl(environmentName, ConstantString.Document);
  };

  constructor(private _moonApi: MoonApiClient) { }

  /**
   * POST: Collaborator/v1/{environmentName}/Document/find
   */
  public async Find(environmentName: string, findParameter: FindDocumentParameter
  ): Promise<ApiContentResult<DocumentFind[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Find);
    return this._moonApi.postWithResultAsync(url, findParameter);
  }

  /**
   * POST: Collaborator/v1/{environmentName}/Document
   */
  public async Create(environmentName: string, document: Document
  ): Promise<ApiContentResult<number>> {

    const url = this.getBaseUrl(environmentName);
    return this._moonApi.postWithResultAsync(url, document);
  }

  /**
   * GET: Collaborator/v1/{environmentName}/Document/{documentID}
   */
  public async GetByID(environmentName: string, documentID: number
  ): Promise<ApiContentResult<DocumentGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID);
    return this._moonApi.getAsync<DocumentGet>(url);
  }

  /**
   * PUT: Collaborator/v1/{environmentName}/Document/{documentID}
   */
  public async Update(environmentName: string, documentID: number, document: Document
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID);
    return this._moonApi.putAsync(url, document);
  }

  /**
   * DELETE: Collaborator/v1/{environmentName}/Document/{documentID}
   */
  public async Delete(environmentName: string, documentID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID);
    return this._moonApi.deleteAsync(url);
  }

  // Start Actions

  /**
   * PUT: Collaborator/v1/{environmentName}/Document/{documentID}/Chunk
   */
  public async ChunkAsync(environmentName: string, documentID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID, ConstantString.Chunk);
    return this._moonApi.putAsync(url, null);
  }

  /**
   * PUT: Collaborator/v1/{environmentName}/Document/{documentID}/Vector
   */
  public async VectorizeAsync(environmentName: string, documentID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID, ConstantString.Vector);
    return this._moonApi.putAsync(url, null);
  }

  /**
   * POST: Collaborator/v1/{environmentName}/Document/Download
   */
    public async DownloadWordFilesZip(environmentName: string,  documentIDList: Array<number>): Promise<ApiBlobResult> {
        const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Download);
      return this._moonApi.getBlobWithPostAsync(url, documentIDList);
    }
  
  // End Actions

  // Start DocumentChunk

  /**
   * POST: Collaborator/v1/{environmentName}/Document/{documentID}/Chunk
   */
  public async GetChunkListAsync(environmentName: string, documentID: number
  ): Promise<ApiContentResult<DocumentChunkGet[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID, ConstantString.Chunk);
    return this._moonApi.getAsync<DocumentChunkGet[]>(url);
  }


  /**
   * GET: Collaborator/v1/{environmentName}/DocumentChunk/{documentChunkID}
   */
  public async GetDocumentChunkAsync(environmentName: string, documentChunkID: number
  ): Promise<ApiContentResult<DocumentChunkGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.DocumentChunk, documentChunkID);
    return this._moonApi.getAsync<DocumentChunkGet>(url);
  }

  /**
   * POST: Collaborator/v1/{environmentName}/Document/{documentID}/Chunk/{ordinal}
   */
  public async GetChunkListOrdinalAsync(environmentName: string, documentID: number, ordinal: number
  ): Promise<ApiContentResult<DocumentChunkGet[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID, ConstantString.Chunk, ordinal);
    return this._moonApi.getAsync<DocumentChunkGet[]>(url);
  }

  /**
   * PUT: Collaborator/v1/{environmentName}/Document/Chunk/{status}
   */
  public async UpdateChunkStatusAsync(environmentName: string, documentIDList: number[], embeddingStatus: 'DNC' | 'MCR'
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Chunk, embeddingStatus);
    return this._moonApi.putAsync(url, documentIDList);
  }

  // End DocumentChunk

  // Start DocumentBlob

  /**
   * GET: Collaborator/v1/{environmentName}/Document/{documentID}/Blob
   */
  public async GetBlobAsync(environmentName: string, documentID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentID, ConstantString.Blob);
    return this._moonApi.getBlobAsync(url);
  }

  // End DocumentBlob

  /**
   * GET: Collaborator/v1/{environmentName}/Document/Export
   */
  public async ExportAsync(environmentName: string
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Export);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   * POST: Collaborator/v1/{environmentName}/Document/Import/{hasMetaData}
   */
  public async ImportAsync(environmentName: string, inputFile: File, hasMetadata: BooleanString
  ): Promise<ApiResult> {

    let formFile = new FormData();
    formFile.append('formFile', inputFile);

    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Import, hasMetadata);
    return this._moonApi.postAsync(url, formFile);
  }

  // Start Metadata
  /**
   * GET: Collaborator/v1/{environmentName}/Metadata/Update
   */
  public async UpdateMetaData(environmentName: string, documentMetadata: DocumentMetaData
    ): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Metadata, ConstantString.Update);
    return this._moonApi.putAsync(url, documentMetadata);  
  }
  
  /**
   * GET: Collaborator/v1/{environmentName}/Metadata/Export
   */
  public async MetadataExportAsync(environmentName: string
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Metadata, ConstantString.Export);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   * PUT: Collaborator/v1/{environmentName}/Metadata/Import
   */
  public async MetadataImportAsync(environmentName: string, metadataFile: File
    ): Promise<ApiResult>  {

    let formData = new FormData();
    formData.append('formFile', metadataFile)
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), ConstantString.Metadata, ConstantString.Import);
    return this._moonApi.putAsync(url, formData);
  }

  // End Metadata
}
