<div
    class="moon-progress-bar"
    *ngIf="MSIsProcessInProgress"
>
    <p class="moon-progress-message">
        {{ MSProcessMessage }}
    </p>
    <p-progressBar
        mode="indeterminate"
        styleClass="h-1px"
    ></p-progressBar>
</div>

<p-table
    #MSTable
    [columns]="MSTableColumns"
    class="cb-table"
    [value]="MSFilteredItems"
    [rowHover]="true"
    [showCurrentPageReport]="true"
    selectionMode="single"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    [scrollable]="true"
    [scrollHeight]="MSScrollHeight"
    [(selection)]="MSSelectedItems"
    [globalFilterFields]="['documentName', 'metadataJson']"
>
    <ng-template pTemplate="caption">
        <div class="table-header">
            <div class="grid">
                <div class="moon-back-button">
                    <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-arrow-left"
                        (click)="MSOnClickGoBack()"
                        class="p-button-rounded p-button-text mr-2 mb-2"
                        pTooltip="Go Back"
                        tooltipPosition="bottom"
                    ></button>
                </div>
                <div class="moon-page-title">
                    <h5>{{ MSPageTitle }}</h5>
                </div>
            </div>
            <div class="moon-table-action-right">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                        #textInput
                        pInputText
                        type="text"
                        (input)="
                            MSTable.filterGlobal(textInput.value, 'contains')
                        "
                        placeholder="Search"
                    />
                </span>
            </div>
        </div>
    </ng-template>
    <ng-template
        pTemplate="header"
        let-columns
    >
        <tr>
            <th
                [style.flex-basis]="'auto'"
                class="moon-col-checkbox"
            >
                <p-tableHeaderCheckbox [disabled]="MSIsProcessInProgress"></p-tableHeaderCheckbox>
            </th>

            <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [class]="col.class"
                [ngClass]="{ 
                'actions-column': col.field === 'actions',
                'metadata-json-field': col.field === 'metadataJson'
                }"
            >
                <div [ngSwitch]="col.field">

                    <div *ngSwitchCase="'isChunked'">
                        {{ col.header }}

                        <p-columnFilter
                            field="isChunked"
                            matchMode="in"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
                        >
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">{{ col.header }}</span>
                                </div>
                            </ng-template>
                            <ng-template
                                pTemplate="filter"
                                let-value
                                let-filter="filterCallback"
                            >
                                <p-multiSelect
                                    [ngModel]="value"
                                    [options]="MSIsChunkedSelectItemList"
                                    (onChange)="filter($event.value)"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Any"
                                >
                                    <ng-template
                                        let-option
                                        pTemplate="item"
                                    >
                                        <div class="inline-block vertical-align-middle">
                                            <span class="ml-1 mt-1">{{ option.label }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>

                    <div *ngSwitchCase="'totalVectors'">
                        {{ col.header }}
                        <p-columnFilter
                            type="numeric"
                            field="totalVectors"
                            display="menu"
                            class="ml-auto"
                        ></p-columnFilter>
                    </div>

                    <span *ngSwitchDefault>
                        {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                    </span>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
    >
        <tr class="p-selectable-row">
            <td
                [style.flex-basis]="'auto'"
                class="moon-col-checkbox"
            >
                <p-tableCheckbox
                    [disabled]="MSIsProcessInProgress"
                    [value]="rowData"
                ></p-tableCheckbox>
            </td>

            <td
                *ngFor="let col of columns"
                [class]="col.class"
                [ngClass]="{
            'actions-column': col.field === 'actions',
            'metadata-json-field': col.field === 'metadataJson',
            clickable: col.field !== 'actions'
        }"
            >
                <div [ngSwitch]="col.field">
                    <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td
                [style.flex-basis]="'auto'"
                colspan="6"
                class="moon-text-center"
            >
                No Documents found.
            </td>
        </tr>
    </ng-template>
</p-table>