import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
}                                 from "@angular/common/http";
import { Observable } from "rxjs";
import { UserSessionService }  from "@moon-core/services/user-session.service";

@Injectable()
export class MoonUserSessionHeaderInterceptor implements HttpInterceptor {
    constructor(private _userSessionService: UserSessionService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const moonUserSessionHeader = this._userSessionService.GetMoonUserSessionHeader();

        const updatedReq: HttpRequest<any> = moonUserSessionHeader ? req.clone({ setHeaders: { moonstoneusersession: moonUserSessionHeader } }) : req;

        return next.handle(updatedReq);
    }
}
