<!-- Moon Auto Generated (1.0.0) at 11/22/2023 11:48:18 AM -->
<moon-table-page
  [MSPageTitle]="''"
  [MSItemList]="MSFilteredChunkList"
  [MSDisplayCreateButton]="MSDisplayCreate"
  [MSDisplaySecondaryButton]="MSDisplaySecondaryButton"
  [MSDisplayFindButton]="MSDisplayFind"
  [MSSecondaryButtonToolTip]="MSSecondaryButtonToolTip"
  [MSSecondaryButtonIcon]="MSSecondaryButtonIcon"
>
  <p-table
    #MSTable
    [columns]="MSTableColumns"
    [rowHover]="true"
    [value]="MSFilteredChunkList"
    selectionMode="single"
    [loading]="MSLoading"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    [(selection)]="MSSelectedChunks"
    dataKey="ordinal"
    [paginator]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rows]="20"
    [scrollable]="true"
    scrollHeight="flex"
  >
    <ng-template
      pTemplate="header"
      let-columns
    >
      <tr>
        <th class="w-4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [class]="col.class"
          [ngClass]="{ 'actions-column': col.field === 'actions' }"
        >
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
    >
      <tr>
        <td>
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td
          *ngFor="let col of columns"
          [class]="col.class"
          [ngClass]="{
            'actions-column': col.field === 'actions',
            clickable: col.field !== 'actions'
          }"
        >
          <div [ngSwitch]="col.field">
            <div
              *ngSwitchCase="'actions'"
              class="text-right"
            >
              <moon-custom-dropdown [model]="rowData.actions">
                <i class="pi pi-ellipsis-v"></i>
              </moon-custom-dropdown>
            </div>
            <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</moon-table-page>