<!-- Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM -->
<moon-form-page
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    (MSOnClickEventSubmit)="OnClickSubmit()"
    (MSOnClickEventCancel)="OnClickCancel()"
>
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'Environment Name'"
            [MSFormControl]="MSParentForm.controls.environmentName"
        >
            <input
                type="text"
                formControlName="environmentName"
                pInputText
            />
        </moon-form-control>
    </div>
</moon-form-page>