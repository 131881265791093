<!-- Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                [MSDisplayRouteBackButton]="MSDisplayRouteBackButton"
                (MSOnClickEventRemove)="OnClickRemove()"
                (MSOnClickEventRouteBack)="OnClickRouteBack()">
    <ng-container *ngIf="!MSLoading">
        <document-category-form #MSForm
                       [MSEnvironmentName]="MSEnvironmentName"
                       [MSDocumentCategoryID]="MSDocumentCategoryID"
                       [MSDocumentCategoryGet]="MSDocumentCategoryGet">
        </document-category-form>
    </ng-container>
</moon-edit-page>
