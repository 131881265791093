import { PDFDocumentProxy } from "ng2-pdf-viewer";

export class TitleSummaryPdf {
  pdfSrc: string | Uint8Array | null;
  activePdfPage: number = 1;
  totalPdfPage: number;
  zoom: number = 1;
  #exceptionPages: Set<number> = new Set();
  pdfThumbnails: { page: number, url: string }[] = [];

  public Reset() {
    this.pdfSrc = null;
    this.activePdfPage = 1;
    this.totalPdfPage = 0;
    this.zoom = 1;
    this.#exceptionPages = new Set();
  }

  public MSZoomInPdf() {
    this.zoom += 0.1;
  }

  public MSZoomOutPdf() {
    this.zoom -= 0.1;
  }

  public SetExceptionPages(pageNumbers: number[]) {
    this.#exceptionPages = new Set(pageNumbers);
    console.warn('TitleSummaryPdf.SetExceptionPages().#exceptionPages', this.#exceptionPages);
  }

  public IsCurrentPageMarked() {
    return this.#exceptionPages.has(this.activePdfPage);
  }

  public IsPageMarked(pageNumber: number) {
    return this.#exceptionPages.has(pageNumber);
  }
  public HasExceptionPages(): boolean {
    return this.#exceptionPages.size > 0;
  }

  public GetExceptionPageArray(): number[] {
    return Array.from(this.#exceptionPages);

  }

  public GetExceptionPagesList(): string {
    return Array.from(this.#exceptionPages).join(', ');
  }

  public ClearExceptionPages() {
    this.#exceptionPages.clear();
  }

  public IsNextDisabled() {
    return this.activePdfPage === this.totalPdfPage;
  }

  public NavigateNextPage() {
    if (this.activePdfPage < this.totalPdfPage)
      this.activePdfPage++;
  }

  public IsPreviousDisabled() {
    return this.activePdfPage === 1;
  }

  public NavigatePreviousPage() {
    if (this.activePdfPage > 1)
      this.activePdfPage--;
  }

  public MarkUnmarkAsExceptionPage(pageNumber: number) {
    if (this.#exceptionPages.has(pageNumber))
      this.#exceptionPages.delete(pageNumber);

    else
      this.#exceptionPages.add(pageNumber);
  }

  public ReadContentFromBlob(blob: Blob) {
    if (blob.size > 0)
      this.pdfSrc = URL.createObjectURL(blob);
  }

  public ReadContentFromFile(file: File) {
    let reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result)
        this.pdfSrc = e.target.result as string | Uint8Array;
    };

    reader.readAsArrayBuffer(file);
  }

  
  public OnPdfPageChanged(pageNumber: number) {
    // do not scroll into view if page was changed manually from side navigation
    if (this.activePdfPage !== pageNumber) {
      this.activePdfPage = pageNumber;
      document.getElementById('moon-thumbnail-page-' + pageNumber)?.scrollIntoView(true);
    }
  }

  public async OnPdfLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPdfPage = pdf.numPages;
    const promises = [];

    for (let pageNo = 1; pageNo <= pdf.numPages; pageNo += 1) {
      const page = await pdf.getPage(pageNo);
      let canvasElement = document.createElement('canvas');
      canvasElement.height = 238;
      canvasElement.width = 185;
      let canvasContext = canvasElement.getContext('2d');

      if (canvasContext) {
        const renderPromise = page.render({
          viewport: page.getViewport({ scale: 0.31}),
          canvasContext: canvasContext
        }).promise;

        promises.push(renderPromise.then(() => ({
          page: pageNo,
          url: canvasElement.toDataURL()
        })));
      }
    }

    // Wait for all promises to resolve
    this.pdfThumbnails = (await Promise.all(promises)).orderBy('page');
  }

}
