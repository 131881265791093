<ul class="layout-menu">
    <ng-container *ngIf="MSShowPagesRoute">
        <ng-container *ngIf="MSMenuItems">
            <li
                app-menuitem
                *ngFor="let item of MSMenuItems; let i = index"
                [item]="item"
                [index]="i"
                [root]="true"
            ></li>
        </ng-container>
    </ng-container>
</ul>