<!-- Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <div
        class="moon-progress-bar"
        *ngIf="MSIsProcessInProgress"
    >
        <p class="moon-progress-message">
            {{ MSProcessMessage }}
        </p>
        <p-progressBar
            mode="indeterminate"
            styleClass="h-1px"
        ></p-progressBar>
    </div>

    <mezz-loan-chunk-table
        #MSTableComponent
        [MSPageTitle]="MSPageTitle"
        [MSMezzLoanChunkList]="MSMezzLoanChunkList"
        [MSLoading]="MSLoading"
        (MSEventReloadPage)="MSReloadPage()"
    ></mezz-loan-chunk-table>

    <div class="col-12 md:col-6 moon-div-item-count">
        <span>Number of selected items: {{ MSSSelectedItemsLength }} | Total Token Size: {{ MSSSelectedItemsTokenSize }}</span>
    </div>
    <div class="grid">
        <button
            [disabled]="MSIsProcessInProgress"
            pButton
            pRipple
            type="button"
            (click)="OnRunPromptClick()"
            label="Prompt"
            pTooltip="Run Prompt"
            tooltipPosition="top"
        ></button>

        <button
            [disabled]="MSIsProcessInProgress"
            pButton
            pRipple
            type="button"
            (click)="OnRunPromptBatchClick()"
            label="Prompt Batch"
            pTooltip="Run Prompt Batch"
            tooltipPosition="top"
        ></button>
    </div>
</div>