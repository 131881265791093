import { Clipboard }                        from '@angular/cdk/clipboard';
import { Component, HostListener, Input }   from '@angular/core';
import { 
  FormControl, FormGroup, 
  FormsModule, ReactiveFormsModule }        from '@angular/forms';
import { NgIf }                             from '@angular/common';
// Third Party imports
import { ProgressSpinnerModule }            from 'primeng/progressspinner';
import { TooltipModule }                    from 'primeng/tooltip';
import { BadgeModule }                      from 'primeng/badge';
import { RippleModule }                     from 'primeng/ripple';
import { ButtonModule }                     from 'primeng/button';
import { InputTextareaModule }              from 'primeng/inputtextarea';
import { MenuItem, SelectItem }             from 'primeng/api';
import { DropdownChangeEvent, DropdownModule }                   from 'primeng/dropdown';
// MS Imports
import { ApiContentResult }                 from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';

import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { TokenBreakdownComponent }          from '@moon-shared/components/token-breakdown/token-breakdown.component';

import { ChatResult }                       from '@moon-public/api/response/chat-result.response';
import { ChatSkillController }              from '@moon-public/api/chat-skill.controller';
import { ChatParameter }                    from "@moon-public/models/chat-parameter";

import { MaintainerRouter }                 from '@moon-maintainer/maintainer.router';
import { SkillGet }                         from '@moon-maintainer/api/response/skill-get.response';
import { AppBreadcrumbService }             from '@app/app-layout/services/app.breadcrumb.service';
import { RoutePath }                        from '@moon-shared/constants/route-path';
import { ActivatedRoute }                   from '@angular/router';
import { SkillController }                  from '@moon-maintainer/api/skill.controller';
import { RouteParameter }                   from '@moon-shared/constants/route-parameter';
import { MoonLoadingComponent }             from '@moon-shared/components/moon-loading/moon-loading.component';

@Component({
    selector: 'moon-skill-test',
    templateUrl: './skill-test.component.html',
    styleUrls: ['./skill-test.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, 
              TokenBreakdownComponent, MoonFormControlComponent, InputTextareaModule, ButtonModule, RippleModule, DropdownModule,
              NgIf, BadgeModule, TooltipModule, ProgressSpinnerModule, MoonLoadingComponent],
    providers: [
      ChatSkillController,
      MaintainerRouter,
      SkillController,
    ]
})
export class SkillTestComponent {

  public MSSkillID: number | null;
  @Input() public MSSkillGet: SkillGet;
  @Input() public MSDisableForSkillEdit: boolean = false;
  @Input() public MSSubmitting: boolean = false;
  
  public MSSkillTestForm = new FormGroup<{ message: FormControl<string>, messageToken: FormControl<number> }>({
    message: new FormControl('', { nonNullable: true }),
    messageToken: new FormControl(0, { nonNullable: true })
  });

  public MSSkillTesting: boolean = false;
  public MSChatResult: ChatResult | null;
  public MSTestMode: boolean = false;
  public MSLoading: boolean = false;
  public MSSkillListSelectItem: SelectItem[] = [];
  public MSSelectedSkillList: SelectItem | null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _chatSkillController: ChatSkillController,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _maintainerRouter: MaintainerRouter,
    private _componentUtilityService: ComponentUtilityService,
    private _clipboard: Clipboard,
    private _skillController: SkillController,
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    await this.loadSkillList();
    this.setDefaultDropdown();
    //this.calculateTokenOnSKillTestFormMessageChange();
  }
  
  private readRouteParameters() {
    const skillID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.SkillID);
    this.MSSkillID = skillID ? +skillID : null;
  }
  
  private setBreadcrumb() {
    if(this.MSDisableForSkillEdit) return;
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Skill, routerLink: this._maintainerRouter.SkillRouteLink() }, 
      { label: RoutePath.Test, routerLink: null }
    ];
    
    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
  
  private async getSkillByID(skillID: number) {
    if (skillID) {
      const apiResult: ApiContentResult<SkillGet> = await this._skillController.GetByID(skillID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSSkillGet = apiResult.content;
      }
    }
  }

  public async MSOnSkillChange(event: DropdownChangeEvent): Promise<void> {
    let skillID = event.value;
    if (skillID){
      await this.getSkillByID(skillID);
    }
  }

  public MSOnClickGoBack(): void {
    this._maintainerRouter.ToSkill();
  }

  public get MSTokenBreakdown() {
    let tokenBreakdown = { promptTokens: 0, inputTokens: 0, maxOutputTokens: 0, used: 0, limit: 0 };
    // tokenBreakdown.promptTokens =
    //   this.MSSkillForm.controls.systemTokens.value +
    //   this.MSSkillExampleFormArray.controls.reduce(
    //     (a, b) => a + (b.controls.userTokens.value + b.controls.assistantTokens.value), 0
    //   );
    tokenBreakdown.inputTokens = this.MSSkillTestForm.controls.messageToken.value;
    // tokenBreakdown.maxOutputTokens = this.MSSkillForm.controls.maxOutputTokens.value;
    tokenBreakdown.limit = 32000;
    tokenBreakdown.used = tokenBreakdown.promptTokens + tokenBreakdown.inputTokens + tokenBreakdown.maxOutputTokens;
    return tokenBreakdown;
  }
  
  public async MSApplySkill(): Promise<void>  {
    if (this.MSSkillGet == null) { return; }
    if (this.MSSkillTestForm.invalid) { return; }

    let message: string = this.MSSkillTestForm.value.message?.trim() ?? String.empty;
    if (message === String.empty) { return; }

    const chatParameter: ChatParameter = new ChatParameter();
    chatParameter.skillName = this.MSSkillGet.skillName;
    chatParameter.skillType = this.MSSkillGet.skillType;
    chatParameter.inputText = message;
    chatParameter.isTest = this.MSTestMode;
    
    this.MSSkillTesting = true;

    const apiResult: ApiContentResult<ChatResult> = await this._chatSkillController.RunChat(chatParameter);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSChatResult = apiResult.content;
    }

    this.MSSkillTesting = false;
  }

  public MSClearSkillTestForm(): void {
    this.MSSkillTestForm.reset();
    this.MSChatResult = null;
  }

  public MSCopyOutputToClipboard(): void {
    if (this.MSChatResult?.content)
      this._clipboard.copy(this.MSChatResult.content);
  }

  // private calculateTokenOnSKillTestFormMessageChange() {
  //   this.MSSkillTestForm.controls.message.valueChanges
  //     .pipe(
  //       distinctUntilChanged(),
  //       debounceTime(3000),
  //       switchMap(newValue => this._chatSkillApiService.PostCountToken(newValue))
  //     )
  //     .subscribe(tokenSize => {
  //       this.MSSkillTestForm.controls.messageToken.setValue(tokenSize);
  //     });
  // }

  private async loadSkillList(): Promise<void> {
    this.MSLoading = true;

    const apiResult: ApiContentResult<SkillGet[]> = await this._skillController.GetList();
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      apiResult.content.forEach((item) => {
        this.MSSkillListSelectItem.push({
          label: item.skillName,
          value: item.skillID,
        });
      });
    }
    this.MSLoading = false;
  }

  private setDefaultDropdown(): void {
    if (!this.MSDisableForSkillEdit) return;
    this.MSSelectedSkillList = this.MSSkillListSelectItem.find(item => item.value === this.MSSkillID) ?? null;
  }

  @HostListener('window:keyup', ['$event'])
  public MSOnKeyPress($event: KeyboardEvent): void {
    if ($event.ctrlKey && $event.altKey && $event.key == "t")
      this.MSTestMode = !this.MSTestMode;
  }
}
