// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM
import { Component, Input, OnInit }         from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule }              from '@angular/forms';
// Third Party Imports
import { SharedModule }                     from 'primeng/api';
import { InputTextModule }                  from 'primeng/inputtext';
import { InputNumberModule }                from 'primeng/inputnumber';
import { DropdownModule }                   from 'primeng/dropdown';
// Moon Imports
import { IApiResult }                       from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { NodeEnvironment }                  from '@moon-maintainer/api/request/node-environment.request';
import { NodeEnvironmentGet }               from "@moon-maintainer/api/response/node-environment-get.response";
import { NodeEnvironmentController }        from '@moon-maintainer/api/node-environment.controller';
import { MaintainerRouter }                 from '@moon-maintainer/maintainer.router';
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';

@Component({
    selector: 'node-environment-form',
    templateUrl: './node-environment-form.component.html',
    styleUrls: ['./node-environment-form.component.css'],
    standalone: true,
    imports: [ FormsModule, ReactiveFormsModule, SharedModule, 
               InputTextModule, InputNumberModule, DropdownModule,
               MoonFormPageComponent, MoonFormControlComponent],
    providers: [NodeEnvironmentController]
})
export class NodeEnvironmentFormComponent implements OnInit {
    @Input() public MSNodeEnvironmentGet: NodeEnvironmentGet;
    @Input() public MSNodeEnvironmentID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<NodeEnvironment>> = new FormGroup<FormGroupOf<NodeEnvironment>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		environmentName: new FormControl('', {nonNullable: true }),
		productName: new FormControl('', {nonNullable: true }),
		clientEnvironment: new FormControl('', {nonNullable: true }),
		testEnvironment: new FormControl('', {nonNullable: true }),

    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _nodeEnvironmentController: NodeEnvironmentController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSNodeEnvironmentGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {
    }

    private doMapGetDataToFormControl(nodeEnvironment: NodeEnvironmentGet) {
        if (!nodeEnvironment) {
            return;
        }        
        this.MSNodeEnvironmentGet = nodeEnvironment;
        this.MSParentForm.patchValue(nodeEnvironment);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSNodeEnvironmentID
            ? await this._nodeEnvironmentController.Update(this.MSNodeEnvironmentID, this.MSParentForm.getRawValue())
            : await this._nodeEnvironmentController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        };
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToNodeEnvironment();
    }
}
