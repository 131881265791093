import { SimilarChunk }         from "@app/moon-public/clause-search/models/similar-chunk";
import { SameDocumentChunk }    from "@app/moon-public/full-text-search/models/same-document-chunk";

type FacetValue = { filterStatus: boolean; count: number; };
export type Facet = Map<string, FacetValue>;

export class FullTextSearchResultFilter {
    year: Facet = new Map<string, FacetValue>();
    lawFirmName: Facet = new Map<string, FacetValue>();
    author: Facet = new Map<string, FacetValue>();
    propertyState: Facet = new Map<string, FacetValue>();
    propertyType: Facet = new Map<string, FacetValue>();
    matterName: Facet = new Map<string, FacetValue>();

    public MSAddSimilarChunkToFilter(item: SimilarChunk) {
        this.addToMapKey(this.year, item.year, false);
        this.addToMapKey(this.lawFirmName, item.documentMetaData.lawFirmName, false);
        this.addToMapKey(this.author, item.documentMetaData.author, false);
        this.addToMapKey(this.propertyState, item.documentMetaData.propertyState, false);
        this.addToMapKey(this.propertyType, item.documentMetaData.propertyType, false);
        this.addToMapKey(this.matterName, item.documentMetaData.matterName, false);
    }

    private addToMapKey(map: Facet, key: string, filterStatus: boolean) {
        let previousCount = map.get(key)?.count ?? 0;
        map.set(key, { filterStatus: filterStatus, count: ++previousCount }
        );
    }

    /** Send original chunk response here */
    public MSApplyFilter(originalData: SameDocumentChunk[]): SameDocumentChunk[] {
        FullTextSearchResultFilter.showAllChunks(originalData);
        originalData = this.filterByAllProperties(originalData);
        return originalData.filter(a => a.hideInResult === false);
    }

    private filterByAllProperties(originalData: SameDocumentChunk[]) {
        originalData = this.filterByAuthor(originalData);
        originalData = this.filterByYear(originalData);
        originalData = this.filterByLawFirmName(originalData);
        originalData = this.filterByPropertyState(originalData);
        originalData = this.filterByMatterName(originalData);
        originalData = this.filterByPropertyType(originalData);
        return originalData;
    }

    private static showAllChunks(originalData: SameDocumentChunk[]) {
        originalData.forEach(chunk => chunk.hideInResult = false);
    }

    private filterByYear(data: SameDocumentChunk[]) {
        let truthyKeys: string[] = [];
        this.year.forEach((value, key) => {
            if (value.filterStatus === true)
                truthyKeys.push(key);
        });

        if (truthyKeys.length === 0)
            return data;

        return data.filter(chunk => {
            if (!truthyKeys.includes(chunk.year)) {
                return false;
            }
            return true;
        });

    }

    private filterByLawFirmName(data: SameDocumentChunk[]) {
        let truthyKeys: string[] = [];
        this.lawFirmName.forEach((value, key) => {
            if (value.filterStatus === true)
                truthyKeys.push(key);
        });


        if (truthyKeys.length === 0)
            return data;

        return data.filter(chunk => {
            if (!truthyKeys.includes(chunk.documentMetaData.lawFirmName)) {
                return false;
            }
            return true;
        });
    }

    private filterByAuthor(data: SameDocumentChunk[]) {
        let truthyKeys: string[] = [];
        this.author.forEach((value, key) => {
            if (value.filterStatus === true)
                truthyKeys.push(key);
        });

        if (truthyKeys.length === 0)
            return data;

        return data.filter(chunk => {
            if (!truthyKeys.includes(chunk.documentMetaData.author)) {
                return false;
            }
            return true;
        });

    }

    private filterByPropertyState(data: SameDocumentChunk[]) {
        let truthyKeys: string[] = [];
        this.propertyState.forEach((value, key) => {
            if (value.filterStatus === true)
                truthyKeys.push(key);
        });


        if (truthyKeys.length === 0)
            return data;

        return data.filter(chunk => {
            if (!truthyKeys.includes(chunk.documentMetaData.propertyState)) {
                return false;
            }
            return true;
        });

    }

    private filterByMatterName(data: SameDocumentChunk[]) {
        let truthyKeys: string[] = [];
        this.matterName.forEach((value, key) => {
            if (value.filterStatus === true)
                truthyKeys.push(key);
        });


        if (truthyKeys.length === 0)
            return data;

        return data.filter(chunk => {
            if (!truthyKeys.includes(chunk.documentMetaData.matterName)) {
                return false;
            }
            return true;
        });
    }

    
    private filterByPropertyType(data: SameDocumentChunk[]) {
        let truthyKeys: string[] = [];
        this.propertyType.forEach((value, key) => {
            if (value.filterStatus === true)
                truthyKeys.push(key);
        });


        if (truthyKeys.length === 0)
            return data;

        return data.filter(chunk => {
            if (!truthyKeys.includes(chunk.documentMetaData.propertyType)) {
               return false;
            }
            return true;
        });
    }
}