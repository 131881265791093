import { Component, OnInit }          from "@angular/core";
// Third Party
import { RippleModule } from "primeng/ripple";
import { ButtonModule } from "primeng/button";
import { SharedModule } from "primeng/api";
import { DialogModule } from "primeng/dialog";
// MS Imports
import { AppRouter }       from "@app/app.router";
import { DbConnectApiService }          from "@moon-core/api/db-connect-api.service";
import { UserSessionService }           from "@moon-core/services/user-session.service";
import { ApiContentResult, ApiResult }                  from "@moon-core/models/api-result";
import { Disclaimer } from "@moon-core/api/response/disclaimer.response";
import { UserSession } from "@moon-core/api/response/user-session.response";

@Component({
    selector: "moon-disclaimer-agreement",
    templateUrl: "./disclaimer-agreement.component.html",
    styleUrls: ["./disclaimer-agreement.component.css"],
    standalone: true,
    imports: [
        DialogModule,
        SharedModule,
        ButtonModule,
        RippleModule,
    ],
})
export class DisclaimerAgreementComponent implements OnInit {
    private _disclaimer: Disclaimer;

    // Start Template variables
    public MSMessage: string;
    public MSDisplayDialog: boolean = false;
    public MSIsProcessInProgress: boolean = false;    
    // End Template variables

    constructor(
        private _appRouter: AppRouter,
        private _userSessionService: UserSessionService,
        private _dbConnectApiService: DbConnectApiService
    ) {}

    async ngOnInit() {
        await this.loadDisclaimer();
    }

    private async loadDisclaimer(): Promise<void> {

        const apiResult: ApiContentResult<Disclaimer> = await this._dbConnectApiService.GetDisclaimerAsync();
        if (apiResult.success){
            this._disclaimer = apiResult.content;
            this.MSMessage = this._disclaimer.disclaimerAgreement;
            this.MSDisplayDialog = true;
        }
    }

    public async MSOnConfirmClick(): Promise<void> {
        
        this.MSIsProcessInProgress = true;
        const userSession: UserSession = this._userSessionService.GetCurrentUserSession;
        const apiResult: ApiResult = await this._dbConnectApiService.AcceptDisclaimerAsync(this._disclaimer.disclaimerID, userSession);
        if (apiResult.success){
            this._appRouter.RouteToDefault();
        }
        this.MSDisplayDialog = false;
    }
    
    public MSOnRejectClick(): void {
        this.MSIsProcessInProgress = true;
        this._userSessionService.OnDisclaimerReject();
        this.MSDisplayDialog = false;
        this._appRouter.RouteToLogin();
    }
}
