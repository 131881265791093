<moon-loading *ngIf="MSLoading"></moon-loading>

<div
    class="card moon-bottom-padding"
    *ngIf="!MSLoading"
>
    <div class="col-12 grid">
        <div class="col-6 moon-switch">
            <label
                for="time-input-switch"
                [ngStyle]="{
                    'color': !MSTimeSwitch ? '#2196f3' : '#756c6c',
                    'font-weight': 'bold'
                }"
            >Local Time</label>&nbsp;
            <p-inputSwitch
                id="time-input-switch"
                [(ngModel)]="MSTimeSwitch"
            ></p-inputSwitch>&nbsp;
            <label
                for="time-input-switch"
                [ngStyle]="{
                    'color': MSTimeSwitch ? '#2196f3' : '#756c6c',
                    'font-weight': 'bold'
                }"
            >UTC Time</label>
        </div>

        <div class="col-6">
            <moon-search-field
                class="moon-search"
                [MSSearchTerm]="MSSearchTerm"
                (MSSearchTermOutput)="MSSearchFilter($event)"
            ></moon-search-field>
        </div>
    </div>
    <div>
        <p-timeline
            [value]="MSFilteredHistoryList"
            align="left"
            styleClass="customized-timeline"
        >
            <ng-template
                pTemplate="marker"
                let-event
            >
                <span
                    class="shadow-2 moon-icon-timeline"
                    [style.backgroundColor]="event.color"
                >
                    <i
                        [ngClass]="event.icon"
                        pTooltip="{{ event.actionName }}"
                        tooltipPosition="bottom"
                    ></i>
                </span>
            </ng-template>
            <ng-template
                pTemplate="content"
                let-event
            >
                <p-card class="moon-timeline-text-align">
                    <div class="moon-main-values">
                        <span *ngIf="event.actionName == 'Updated'; else addedTexts">
                            <b>{{event.actionName}}</b> {{event.entityType}}: <b>{{event.entityName ??
                                MSBlank}}</b><br />
                            <b>{{event.propertyName ?? MSBlank}}</b>&nbsp;
                            <div *ngIf="event.enableBlackLine; else normal">
                                <ms-black-lines
                                    #blackLine
                                    [MSOriginalText]="event.originalValue ?? ''"
                                    [MSModifiedText]="event.modifiedValue"
                                ></ms-black-lines>
                            </div>
                            <ng-template #normal>
                                <em>from</em>&nbsp;
                                <span
                                    *ngIf="event.originalValue; else emptyValue"
                                    class="moon-font-color"
                                >
                                    {{ event.originalValue }}
                                </span>
                                <ng-template #emptyValue>
                                    <span>{{ MSBlank }}</span>
                                </ng-template>
                                &nbsp;<em>to</em>&nbsp;
                                <span
                                    *ngIf="event.modifiedValue; else emptyValue"
                                    class="moon-font-color"
                                >
                                    {{ event.modifiedValue }}
                                </span>
                                <ng-template #emptyValue>
                                    <span>{{ MSBlank }}</span>
                                </ng-template>
                                <br />
                            </ng-template>

                        </span>
                        <ng-template #addedTexts>
                            <b>{{event.actionName}}</b> {{event.entityType}}: <b>{{event.entityName ?? MSBlank}}</b>
                        </ng-template>
                    </div>

                    <div>
                        <span *ngIf="MSTimeSwitch; else localTime">
                            <em>
                                By
                                {{ event.lastUpdatedBy }}
                                at
                                {{ event.lastUpdatedAtUTC | date: 'short' }}
                            </em>
                        </span>

                        <ng-template #localTime>
                            <em>
                                By
                                {{ event.lastUpdatedBy }}
                                at
                                {{ event.localDate | date : 'short' }}
                            </em>
                        </ng-template>
                    </div>
                </p-card>
                <br />
            </ng-template>
        </p-timeline>
    </div>

    <div>
        <moon-empty-state
            *ngIf="!MSHistoryList?.length"
            label="No Data To Show"
        ></moon-empty-state>
    </div>

    <!-- <moon-history-table></moon-history-table> -->
</div>