<div class="flex flex-1 gap-3 my-2 align-items-start">
    <div class="flex flex-column gap-2 w-100per">
        <div class="flex flex-column gap-1 justify-content-between">
            <h1 class="text-base mb-1">
                {{MSTitleDocumentNumber+1}}.&nbsp;{{MSTitleDocumentMap.textList}}
            </h1>
            <div
                *ngIf="MSTitleDocumentMap.pageList"
                class="text-sm"
            >
                Page: {{MSTitleDocumentMap.pageList}}
            </div>
            <div
                *ngIf="MSTitleDocumentMap.fileSize"
                class="text-sm"
            >
                Size: {{MSTitleDocumentMap.fileSize / 1024 | number:'1.0-2'}} kb
            </div>
            <div
                *ngIf="MSTitleDocumentMap.fileUrl"
                class="text-sm"
            >
                Url:
                <a
                    class="cursor-pointer hover:text-blue-500"
                    [href]="MSTitleDocumentMap.fileUrl"
                    target="_blank"
                >
                    {{MSTitleDocumentMap.fileUrl | slice:0:80}}
                    <span *ngIf="MSTitleDocumentMap.fileUrl.length > 80">...</span>
                </a>
            </div>
        </div>

        <div
            class="mb-2"
            *ngIf="MSTitleDocumentMap.exceptionNumberJson"
        >
            <span class="font-bold">Exception Number</span>:
            <div *ngIf="MSOverrideExceptionNumber; else showExceptionNumber">
                <form
                    [formGroup]="MSExceptionNumberFormGroup"
                    (ngSubmit)="MSSaveExceptionNumber()"
                >
                    <moon-form-control
                        [MSFormControl]="MSExceptionNumberFormGroup.controls.exceptionNumberJson"
                        [MSLabel]="'Exception Type'"
                        [MSDisplayLabel]="false"
                        [MSInputSize]="12"
                        [MSErrorMessageSize]="12"
                    >
                        <input
                            id="exceptionNumberJson"
                            #action
                            pInputText
                            [formControl]="MSExceptionNumberFormGroup.controls.exceptionNumberJson"
                        />
                    </moon-form-control>
                    <button
                        type="submit"
                        pButton
                        pRipple
                        label="Save"
                        class="p-button-raised mr-2 mb-2"
                        *ngIf="MSExceptionNumberFormGroup?.dirty"
                        [disabled]="MSSaving"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Cancel"
                        [disabled]="MSSaving"
                        class="p-button-raised p-button-secondary mr-2 mb-2"
                        (click)="MSToggleExceptionNumberOverride()"
                    ></button>
                </form>
            </div>
            <ng-template #showExceptionNumber>
                <span
                    title="Click to edit"
                    class="hover:surface-hover"
                    (click)="MSToggleExceptionNumberOverride()"
                >{{MSTitleDocumentMap.overrideExceptionNumberJson ?? MSTitleDocumentMap.exceptionNumberJson}}
                </span>
                <i
                    class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                    (click)="MSToggleExceptionNumberOverride()"
                ></i>
                <span
                    *ngIf="MSTitleDocumentMap.overrideExceptionNumberJson"
                    class="text-gray-400 text-sm"
                >(Edited)
                </span>
            </ng-template>
        </div>
        <div *ngIf="MSTitleDocumentMap.logMessage">
            <span class="font-bold">Log Message</span>: {{MSTitleDocumentMap.logMessage}}
        </div>

        <div class="flex h-min">
            <p-fileUpload
                #MSFileUpload
                mode="basic"
                accept=".pdf"
                [maxFileSize]="1000000"
                [auto]="true"
                (uploadHandler)="MSOnClickOverrideDocument($event, MSFileUpload)"
                [customUpload]="true"
                chooseIcon="pi pi-upload"
                chooseLabel="Upload document"
                title="Upload document manually"
                [disabled]="MSSaving"
                class="p-button-raised mr-2 mb-2 moon-button"
            ></p-fileUpload>
            <button
                class="moon-ocr-button"
                pButton
                [disabled]="MSSaving"
                label="Ocr"
                (click)="MSOnClickOcr()"
            ></button>
        </div>
    </div>
</div>