<div class="card relative">
    <button
        [disabled]="MSIsPdfDownloadingAndLoading"
        pButton
        pRipple
        class="p-button-outlined cursor-pointer absolute right-0 p-2"
        icon="pi pi-cloud-download"
        title="Download Document"
        tooltipPosition="top"
        (click)="MSDownloadPdf()"
    ></button>
    <h1 class="text-base font-bold text-center my-2">
        Uploaded Pdf
        <span
            *ngIf="MSExceptionPagesCSV"
            class="text-sm font-italic font-light text-center ml-2"
        >
            (exception pages: {{MSExceptionPagesCSV}})
        </span>
    </h1>
    <div
        *ngIf="MSIsPdfDownloadingAndLoading; else loadingComplete"
        class="flex justify-content-center align-items-center moon-pdf-viewer-spinner"
    >
        <p-progressSpinner
            aria-label="Loading"
            styleClass="w-4rem h-4rem"
            strokeWidth="4"
            animationDuration=".5s"
        ></p-progressSpinner>
    </div>
    <ng-template #loadingComplete>
        <ng-template #noContent>
            <div class="flex justify-content-between align-items-center">
                No content found.
            </div>
        </ng-template>
        <div
            *ngIf="MSTitleSummaryPdfBlob.pdfSrc; else noContent"
            class="moon-pdf-viewer-container"
        >
            <div
                id="moon-pdf-indicator"
                class="flex flex-column align-items-center py-2 px-4 gap-4 w-20rem"
                style="overflow: auto;"
            >
                <ng-container *ngFor="let item of MSTitleSummaryPdfBlob.pdfThumbnails; let i=index">
                    <div
                        class="moon-thumnail-image-container relative"
                        [ngClass]="{'active' : MSTitleSummaryPdfBlob.activePdfPage == i+1, 'marked': MSTitleSummaryPdfBlob.IsPageMarked(item.page) }"
                        [title]="MSTitleSummaryPdfBlob.IsPageMarked(item.page) ? 'Marked as exception page': ''"
                        (click)="MSTitleSummaryPdfBlob.activePdfPage = i+1"
                        id="moon-thumbnail-page-{{item.page}}"
                    >
                        <img
                            src="{{item.url}}"
                            alt="item.page"
                            class="moon-thumbnail-image"
                        />
                    </div>
                    <div class="pagenumber pb-1">{{item.page}}</div>
                </ng-container>
            </div>
            <pdf-viewer
                #pdfViewer
                class="moon-pdf-viewer"
                [src]="MSTitleSummaryPdfBlob.pdfSrc"
                [page]="MSTitleSummaryPdfBlob.activePdfPage"
                [show-all]="true"
                [original-size]="false"
                [zoom-scale]="'page-width'"
                [zoom]="MSTitleSummaryPdfBlob.zoom"
                (after-load-complete)="MSTitleSummaryPdfBlob.OnPdfLoadComplete($event)"
            ></pdf-viewer>
        </div>
    </ng-template>
</div>