// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import { Injectable }                 from "@angular/core";
// MS Imports
import {
  ApiBlobResult, ApiContentResult,
  ApiResult
}                                     from '@moon-core/models/api-result';
import { MoonApiClient }              from '@moon-core/services/moon-api.client';
import { FindTitleSummaryParameter }  from "@moon-public/api/request/find-title-summary.parameter";
import { TitleSummary }               from "@moon-public/api/request/title-summary.request";
import { TitleSummaryUpload }         from "@moon-public/api/request/title-summary.upload";
import { DocumentOcr }                from '@moon-public/api/response/document-ocr.response';
import { TitleExceptionMap }          from "@moon-public/api/response/title-exception-map.response";
import { TitleDocumentMap }           from "@moon-public/api/response/title-document-map.response";
import { TitleSummaryFind }           from "@moon-public/api/response/title-summary-find.response";
import { TitleSummaryFull }           from "@moon-public/api/response/title-summary-full.response";
import { TitleSummaryGet }            from "@moon-public/api/response/title-summary-get.response";
import { ConstantString }             from "@moon-shared/constants/constant-string";
import { RoutePath }                  from "@moon-shared/constants/route-path";
import { ChatResult } from "@app/moon-public/api/response/chat-result.response";

@Injectable()
export class TitleSummaryController {
  
  private getBaseUrl(): string {
    return this._moonApi.getPublicUrl(ConstantString.TitleSummary);
  };

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Public/v1/TitleSummary/Find
 */
  public async Find(findParameter: FindTitleSummaryParameter
    ): Promise<ApiContentResult<TitleSummaryFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), RoutePath.Find);
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/Full
 */
  public async GetFullByID(titleSummaryID: number
    ): Promise<ApiContentResult<TitleSummaryFull>> {
  
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Full);
    return this._moonApi.getAsync<TitleSummaryFull>(url);
  }

/**
 *   POST: Public/v1/TitleSummary
 */
  public async CreateTitleSummary(titleSummary: TitleSummaryUpload
    ): Promise<ApiContentResult<number>> {
    
    let formData = new FormData();
    formData.set('exceptionPagesCsv', titleSummary.exceptionPagesCsv);
    formData.set('matterName', titleSummary.matterName);
    formData.set('summaryType', titleSummary.summaryType);
    if (titleSummary.inputFile) {
      formData.set('inputFile', titleSummary.inputFile);
    }
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, formData);
  }
  
/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}
 */
  public async GetByID(titleSummaryID: number
    ): Promise<ApiContentResult<TitleSummaryGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID);
    return this._moonApi.getAsync<TitleSummaryGet>(url);
  }
  
/**
 *   PUT: Public/v1/TitleSummary/{titleSummaryID}
 */
  public async Update(titleSummaryID: number, titleSummary: TitleSummary
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID);
    return this._moonApi.putAsync(url, titleSummary);
  }
  
/**
 *   DELETE: Public/v1/TitleSummary/{titleSummaryID}
 */
  public async Delete(titleSummaryID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID);
    return this._moonApi.deleteAsync(url);
  }
  
/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleExceptionMapList
 */
  public async GetExceptionMapList(titleSummaryID: number
    ): Promise<ApiContentResult<TitleExceptionMap[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.TitleExceptionMapList);
    return this._moonApi.getAsync<TitleExceptionMap[]>(url);
  }
  
/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocumentMapList
 */
  public async GetDocumentMapList(titleSummaryID: number
    ): Promise<ApiContentResult<TitleDocumentMap[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.TitleDocumentMapList);
    return this._moonApi.getAsync<TitleDocumentMap[]>(url);
  }

/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/PdfBlob
 */
  public async GetPdfBlob(titleSummaryID: number
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PdfBlob);
    return this._moonApi.getBlobAsync(url);
  }
  
/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/DocxBlob
 */
public async GetDocxBlob(titleSummaryID: number
  ): Promise<ApiBlobResult> {
  
  const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.DocxBlob);
  return this._moonApi.getBlobAsync(url);
}

/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/Ocr
 */
  public async GetOcr(titleSummaryID: number
    ): Promise<ApiContentResult<DocumentOcr>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Ocr);
    return this._moonApi.getAsync<DocumentOcr>(url);
  }

/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/GetTitleSummaryOcrText
 */
  public async GetTitleSummaryOcrText(titleSummaryID: number
    ): Promise<ApiContentResult<ChatResult>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptTitleSummaryReviseOcrText);
    return this._moonApi.getAsync<ChatResult>(url);
  }

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/Ocr
 */
  public async OcrPdfs(titleSummaryID: number
    ): Promise<ApiResult> {
    
      const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Ocr);
    return this._moonApi.postAsync(url, null);
}

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/Download
 */
  public async DownloadPdfs(titleSummaryID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Download);
    return this._moonApi.postAsync(url, null);
  }
  
// Prompts Start

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/PromptTitleSummary
 */
public async PromptTitleSummary(titleSummaryID: number
  ): Promise<ApiResult> {
  
  const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptTitleSummary);
  return this._moonApi.postAsync(url, null);
}

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/PromptTitleDocument
 */
public async PromptTitleDocument(titleSummaryID: number
  ): Promise<ApiResult> {
  
  const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptTitleDocument);
  return this._moonApi.postAsync(url, null);
}

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/PromptTitleException
 */
  public async PromptTitleException(titleSummaryID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptTitleException);
    return this._moonApi.postAsync(url, null);
  }

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/PromptAll
 */
  public async PromptAll(titleSummaryID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptAll);
    return this._moonApi.postAsync(url, null);
  }

// Prompts End

/**
 *   POST: Public/v1/TitleSummary/{titleSummaryID}/Complete
 */
  public async Complete(titleSummaryID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Complete);
    return this._moonApi.postAsync(url, null);
  }

/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/ExportPdf
 */
  public async ExportPdf(titleSummaryID: number
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ExportPdf);
    return this._moonApi.getBlobAsync(url);
  }

/**
 *   GET: Public/v1/TitleSummary/{titleSummaryID}/ExportOcr
 */
  public async ExportOcr(titleSummaryID: number
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ExportOcr);
    return this._moonApi.getBlobAsync(url);
  }

}
