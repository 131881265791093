import { Clipboard }                         from '@angular/cdk/clipboard';
import { CommonModule, DecimalPipe, NgIf }                 from '@angular/common';
import { Component, HostListener, OnInit }   from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators }              from '@angular/forms';
// Third party imports
import { AppBreadcrumbService }              from '@app-layout/services/app.breadcrumb.service';
import { ButtonModule }                      from 'primeng/button';
import { DropdownChangeEvent, DropdownModule }                    from 'primeng/dropdown';
import { FileUpload, FileUploadModule }      from 'primeng/fileupload';
import { InputTextareaModule }               from 'primeng/inputtextarea';
import { OrderList, OrderListModule }        from 'primeng/orderlist';
import { ProgressSpinnerModule }             from 'primeng/progressspinner';
import { RadioButtonModule }                 from 'primeng/radiobutton';
import { RippleModule }                      from 'primeng/ripple';
import { TooltipModule }                     from "primeng/tooltip";
// MS Imports
import { MoonFormControlComponent }          from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }              from '@moon-shared/components/moon-loading/moon-loading.component';
import { TokenBreakdownComponent }           from '@moon-shared/components/token-breakdown/token-breakdown.component';
import { MoonMessageService }                from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                       from '@moon-shared/types/form-group-of.type';
import { ChatSkillController }               from '@moon-public/api/chat-skill.controller';
import { SummarizeController }               from '@moon-public/api/summarize.controller';
import { ApiBlobResult, ApiContentResult }   from '@moon-core/models/api-result';
import { ChatResult }                        from "@moon-public/api/response/chat-result.response";
import { ComponentUtilityService }           from '@moon-core/services/component-utility.service';
import { SummarizeChatParameterForm }        from '@moon-public/models/chat-parameter-form';
import { SkillGet }                          from '@moon-maintainer/api/response/skill-get.response';
import { PerformanceTimer }                  from '@moon-shared/helper/performance-timer/performance-timer';
import { MessageSeverity }                   from '@moon-shared/constants/message-severity';
import { SkillController }                   from '@moon-maintainer/api/skill.controller';
import { FieldValues }                       from '@moon-shared/constants/field-values';

@Component({
  selector: 'ms-summarize',
  templateUrl: './summarize.component.html',
  styleUrls: ['./summarize.component.scss'],
  standalone: true,
  imports: [
    NgIf, CommonModule, MoonLoadingComponent, FormsModule, ReactiveFormsModule, DecimalPipe,
    DropdownModule, RadioButtonModule, ButtonModule, RippleModule, InputTextareaModule, TooltipModule, ProgressSpinnerModule, OrderListModule, FileUploadModule,
    TokenBreakdownComponent, MoonFormControlComponent
  ],
  providers: [ChatSkillController, SummarizeController, SkillController]
})
export class SummarizeComponent implements OnInit {
  private readonly _skillType = "summarize";
  private readonly defaultSkillOutput: string = "Output";
  public MSApplying: boolean = false;
  public MSSummarizeSkillList: SkillGet[] = [];
  public MSTimeElapsedForSkillDuration: number | null;
  public MSLoading: boolean = false;
  public MSSummarizeForm = new FormGroup<FormGroupOf<SummarizeChatParameterForm>>({
    isTest: new FormControl(false, { nonNullable: true }),
    skillType: new FormControl(this._skillType, { nonNullable: true }),
    skillName: new FormControl('', { nonNullable: true, validators: Validators.required }),
    inputText: new FormControl('', { nonNullable: true }),
    inputToken: new FormControl(0, { nonNullable: true, validators: Validators.required }),
    inputMode: new FormControl("chat", { nonNullable: true, validators: Validators.required }),
    outputMode: new FormControl("text", { nonNullable: true, validators: Validators.required }),
    inputFiles: new FormControl(),
    skillID: new FormControl(),
  });

  public MSSkillOutput: string = this.defaultSkillOutput;
  public MSFileList : File[] = [];
  public MSSkillGet: SkillGet;
  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _clipboard: Clipboard,
    private _chatSkillController: ChatSkillController,
    private _summarizeController: SummarizeController,
    private _componentUtilityService: ComponentUtilityService,
    private _moonMessageService: MoonMessageService,
    private _skillController: SkillController
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  public MSSKillChange(event: DropdownChangeEvent) {
    this.getSkillByID(event.value);
    let skillName = this.MSSummarizeSkillList.find(item => item.skillID === event.value)?.skillName ?? String.empty;
    this.MSSummarizeForm.controls.skillName.setValue(skillName);
  }

  public MSHandleFileInput(files: File[] | null, fileUploader?: FileUpload) {
    if (!files?.length) return;
    this.updateFileControl(files);
    if (fileUploader)
      fileUploader.files = [];
  }

  // public MSOnFileDrop(fileList: File[]) {
  //   if (fileList?.length > 0) {
  //     this.updateFileControl(fileList);
  //     return;
  //   }

  //   this._moonMessageService.toastInfo(
  //     "The file you trying to upload is not extracted from zip or may be empty. Please check and try again!"
  //   );
  // }

  private updateFileControl(fileList: File[]) {
    for(let file of fileList){
      this.MSFileList.push(file);
    }
    this.MSFileList = this.MSFileList.slice();
    this.MSSummarizeForm.controls.inputFiles.setValue(this.MSFileList);
    this.MSSummarizeForm.markAsDirty();
    this.MSSummarizeForm.controls.inputFiles.updateValueAndValidity();
    this.MSSummarizeForm.updateValueAndValidity();

  }

  public MSClearSelectedFile(orderList: OrderList) {
    if (orderList.selection.length) {
      this.MSFileList = this.MSFileList.filter(file => !orderList.selection.some(selected => file.name === selected.name && file.size === selected.size));
      orderList.selection = [];
      this.MSSummarizeForm.controls.inputFiles.setValue(this.MSFileList);
    }
    else {
      this.MSFileList = [];
      this.MSSummarizeForm.controls.inputFiles.setValue(null);
    }
    this.MSSummarizeForm.updateValueAndValidity();
  }

  public MSCheckCondition(): boolean {
    return this.MSSkillOutput === this.defaultSkillOutput || this.MSSkillOutput === String.empty;
  }

  public async MSApply(): Promise<void> {
    if(this.MSSkillGet.multipleFileProcessing === FieldValues.SummarizeAndAppend.toLocaleLowerCase().trim()) {
      await this.applyChatAndAppend();
      return;
    }
    await this.applyChat();
  }

  private async applyChat(): Promise<void> {
    this.MSApplying = true;
    this.MSTimeElapsedForSkillDuration = null;
    this.MSClearOutput();
    this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait...");
    const performanceTimer: PerformanceTimer = PerformanceTimer.start();

    try {
      const chatParameterForm: SummarizeChatParameterForm = this.MSSummarizeForm.getRawValue();

      this.MSSummarizeForm.controls.inputFiles.setValue(this.MSFileList.isEmpty() ? null : this.MSFileList);
      if (this.MSSkillGet.fileOutputFormat == "file")
        await this.getFileSummary(chatParameterForm);
      else
        await this.getSummary(chatParameterForm);
    }
    finally {
      this.MSApplying = false;
      this.MSTimeElapsedForSkillDuration = performanceTimer.getElapsedTime();
    }
  }

  private async applyChatAndAppend(): Promise<void> {
    this.MSApplying = true;
    this.MSClearOutput();
    this.MSTimeElapsedForSkillDuration = null;
    this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait...");
    const performanceTimer: PerformanceTimer = PerformanceTimer.start();

    try {
      const chatParameterForm: SummarizeChatParameterForm = this.MSSummarizeForm.getRawValue();
    
      this.MSSummarizeForm.controls.inputFiles.setValue(this.MSFileList.isEmpty() ? null : this.MSFileList)
      await this.getSummaryAndAppend(chatParameterForm);
    }
    finally {
      this.MSApplying = false;
      this.MSTimeElapsedForSkillDuration = performanceTimer.getElapsedTime();
    }
  }

  private async getFileSummary(chatParameterForm: SummarizeChatParameterForm) {
    let formData = new FormData();
    formData.set('isTest', chatParameterForm.isTest.toString());
    formData.set('skillType', chatParameterForm.skillType.toString());
    formData.set('skillName', chatParameterForm.skillName.toString());
    formData.set('outputMode', chatParameterForm.outputMode.toString());
    formData.set('inputText', chatParameterForm.inputText.toString());

    if (chatParameterForm.inputFiles?.length) {
      for (let file of chatParameterForm.inputFiles) {
        formData.append('inputFiles', file, file.name);
      }
    }
    else {
      if(this.MSSkillGet.inputFormat === "file"){
        this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Input file is required");
        return;
      }
    }

    if ("file".equalsIgnoreCase(this.MSSkillGet.fileOutputFormat)) {
      let apiBlobResult: ApiBlobResult;
      if ("file".equalsIgnoreCase(this.MSSkillGet.inputFormat))
        apiBlobResult = await this._summarizeController.SummarizeFileAndDownload(formData);
      else
        apiBlobResult = await this._summarizeController.SummarizeAndDownload(formData);
      if (this._componentUtilityService.WasDownloadSuccessful(apiBlobResult)) {
        this._moonMessageService.toastSuccess("Summary downloaded.");
      };
    }

    else {
      let apiResult: ApiContentResult<ChatResult>;
      if ("file".equalsIgnoreCase(this.MSSkillGet.inputFormat))
        apiResult = await this._summarizeController.SummarizeFile(formData);
      else
        apiResult = await this._summarizeController.Summarize(formData);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        const chatResult: ChatResult = apiResult.content;
        this.MSSummarizeForm.controls.inputToken.setValue(chatResult.inputTokens);
        this.MSSkillOutput = chatResult.content;
      }
    }
  }

  private async getSummaryAndAppend(chatParameterForm: SummarizeChatParameterForm) {

    let formData = new FormData();
    formData.set('isTest', chatParameterForm.isTest.toString());
    formData.set('skillType', chatParameterForm.skillType.toString());
    formData.set('skillName', chatParameterForm.skillName.toString());
    formData.set('outputMode', chatParameterForm.outputMode.toString());
    formData.set('inputText', chatParameterForm.inputText.toString());

    if (chatParameterForm.inputFiles?.length) {
      for (let file of chatParameterForm.inputFiles) {
        formData.append('inputFiles', file, file.name);
      }
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Input file is required");
    }

    if ("file".equalsIgnoreCase(this.MSSkillGet.fileOutputFormat)) {
      let apiBlobResult: ApiBlobResult = await this._summarizeController.CascadeSummarizeFileAndDownload(formData);
      if (this._componentUtilityService.WasDownloadSuccessful(apiBlobResult)) {
        this._moonMessageService.toastSuccess("Summary downloaded.");
      };
    }
    else {
      let apiResult: ApiContentResult<ChatResult> = await this._summarizeController.CascadeSummarizeFile(formData);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        const chatResult: ChatResult = apiResult.content;
        this.MSSummarizeForm.controls.inputToken.setValue(chatResult.inputTokens);
        this.MSSkillOutput = chatResult.content;
      }
    }
  }

  public async getSummary(chatParameterForm: SummarizeChatParameterForm) {
    let formData = new FormData();
    formData.set('isTest', chatParameterForm.isTest.toString());
    formData.set('skillType', chatParameterForm.skillType.toString());
    formData.set('skillName', chatParameterForm.skillName.toString());
    formData.set('inputText', chatParameterForm.inputText.toString());
    if (chatParameterForm.inputFiles?.length) {
      for (let file of chatParameterForm.inputFiles) {
        formData.append('inputFiles', file, file.name);
      }
    }
    else {
      if(this.MSSkillGet.inputFormat === "file"){
        this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Input file is required");
        return;
      }
    }

    if ("file".equalsIgnoreCase(this.MSSkillGet.inputFormat)) {
        const apiResult: ApiContentResult<ChatResult> = await this._summarizeController.SummarizeFile(formData);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        const chatResult: ChatResult = apiResult.content;
        this.MSSkillOutput = chatResult.content ?? String.empty;
      }
    }
    else {
      const apiResult: ApiContentResult<ChatResult> = await this._chatSkillController.RunChat(chatParameterForm);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        const chatResult: ChatResult = apiResult.content;
        this.MSSkillOutput = chatResult.content ?? String.empty;
      }
    }
    
  }

  public MSClearOutput() {
    this.MSSkillOutput = this.defaultSkillOutput;
  }

  public MSCopyOutputToClipboard() {
    this._clipboard.copy(this.MSSkillOutput);
  }

  private initialize() {
    this.setBreadcrumb();
    this.getSkills();
  }

  private async getSkills() {
    this.MSLoading = true;

    const apiResult: ApiContentResult<SkillGet[]> = await this._chatSkillController.GetListSkillAsync(this._skillType);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSSummarizeSkillList = apiResult.content;
      this.setSkillIDAndName();
    }

    this.MSLoading = false;
  }

  private async getSkillByID(skillID: number): Promise<void> {
    this.MSLoading = true;

    const apiResult: ApiContentResult<SkillGet> = await this._skillController.GetByID(skillID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSSkillGet = apiResult.content;
      this.MSSummarizeForm.controls.inputMode.setValue(this.MSSkillGet.inputFormat);
      this.MSSummarizeForm.controls.outputMode.setValue(this.MSSkillGet.fileOutputFormat);
    }
    this.MSLoading = false;
  }

  private setSkillIDAndName(): void {
    const firstFunction = this.MSSummarizeSkillList[0];
    if (firstFunction) {
      this.getSkillByID(firstFunction.skillID);
      this.MSSummarizeForm.controls.skillID.setValue(firstFunction.skillID);
      this.MSSummarizeForm.controls.skillName.setValue(firstFunction.skillName);
    }
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.setItems([
      { label: "Summarize", routerLink: null },
    ]);
  }

  @HostListener('window:keyup', ['$event'])
  public MSOnKeyPress($event: KeyboardEvent) {
    if ($event.ctrlKey && $event.altKey && $event.key == "t")
      this.MSSummarizeForm.controls.isTest.setValue(!this.MSSummarizeForm.value.isTest);
  }
}
