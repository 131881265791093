<!-- Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSTitleSummaryList"
    [MSNumberOfFilteredTableRows]="MSTable?.filteredValue?.length || 0"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplayFindButton]="MSDisplayFind"
    (MSEventOnClickCreate)="OnClickCreate()"
    (MSEventOnClickFind)="OnClickFind()"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [scrollable]="true"
        [rowHover]="true"
        [value]="MSTitleSummaryList"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-striped"
    >
        <ng-template
            pTemplate="emptymessage"
            let-columns
        >
            <tr>
                <td
                    [style.flex-basis]="'auto'"
                    [attr.colspan]="columns?.length"
                    class="moon-text-center"
                >
                    No data found.
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="MSDisplayCreate"
                [MSDisplayFindButton]="MSDisplayFind"
                [MSDisplaySecondaryButton]="MSDisplaySecondary"
                MSSecondaryButtonIcon="pi pi-refresh"
                MSSecondaryButtonToolTip="Refresh"
                (MSEventOnClickCreate)="OnClickCreate()"
                (MSEventOnClickFind)="OnClickFind()"
                (MSEventOnSearchInput)="OnSearchInput($event)"
                (MSEventOnClickSecondary)="MSOnClickSecondaryButton()"
            >
                <ng-template moonTemplate="filter">
                    <div class="flex align-items-center gap-2 mr-2">
                        <label for="status-filter">Status</label>
                        <p-dropdown
                            class="align-self-baseline"
                            inputId="status-filter"
                            [options]="MSStatusFilterOptions"
                            (onChange)="MSOnStatusFilterChanged($event)"
                        ></p-dropdown>
                    </div>
                </ng-template>
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [pSortableColumn]="col.sortable ? col.field: ''"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                >
                    {{ col.header }}
                    <p-sortIcon
                        *ngIf="col.sortable"
                        [field]="col.field"
                    ></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions' || OnClickItem(rowData)"
                >
                    <div [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'status'">
                            <span
                                [ngClass]="{'text-orange-500 font-bold': MSIsTitleSummaryProcessing(rowData[col.field])}"
                            >{{ rowData[col.field] }}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'fileSize'">
                            <span>{{ rowData[col.field] / 1024 | number:'1.0-2'}} kb</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'lastUpdatedAt'">
                            <span>{{ rowData[col.field] | date: 'short'}}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'actions'">
                            <moon-custom-dropdown
                                [model]="rowData.actions"
                                class="text-center"
                            >
                                <i class="pi pi-ellipsis-v text-2xl"></i>
                            </moon-custom-dropdown>
                        </ng-container>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

<moon-loading
    *ngIf="MSLoading"
    [MSLoadingMessage]="'Processing'"
></moon-loading>