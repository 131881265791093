<moon-error-message
    class="flex justify-content-center"
    [messages]="MSMessages"
></moon-error-message>

<moon-dialog></moon-dialog>

<p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
    styleClass="w-30rem"
    acceptButtonStyleClass="p-button-text"
    rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
<router-outlet *ngIf="!NsIsIFrame"></router-outlet>