// Moon Auto Generated (1.0.0) at 11/22/2023 11:48:18 AM
import { Route }                                from "@angular/router";
import { MsalGuard }                            from "@azure/msal-angular";
// MS Imports
import { DocumentBatchOperationComponent }      from '@moon-collaborator/document/document-batch-operation/document-batch-operation.component';
import { DocumentCategoryEditPageComponent }    from '@moon-collaborator/document/document-category/document-category-edit-page/document-category-edit-page.component';
import { DocumentCategoryComponent }            from '@moon-collaborator/document/document-category/document-category.component';
import { DocumentChunkComponent }               from '@moon-collaborator/document/document-chunk/document-chunk.component';
import { DocumentUploadComponent }              from '@moon-collaborator/document/document-upload/document-upload.component';
import { DocumentComponent }                    from '@moon-collaborator/document/document.component';
import { EnvironmentComponent }                 from "@moon-collaborator/document/environment/environment.component";
import { CollaboratorRouteGuard }               from "@moon-core/guards/collaborator-route.guard";
import { UserSessionGuard }                     from "@moon-core/guards/user-session.guard";
import { RouteParameter }                       from "@moon-shared/constants/route-parameter";
import { RoutePath }                            from "@moon-shared/constants/route-path";

export const CollaboratorRoutes: Route[] = [
    {
        path: RoutePath.Environment,
        canActivate: [MsalGuard, UserSessionGuard, CollaboratorRouteGuard],
        children: [
            { path: RoutePath.Empty, component: EnvironmentComponent },
            {
                path: String.createRoute(RouteParameter.EnvironmentName.toRouteParam(), RoutePath.Documents),
                children: [
                    { path: RoutePath.Empty, component: DocumentComponent },
                    { path: RoutePath.Upload, component: DocumentUploadComponent },
                    { path: RoutePath.UpdateAll, component: DocumentBatchOperationComponent },
                    { path: String.createRoute(RouteParameter.DocumentID.toRouteParam(), RoutePath.Chunk), component: DocumentChunkComponent },
                ]
            },
            {
                path: String.createRoute(RouteParameter.EnvironmentName.toRouteParam(), RoutePath.DocumentCategory),
                children: [
                    { path: RoutePath.Empty, component: DocumentCategoryComponent },
                    { path: RoutePath.Add, component: DocumentCategoryEditPageComponent },
                    { path: String.createRoute(RouteParameter.DocumentCategoryID.toRouteParam(), RoutePath.Edit), component: DocumentCategoryEditPageComponent },
                ],
            },
            // Placeholder for route
        ]
    }
];
