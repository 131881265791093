import {Component, Input} from '@angular/core';

@Component({
    selector: 'moon-loading',
    templateUrl: './moon-loading.component.html',
    styleUrls: ['./moon-loading.component.css'],
    standalone: true
})

export class MoonLoadingComponent {
    @Input() public MSLoadingMessage: string = 'Loading Please wait...';
    constructor() {}
}
