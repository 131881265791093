// Moon Auto Generated (1.5.0) at 2/21/2024 10:18:48 AM
export class FieldNames {    
    public static readonly ActionContext: string = "ActionContext";
    public static readonly ActionName: string = "ActionName";
    public static readonly Actions: string = "Actions";
    public static readonly AllowFavoriteSearchResult = "AllowFavoriteSearchResult" as const;    
    public static readonly ApplicationName: string = "ApplicationName";
    public static readonly ApplicationVersion: string = "ApplicationVersion";
    public static readonly AssistantMessage: string = "AssistantMessage";
    public static readonly AssistantTokens: string = "AssistantTokens";
    public static readonly Author: string = "Author";
    public static readonly AzureGroupCsv: string = "AzureGroupCsv";
    public static readonly BlobID: string = "BlobID";
    public static readonly CategoryName: string = "CategoryName";
    public static readonly ChatLogID: string = "ChatLogID";
    public static readonly ClientEnvironment: string = "ClientEnvironment";
    public static readonly ConnectAsAdmin: string = "ConnectAsAdmin";
    public static readonly ConnectedAtUtc: string = "ConnectedAtUtc";
    public static readonly Content: string = "Content";
    public static readonly Count: string = "Count";
    public static readonly CreationDate: string = "CreationDate";
    public static readonly DataURL: string = "DataURL";
    public static readonly DBVersion: string = "DBVersion";
    public static readonly DeploymentName: string = "DeploymentName";
    public static readonly DisclaimerAgreementId: string = "DisclaimerAgreementId";
    public static readonly Document: string = "Document";
    public static readonly DocumentDate: string = "DocumentDate";
    public static readonly DocumentName: string = "DocumentName";
    public static readonly DownloadActions: string = "DownloadActions";
    public static readonly DurationInMs: string = "DurationInMs";
    public static readonly DurationMs: string = "DurationMs";
    public static readonly EmailAddress: string = "EmailAddress";
    public static readonly EmbeddingStatus: string = "EmbeddingStatus";
    public static readonly EntityID: string = "EntityID";
    public static readonly EntityType: string = "EntityType";
    public static readonly EnvironmentName: string = "EnvironmentName";
    public static readonly ErrorMessage: string = "ErrorMessage";
    public static readonly ExampleTokens: string = "ExampleTokens";
    public static readonly ExceptionPagesCsv: string = "ExceptionPagesCsv";
    public static readonly ExternalGroupID: string = "ExternalGroupID";
    public static readonly FavoriteLastUpdatedAt = 'FavoriteLastUpdatedAt' as const;
    public static readonly FavoriteLastUpdatedBy = 'FavoriteLastUpdatedBy' as const;
    public static readonly FavoriteNotes = 'FavoriteNotes' as const;
    public static readonly FileName: string = "FileName";
    public static readonly FileOutputFormat: string = "FileOutputFormat";
    public static readonly FileSize = "FileSize" as const;
    public static readonly FirstName: string = "FirstName";
    public static readonly FrequencyPenalty: string = "FrequencyPenalty";
    public static readonly InputFormat: string = "InputFormat";
    public static readonly InputTokens: string = "InputTokens";
    public static readonly IsChunked: string = "IsChunked";
    public static readonly LastName: string = "LastName";
    public static readonly LastSyncedAtUtc: string = "LastSyncedAtUtc";
    public static readonly LastSyncedBy: string = "LastSyncedBy";
    public static readonly LastUpdatedAt = "LastUpdatedAt" as const;
    public static readonly LastUpdatedAtUtc: string = "LastUpdatedAtUtc";
    public static readonly LastUpdatedBy: string = "LastUpdatedBy";
    public static readonly LawFirmName: string = "LawFirmName";
    public static readonly LogMessage: string = "LogMessage";
    public static readonly MatterName: string = "MatterName";
    public static readonly MaxOutputTokens: string = "MaxOutputTokens";
    public static readonly MezzLoanChunkID: string = "MezzLoanChunkID";
    public static readonly MezzLoanID: string = "MezzLoanID";
    public static readonly ModelType: string = "ModelType";
    public static readonly MultipleFileProcessing: string = "MultipleFileProcessing";
    public static readonly NameIdentifier: string = "NameIdentifier";
    public static readonly NodeEnvironmentID: string = "NodeEnvironmentID";
    public static readonly ObjectIdentifier: string = "ObjectIdentifier";
    public static readonly OptionName: string = "OptionName";
    public static readonly OptionValue: string = "OptionValue";
    public static readonly Ordinal: string = "Ordinal";
    public static readonly ParagraphID: string = "ParagraphID";
    public static readonly PreferredUserName: string = "PreferredUserName";
    public static readonly PresencePenalty: string = "PresencePenalty";
    public static readonly ProcessingStatus: string = "ProcessingStatus";
    public static readonly ProductName: string = "ProductName";
    public static readonly PropertyState: string = 'PropertyState';
    public static readonly PropertyType: string = 'PropertyType';
    public static readonly relevance = "relevance" as const;
    public static readonly RequestCost: string = "RequestCost";
    public static readonly RequestTokens: string = "RequestTokens";
    public static readonly ResponseCost: string = "ResponseCost";
    public static readonly ResponseTokens: string = "ResponseTokens";
    public static readonly RevisedContent: string = "RevisedContent";
    public static readonly RevisionStatus: string = "RevisionStatus";
    public static readonly RoleCode: string = "RoleCode";
    public static readonly savedAsFavorite = 'savedAsFavorite' as const;
    public static readonly SearchResultJson: string = "SearchResultJson";
    public static readonly SearchTableNumber: string = "SearchTableNumber";
    public static readonly SimilarChunks: string = "SimilarChunks";
    public static readonly SkillExample: string = "SkillExample";
    public static readonly SkillID: string = "SkillID";
    public static readonly SkillMessageHashMD5: string = "SkillMessageHashMD5";
    public static readonly SkillName: string = "SkillName";
    public static readonly SkillStatus: string = "SkillStatus";
    public static readonly SkillSubType: string = "SkillSubType";
    public static readonly SkillType: string = "SkillType";
    public static readonly SkillVersion: string = "SkillVersion";
    public static readonly SortKey: string = "SortKey";
    public static readonly SqlMessage: string = "SqlMessage";
    public static readonly Status: string = "Status";
    public static readonly StopSequence: string = "StopSequence";
    public static readonly Success: string = "Success";
    public static readonly SummaryType: string = "SummaryType";
    public static readonly SystemMessage = "SystemMessage" as const;
    public static readonly SystemTokens: string = "SystemTokens";
    public static readonly Temperature: string = "Temperature";
    public static readonly TemplateFileName: string = "TemplateFileName";
    public static readonly TemplateID: string = "TemplateID";
    public static readonly TemplateName: string = "TemplateName";
    public static readonly TemplateStorageLocation: string = "TemplateStorageLocation";
    public static readonly TemplateType: string = "TemplateType";
    public static readonly TestEnvironment: string = "TestEnvironment";
    public static readonly TitleSummaryBlobID: string = "TitleSummaryBlobID";
    public static readonly TokenSize: string = "TokenSize";
    public static readonly TopP: string = "TopP";
    public static readonly TotalCost: string = "TotalCost";
    public static readonly TotalTokens: string = "TotalTokens";
    public static readonly TotalVectors: string = "TotalVectors";
    public static readonly TraceJson: string = "TraceJson";
    public static readonly UserGroupID: string = "UserGroupID";
    public static readonly UserGroupName: string = "UserGroupName";
    public static readonly UserInfoID: string = "UserInfoID";
    public static readonly UserMessage: string = "UserMessage";
    public static readonly UserName: string = "UserName";
    public static readonly UserOptionID: string = "UserOptionID";
    public static readonly UserOptionMapID: string = "UserOptionMapID";
    public static readonly UserSessionID: string = "UserSessionID";
    public static readonly UserStatus: string = "UserStatus";
    public static readonly UserTokens: string = "UserTokens";
    public static readonly UserType: string = "UserType";
    public static readonly ValidUntilUtc: string = "ValidUntilUtc";
    public static readonly Vector: string = "Vector";
    public static readonly VectorAsCsv: string = "VectorAsCsv";
    public static readonly Version: string = "Version";
    public static readonly WebAppURL: string = "WebAppURL";
    public static readonly XMLLastUpdatedAtUtc: string = "XMLLastUpdatedAtUtc";
    public static readonly XMLLastUpdatedBy: string = "XMLLastUpdatedBy";
}
