import { Injectable }               from '@angular/core';
// Third party imports
import { MenuItem }                 from 'primeng/api';
// Moon Imports
import { CollaboratorMenuItems }    from '@moon-collaborator/collaborator.menu';
import { UserSession }              from '@moon-core/api/response/user-session.response';
import { HistoryMenuItems }         from '@moon-maintainer/history.menu';
import { MaintainerMenuItems }      from '@moon-maintainer/maintainer.menu';
import { PreviewMenuItems }         from '@moon-public/preview.menu';
import { PublicMenuItems }          from '@moon-public/public.menu';
import { DefaultValues }            from '@moon-shared/constants/default-values';
@Injectable()
export class MoonMenuItemService {

    private _menuItems: MenuItem[] = [];

    constructor() { }

    public GetMenuItems(session: UserSession | null) {

        this._menuItems = this.getUserMenuItems(session);
        return this._menuItems;
    }


    private getUserMenuItems(session: UserSession | null): MenuItem[] {
        let menuItems: MenuItem[] = [PublicMenuItems];


        if (DefaultValues.Y.equalsIgnoreCase(session?.showCollaborator)) {
            menuItems.push(CollaboratorMenuItems);
        }
        if (DefaultValues.Y.equalsIgnoreCase(session?.showPreview)) {
            menuItems.push(PreviewMenuItems);
        }
        if (DefaultValues.Y.equalsIgnoreCase(session?.showMaintainer)) {
            menuItems.push(MaintainerMenuItems);
            menuItems.push(HistoryMenuItems);
        }
        return menuItems;
    }
}