<!-- Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <node-environment-user-group-map-table
        [MSPageTitle]="MSPageTitle"
        [MSNodeEnvironmentUserGroupMapList]="MSNodeEnvironmentUserGroupMapList"
        [MSLoading]="MSLoading"
    ></node-environment-user-group-map-table>
</div>