// Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM
import { Component, Input, OnInit,
    ViewChild, ViewEncapsulation }              from "@angular/core";
import { NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                           from "@angular/common";
// Third Party imports
import { Table, TableModule }                   from "primeng/table";
// Moon imports
import { FieldNames }                           from '@moon-shared/constants/field-names';
import { TableColumn }                          from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }               from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }             from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }          from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { SkillExampleGet }                      from "@moon-maintainer/api/response/skill-example-get.response";
import { MaintainerRouter }                     from '@moon-maintainer/maintainer.router';

@Component({
    selector: "skill-example-table",
    templateUrl: "./skill-example-table.component.html",
    styleUrls: ['./skill-example-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        TableModule,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter]
})
export class SkillExampleTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSSkillExampleList: SkillExampleGet[];
    @Input() public MSLoading = true;
    @Input() public MSSkillID: number | null;
    @Input() public MSNavigatedFromEdit: boolean = false;

    public MSTableColumns: TableColumn[] = [
        { field: FieldNames.UserMessage.toCamelCase(), header: FieldNames.UserMessage.getDisplayName(), class: 'w-45per white-space-pre-wrap' },
        { field: FieldNames.UserTokens.toCamelCase(), header: FieldNames.UserTokens.getDisplayName(), class: 'w-5per' },
        { field: FieldNames.AssistantMessage.toCamelCase(), header: FieldNames.AssistantMessage.getDisplayName(), class: 'w-45per white-space-pre-wrap' },
        { field: FieldNames.AssistantTokens.toCamelCase(), header: FieldNames.AssistantTokens.getDisplayName(), class: 'w-5per' },

        // Column PlaceHolder - DO NOT REMOVE
        { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-20per' },
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplayRouteBack: boolean;

    @ViewChild("MSTable") MSTable: Table;

    constructor(private _maintainerRouter: MaintainerRouter) { }

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
        this.MSDisplayRouteBack = true;
    }

    public OnClickItem(item: SkillExampleGet): void {
        this._maintainerRouter.ToSkillExampleEdit(this.MSSkillID ?? 0, item.skillExampleID);
    }

    public OnClickCreate(): void {
        if (this.MSSkillID) {
            this._maintainerRouter.ToSkillExampleAdd(this.MSSkillID);
        }
    }

    public OnClickFind(): void {
        this._maintainerRouter.ToSkillExample(this.MSSkillID ?? 0);
    }

    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }

    public MSOnClickGoBack(): void {
        if (this.MSNavigatedFromEdit && this.MSSkillID) {
            this._maintainerRouter.ToSkillEdit(this.MSSkillID);
            return;
        }
        this._maintainerRouter.ToSkill();
    }
}
