import { NgIf, NgTemplateOutlet }                                     from "@angular/common";
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild }   from "@angular/core";
// Third party imports
import { ButtonModule }                             from "primeng/button";
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from "primeng/fileupload";
import { InputTextModule }                          from "primeng/inputtext";
import { TooltipModule }                            from "primeng/tooltip";
// Moon Imports
import { MoonTemplateDirective } from "@moon-shared/directives/moon-template.directive";
@Component({
    selector: "moon-table-header",
    templateUrl: "./moon-table-header.component.html",
    styleUrls: ["./moon-table-header.component.scss"],
    standalone: true,
    imports: [
        NgIf, NgTemplateOutlet,
        InputTextModule,
        ButtonModule,
        TooltipModule,
        FileUploadModule
    ],
})
export class MoonTableHeaderComponent {
    @Input() MSPageTitle: string;
    @Input() MSEnableSearch: boolean = false;
    @Input() MSDisplayRouteBackButton: boolean = false;
    @Input() MSDisplayCreateButton: boolean = true;
    @Input() MSDisplayFindButton: boolean = false;
    @Input() MSDisplayUploadButton: boolean = false;
    @Input() MSAcceptedFileExtension: string = String.empty;

    @Input() public MSDisplaySecondaryButton: boolean = false;
    @Input() public MSSecondaryButtonIcon: string;
    @Input() public MSSecondaryButtonToolTip: string;
    
    @Output() public MSEventOnClickRouteBack = new EventEmitter();
    @Output() public MSEventOnClickCreate = new EventEmitter();
    @Output() public MSEventOnClickFind = new EventEmitter();
    @Output() public MSEventOnSearchInput = new EventEmitter();
    @Output() public MSEventOnClickSecondary = new EventEmitter();
    @Output() public MSEventOnUploadFile: EventEmitter<FileUploadHandlerEvent> = new EventEmitter();
    @ViewChild('MSFileUpload') MSFileUpload: FileUpload;
    @ContentChild(MoonTemplateDirective, { static: false }) MSTemplateDirective: MoonTemplateDirective;
    MSFilterTemplate: TemplateRef<any> | null;
    MSButtonTemplate: TemplateRef<any> | null;
    ngAfterContentInit() {
        this.MSFilterTemplate = this.MSTemplateDirective?.MSGetFilterTemplate();
        this.MSButtonTemplate = this.MSTemplateDirective?.MSGetButtonTemplate();
    }
    public OnClickRouteBack() {
        this.MSEventOnClickRouteBack.emit();
    }

    public OnClickCreate() {
        this.MSEventOnClickCreate.emit();
    }

    public OnClickFind() {
        this.MSEventOnClickFind.emit();
    }

    public OnSearchInput(searchInput: string) {
        this.MSEventOnSearchInput.emit(searchInput);
    }

    public OnClickSecondary() {
        this.MSEventOnClickSecondary.emit();
    }
    public OnUploadFile(event: FileUploadHandlerEvent) {
        this.MSEventOnUploadFile.emit(event);
    }

    public MSClearFile(): void {
        this.MSFileUpload.clear();
    }
}
