<div class="pages-body login-page flex flex-column">
    <div class="align-self-center mt-auto mb-auto login-card">
        <div class="pages-panel card flex flex-column login-card-inner">
            <div class="pages-header px-3 py-1">
                <h2>MOONSTONE</h2>
            </div>

            <h4>Welcome</h4>

            <div class="pages-detail mb-2 px-6" *ngIf="!MSHasErrors">
                {{ MSDisplayMessage }}
            </div>
            <div
                class="pages-detail mb-2 px-6 moon-error-message"
                *ngIf="MSHasErrors"
            >
                {{ MSDisplayMessage}}
            </div>

            <p-progressBar
                mode="indeterminate"
                *ngIf="MSLoading"
                [style]="{ height: '6px' }"
            ></p-progressBar>
            <p-button
                class="login-button mb-2 px-3 login-button"
                *ngIf="!MSLoading && !MSHasErrors"
                (click)="MSOnClickLoginButton()"
                label="LOGIN"
            ></p-button>
            <p-button
                class="login-button mb-2 px-3 login-button"
                *ngIf="!MSLoading && MSHasErrors"
                (click)="MSOnClickReloadButton()"
                label="RELOAD"
            ></p-button>
            <div
                class="pages-detail mb-2 px-3"
                [ngStyle]="{
                    'margin-top': MSLoading ? '35px' : '0px'
                }"
            >
                <hr />
                <p>
                    <span>
                        Powered by: {{ MSDiagnostic.apiName }} | Version:
                        {{ MSDiagnostic.apiVersion }}<br />
                    </span>
                    <span> Connected To: {{ MSDiagnostic.databaseName }} </span>
                </p>
                <p>Application Version: {{ MSWebApplicationVersion }}</p>
            </div>
        </div>
    </div>
</div>
