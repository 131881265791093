import { AsyncPipe, NgClass, NgFor, NgIf }          from '@angular/common';
import { Component, OnInit }                        from '@angular/core';
import { FormsModule, ReactiveFormsModule }         from '@angular/forms';
// Third party imports
import { AppBreadcrumbService }                     from '@app-layout/services/app.breadcrumb.service';
import { SharedModule }                             from 'primeng/api';
import { ButtonModule }                             from 'primeng/button';
import { DropdownModule }                           from 'primeng/dropdown';
import { InputTextModule }                          from 'primeng/inputtext';
import { ProgressBarModule }                        from 'primeng/progressbar';
import { RippleModule }                             from 'primeng/ripple';
import { TableModule }                              from 'primeng/table';
import { ToolbarModule }                            from 'primeng/toolbar';
// MS Imports
import { EnvironmentGet }                           from '@moon-core/api/response/environment-get.response';
import { MoonFormControlComponent }                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ApiContentResult }                         from '@moon-core/models/api-result';
import { EnvironmentApiService }                    from '@moon-core/api/environment-api.service';
import { ComponentUtilityService }                  from '@moon-core/services/component-utility.service';
import { CollaboratorRouter }                       from '@moon-collaborator/collaborator.router';

@Component({
    selector: "ms-environment",
    templateUrl: "./environment.component.html",
    styleUrls: ["./environment.component.css"],
    standalone: true,
    imports: [MoonLoadingComponent, FormsModule, ReactiveFormsModule, NgIf, MoonFormControlComponent, DropdownModule, ProgressBarModule, ToolbarModule, SharedModule, InputTextModule, ButtonModule, RippleModule, TableModule, NgFor, NgClass, AsyncPipe],
    providers: [CollaboratorRouter]
  })
export class EnvironmentComponent implements OnInit {
    public MSEnvironmentList: EnvironmentGet[] = [];
    public MSLoading: boolean = false;

    constructor(    
        private _appBreadcrumbService: AppBreadcrumbService,
        private _environmentApiService: EnvironmentApiService,
        private _componentUtilityService: ComponentUtilityService,
        private _collaboratorRouter: CollaboratorRouter
    ) {
    }

    ngOnInit() {
        this.setBreadcrumb();
        this.loadEnvironmentList();
    }

    private async loadEnvironmentList() {
        this.MSLoading = true;
        const apiResult: ApiContentResult<EnvironmentGet[]> = await this._environmentApiService.GetEnvironmentList();
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSEnvironmentList = apiResult.content;
          this.MSLoading = false;
        };
      }
    
    private setBreadcrumb(): void {
        let breadcrumbLabel = 'Environment'
        this._appBreadcrumbService.setItems([{ label: breadcrumbLabel }]);
    }

    public MSOnClickEnvironment(environment: EnvironmentGet) {
        this._collaboratorRouter.ToDocument(environment.environmentName);
    }

    public MSOnClickReload() {
        this.loadEnvironmentList();
    }
}
