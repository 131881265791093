// Moon Auto Generated (1.0.0) at 11/22/2023 11:48:18 AM
import { Component, Input, OnInit,
  ViewChild, ViewEncapsulation }                from "@angular/core";
import { NgClass, NgFor,
  NgSwitch, NgSwitchCase,
  NgSwitchDefault }                             from "@angular/common";
// Third Party imports      
import { Table, TableModule }                   from "primeng/table";
import { SharedModule }                         from "primeng/api";
import { TooltipModule }                        from "primeng/tooltip";
// Moon imports     
import { DocumentChunkGet }                     from "@moon-collaborator/api/response/document-chunk-get.response";
import { DocumentController }                   from "@moon-collaborator/api/document.controller";
import { FieldNames }                           from '@moon-shared/constants/field-names';
import { TableColumn }                          from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }               from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }             from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }          from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";

@Component({
  selector: 'document-chunk-table',
  templateUrl: './document-chunk-table.component.html',
  styleUrls: ['./document-chunk-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    SharedModule,
    TableModule,
    MoonTablePageComponent,
    MoonTableHeaderComponent,
    MoonCustomDropdownComponent,
    TooltipModule,
  ],
  providers: [DocumentController],
})
export class DocumentChunkTableComponent implements OnInit {
  @Input() public MSPageTitle: string;
  @Input() public MSFilteredChunkList: DocumentChunkGet[];
  @Input() public MSLoading = true;
  @Input() public MSEnvironmentName: string = String.empty;
  @Input() public MSDocumentID: number;
  public MSTableColumns: TableColumn[] = [
    {
      field: FieldNames.Ordinal.toCamelCase(),
      header: FieldNames.Ordinal.getDisplayName(),
      class: 'w-7per',
    },
    {
      field: FieldNames.Content.toCamelCase(),
      header: FieldNames.Content.getDisplayName(),
      class: 'w-45per',
    },
    {
      field: FieldNames.TokenSize.toCamelCase(),
      header: FieldNames.TokenSize.getDisplayName(),
      class: 'w-12per',
    },
    {
      field: FieldNames.EmbeddingStatus.toCamelCase(),
      header: FieldNames.EmbeddingStatus.getDisplayName(),
      class: 'w-12per',
    },
    {
      field: FieldNames.VectorAsCsv.toCamelCase(),
      header: FieldNames.Vector.getDisplayName(),
      class: 'w-12per',
    },
    {
      field: FieldNames.savedAsFavorite,
      header: FieldNames.savedAsFavorite.getDisplayName(),
      class: 'w-12per',
    },
  ];

  public MSEnableSearch: boolean;
  public MSDisplayFind: boolean;
  public MSDisplayCreate: boolean;

  @ViewChild('MSTable') MSTable: Table;
  public MSDisplaySecondaryButton: boolean;
  public MSSecondaryButtonToolTip: string;
  public MSSecondaryButtonIcon: string;

  public MSSelectedChunks: DocumentChunkGet[] = [];
  constructor( ) { }

  ngOnInit(): void {
    this.MSEnableSearch = false;
    this.MSDisplayCreate = false;
    this.MSDisplayFind = false;
  }
}
