<!-- Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>
<p-confirmDialog
    header="Delete Confirmation"
    icon="pi pi-exclamation-triangle"
    styleClass="w-30rem"
    acceptButtonStyleClass="p-button-text"
    rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <document-category-table
        [MSPageTitle]="MSPageTitle"
        [MSDocumentCategoryList]="MSDocumentCategoryList"
        [MSLoading]="MSLoading"
        [MSEnvironmentName]="MSEnvironmentName"
    ></document-category-table>
</div>