<!-- Chat Log Viewer -->
<ng-container *ngIf="MSChatLog as log">

    <p-fieldset
        legend="Outputs"
        [toggleable]="true"
    >
        <div class="mb-2 white-space-pre-line">
            {{MSChatLogData?.outputData}}
        </div>
    </p-fieldset>

    <p-fieldset
        legend="Prompts"
        [toggleable]="true"
    >

        <div class="mb-2">
            <span class="font-bold">Chat log ID</span>:
            {{log.chatLogID}}
        </div>
        <div class="mb-2">
            <span class="font-bold">User</span>:
            {{log.lastUpdatedBy}} ({{log.userSessionID}}) on {{log.lastUpdatedAtUtc | date:'short' }}
        </div>
        <div
            class="mb-2"
            *ngIf="log.entityType"
        >
            <span class="font-bold">Entity</span>: {{log.entityType}}({{log.entityID}})
        </div>
        <div
            class="mb-2"
            *ngIf="log.actionName"
        >
            <span class="font-bold">Action</span>: {{log.actionName}}({{log.entityID}})
        </div>
        <div
            class="mb-2"
            *ngIf="log.skillName"
        >
            <div class="flex justify-content-between">
                <div>
                    <span class="font-bold">Skill</span>: {{log.skillName}}v{{log.skillVersion}} ({{log.modelType}})
                </div>
                <div>
                    <button
                        *ngIf="MSIsCurrentUserMaintainer"
                        pButton
                        pRipple
                        class="p-button-raised p-button-outlined ml-2"
                        label="Go to test"
                        (click)="MSGoToTest()"
                    >
                    </button>
                </div>
            </div>

        </div>
        <div
            class="mb-2"
            *ngIf="log.deploymentName"
        >
            <span class="font-bold">Deployment name</span>: {{log.deploymentName}}
        </div>
        <div class="mb-2">
            <span class="font-bold">Tokens</span>:
            Max Output: {{log.maxOutputTokens}},
            System: {{log.systemTokens}},
            Example: {{log.exampleTokens}},
            Input: {{log.inputTokens}},
            Request: {{log.requestTokens}},
            Response: {{log.responseTokens}}
        </div>
        <div class="mb-2">
            <span class="font-bold">Cost</span>:
            {{log.requestCost}} + {{log.responseCost}} = {{log.requestCost + log.responseCost}}
        </div>
        <div
            class="mb-2"
            *ngIf="log.durationInMs"
        >
            <span class="font-bold">Duration</span>: {{log.durationInMs / 1000}}s
        </div>
        <div class="mb-2">
            <span class="font-bold">Temperature</span>: {{log.temperature}}
        </div>
        <div class="mb-2">
            <span class="font-bold">Top P</span>: {{log.topP}}
        </div>

        <div class="mb-2">
            <span class="font-bold">Trace JSON</span>: {{log.traceJson}}
        </div>
        <div class="mb-2">
            <span class="font-bold">Error Message</span>: {{log.errorMessage}}
        </div>
    </p-fieldset>

    <p-fieldset
        legend="Input"
        [toggleable]="true"
    >
        <div class="mb-2 white-space-pre-line flex justify-content-between">
            <div>{{MSChatLogData?.inputData}}</div>
            <button
                pButton
                pRipple
                class="p-button-raised p-button-outlined ml-2 h-min"
                icon="pi pi-copy"
                label="Copy input"
                (click)="MSCopyInputToClipboard()"
            ></button>
        </div>
    </p-fieldset>
</ng-container>