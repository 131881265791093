<div class="card moon-pdf-ocr-container">
    <p-tabView [scrollable]="true">
        <p-tabPanel header="Document Mapping">

            <ul class="m-0 p-0 list-none">
                <li
                    *ngFor="let document of MSTitleDocumentMap; let i=index"
                    class="py-2 pl-4 border-bottom-1 surface-border"
                >
                    <moon-document-mapping
                        [MSTitleDocumentMap]="document"
                        [MSTitleDocumentNumber]="i"
                        (MSOnTitleDocumentChange)="MSLoadDocumentAndExceptionMaps()"
                    ></moon-document-mapping>
                </li>
            </ul>
        </p-tabPanel>
        <p-tabPanel header="Exception Mapping">
            <p-table
                #MSTable
                [value]="MSTitleExceptionMap"
                sortMode="single"
                rowGroupMode="subheader"
                groupRowsBy="titleExceptionID"
                styleClass="p-datatable-gridlines"
                [scrollable]="true"
                [globalFilterFields]="['exceptionNumber','part','textList']"
            >
                <ng-template pTemplate="caption">
                    <moon-table-header
                        #MSTableHeader
                        [MSEnableSearch]="true"
                        [MSDisplayCreateButton]="false"
                        [MSDisplaySecondaryButton]="false"
                        [MSDisplayUploadButton]="false"
                        (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                    ></moon-table-header>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Document</th>
                        <th>Exception Number Json</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="groupheader"
                    let-exception
                >
                    <tr pRowGroupHeader>
                        <td
                            colspan="3"
                            class="p-2"
                        >
                            <h1 class="text-base mb-1">
                                <span class="font-bold">
                                    {{exception.exceptionNumber}}
                                </span>
                                <span class="font-bold ml-2">
                                    ({{calculateDocumentTotal(exception.exceptionNumber)}} document)
                                </span>
                            </h1>
                            <div
                                *ngIf="exception.logMessage"
                                class="text-sm"
                                [title]="exception.logMessage"
                            >
                                <span>Log Message:</span>
                                <span>{{exception.logMessage | slice: 0:80}}</span>
                                <span *ngIf="exception.logMessage.length > 80">...</span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-document
                    let-rowIndex="rowIndex"
                >
                    <tr *ngIf="document.hasDocument === 'Y'">
                        <td>
                            {{document.textList}}
                        </td>
                        <td>
                            {{document.exceptionNumberJson}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="OCR Data">
            <p>
                <span class="white-space-pre-wrap">
                    {{MSOcrContent}}
                </span>
            </p>
        </p-tabPanel>
        <p-tabPanel header="OCR Skill">
            <div class="grid">
                <div class="col-12">
                    <button
                        *ngIf="MSOcrSkillData"
                        class="float-right"
                        pButton
                        pRipple
                        icon="pi pi-copy"
                        pTooltip="Copy Skill Text to Clipboard"
                        tooltipPosition="left"
                        [disabled]="MSOcrInProgress"
                        (click)="OnClickCopy($event)"
                    ></button>
                    <button
                        class="float-right"
                        pButton
                        [loading]="MSOcrInProgress"
                        loadingIcon="pi pi-spin pi-spinner"
                        pTooltip="Run OCR Skill"
                        tooltipPosition="top"
                        icon="pi pi-play"
                        (click)="OnClickOCRSkill()"
                    >
                    </button>
                </div>
                <div class="col-12">
                    <p
                        *ngIf="MSOcrSkillData"
                        class="white-space-pre-wrap"
                    >
                        {{ MSOcrSkillData.content }}
                    </p>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Chat Result">
            <ng-template #noChatLogID>Chat log is not available.</ng-template>
            <moon-chat-log
                *ngIf="MSChatLogID;else noChatLogID"
                [MSChatLogID]="MSChatLogID"
            ></moon-chat-log>
        </p-tabPanel>
    </p-tabView>
    <span
        *ngIf="MSOcrSkillData"
        class="ms-skill-data-content float-right"
    >
        <br />Token: {{MSOcrSkillData.replyTokens}}
        <br />Duration: {{MSOcrSkillData.durationMs}} ms
    </span>
</div>