<div
    *ngIf="MSShowBackToTopButton"
    id="go-to-top-wrapper"
>
    <button
        pButton
        pRipple
        (click)="MSScrollToTop()"
        icon="pi pi-arrow-up"
        label="Go to Top"
        id="go-to-top-button"
    ></button>
</div>