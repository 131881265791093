<moon-loading *ngIf="MSLoading"></moon-loading>
<div class="moon-card">
    <div class="card" *ngIf="!MSLoading">
        <div class="moon-card-edit">
            <div class="grid moon-header">
                <div class="col-8">
                    <div class="grid">        
                        <button *ngIf="MSDisplayRouteBackButton"
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-arrow-left"
                            (click)="OnClickRouteBack()"
                            class="p-button-rounded p-button-text mr-2 mb-2"
                            pTooltip="Go Back"
                            tooltipPosition="bottom"
                        ></button>              
                        <h5>{{ MSPageTitle }}</h5>
                    </div>
                </div>
                <div class="col-4 moon-button">
                    <button
                        *ngIf="MSDisplayRemoveButton"
                        (click)="OnRemoveClick()"
                        type="button"
                        pTooltip="Remove"
                        tooltipPosition="left"
                        class="
                            p-button-secondary
                            p-button-raised
                            p-button-rounded
                            mr-2
                            mb-2
                        "
                        pButton
                        icon="pi pi-trash"
                    ></button>
                    <button
                        *ngIf="MSDisplaySecondaryButton"
                        (click)="OnSecondaryButtonClick()"
                        type="button"
                        pTooltip="{{MSSecondaryButtonToolTip}}"
                        tooltipPosition="left"
                        class="
                            p-button-secondary
                            p-button-raised
                            p-button-rounded
                            mr-2
                            mb-2
                        "
                        pButton
                        icon="{{MSSecondaryButtonIcon}}"
                    ></button>
                </div>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
    
</div>

<div class="clearfix"></div>
