// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Moon Imports
import { AppBreadcrumbService }     from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { NodeEnvironmentController }    from '@moon-maintainer/api/node-environment.controller';
import { NodeEnvironmentGet }           from '@moon-maintainer/api/response/node-environment-get.response';
import { NodeEnvironmentTableComponent }  from '@moon-maintainer/node-environment/node-environment-table/node-environment-table.component';
import { MoonLoadingComponent }           from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                 from '@moon-shared/constants/constant-string';

@Component({
  selector: 'node-environment',
  templateUrl: './node-environment.component.html',
  styleUrls: ['./node-environment.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, NodeEnvironmentTableComponent],
  providers: [NodeEnvironmentController]
})
export class NodeEnvironmentComponent implements OnInit {

  public MSNodeEnvironmentList: NodeEnvironmentGet[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.NodeEnvironment.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _nodeEnvironmentController: NodeEnvironmentController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadNodeEnvironmentList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadNodeEnvironmentList() {
    this.MSLoading = true;    
    const apiResult: ApiContentResult<NodeEnvironmentGet[]> = await this._nodeEnvironmentController.GetList();
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSNodeEnvironmentList = apiResult.content;
    };
    this.MSLoading = false;  
  }
}
