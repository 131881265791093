<moon-loading *ngIf="MSLoading"></moon-loading>

<div *ngIf="!MSLoading">
    <div
        class="card ms-environment-card p-4"
        *ngIf="MSEnvironmentList.length"
    >
        <div class="grid">
            <ng-container *ngFor="let environment of MSEnvironmentList">
                <div class="col-12 md:col-6 lg:col-3">
                    <a (click)="MSOnClickEnvironment(environment)">
                        <div>
                            <div class="card cursor-pointer">
                                <div class="header">
                                    <h3>
                                        <b>{{ environment.environmentName }}</b>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
    <div
        class="card ms-reload-card"
        *ngIf="!MSEnvironmentList.length"
    >
        <div class="ms-reload-button">
            <button
                pButton
                pRipple
                type="button"
                icon="pi pi-refresh"
                label="Reload Environments"
                (click)="MSOnClickReload()"
                class="p-button-raised mr-2 mb-2"
            ></button>
        </div>
    </div>
</div>