<div class="mb-1">
    <div class="flex justify-content-between">
        <h2 class="text-base">{{MSFilterLabel}}</h2>
        <button
            *ngIf="MSShowClearButton()"
            pButton
            pRipple
            class="h-min w-min p-0 m-0 bg-unset hover:underline text-primary text-base"
            label="Clear"
            (click)="MSOnClickClearFilter()"
        ></button>
    </div>
    <div
        class="grid p-2 align-items-center"
        *ngFor="let filterValues of MSFilterMap | keyvalue"
    >
        <p-checkbox
            [(ngModel)]="filterValues.value.filterStatus"
            (onChange)="MSOnFilterChange()"
            [inputId]="MSFilterLabel +'-'+ filterValues.key"
            [binary]="true"
        ></p-checkbox>
        <label
            class="filter-label"
            [for]="MSFilterLabel +'-'+ filterValues.key"
        >
            {{filterValues.key}} <span class="text-sm text-600">({{filterValues.value.count}})</span>
        </label>
    </div>
    <hr />
</div>