import { DecimalPipe, NgClass, NgFor, NgIf, SlicePipe }   from '@angular/common';
import { Component }                                      from '@angular/core';
import {
  AbstractControl, FormControl,
  FormGroup,
  FormsModule, ReactiveFormsModule,
  Validators
}                                                         from '@angular/forms';
// Third party imports
import { AppBreadcrumbService }                           from '@app-layout/services/app.breadcrumb.service';
import { PdfViewerModule }              from 'ng2-pdf-viewer';
import { ConfirmationService, MenuItem }                  from 'primeng/api';
import { BadgeModule }                                    from 'primeng/badge';
import { ButtonModule }                                   from 'primeng/button';
import { ConfirmDialogModule }                            from 'primeng/confirmdialog';
import { DropdownModule }                                 from 'primeng/dropdown';
import { InputNumberModule }                              from 'primeng/inputnumber';
import { InputTextModule }                                from 'primeng/inputtext';
import { RippleModule }                                   from 'primeng/ripple';
// MS Imports
import { ApiContentResult }                               from '@moon-core/models/api-result';
import { ComponentUtilityService }                        from '@moon-core/services/component-utility.service';
import { TitleSummaryUpload }                             from '@moon-public/api/request/title-summary.upload';
import { TitleSummaryController }                         from '@moon-public/api/title-summary.controller';
import { PublicRouter }                                   from '@moon-public/public.router';
import { TitleReviewSummaryTypeConstant }              from '@moon-public/title-summary/models/title-review-summary-type.constant';
import { TitleSummaryPdf }                                from '@moon-public/title-summary/title-summay-pdf';
import { MoonFormControlComponent }                       from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                           from '@moon-shared/components/moon-loading/moon-loading.component';
import { FieldValues }                                    from '@moon-shared/constants/field-values';
import { MessageSeverity }                                from '@moon-shared/constants/message-severity';
import { RoutePath }                                      from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }                          from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonFileUpload }                                 from '@moon-shared/models/moon-file-upload.model';
import { MoonMessageService }                             from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                    from '@moon-shared/types/form-group-of.type';
@Component({
  selector: 'ms-title-summary-upload',
  templateUrl: './title-summary-upload.component.html',
  styleUrls: ['./title-summary-upload.component.scss'],
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, SlicePipe, DecimalPipe,
    ButtonModule, RippleModule, BadgeModule, InputNumberModule, InputTextModule, ConfirmDialogModule, DropdownModule,
    PdfViewerModule,
    MoonDragDropDirective, MoonFormControlComponent, MoonLoadingComponent
  ],
  providers: [TitleSummaryController, PublicRouter, ConfirmationService]
})
export class TitleSummaryUploadComponent {

  public MSLoading: boolean = false;
  public MSTitleSummaryForm!: FormGroup<FormGroupOf<TitleSummaryUpload>>;
  public MSTitleSummaryPdfPage = new TitleSummaryPdf();
  public MSTitleReviewSummaryTypes = [TitleReviewSummaryTypeConstant.TitleReport, TitleReviewSummaryTypeConstant.OwnersPolicyCommitment, TitleReviewSummaryTypeConstant.LoanPolicyCommitment];
  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _moonMessageService: MoonMessageService,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
    private _publicRouter: PublicRouter,
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.setBreadcrumb();
    this.setDefaultTitleSummaryForm();
  }

  private setDefaultTitleSummaryForm() {
    this.MSTitleSummaryForm = new FormGroup<FormGroupOf<TitleSummaryUpload>>({
      summaryType: new FormControl(TitleReviewSummaryTypeConstant.TitleReport, {nonNullable: true, validators: Validators.required}),
      matterName: new FormControl(String.empty, { nonNullable: true, validators: Validators.required }),
      inputFile: new FormControl(null, Validators.required),
      exceptionPagesCsv: new FormControl('', { nonNullable: true, validators: Validators.required }),
    }, this.fileValidator);
  }

  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.TitleReview.getDisplayName(), routerLink: [`${this._publicRouter.TitleSummaryRouteLink()}`] },
      { label: RoutePath.Add, routerLink: null }
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }

  public MSOnClickGoBack() {
    this._publicRouter.RouteToTitleSummary();
  }

  public MSHandleFileInput(files: FileList | null) {
    if (!files?.length) return;

    const uploadedFile = files.item(0);

    if (uploadedFile)
      this.updateFileControl(uploadedFile);
  }

  public MSOnFileDrop(file: MoonFileUpload) {
    if (file && file.file.size > 0) {
      this.updateFileControl(file.file);
      return;
    }

    this._moonMessageService.toastInfo(
      "The file you trying to upload is not extracted from zip or may be empty. Please check and try again!"
    );
  }

  private updateFileControl(file: File) {
    this.MSTitleSummaryForm.controls.inputFile.setValue(file);
    this.MSTitleSummaryForm.markAsDirty();
    this.MSTitleSummaryForm.controls.inputFile.updateValueAndValidity();
    this.MSTitleSummaryForm.updateValueAndValidity();

    this.MSTitleSummaryPdfPage.ReadContentFromFile(file);
  }

  public MSClearSelectedFile() {
    this.MSTitleSummaryForm.controls.inputFile.setValue(null);
    this.MSTitleSummaryForm.updateValueAndValidity();
    this.MSTitleSummaryPdfPage.Reset();
    this.MSTitleSummaryPdfPage.ClearExceptionPages();

  }

  public MSOnClickProcessExceptions() {
    this.setExceptionPagesFromMarkedPages();
    this.MSTitleSummaryForm.markAllAsTouched();
    this.MSTitleSummaryForm.updateValueAndValidity();
    if (!this._componentUtilityService.IsFormValid(this.MSTitleSummaryForm))
      return;

    const titleSummaryForm: TitleSummaryUpload = this.MSTitleSummaryForm.getRawValue() as TitleSummaryUpload;
    if (!titleSummaryForm.inputFile) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, 'Input file is required');
      return
    }
    this.doProcessExceptions(titleSummaryForm);
  }

  private confirmToGoBack() {
    this._confirmationService.confirm({
      message: "The process has started, please check back in on the processing status",
      acceptLabel: "Ok",
      accept: () => this.MSOnClickGoBack(),
      closeOnEscape: false,
      rejectVisible: false
    });
  }

  private async doProcessExceptions(titleSummaryForm: TitleSummaryUpload) {
    this.confirmToGoBack();

    const apiResult: ApiContentResult<number> = await this._titleSummaryController.CreateTitleSummary(titleSummaryForm);
    this._confirmationService?.close();

    if (!apiResult.success) {
      if (apiResult.apiError.status !== 0)
        this._componentUtilityService.WasSuccessful(apiResult);
    }
    else {
      this._moonMessageService.toastSuccess("Title summary created successfully.");
    }
  }

  private setExceptionPagesFromMarkedPages() {
    const exceptionPages: number[] = this.MSTitleSummaryPdfPage.GetExceptionPageArray();
    if (exceptionPages) {
      this.MSTitleSummaryForm.controls.exceptionPagesCsv.setValue(exceptionPages.join(','));
    }
  }

  private fileValidator() {
    return (form: AbstractControl<TitleSummaryUpload>): { [key: string]: any; } | null => {
      const fileControl: AbstractControl<File | null> | null = form.get('inputFile');

      // File empty check
      if (fileControl?.value == null || fileControl.value.size === 0) {
        form.setErrors({ hasEmptyContent: true });
      }
      else {
        // File type check
        if (![FieldValues.FileType_PDF, FieldValues.FileType_TXT].includes(fileControl.value.type)) {
          fileControl.setErrors({
            invalidFileType: true,
          });
        }
      };
      return null;
    }
  }
}
