<li
    class="py-2 pl-4 border-bottom-1 "
    [ngClass]="MSTitleDocument.hasError ? 'border-top-1 border-red-500' : 'surface-border'"
>
    <div class="flex flex-1 gap-3 my-2 align-items-start">
        <div class="flex flex-column">
            <div class="flex gap-2">
                <h1 class="text-base mb-1">
                    {{MSDocumentIndexNumber+1}}.&nbsp;{{MSTitleDocument.textList}}
                </h1>
                <div
                    *ngIf="MSTitleDocument.numberOfPages"
                    class="text-sm"
                >
                    Pages - {{MSTitleDocument.numberOfPages}}
                </div>
                <div *ngIf="MSTitleDocument.processingStatus">
                    <p-badge
                        severity="warning"
                        title="Processing status"
                        value=" {{MSTitleDocument.processingStatus}}"
                    ></p-badge>
                </div>
                <div *ngIf="MSTitleDocument.hasError">
                    <p-badge
                        severity="danger"
                        value="Error"
                    ></p-badge>
                </div>
            </div>
            <div
                *ngIf="MSTitleDocument.fileSize"
                class="text-sm"
            >
                Size: {{MSTitleDocument.fileSize / 1024 | number:'1.0-2'}} kb
            </div>
            <div
                *ngIf="MSTitleDocument.fileUrl"
                class="text-sm"
            >
                Url:
                <a
                    class="cursor-pointer hover:text-blue-500"
                    [href]="MSTitleDocument.fileUrl"
                    target="_blank"
                >
                    {{MSTitleDocument.fileUrl | slice:0:80}}
                    <span *ngIf="MSTitleDocument.fileUrl.length > 80">...</span>
                </a>
            </div>
        </div>

        <div class="ml-auto flex">
            <button
                *ngIf="MSShowPdfButton(MSTitleDocument)"
                pButton
                [disabled]="MSApplying"
                icon="pi pi-file-pdf"
                label="Pdf"
                (click)="MSOnClickPdfReviewForDocument(MSTitleDocument.titleDocumentID)"
            ></button>

            <button
                #btn
                *ngIf="MSShowPromptAndChatLogButton(MSTitleDocument)"
                type="button"
                pButton
                label="Prompt"
                icon="pi pi-angle-down"
                iconPos="right"
                [disabled]="MSApplying"
                (click)="promptDropdown.toggle($event)"
            ></button>
            <p-tieredMenu
                #promptDropdown
                [model]="MSPromptButtons"
                [popup]="true"
            ></p-tieredMenu>

            <button
                #btn
                *ngIf="MSShowPromptAndChatLogButton(MSTitleDocument)"
                type="button"
                pButton
                label="Log"
                icon="pi pi-angle-down"
                iconPos="right"
                [disabled]="MSApplying"
                (click)="chatDropdown.toggle($event)"
            ></button>
            <p-tieredMenu
                #chatDropdown
                [model]="MSChatLogButtons"
                [popup]="true"
            ></p-tieredMenu>
        </div>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleDocument.exceptionNumberJson"
    >
        <span class="font-bold">Exception Number</span>: {{MSTitleDocument.overrideExceptionNumberJson ?? MSTitleDocument.exceptionNumberJson}}
        <span
                *ngIf="MSTitleDocument.overrideExceptionNumberJson"
                class="text-gray-400 text-sm"
            >(Edited)
            </span>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleDocument.exceptionType"
    >
        <span class="font-bold">Exception Type</span>:
        <div *ngIf="MSEditType; else showType">
            <form
                [formGroup]="MSTypeFormGroup"
                (ngSubmit)="MSSaveExceptionType()"
            >
                <moon-form-control
                    [MSFormControl]="MSTypeFormGroup.controls.exceptionType"
                    [MSLabel]="'Exception Type'"
                    [MSDisplayLabel]="false"
                    [MSInputSize]="12"
                    [MSErrorMessageSize]="12"
                >
                    <input
                        id="exceptionType"
                        #action
                        pInputText
                        [formControl]="MSTypeFormGroup.controls.exceptionType"
                    />
                </moon-form-control>
                <button
                    type="submit"
                    pButton
                    pRipple
                    label="Save"
                    class="p-button-raised mr-2 mb-2"
                    *ngIf="MSTypeFormGroup?.dirty"
                    [disabled]="MSSaving"
                ></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    [disabled]="MSSaving"
                    class="p-button-raised p-button-secondary mr-2 mb-2"
                    (click)="MSToggleTypeEdit()"
                ></button>
            </form>
        </div>
        <ng-template #showType>
            <span
                title="Click to edit"
                class="hover:surface-hover"
                (click)="MSToggleTypeEdit()"
            >{{MSTitleDocument.overrideExceptionType ?? MSTitleDocument.exceptionType}}
            </span>
            <i
                class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                (click)="MSToggleTypeEdit()"
            ></i>
            <span
                *ngIf="MSTitleDocument.overrideExceptionType"
                class="text-gray-400 text-sm"
            >(Edited)
            </span>
        </ng-template>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleDocument.recommendedAction"
    >
        <span class="font-bold">Recommended Action</span>:
        <div *ngIf="MSEditAction; else showAction">
            <form
                [formGroup]="MSActionFormGroup"
                (ngSubmit)="MSSaveRecommendedAction()"
            >
                <moon-form-control
                    [MSFormControl]="MSActionFormGroup.controls.recommendedAction"
                    [MSLabel]="'Recommended Action'"
                    [MSDisplayLabel]="false"
                    [MSInputSize]="12"
                    [MSErrorMessageSize]="12"
                >
                    <div
                        class="grow-wrap"
                        [attr.textarea-value]="action.value"
                    >
                        <textarea
                            id="recommendedAction"
                            #action
                            pInputTextarea
                            [formControl]="MSActionFormGroup.controls.recommendedAction"
                        ></textarea>
                    </div>
                </moon-form-control>
                <button
                    type="submit"
                    pButton
                    pRipple
                    label="Save"
                    class="p-button-raised mr-2 mb-2"
                    *ngIf="MSActionFormGroup?.dirty"
                    [disabled]="MSSaving"
                ></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    [disabled]="MSSaving"
                    class="p-button-raised p-button-secondary mr-2 mb-2"
                    (click)="MSToggleActionEdit()"
                ></button>
            </form>
        </div>
        <ng-template #showAction>
            <span
                title="Click to edit"
                class="hover:surface-hover"
                (click)="MSToggleActionEdit()"
            >{{MSTitleDocument.overrideRecommendedAction ?? MSTitleDocument.recommendedAction}}
            </span>
            <i
                class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                (click)="MSToggleActionEdit()"
            ></i>
            <span
                *ngIf="MSTitleDocument.overrideRecommendedAction"
                class="text-gray-400 text-sm"
            >(Edited)
            </span>
        </ng-template>
    </div>

    <div
        class="mb-2"
        *ngIf="MSTitleDocument.summaryText"
    >
        <div class="font-bold mb-1">Summary:</div>
        <div class="pl-2">
            <ng-container *ngIf="MSTitleDocument.summaryObject; else showSummaryText">
                <div
                    *ngFor="let keyValue of MSTitleDocument.summaryObject | keyvalue:MSPreserveOriginalOrderInKeyValuePipe">
                    <span class="font-bold">{{keyValue.key}}</span> : {{keyValue.value}}
                </div>
            </ng-container>
            <ng-template #showSummaryText>
                {{MSTitleDocument.summaryText }}
            </ng-template>
        </div>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleDocument.logMessage"
    >
        <span class="font-bold">Log Messages</span>: {{MSTitleDocument.logMessage}}
    </div>
</li>

<!-- Chat Log Viewer -->
<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw"
    position="top"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSChatLogID"
    ></moon-chat-log>
</p-dialog>