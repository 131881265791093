import { Injectable }           from '@angular/core';
import { ApplicationInsights }  from '@microsoft/applicationinsights-web';
// MS Imports
import { environment }          from 'src/environments/environment';
import { MoonstoneApp }         from 'src/moon-config/moonstone-app';
import { MoonAppState }         from '@app/moon-app-state';
import { MoonWebRequest }       from '@moon-core/models/moon-web-request.model';
import { MoonTrace }            from '@moon-core/models/moon-trace.model';

@Injectable()
export class MoonApplicationInsight {
    private readonly _id: string;
    private _appInsights: ApplicationInsights;
    private _connectionName: string;
    private _moonUserName: string;
    private _userSessionID: string;
    private _azureUserName: string;

    constructor(private _moonAppState: MoonAppState) {
        this._id = String.currentTimeStamp();
        this._moonAppState.setAppInsightsId(this._id);
    }

    public Initialize(instrumentationKey: string): void {

        this._appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                enableAutoRouteTracking: true, // option to log all route changes
                disableTelemetry: !this._enableTelemetry,
                disableAjaxTracking: true,
            },
        });
        if (this._enableTelemetry) {
            this._appInsights.loadAppInsights();
            // Time Stamp in UTC.

            this._appInsights.context.application.ver = MoonstoneApp.ApplicationVersion;
            this._appInsights.context.user.id = this._id;
            this._appInsights.context.session.id = this._id;

            this.logEvent("Application Insight: Initialized!");
        }
    }

    private get _enableTelemetry(): boolean {
        return environment.production;
    }

    public SetAzureUserName(azureUserName: string) {
        this._azureUserName = azureUserName;

        if (this._appInsights?.context) {
            this._appInsights.context.user.authenticatedId = azureUserName;
        }
    }

    public MSSetMoonUserDetail(preferredUserName: string, userSessionID: number) {
        this._moonUserName = preferredUserName;
        this._userSessionID = userSessionID?.toString();

        if (this._appInsights?.context) {
            this._appInsights.context.session.id = this._userSessionID;
            this._appInsights.context.user.accountId = this._moonUserName;
        }
    }

    public MSInitialize() {
        this._connectionName = "";
        this._moonUserName = "";
        this._userSessionID = "";

        if (this._appInsights?.context) {
            this._appInsights.context.session.id = this._id;
            this._appInsights.context.user.accountId = "";
        }
    }

    public logPageView(name?: string, url?: string): void {
        if (!this._enableTelemetry)
            return;
        // option to call manually
        this._appInsights.trackPageView({
            name: name ?? String.empty,
            uri: url ?? String.empty,
        });
    }

    public logEvent(name: string, properties?: { [key: string]: any }): void {
        console.log(name);
        if (!this._enableTelemetry)
            return;

        if (properties === null) {
            properties = this.getCustomProperties();
        }
        name = `${this._id} - ${name}; ${this.getTelemetryPropertyString()}`;

        this._appInsights.trackEvent({ name: name }, properties);
    }

    public logMetric(
        name: string,
        average: number,
        properties?: { [key: string]: any }
    ): void {
        if (!this._enableTelemetry)
            return;
        if (properties === null) {
            properties = this.getCustomProperties();
        }
        this._appInsights.trackMetric({ name: name, average: average }, properties);
    }

    public logException(exception: Error, severityLevel?: number): void {
        console.error(exception);

        if (!this._enableTelemetry)
            return;

        const customProperties: { [key: string]: string } = this.getCustomProperties();

        this._appInsights.trackException({ exception: exception, severityLevel: severityLevel ?? 0 }, customProperties
        );
    }

    public logTrace(moonTrace: MoonTrace): void {
        moonTrace.stopTimer();
        console.info(moonTrace);

        if (!this._enableTelemetry)
            return;

        const properties: { [key: string]: string } = this.getCustomProperties();
        properties["ClassName"] = moonTrace.className;
        properties["MethodName"] = moonTrace.methodName;
        properties["DurationMs"] = moonTrace.durationMs.toString();

        if (moonTrace.entityType) {
            properties["EntityType"] = moonTrace.entityType;
        }
        if (moonTrace.entityID) {
            properties["EntityID"] = moonTrace.entityID;
        }

        const traceMessage: string = `${this._id} - ${moonTrace.getLogMessage()}; ${this.getTelemetryPropertyString()}`;

        this._appInsights.trackTrace({ message: traceMessage }, properties);
    }

    public logWebRequest(moonWebRequest: MoonWebRequest): void {
        moonWebRequest.stopTimer();
        console.info(moonWebRequest);

        if (!this._enableTelemetry)
            return;


        const properties: { [key: string]: string } = this.getCustomProperties();
        properties["MethodName"] = moonWebRequest.methodName;
        properties["UrlWithParams"] = moonWebRequest.urlWithParams;
        properties["DurationMs"] = moonWebRequest.durationMs.toString();

        const traceMessage: string = `[Http] ${moonWebRequest.getLogMessage()}; ${this.getTelemetryPropertyString()}`;

        this._appInsights.trackTrace({ message: traceMessage }, properties);
    }

    private getTelemetryPropertyString(): string {
        let returnString = "";
        if (this._azureUserName) {
            returnString += `${this._azureUserName}`;
        }

        if (this._userSessionID) {
            returnString += `; MoonConnectionID: ${this._userSessionID}`;
        }

        if (this._connectionName) {
            returnString += `; ${this._connectionName}`;
        }

        return returnString;
    }

    private getCustomProperties() {
        let returnProperties: { [key: string]: string } = {};

        const properties: { [key: string]: string } = {
            AzureUserName: this._azureUserName,
            ConnectionName: this._connectionName,
            UserConnectionID: this._userSessionID,
            MoonUserName: this._moonUserName,
            ApplicationVersion: MoonstoneApp.ApplicationVersion,
            ApplicationName: MoonstoneApp.ApplicationName
        };

        for (let key in properties) {
            if (properties[key]) {
                returnProperties[key] = properties[key] ?? String.empty;
            }
        }

        return returnProperties;
    }
}
