import { CommonModule }             from '@angular/common';
import { Component }                from '@angular/core';
import { ActivatedRoute, Router }           from '@angular/router';
// Third party imports
import { SharedModule }             from 'primeng/api';
import { ButtonModule }             from 'primeng/button';
import { InputNumberModule }        from 'primeng/inputnumber';
import { ProgressSpinnerModule }    from 'primeng/progressspinner';
import { RippleModule }             from 'primeng/ripple';
import { TabMenuModule }            from 'primeng/tabmenu';
// Moon imports
import { AppBreadcrumbService }     from '@app-layout/services/app.breadcrumb.service';
import { PublicRouter }             from '@app/moon-public/public.router';
import { TitleDocumentController }  from '@moon-public/api/title-document.controller';
import { TitleSummaryController }   from '@moon-public/api/title-summary.controller';
import { PdfDisplayComponent }      from '@moon-public/title-summary/pdf-display/pdf-display.component';
import { SummaryResultComponent }   from '@moon-public/title-summary/summary-result/summary-result.component';
import { DefaultValues }            from '@moon-shared/constants/default-values';
import { RouteParameter }           from '@moon-shared/constants/route-parameter';
import { RoutePath }                from '@moon-shared/constants/route-path';
import { FieldNames } from '@app/moon-shared/constants/field-names';

@Component({
  selector: 'summary-pdf-review',
  templateUrl: './summary-pdf-review.component.html',
  styleUrls: ['./summary-pdf-review.component.scss'],
  standalone: true,
  imports: [CommonModule,
    SharedModule, InputNumberModule, ProgressSpinnerModule, TabMenuModule, ButtonModule, RippleModule,
    SummaryResultComponent, PdfDisplayComponent],
  providers: [TitleSummaryController, TitleDocumentController, PublicRouter]
})
export class SummaryPdfReviewComponent {

  public MSEntityType: string = RoutePath.TitleReview;
  public MSTitleSummaryID: number;
  public MSExceptionPagesCSV: string;
  public MSTitleSummaryChatLogID: number | null;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _publicRouter: PublicRouter

  ) { 
    let currentRouterState = this._router.getCurrentNavigation()?.extras.state;
    if (currentRouterState) {
      this.MSExceptionPagesCSV = currentRouterState[FieldNames.ExceptionPagesCsv.toCamelCase()];
      this.MSTitleSummaryChatLogID = currentRouterState[FieldNames.ChatLogID.toCamelCase()];
    }
  }

  ngOnInit() {
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
    this.setBreadcrumb();
  }
  public MSOnClickRouteBack() {
    this._publicRouter.RouteToTitleSummaryDetail(this.MSTitleSummaryID)
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetSimple([this.MSEntityType, RoutePath.PdfReview]);
  }

}
