<!-- Moon Auto Generated (1.0.0) at 11/22/2023 11:48:18 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div *ngIf="!MSLoading">

  <moon-form-sticky-navbar>
    <p-toolbar>
      <div class="p-toolbar-group-start">
        <button
          pButton
          pRipple
          icon="pi pi-arrow-left"
          class="mr-2"
          pTooltip="Go Back"
          tooltipPosition="top"
          (click)="MSOnClickGoBack()"
          [disabled]="MSIsProcessInProgress"
        ></button>

        <button
          pButton
          pRipple
          icon="pi pi-refresh"
          class="mr-2"
          pTooltip="Refresh"
          tooltipPosition="top"
          (click)="MSOnRefreshClick()"
          [disabled]="MSIsProcessInProgress"
        ></button>
        <i class="pi pi-circle-fill toolbar-separator"></i>

        <button
          pButton
          pRipple
          icon="pi pi-download"
          class="mr-2"
          pTooltip="Download Document"
          tooltipPosition="top"
          (click)="MSOnClickDownloadDocument()"
          [disabled]="MSIsProcessInProgress"
        ></button>
        <button
          pButton
          pRipple
          label="Mark For Create"
          class="mr-2"
          (click)="MSOnClickMarkForCreate()"
          [disabled]="MSDoDisableActionButton"
        ></button>
        <button
          pButton
          pRipple
          label="Do Not Create"
          class="mr-2"
          (click)="MSOnClickDoNotCreate()"
          [disabled]="MSDoDisableActionButton"
        ></button>
      </div>

      <div class="p-toolbar-group-end">
        <div
          *ngIf="MSFilterChunkForm"
          class="grid moon-div-form"
        >
          <form [formGroup]="MSFilterChunkForm">
            <div class="chunk-filter-grid">
              <moon-form-control
                [MSInputSize]="6"
                [MSLabelSize]="6"
                [MSFormControl]="MSFilterChunkForm.controls.embeddingFilter"
                [MSLabel]="'Vector Status'"
              >
                <div class="w-7rem">
                  <p-dropdown
                    class="p-fluid"
                    [options]="MSEmbeddingStatuses"
                    [formControl]="MSFilterChunkForm.controls.embeddingFilter"
                  ></p-dropdown>
                </div>
              </moon-form-control>

              <moon-form-control
                [MSInputSize]="8"
                [MSLabelSize]="4"
                [MSFormControl]="MSFilterChunkForm.controls.tokenFilter"
                [MSLabel]="'Token Range'"
              >
                <div class="grid gap-1">
                  <div class="col-5">
                    <p-inputNumber
                      class="p-fluid"
                      placeholder="Min"
                      [formControl]="MSFilterChunkForm.controls.tokenMin"
                      [useGrouping]="false"
                      [required]="true"
                      [min]="MSOriginalTokenRange.min"
                      [max]="MSOriginalTokenRange.max"
                      [minFractionDigits]="0"
                      [maxFractionDigits]="2"
                    ></p-inputNumber>
                  </div>
                  <div class="col-5">
                    <p-inputNumber
                      class="p-fluid"
                      placeholder="Max"
                      [formControl]="MSFilterChunkForm.controls.tokenMax"
                      [useGrouping]="false"
                      [required]="true"
                      [min]="MSOriginalTokenRange.min"
                      [max]="MSOriginalTokenRange.max"
                      [minFractionDigits]="0"
                      [maxFractionDigits]="2"
                    ></p-inputNumber>
                  </div>
                </div>
              </moon-form-control>
            </div>
          </form>
          <div class="grid ms-action-button">
            <button
              pButton
              pRipple
              class="p-button-raised moon-apply-summarize-button"
              label="Apply Filter"
              title="Apply Filter"
              (click)="MSApplyFilter()"
            ></button>

            <button
              *ngIf="MSFilterApplied"
              pButton
              pRipple
              icon="pi pi-filter-slash"
              class="fadeinright p-button-raised moon-apply-summarize-button"
              (click)="MSClearFilter()"
            ></button>
          </div>
        </div>
      </div>
    </p-toolbar>
  </moon-form-sticky-navbar>

  <div
    class="moon-progress-bar"
    *ngIf="MSIsProcessRunning"
  >
    <p class="moon-progress-message">{{ MSProcessMessage }}</p>
    <p-progressBar
      mode="indeterminate"
      styleClass="h-1px"
    ></p-progressBar>
  </div>

  <div class="card moon-card">
    <document-chunk-table
      #MSTableComponent
      [MSPageTitle]="MSPageTitle"
      [MSFilteredChunkList]="MSFilteredChunkList"
      [MSLoading]="MSLoading"
      [MSDocumentID]="MSDocumentID"
      [MSEnvironmentName]="MSEnvironmentName"
    ></document-chunk-table>
  </div>
</div>