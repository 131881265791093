import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MoonLoadingComponent } from '../../moon-loading/moon-loading.component';
import { NgIf } from '@angular/common';
import { FormStickyFooterService } from '@app/moon-shared/services/form-sticky-footer.service';

@Component({
    selector: 'moon-edit-page',
    templateUrl: './moon-edit-page.component.html',
    styleUrls: ['./moon-edit-page.component.scss'],
    standalone: true,
    imports: [NgIf, MoonLoadingComponent, TooltipModule, ButtonModule],
    providers:[      
      FormStickyFooterService, 
    ]
})
export class MoonEditPageComponent {
  @Input() public MSLoading: boolean = true;
  @Input() public MSPageTitle: string;
  @Input() public MSDisplayRemoveButton: boolean = false;
  @Input() public MSDisplayRouteBackButton: boolean = false;
  @Input() public MSDisplaySecondaryButton: boolean = false;
  @Input() public MSSecondaryButtonToolTip: string;
  @Input() public MSSecondaryButtonIcon: string;

  @Output() public MSOnClickEventRouteBack = new EventEmitter();
  @Output() public MSOnClickEventRemove = new EventEmitter();
  @Output() public MSOnClickEventSecondaryButton = new EventEmitter();

  constructor() {
  }
  
  public OnRemoveClick() {
    this.MSOnClickEventRemove.emit();
  }

  public OnSecondaryButtonClick() {
    this.MSOnClickEventSecondaryButton.emit();
  }

  public OnClickRouteBack() {
    this.MSOnClickEventRouteBack.emit();
  }
}
