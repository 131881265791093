<!-- Moon Auto Generated (1.0.0) at 11/26/2023 11:22:45 PM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSChatLogList"
    [MSDisplayCreateButton]="MSDisplayCreate"
>

    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [rowHover]="true"
        [value]="MSChatLogList"
        selectionMode="single"
        [loading]="MSLoading"
        [scrollable]="true"
        styleClass="p-datatable-gridlines p-datatable-striped"
        [globalFilterFields]="['entityID', 'actionName']"
    >
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="MSDisplayCreate"
                (MSEventOnSearchInput)="OnSearchInput($event)"
            >
                <ng-template moonTemplate="filter">
                    <div class="grid gap-2 align-items-end mr-2">
                        <div>
                            <label for="DateFrom">Date From</label>&nbsp;
                            <p-calendar
                                [(ngModel)]="MSFindChatLogParameter.dateFrom"
                                dateFormat="mm/dd/yy"
                            ></p-calendar>
                        </div>
                        <div>
                            <label for="DateTo">Date To</label>&nbsp;
                            <p-calendar
                                [(ngModel)]="MSFindChatLogParameter.dateTo"
                                dateFormat="mm/dd/yy"
                            ></p-calendar>&nbsp;
                        </div>
                        <button
                            pButton
                            pRipple
                            pTooltip="Search"
                            tooltipPosition="top"
                            type="button"
                            icon="pi pi-search fs-medium"
                            class="p-button-rounded moon-button"
                            (click)="MSOnClickDateFilterSearch()"
                        ></button>
                    </div>
                </ng-template>
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                    [pSortableColumn]="col.field"
                >
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'entityType'">
                            {{ col.header }}
                            <p-columnFilter
                                field="entityType"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Skill Type</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="MSEntityTypeSelectItems"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>

                        <div *ngSwitchCase="'skillType'">
                            {{ col.header }}
                            <p-columnFilter
                                field="skillType"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Skill Type</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="MSSkillTypeSelectItems"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>

                        <span *ngSwitchDefault>
                            {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                        </span>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions' ||  MSOnClickItem(rowData)"
                >
                    <div [ngSwitch]="col.field">
                        <div
                            *ngSwitchCase="'actions'"
                            class="text-right"
                        >

                            <moon-custom-dropdown [model]="rowData.actions">
                                <i class="pi pi-ellipsis-v"></i>
                            </moon-custom-dropdown>
                        </div>
                        <div *ngSwitchCase="'lastUpdatedAtUtc'">
                            {{ rowData[col.field] | date:'short' }}
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>