import { Injectable } from "@angular/core";
import { ApiContentResult } from "@moon-core/models/api-result";
// MS Imports
import { MoonApiClient } from '@moon-core/services/moon-api.client';
import { ChatResult } from "@moon-public/api/response/chat-result.response";
import { ChatParameter } from "../models/chat-parameter";
import { ConstantString } from "@app/moon-shared/constants/constant-string";
import { SkillGet } from "@app/moon-maintainer/api/response/skill-get.response";

@Injectable()
export class ChatSkillController {
    
  constructor(private _moonApi: MoonApiClient) { }

  private getBaseUrl(): string {
    return this._moonApi.getPublicUrl(ConstantString.ChatSkill);
  };

  public async GetListSkillAsync(skillType: string
    ): Promise<ApiContentResult<SkillGet[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillType, ConstantString.Skill);
    return this._moonApi.getAsync<SkillGet[]>(url);
  }
  
  public async RunChat(chatParameter: ChatParameter
    ): Promise<ApiContentResult<ChatResult>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(),  ConstantString.Function);
    return this._moonApi.postWithResultAsync(url, chatParameter);
  }

  public async RunFileSummaryChat(skillFormData: FormData
    ): Promise<ApiContentResult<ChatResult>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(),  ConstantString.FileSummary);
    return this._moonApi.postWithResultAsync(url, skillFormData);
  }
}
