// Moon Auto Generated (1.2.0) at 12/22/2023 6:21:39 AM
import { Injectable }                     from "@angular/core";
// MS Imports
import { ApiContentResult }               from '@moon-core/models/api-result';
import { MoonApiClient }                  from '@moon-core/services/moon-api.client';
import { UserOptionGet }                  from "@moon-public/api/response/user-option-get.response";
import { ConstantString }                 from "@moon-shared/constants/constant-string";
import { FieldNames }                     from "@app/moon-shared/constants/field-names";

@Injectable()
export class UserOptionController {

  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.UserOption);
  };

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   GET: Public/v1/UserOption/OptionName/{optionName}
   */
  public async GetByName(userOptionName: string
  ): Promise<ApiContentResult<UserOptionGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), FieldNames.OptionName, userOptionName);
    return this._moonApi.getAsync<UserOptionGet>(url);
  }
}
