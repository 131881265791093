import { Component, OnInit }         from '@angular/core';
import { CommonModule }              from '@angular/common';
//Third Party Imports
import { DynamicDialogConfig }       from 'primeng/dynamicdialog';
import { FieldsetModule }            from 'primeng/fieldset';
//Naya Imports
import { ChatLogDataGet }            from '@moon-maintainer/api/response/chat-log-data-get.response';

@Component({
  selector: 'moon-chat-log-detail-modal',
  standalone: true,
  imports: [CommonModule, FieldsetModule ],
  templateUrl: './chat-log-detail-modal.component.html',
  styleUrls: ['./chat-log-detail-modal.component.scss']
})
export class ChatLogDetailModalComponent implements OnInit{

  public MSOutputData: string;
  public MSInputData: string;
  private _chatLogData: ChatLogDataGet;
  private readonly _noData: string = "No Data To Show";
  constructor(
    private _dialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit() {
    this._chatLogData = this._dialogConfig.data.ChatLogData;
    this.MSOutputData = this._chatLogData.outputData ? this._chatLogData.outputData : this._noData;
    this.MSInputData = this._chatLogData.inputData ? this._chatLogData.inputData : this._noData;
  }

}
