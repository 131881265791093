// Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Moon Imports
import { AppBreadcrumbService }     from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { UserSessionLogController }           from '@moon-maintainer/api/user-session-log.controller';
import { FindUserSessionParameter }        from "@moon-maintainer/api/request/find-user-session.parameter";
import { UserSessionLogFind }              from "@moon-maintainer/api/response/user-session-log-find.response";
import { UserSessionTableComponent }       from '@moon-maintainer/user-session/user-session-table/user-session-table.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, UserSessionTableComponent],
  providers: [UserSessionLogController]
})
export class UserSessionComponent implements OnInit {

  public MSUserSessionList: UserSessionLogFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.UserSessionLog.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _userSessionLogController: UserSessionLogController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadUserSessionList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadUserSessionList() {    
    try {
      this.MSLoading = true;
      const findParameter = new FindUserSessionParameter();
      const apiResult: ApiContentResult<UserSessionLogFind[]> = await this._userSessionLogController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSUserSessionList = apiResult.content;
      };
    } finally {
      this.MSLoading = false;
    }
  }
}
