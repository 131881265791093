<div class="flex">
    <!-- filter result component -->
    <div
        *ngIf="MSShowFilter"
        id="moon-doc-filter"
        class="card col-12 md:col-3 mr-2"
        [ngClass]="MSShowFilter ? 'fadeinleft animation-duration-100' : 'hidden'"
    >
        <ms-full-text-filter-result
            [MSResultFilter]="MSSearchResultFilter"
            (MSResultFilterChangedEvent)="MSOnResultFilterChangedEvent($event)"
            (MSResultFilterClearedEvent)="MSOnResultFilterClearedEvent()"
        ></ms-full-text-filter-result>
    </div>

    <!-- display result component -->
    <div
        class="mt-2"
        [ngClass]="MSShowFilter ? 'md:col-9' : 'col-12'"
        id="moon-doc-result-data-view"
    >
        <p-dataView
            #dv
            [value]="MSFilteredSearchChunkList"
            [paginator]="true"
            [rows]="50"
            [showCurrentPageReport]="true"
        >
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-cneter flex-wrap">
                    <div class="grid gap-5 align-items-center">
                        <div class="grid gap-2">
                            <p-inputSwitch
                                inputId="togglefilter"
                                [(ngModel)]="MSShowFilter"
                            ></p-inputSwitch>
                            <label for="togglefilter">{{MSShowFilter ? 'Hide ' : 'Show '}} Filter</label>
                        </div>
                    </div>
                    <div
                        *ngIf="MSFilteredSearchChunkList"
                        class="mr-2"
                    >
                        <span>
                            {{MSShowingOutputContent}}
                            <span
                                *ngIf="MSTimeElapsedForResult"
                                class="text-right text-600"
                            >
                                ({{MSTimeElapsedForResult / 1000 | number: '1.0-3'}}s)
                            </span>
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template
                let-similarChunk
                pTemplate="listItem"
            >
                <div class="col-12">
                    <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                        <div
                            class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                            <div class="flex flex-column align-items-center sm:align-items-start gap-2 w-full">
                                <div
                                    class="flex flex-row-reverse justify-content-between align-items-center flex-wrap w-full">
                                    <div class="align-self-start">
                                        <button
                                            pButton
                                            pRipple
                                            class="p-button-outlined cursor-pointer"
                                            icon="pi pi-eye"
                                            label="{{MSGetLabel(similarChunk)}}"
                                            tooltipPosition="top"
                                            (click)="MSOnClickViewAllChunks(similarChunk)"
                                        ></button>
                                        <button
                                            *ngIf="MSIsCollaborator"
                                            pButton
                                            pRipple
                                            class="p-button-outlined cursor-pointer"
                                            icon="pi pi-cloud-download"
                                            pTooltip="Download Document"
                                            tooltipPosition="top"
                                            (click)="MSOnClickDownloadDocument(similarChunk)"
                                        ></button>
                                    </div>
                                    <div>
                                        <div class="flex gap-2 text-lg font-bold align-items-center">
                                            {{ similarChunk.documentName }}
                                        </div>
                                    </div>
                                </div>

                                <!-- Source Metadata Tag -->
                                <div class="flex align-items-center flex-wrap gap-1">
                                    <span *ngIf="similarChunk.year">
                                        <p-tag value="Year: {{similarChunk.year}}"></p-tag>
                                    </span>
                                    <ng-container *ngFor="let metadata of similarChunk.documentMetaData | keyvalue">
                                        <span *ngIf="metadata.value !== ''">
                                            <p-tag
                                                value="{{metadata.key?.toString()?.getDisplayName()}}: {{metadata.value}}"
                                            ></p-tag>
                                        </span>
                                    </ng-container>
                                    <span *ngIf="similarChunk.environmentName">
                                        <p-tag value="Environment: {{similarChunk.environmentName}}"></p-tag>
                                    </span>
                                </div>
                                <!-- Additional Info -->
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
</div>