<!-- Moon Auto Generated (1.0.0) at 11/27/2023 10:27:34 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
    <div class="grid">
        <div class="col-12 md:col-6 lg:col-4">
            <label for="DateFrom">Date From</label>&nbsp;
            <p-calendar [(ngModel)]="MSDateFrom" dateFormat="mm/dd/yy"></p-calendar>
        </div>
        <div class="col-12 md:col-8 lg:col-5">
            <label for="DateTo">Date To</label>&nbsp;
            <p-calendar [(ngModel)]="MSDateTo" dateFormat="mm/dd/yy"></p-calendar>&nbsp;
            <button
                pButton
                pRipple
                pTooltip="Search"
                tooltipPosition="top"
                type="button"
                icon="pi pi-search fs-medium"
                class="p-button-rounded moon-button"
                (click)="MSOnClickSearch()"
            ></button>
        </div>
    </div>
    <search-log-table [MSPageTitle]="MSPageTitle"
                [MSSearchLogList]="MSSearchLogList"
                [MSLoading]="MSLoading"
                ></search-log-table>
</div>
