// Moon Auto Generated (1.5.0) at 2/13/2024 5:42:29 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { TitleException }       from "@moon-public/api/request/title-exception.request";
import { ConstantString }       from "@moon-shared/constants/constant-string";
import { RoutePath }            from "@moon-shared/constants/route-path";

@Injectable()
export class TitleExceptionController {
  
  private getBaseUrl(): string {
    return this._moonApi.getPublicUrl(ConstantString.TitleException);
  };

  constructor(private _moonApi: MoonApiClient) { }
/**
 *   PUT: Public/v1/TitleException/{titleExceptionID}
 */
  public async Update(titleExceptionID: number, titleException: TitleException
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleExceptionID);
    return this._moonApi.putAsync(url, titleException);
  }

  
  /**
   *   PUT: Public/v1/TitleException/{titleExceptionID}/PromptExceptionTypeAndAction/{testMode}
   */
  public async PromptExceptionTypeAndAction(titleExceptionID: number, testMode: boolean
  ): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleExceptionID, RoutePath.PromptExceptionTypeAndAction, testMode.toString());
    return this._moonApi.putAsync(url, null);
  }
  
  
  /**
   *   PUT: Public/v1/TitleException/{titleExceptionID}/OverrideExceptionType
   */
  public async OverrideExceptionType(titleExceptionID: number, exceptionType: string
  ): Promise<ApiResult> {
    let form = new FormData();
    form.set('exceptionType', exceptionType);
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleExceptionID, RoutePath.OverrideExceptionType);
    return this._moonApi.putAsync(url, form);
  }

  /**
   *   PUT: Public/v1/TitleException/{titleExceptionID}/OverrideRecommendedAction
   */
  public async OverrideRecommendedAction(titleExceptionID: number, recommendedAction: string
  ): Promise<ApiResult> {
    let form = new FormData();
    form.set('recommendedAction', recommendedAction);
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleExceptionID, RoutePath.OverrideRecommendedAction);
    return this._moonApi.putAsync(url, form);
  }

}
