import { UserOptionMapGet }     from "@moon-public/api/response/user-option-map-get.response";
import { DefaultValues }        from "@moon-shared/constants/default-values";
import { BooleanString } from "@moon-shared/types/boolean-string.type";

export type ValidUserOptionValueTypes = string[] | number[] | BooleanString;

export class UserOptions {
    public static ClauseSearchEnvironment = 'ClauseSearchEnvironment' as const;
    public static ClauseSearchDocumentType = 'ClauseSearchDocumentType' as const;
}

export class FlatUserOption {
    public ClauseSearchEnvironment: string[] = [];
    public ClauseSearchDocumentType: number[] = [];
    public ClauseSearchAllowFavorites: BooleanString = DefaultValues.N;

    constructor(userOptionMaps: UserOptionMapGet[]) {

        for (const userOptionMap of userOptionMaps) {

            const optionName = userOptionMap.optionName;
            switch (optionName) {
                // options that requires JSON.parse
                case UserOptions.ClauseSearchDocumentType:
                case UserOptions.ClauseSearchEnvironment: {
                    Object.safelyDefineProperty(this, optionName, JSON.parse(userOptionMap.optionValue));
                    break;
                }
                default:
                    if (optionName in this) {
                        const optionNameStrictType = optionName as keyof this;
                        Object.safelyDefineProperty(this, optionNameStrictType, userOptionMap.optionValue as any);
                    }
            }
        }
    }
}