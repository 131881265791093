import { DecimalPipe, NgIf }                    from '@angular/common';
import { Component }              from '@angular/core';
import { 
  AbstractControl, FormBuilder, 
  FormGroup, FormsModule, 
  ReactiveFormsModule, Validators 
}                                               from '@angular/forms';
import { ActivatedRoute }                       from '@angular/router';
// Third Party Imports
import { SharedModule }                         from 'primeng/api';
import { BadgeModule }                          from 'primeng/badge';
import { ButtonModule }                         from 'primeng/button';
import { CheckboxChangeEvent, CheckboxModule }  from 'primeng/checkbox';
import { RippleModule }                         from 'primeng/ripple';
import { ToolbarModule }                        from 'primeng/toolbar';
// MS Imports
import { AppBreadcrumbService }                 from '@app-layout/services/app.breadcrumb.service';
import { DocumentController }                   from '@moon-collaborator/api/document.controller';
import { CollaboratorRouter }                   from '@moon-collaborator/collaborator.router';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { DocumentUpload }                     from '@moon-collaborator/api/request/document.upload';
import { MoonFormControlComponent }             from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { FieldValues }                          from '@moon-shared/constants/field-values';
import { RouteParameter }                       from '@moon-shared/constants/route-parameter';
import { RoutePath }                            from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }                from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonFileUpload }                       from '@moon-shared/models/moon-file-upload.model';
import { MoonMessageService }                   from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                          from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule, MoonDragDropDirective, MoonFormControlComponent,
    NgIf, CheckboxModule, ButtonModule, RippleModule, ToolbarModule, SharedModule,
    BadgeModule, DecimalPipe, MoonLoadingComponent
  ],
  providers: [
    DocumentController,
    CollaboratorRouter,
  ]
})
export class DocumentUploadComponent {

  public MSUploading: boolean = false;
  public MSTitleSummaryForm: FormGroup<FormGroupOf<DocumentUpload>> = this._formBuilder.group({
    isTest: false,
    inputFile: [null, Validators.required],
    settingsJson: '',
    hasMetadata: 'Y'
  }, this.fileValidator
  );

  private _environmentName: string;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _moonMessageService: MoonMessageService,
    private _componentUtilityService: ComponentUtilityService,
    private _documentController: DocumentController,
    private _collaboratorRouter: CollaboratorRouter,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.readRouteParameters();
    this.setBreadcrumb();
  }

  private readRouteParameters() {
    this._environmentName = this._activatedRoute.snapshot.paramMap.get(RouteParameter.EnvironmentName) ?? String.empty;
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetSimple([this._environmentName, RoutePath.Documents, RoutePath.Upload]);
  }

  public MSOnHasMetadataChanged(event: CheckboxChangeEvent) {
    this.MSTitleSummaryForm.controls.hasMetadata.setValue(event.checked ? 'Y' : 'N');
  }

  public MSHandleFileInput(files: FileList | null) {
    if (!files?.length) return;

    const uploadedFile = files.item(0);

    if (uploadedFile)
      this.updateFileControl(uploadedFile);
  }

  public MSOnFileDrop(file: MoonFileUpload) {
    if (file && file.file.size > 0) {
      this.updateFileControl(file.file);
      return;
    }

    this._moonMessageService.toastInfo(
      "The file you trying to upload is not extracted from zip or may be empty. Please check and try again!"
    );
  }

  public async MSUploadDocument() {
    this.MSTitleSummaryForm.markAllAsTouched();
    this.MSTitleSummaryForm.updateValueAndValidity();

    if (this.MSTitleSummaryForm.invalid)
      return;

    this.MSUploading = true;

    const documentImportForm: DocumentUpload = this.MSTitleSummaryForm.getRawValue() as DocumentUpload;
    if (documentImportForm.inputFile) {

      const apiResult = await this._documentController.ImportAsync(this._environmentName, documentImportForm.inputFile, documentImportForm.hasMetadata);

      if (this._componentUtilityService.WasSuccessfulWithErrorDialog(apiResult)){
        this._moonMessageService.toastSuccess('Document upload successful.');
        this.navigateToDocumentPage();
      }

      this.MSUploading = false;
    }

  }

  public MSCancelUpload() {
    this.MSTitleSummaryForm.reset();
    this.navigateToDocumentPage();
  }

  private navigateToDocumentPage() {
    this._collaboratorRouter.ToDocument(this._environmentName);
  }

  private updateFileControl(file: File) {
    this.MSTitleSummaryForm.controls.inputFile.setValue(file);
    this.MSTitleSummaryForm.markAsDirty();
    this.MSTitleSummaryForm.controls.inputFile.updateValueAndValidity();
    this.MSTitleSummaryForm.updateValueAndValidity();
  }
  public MSClearSelectedFile() {
    this.MSTitleSummaryForm.controls.inputFile.setValue(null);
    this.MSTitleSummaryForm.updateValueAndValidity();
  }

  private fileValidator() {
    return (form: AbstractControl<DocumentUpload>): { [key: string]: any; } | null => {
      const fileControl: AbstractControl<File | null> | null = form.get('inputFile');

      // File empty check
      if (fileControl?.value == null || fileControl.value.size === 0) {
        form.setErrors({ hasEmptyContent: true });
      }
      else {
        // File type check
        if (![FieldValues.FileExtension_ZIP].includes(fileControl.value.type)) {
          fileControl.setErrors({
            invalidFileType: true,
          });
        }
      };
      return null;
    }
  }
  
}
