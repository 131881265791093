<li id="visible">
    {{LoggedUserFullName}}
    <ul id="hidden">
        <li id="details">
            <b>Azure</b><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{LoggedUserFullName}} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{LoggedUserName}}<br /><br />

            <b>Moonstone Web Service</b><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; API: {{MSWebApiName}}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Version: {{MSWebApiVersion}} <br /><br />

            <b>Moonstone Database</b><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Database: {{MSDatabaseName}}<br />
            <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Username: {{moonConnectionDetail?.userName}}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Connected At: {{moonConnectionDetail?.connectedAt | moonDateTimeFormat}}<br /><br /> -->

            <b>Moonstone Web Application</b><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Version: {{getWebApplicationVersion()}}<br />
        </li>
    </ul>
</li>
