<div
    class="mb-1 border-bottom-1 surface-border py-2"
    [ngClass]="MSTitleException.hasError ? 'border-top-1 border-red-500' : 'surface-border'"
>
    <h1 class="flex flex-1 gap-3 mb-2 text-lg align-items-center">
        <div>{{MSTitleException.part}}</div>
        <div>{{MSTitleException.exceptionNumber}}</div>
        <div *ngIf="MSTitleException.processingStatus">
            <p-badge
                severity="warning"
                title="Processing status"
                value=" {{MSTitleException.processingStatus}}"
            ></p-badge>
        </div>
        <div *ngIf="MSTitleException.hasError">
            <p-badge
                severity="danger"
                value="Error"
            ></p-badge>
        </div>

        <div
            class="ml-auto"
            *ngIf="MSTitleException.hasDocument === 'N'"
        >
            <button
                *ngIf="MSTitleException.titleExceptionID"
                type="button"
                pButton
                label="Prompt"
                iconPos="right"
                [disabled]="MSSaving"
                (click)="MSOnClickPromptException()"
            ></button>
            <button
                *ngIf="MSTitleException.titleExceptionID"
                type="button"
                pButton
                label="Log"
                iconPos="right"
                [disabled]="MSSaving"
                (click)="MSOnClickChatLog()"
            ></button>
        </div>
    </h1>
    <div
        class="mb-2"
        *ngIf="MSTitleException.exceptionDescription"
    >
        {{MSTitleException.exceptionDescription}}
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleException.exceptionType"
    >
        <span class="font-bold">Exception Type</span>:
        <div *ngIf="MSEditType; else showType">
            <form
                [formGroup]="MSTypeFormGroup"
                (ngSubmit)="MSSaveExceptionType()"
            >
                <moon-form-control
                    [MSFormControl]="MSTypeFormGroup.controls.exceptionType"
                    [MSLabel]="'Exception Type'"
                    [MSDisplayLabel]="false"
                    [MSInputSize]="12"
                    [MSErrorMessageSize]="12"
                >
                    <input
                        id="exceptionType"
                        #action
                        pInputText
                        [formControl]="MSTypeFormGroup.controls.exceptionType"
                    />
                </moon-form-control>
                <button
                    type="submit"
                    pButton
                    pRipple
                    label="Save"
                    class="p-button-raised mr-2 mb-2"
                    *ngIf="MSTypeFormGroup?.dirty"
                    [disabled]="MSSaving"
                ></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    [disabled]="MSSaving"
                    class="p-button-raised p-button-secondary mr-2 mb-2"
                    (click)="MSToggleTypeEdit()"
                ></button>
            </form>
        </div>
        <ng-template #showType>
            <span
                title="Click to edit"
                class="hover:surface-hover"
                (click)="MSToggleTypeEdit()"
            >{{MSTitleException.overrideExceptionType ?? MSTitleException.exceptionType}}
            </span>
            <i
                class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                (click)="MSToggleTypeEdit()"
            ></i>
            <span
                *ngIf="MSTitleException.overrideExceptionType"
                class="text-gray-400 text-sm"
            >(Edited)
            </span>
        </ng-template>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleException.recommendedAction"
    >
        <span class="font-bold">Recommended Action</span>:
        <div *ngIf="MSEditAction; else showAction">
            <form
                [formGroup]="MSActionFormGroup"
                (ngSubmit)="MSSaveRecommendedAction()"
            >
                <moon-form-control
                    [MSFormControl]="MSActionFormGroup.controls.recommendedAction"
                    [MSLabel]="'Recommended Action'"
                    [MSDisplayLabel]="false"
                    [MSInputSize]="12"
                    [MSErrorMessageSize]="12"
                >
                    <div
                        class="grow-wrap"
                        [attr.textarea-value]="action.value"
                    >
                        <textarea
                            id="recommendedAction"
                            #action
                            pInputTextarea
                            [formControl]="MSActionFormGroup.controls.recommendedAction"
                        ></textarea>
                    </div>
                </moon-form-control>
                <button
                    type="submit"
                    pButton
                    pRipple
                    label="Save"
                    class="p-button-raised mr-2 mb-2"
                    *ngIf="MSActionFormGroup?.dirty"
                    [disabled]="MSSaving"
                ></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    [disabled]="MSSaving"
                    class="p-button-raised p-button-secondary mr-2 mb-2"
                    (click)="MSToggleActionEdit()"
                ></button>
            </form>
        </div>
        <ng-template #showAction>
            <span
                title="Click to edit"
                class="hover:surface-hover"
                (click)="MSToggleActionEdit()"
            >{{MSTitleException.overrideRecommendedAction ?? MSTitleException.recommendedAction}}
            </span>
            <i
                class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                (click)="MSToggleActionEdit()"
            ></i>
            <span
                *ngIf="MSTitleException.overrideRecommendedAction"
                class="text-gray-400 text-sm"
            >(Edited)
            </span>
        </ng-template>
    </div>
    <div
        class="mb-2 white-space-pre-line"
        *ngIf="MSTitleException.logMessage"
    >
        <span class="font-bold">Log Messages</span>:
        <span>{{MSTitleException.logMessage}}</span>
    </div>
</div>

<!-- Chat Log Viewer -->
<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw"
    position="top"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSChatLogID"
    ></moon-chat-log>
</p-dialog>