<div
    class="moon-card"
    id="moon-matching-clause"
>
    <div class="flex flex-column xl:flex-row xl:align-items-start p-1 gap-4">
        <div
            class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div class="flex flex-column align-items-center sm:align-items-start gap-2 w-full">
                <div class="flex align-items-center w-full">
                    <span class="white-space-pre-wrap">

                        <div *ngFor="let item of MSOriginalChunkList">
                            <ms-search-match-text
                                [MSSearchedText]="MSOriginalSearchText"
                                [MSOriginalContent]="item.content"
                                [MSSearchedTextList]="MSContentSearchList"
                            >
                            </ms-search-match-text>
                            <hr />
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>