import { Component, OnDestroy }                 from "@angular/core";
import { CommonModule }                         from "@angular/common";
// Third Party IMports
import { MenuItem }                             from "primeng/api";
import { BreadcrumbModule }                     from "primeng/breadcrumb";
import { ButtonModule }                         from "primeng/button";
import { DialogService }                        from "primeng/dynamicdialog";
import { Subscription }                         from "rxjs";
//Moonstone Imports
import { AppBreadcrumbService }                 from "@app-layout/services/app.breadcrumb.service";
import { RoutePath }                            from "@moon-shared/constants/route-path";
import { HistoryComponent }                     from "@moon-maintainer/history/history.component";
import { HistoryService }                       from "@moon-shared/services/history.service";

@Component({
    selector: "app-breadcrumb",
    templateUrl: "./app.breadcrumb.component.html",
    styleUrls: ["./app.breadcrumb.component.scss"],
    standalone: true,
    imports: [BreadcrumbModule, ButtonModule, CommonModule],
    providers: [DialogService]
})
export class AppBreadcrumbComponent implements OnDestroy {
    subscription: Subscription;
    homeSubscription: Subscription;
    headerName: Subscription;
    items: MenuItem[];
    home: MenuItem;
    constructor(
        public _appBreadcrumbService: AppBreadcrumbService,
        private _dialogService: DialogService,
        private _historyService: HistoryService
    ) {
        this.subscription = _appBreadcrumbService.itemsHandler.subscribe(
            (response) => {
                this.items = response;
            }
        );
        this.homeSubscription = _appBreadcrumbService.homeHandler.subscribe(
            (response) => {
                this.home = response;
            }
        );
        this.home = { icon: "pi pi-home", routerLink: [RoutePath.Empty] };
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.homeSubscription.unsubscribe();
        }
        if(this.headerName) {
            this.headerName.unsubscribe();
        }
    }

    public MSDisplayButton(): boolean {
        return this._historyService.MSShowButton;
    }

    public OnClickHistory(): void {
        let headerTitle: string = "History";
        this.headerName = this._historyService.GetHistoryHeaderName().subscribe( (item) => headerTitle = item ?? headerTitle);
        this._dialogService.open(HistoryComponent, {
            header: headerTitle,
            width: '80%',
            height: '70%',
            // data: {
            //     specificHistory: true
            // }
        })
    }
}