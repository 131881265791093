// Moon Auto Generated (1.5.0) at 2/21/2024 10:18:48 AM
export class RouteParameter {
    public static readonly ChatLogID: string = 'ChatLogID';    
    public static readonly DocumentCategoryID: string = 'DocumentCategoryID';
    public static readonly DocumentID: string = 'DocumentID';
    public static readonly EntityID: string = 'EntityID';
    public static readonly EntityType: string = 'EntityType';
    public static readonly EnvironmentName: string = 'EnvironmentName';
    public static readonly MezzLoanChunkID: string = "MezzLoanChunkID";
    public static readonly MezzLoanID: string = "MezzLoanID";
    public static readonly NodeEnvironmentID: string = 'NodeEnvironmentID';
    public static readonly NodeEnvironmentUserGroupMapID: string = 'NodeEnvironmentUserGroupMapID';
    public static readonly SkillExampleID: string = 'SkillExampleID';
    public static readonly SkillID: string = 'SkillID';
    public static readonly TemplateID: string = "TemplateID";
    public static readonly TitleDocumentID: string = 'TitleDocumentID';
    public static readonly TitleSummaryID: string = 'TitleSummaryID';
    public static readonly UserGroupID: string = "UserGroupID";
    public static readonly UserInfoID = 'UserInfoID' as const;
    public static readonly UserOptionMapID: string = "UserOptionMapID";
    
}
