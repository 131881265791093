// Moon Imports
import { BooleanString } from "@app/moon-shared/types/boolean-string.type";
import { DocumentChunkGet }         from "@moon-collaborator/api/response/document-chunk-get.response";
import { DocumentMetaData }         from "@moon-public/clause-search/api/response/document-metadata.response";
import { SimilarDocumentChunk }     from "@moon-public/clause-search/api/response/similar-document-chunk.response";

export class SimilarChunk {
    environmentName: string;
    documentName: string;
    categoryName: string;
    relevance: number;
    levSimilarity: number;
    diceSimilarity: number; // 0 to 1
    documentChunkID: number;
    documentID: number;
    lastUpdatedBy: string;
    lastUpdatedAtUtc: Date;
    ordinal: number;
    content: string;
    tokenSize: number;
    paragraphID: number | null;
    year: string;
    exactMatch: boolean;
    documentMetaData: DocumentMetaData;
    neighborChunkList: DocumentChunkGet[];
    savedAsFavorite: BooleanString;
    favoriteLastUpdatedBy: string;
    favoriteNotes: string;
    // ui
    totalMatchingChunk: number = 0;
    hideInResult: boolean = false;
    matchingDocuments: {environmentName: string, documentChunkID: number}[] = [];

    constructor(chunk: SimilarDocumentChunk) {
        this.documentName = chunk.documentName;
        this.categoryName = chunk.categoryName;
        this.relevance = chunk.relevance;
        this.levSimilarity = chunk.levSimilarity;
        this.diceSimilarity = chunk.diceSimilarity;
        this.documentChunkID = chunk.documentChunkID;
        this.documentID = chunk.documentID;
        this.lastUpdatedBy = chunk.lastUpdatedBy;
        this.lastUpdatedAtUtc = chunk.lastUpdatedAtUtc;
        this.ordinal = chunk.ordinal;
        this.content = chunk.content;
        this.tokenSize = chunk.tokenSize;
        this.paragraphID = chunk.paragraphID;
        this.savedAsFavorite = chunk.savedAsFavorite;
        this.favoriteLastUpdatedBy = chunk.favoriteLastUpdatedBy;
        this.favoriteNotes = chunk.favoriteNotes;

        // Metadata
        this.year = chunk.documentYear;
        this.documentMetaData = new DocumentMetaData(chunk);
        // Rating form
        // this.ratingForm = defaultSearchRatingFrom
    }
}


// const defaultSearchRatingFrom: FormGroup<FormGroupOf<SearchRating>> = new FormGroup<FormGroupOf<SearchRating>>({
//     documentChunkID: new FormControl(),
//     rating: new FormControl(),
//     searchLogID: new FormControl()
// });