<!-- Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                [MSDisplayRouteBackButton]="true"
                (MSOnClickEventRemove)="OnClickRemove()"
                (MSOnClickEventRouteBack)="MSOnClickGoBack()">
    <ng-container *ngIf="!MSLoading">
        <template-form #MSForm
                       [MSTemplateID]="MSTemplateID"
                       [MSTemplateGet]="MSTemplateGet">
        </template-form>
    </ng-container>
</moon-edit-page>
