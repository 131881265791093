// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
// Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM
// Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM
import { Injectable }   from "@angular/core";
import { Router }       from "@angular/router";
// Moon Imports
import { RoutePath }    from "@moon-shared/constants/route-path";

@Injectable()
export class MaintainerRouter {
    constructor(private _router: Router) { }

    public ToChatLog() {
        this._router.navigate([RoutePath.ChatLog]);
    }

    public ToSearchLog() {
        this._router.navigate([RoutePath.SearchLog]);
    }
    
    // UserInfo Start
    public UserInfoRouteLink(): (string | number)[] {
        return [RoutePath.UserInfo];
    }

    public ToUserInfo(...command: (string | number)[]) {
        this._router.navigate([RoutePath.UserInfo, ...command]);
    }

    public ToUserInfoAdd(): void {
        this.ToUserInfo(RoutePath.Add);
    }

    public ToUserInfoEdit(userInfoID: number) {
        this.ToUserInfo(userInfoID, RoutePath.Edit);
    }
    // UserInfo End
    
    // NodeEnvironmentUserGroupMap Start
    public NodeEnvironmentUserGroupMapRouteLink(nodeEnvironmentID: number) {
        return [RoutePath.NodeEnvironment, nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap];
    }

    private RouteToNodeEnvironmentUserGroupMap(...command: (string | number)[]) {
        this._router.navigate([RoutePath.NodeEnvironment, ...command]);
    }

    public ToNodeEnvironmentUserGroupMap(nodeEnvironmentID: number) {
        this.RouteToNodeEnvironmentUserGroupMap(nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap);
    }

    public ToNodeEnvironmentUserGroupMapAdd(nodeEnvironmentID: number): void {
        this.RouteToNodeEnvironmentUserGroupMap(nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap, RoutePath.Add);
    }

    public ToNodeEnvironmentUserGroupMapEdit(nodeEnvironmentID: number,nodeEnvironmentUserGroupMapID: number) {
        this.RouteToNodeEnvironmentUserGroupMap(nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap, nodeEnvironmentUserGroupMapID, RoutePath.Edit);
    }
    // NodeEnvironmentUserGroupMap End
    
    // NodeEnvironment Start
    public NodeEnvironmentRouteLink() {
        return [RoutePath.NodeEnvironment];
    }

    public ToNodeEnvironment(...command: (string | number)[]) {
        this._router.navigate([RoutePath.NodeEnvironment, ...command]);
    }
    
    public ToNodeEnvironmentAdd(): void {
        this.ToNodeEnvironment(RoutePath.Add);
    }

    public ToNodeEnvironmentEdit(nodeEnvironmentID: number) {
        this.ToNodeEnvironment(nodeEnvironmentID, RoutePath.Edit);
    }
    // NodeEnvironment End

    // Skill Start
    public SkillRouteLink() {
        return [RoutePath.Skill];
    }

    public ToSkill(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Skill, ...command]);
    }
    
    public ToSkillAdd(): void {
        this.ToSkill(RoutePath.Add);
    }

    public ToSkillEdit(skillID: number) {
        this.ToSkill(skillID, RoutePath.Edit);
    }

    public ToSkillTest() {
        this.ToSkill(RoutePath.Test);
    }
    // Skill End

    // SkillExample Start
    public SkillExampleRouteLink(skillID: number) {
        return [RoutePath.Skill, skillID, RoutePath.SkillExample];
    } 

    public ToSkillExample(skillID: number, navigatedFromSkillPage: boolean = false, ...command: (string | number)[]) {
        if (navigatedFromSkillPage) {
            this._router.navigate([RoutePath.Skill, skillID, RoutePath.SkillExample], {
                state: { navigatedFromEdit: true }
            })
            return;
        }
        this.ToSkill(skillID, RoutePath.SkillExample, ...command);
    }
    
    public ToSkillExampleAdd(skillID: number): void {
        this.ToSkillExample(skillID, false, RoutePath.Add);
    }

    public ToSkillExampleEdit(skillID: number, skillExampleID: number) {
        this.ToSkillExample(skillID, false, skillExampleID, RoutePath.Edit);
    }
    // SkillExample End

    // UserGroup Start

    public ToUserGroup(...command: (string | number)[]) {
        this._router.navigate([RoutePath.UserGroup, ...command]);
    }
   
    public ToUserGroupAdd(): void {
        this.ToUserGroup(RoutePath.Add);
    }

    public ToUserGroupEdit(userInfoID: number) {
        this.ToUserGroup(userInfoID, RoutePath.Edit);
    }

    // UserGroup End

    
    // Template Start
    public RouteToTemplate() {
        return [RoutePath.Template];
    }

    public ToTemplate(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Template, ...command]);
    }
    
    public RouteToTemplateCreate(): void {
        this.ToTemplate(RoutePath.Add);
    }

    public RouteToTemplateEdit(templateID: number) {
        this.ToTemplate(templateID, RoutePath.Edit);
    }
    // Template End

}
