<!-- Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                (MSOnClickEventRemove)="OnClickRemove()">
    <ng-container *ngIf="!MSLoading">
        <node-environment-user-group-map-form #MSForm
                       [MSNodeEnvironmentUserGroupMapID]="MSNodeEnvironmentUserGroupMapID"
                       [MSNodeEnvironmentUserGroupMapGet]="MSNodeEnvironmentUserGroupMapGet"
                       [MSNodeEnvironmentUserGroupMapList]="MSNodeEnvironmentUserGroupMapList">
        </node-environment-user-group-map-form>
    </ng-container>
</moon-edit-page>
