import { KeyValuePipe, NgFor, NgIf }              from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule }       from '@angular/forms';
// Third party imports
import { ButtonModule }                           from 'primeng/button';
import { CheckboxModule }                         from 'primeng/checkbox';
import { RippleModule }                           from 'primeng/ripple';
import { SliderModule }                           from 'primeng/slider';
// Moon Imports
import { FilterItemComponent }                    from '@moon-public/clause-search/filter-result/filter-item/filter-item.component';
import { SearchResultFilter }                     from '@moon-public/clause-search/models/search-result-filter';
import { MoonFormControlComponent }               from '@moon-shared/components/moon-form-control/moon-form-control.component';
@Component({
    selector: 'ms-filter-result',
    templateUrl: './filter-result.component.html',
    styleUrls: ['./filter-result.component.scss'],
    standalone: true,
    imports: [
      NgIf, ButtonModule, RippleModule, NgFor,
      CheckboxModule, FormsModule, ReactiveFormsModule,
      MoonFormControlComponent, SliderModule, KeyValuePipe,
      FilterItemComponent
    ]
})
export class FilterResultComponent {

  @Input() public MSResultFilter: SearchResultFilter;
  @Output() public MSResultFilterChangedEvent = new EventEmitter<SearchResultFilter>();
  @Output() public MSResultFilterClearedEvent = new EventEmitter<undefined>();

  public MSOnFilterChanged(): void {
    this.MSResultFilterChangedEvent.emit(this.MSResultFilter);
  }
  public MSOnFilterCleared(): void {
    this.MSResultFilterChangedEvent.emit();
  }
}
