// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { Component, Input, OnInit }         from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule }  from '@angular/forms';
// Third Party Imports
import { SharedModule }                     from 'primeng/api';
import { InputTextModule }                  from 'primeng/inputtext';
// Moon Imports
import { IApiResult }                       from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { MezzLoan }                         from '@moon-public/api/request/mezz-loan.request';
import { MezzLoanGet }                      from "@moon-public/api/response/mezz-loan-get.response";
import { MezzLoanController }               from '@moon-public/api/mezz-loan.controller';
import { PublicRouter }                     from "@moon-public/public.router";
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';

@Component({
    selector: 'mezz-loan-form',
    templateUrl: './mezz-loan-form.component.html',
    styleUrls: ['./mezz-loan-form.component.css'],
    standalone: true,
    imports: [ FormsModule, ReactiveFormsModule, SharedModule,
               MoonFormPageComponent, MoonFormControlComponent,
               InputTextModule
            ],
    providers: [MezzLoanController]
})
export class MezzLoanFormComponent implements OnInit {
    @Input() public MSMezzLoanGet: MezzLoanGet;
    @Input() public MSMezzLoanID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<MezzLoan>> = new FormGroup<FormGroupOf<MezzLoan>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		fileName: new FormControl('', {nonNullable: true }),
		processingStatus: new FormControl('', {nonNullable: true }),
    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _mezzLoanController: MezzLoanController,
        private _componentUtilityService: ComponentUtilityService,
        private _publicRouter: PublicRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSMezzLoanGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {
    }

    private doMapGetDataToFormControl(mezzLoan: MezzLoanGet) {
        if (!mezzLoan) {
            return;
        }        
        this.MSMezzLoanGet = mezzLoan;
        this.MSParentForm.patchValue(mezzLoan);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSMezzLoanID
            ? await this._mezzLoanController.Update(this.MSMezzLoanID, this.MSParentForm.getRawValue())
            : await this._mezzLoanController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        };
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._publicRouter.RouteToMezzLoan();
    }
}
