// Moon Auto Generated (1.0.0) at 11/22/2023 10:52:50 AM
import { NgIf, NgStyle }                                from '@angular/common';
import { Component, OnInit, ViewChild }                 from '@angular/core';
import { ActivatedRoute }                               from '@angular/router';
// Third party imports
import { ConfirmationService, PrimeIcons }              from 'primeng/api';
import { ButtonModule }                                 from 'primeng/button';
import { DialogService }                                from 'primeng/dynamicdialog';
import { ProgressBarModule }                            from 'primeng/progressbar';
import { RippleModule }                                 from 'primeng/ripple';
import { TableModule }                                  from 'primeng/table';
import { ToolbarModule }                                from 'primeng/toolbar';
import { TooltipModule }                                from 'primeng/tooltip';
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
// Moon Imports
import { AppBreadcrumbService }                         from '@app-layout/services/app.breadcrumb.service';
import { DocumentController }                           from '@moon-collaborator/api/document.controller';
import { FindDocumentParameter }                        from '@moon-collaborator/api/request/find-document.parameter';
import { DocumentFind }                                 from "@moon-collaborator/api/response/document-find.response";
import { DocumentGet }                                  from '@moon-collaborator/api/response/document-get.response';
import { CollaboratorRouter }                           from '@moon-collaborator/collaborator.router';
import { DocumentTableComponent }                       from '@moon-collaborator/document/document-table/document-table.component';
import { ImportDocumentMetadataModalComponent }         from '@moon-collaborator/document/modal/import-document-metadata-modal/import-document-metadata-modal.component';
import { ApiBlobResult, ApiContentResult, ApiResult }   from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';
import { FormStickyNavbarComponent }                    from '@moon-shared/components/form-sticky-navbar/form-sticky-navbar.component';
import { MoonCustomDropdownComponent }                  from '@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component';
import { MoonLoadingComponent }                         from '@moon-shared/components/moon-loading/moon-loading.component';
import { SearchFieldComponent }                         from '@moon-shared/components/search-field/search-field.component';
import { DefaultValues }                                from '@moon-shared/constants/default-values';
import { MessageSeverity }                              from '@moon-shared/constants/message-severity';
import { RouteParameter }                               from '@moon-shared/constants/route-parameter';
import { RoutePath }                                    from '@moon-shared/constants/route-path';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';

@Component({
  selector: 'moon-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    ToolbarModule,
    ButtonModule,
    RippleModule,
    TableModule,
    TooltipModule,
    ProgressBarModule,
    FileUploadModule,
    MoonLoadingComponent,
    DocumentTableComponent,
    FormStickyNavbarComponent,
    SearchFieldComponent,
    MoonCustomDropdownComponent,
    ImportDocumentMetadataModalComponent,
  ],
  providers: [
    DialogService,
    DocumentController,
    CollaboratorRouter,
  ],
})
export class DocumentComponent implements OnInit {
  public MSDocumentList: DocumentFind[];
  public MSLoading: boolean = true;
  public MSPageTitle: string = 'Document';
  public MSEnvironmentName: string = String.empty;
  public MSSearchTerm: string;
  public MSIsProcessRunning: boolean;
  public MSCurrentProcessMessage: string;
  public MSDownloadInProgress: boolean = false;
  public MSDownloadInProgressZip: boolean = false;
  @ViewChild('MSDocumentTable') MSDocumentTable: DocumentTableComponent;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _moonMessageService: MoonMessageService,
    private _collaboratorRouter: CollaboratorRouter,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _documentController: DocumentController,
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.readRouteParameters();
    this.loadDocumentList();
    this.setBreadcrumb();
  }

  public get MSIsProcessInProgress(): boolean {
    return (this.MSIsProcessRunning || this.MSDocumentTable?.MSSaving) ?? false;
  }

  public get MSProcessMessage(): string {
    return this.MSCurrentProcessMessage ?? String.empty;
  }

  private readRouteParameters() {
    this.MSEnvironmentName =
      this._activatedRoute.snapshot.paramMap.get(RouteParameter.EnvironmentName) ??
      String.empty;
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.setItems([
      { label: `${this.MSEnvironmentName}`, routerLink: [`${RoutePath.Environment}`] },
      { label: `${RoutePath.Documents}`, routerLink: null }
    ]);
  }

  private async loadDocumentList() {
    this.MSLoading = true;

    const findParameter = new FindDocumentParameter();
    const apiResult: ApiContentResult<DocumentFind[]> =
      await this._documentController.Find(
        this.MSEnvironmentName,
        findParameter
      );
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.onGetListSuccess(apiResult.content);
    }
    this.MSLoading = false;
  }

  private onGetListSuccess(documentCategoryList: DocumentFind[]) {
    if (documentCategoryList === null) {
      return;
    }

    this.MSDocumentList = documentCategoryList.map((documentCategory: DocumentFind) => {
      documentCategory.actions = this.getActions(this.MSEnvironmentName, documentCategory);
      return documentCategory;
    });
  }
  public MSOnChangeSearchField(name: string | any): void {
    this.MSSearchTerm = name;
  }

  public MSOnRefreshClick() {
    this.loadDocumentList();
  }

  public MSOnClickGoBack() {
    this._collaboratorRouter.ToEnvironment();
  }

  public async MSOnClickDocumentExport() {
    this.MSDownloadInProgressZip = true;
    this._moonMessageService.showToastMessage(MessageSeverity.Info, "Exporting. Please wait...");
    try {
      const apiResult: ApiBlobResult = await this._documentController.ExportAsync(this.MSEnvironmentName);

      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Exported successfully.");
      }
    }
    finally {
      this.MSDownloadInProgressZip = false;
    }
  }

  public async MSOnClickMetadataExport() {
    this.MSDownloadInProgress = true;
    this._moonMessageService.showToastMessage(MessageSeverity.Info, "Exporting. Please wait...");
    try {
      const apiResult: ApiBlobResult = await this._documentController.MetadataExportAsync(this.MSEnvironmentName);

      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Exported successfully.");
      }
    }
    finally {
      this.MSDownloadInProgress = false;
    }
  }

  public async MSOnClickMetadataImport(event: FileUploadHandlerEvent, fileUpload: FileUpload) {
    if (event.files.length > 0 && event.files[0]){
      try {
        const apiResult = await this._documentController.MetadataImportAsync(this.MSEnvironmentName, event.files[0]);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Metadata upload successful.');
          this.loadDocumentList();
        }
      }
      finally {
        fileUpload.clear();
      }
    }
  }

  public async MSOnClickVectorAsCSV() {
    if (!this.MSEnvironmentName) {
      this._moonMessageService.toastInfo("No environment selected");
      this._collaboratorRouter.ToEnvironment();
      return;
    }

    if (this.selectedDocumentItems.isEmpty()) {
      this._moonMessageService.toastInfo("No documents selected");
      return;
    }
    
    this._moonMessageService.toastInfo('Vectorization started.');

    const apiErrors: ApiResult[] = [];

    for (const document of this.selectedDocumentItems) {
      const apiResult = await this._documentController.VectorizeAsync(
        this.MSEnvironmentName,
        document.documentID
      );
      if (!apiResult.success) {
        apiErrors.push(apiResult);
      }
    }

    if (apiErrors.length) {
      const singleApiError = apiErrors.reduce((a, b) => {
        a.apiError.error.concat('\n' + b.apiError.error);
        return a;
      });
      this._componentUtilityService.WasSuccessful(singleApiError);
    } else {
      this._moonMessageService.toastSuccess('Document Vector completed');
      this.loadDocumentList();
    }
  }

  public async MSOnClickChunk() {

    if (!this.MSEnvironmentName) {
      this._moonMessageService.toastInfo("No environment selected");
      this._collaboratorRouter.ToEnvironment();
      return;
    }

    if (this.selectedDocumentItems.isEmpty()) {
      this._moonMessageService.toastInfo("No documents selected");
      return;
    }

    const isUnchunkedDocumentSelected = this.selectedDocumentItems.some(doc => DefaultValues.N.equalsIgnoreCase(doc.isChunked));
    if (isUnchunkedDocumentSelected === false) {
      this._moonMessageService.toastInfo("Selected documents are already chunked.");
      return;
    }

    const selectedUnchunkedDocuments: DocumentGet[] = this.selectedDocumentItems.filter(doc => DefaultValues.N.equalsIgnoreCase(doc.isChunked));

    this.MSCurrentProcessMessage = 'Processing. Please wait.';
    this.MSIsProcessRunning = true;

    const apiResults: { result: ApiResult, documentName: string }[] = [];
    let currentDocument: number = 0;
    const totalDocuments: number = selectedUnchunkedDocuments.length;

    try {
      for (const document of selectedUnchunkedDocuments) {
        this.updateProcessMessage(++currentDocument, totalDocuments, document.documentName);

        const apiResult = await this.chunkDocument(this.MSEnvironmentName, document.documentID);

        if (!apiResult.success) {
          apiResults.push({ result: apiResult, documentName: document.documentName });
        }
      }

      this.handleChunkingResults(apiResults);
    } finally {
      this.MSIsProcessRunning = false;
      this.loadDocumentList();
    }
  }

  private updateProcessMessage(currentDocument: number, totalDocuments: number, documentName: string): void {
    this.MSCurrentProcessMessage = `${currentDocument} of ${totalDocuments}. Chunking ${documentName}`;
  }

  private async chunkDocument(environmentName: string, documentID: number): Promise<ApiResult> {
    return this._documentController.ChunkAsync(environmentName, documentID);
  }

  private handleChunkingResults(apiResults: { result: ApiResult, documentName: string }[]): void {
    const singleApiError = this.combineApiErrors(apiResults);
    if (this._componentUtilityService.WasSuccessful(singleApiError))
      this._moonMessageService.toastSuccess('Document chunk completed');

  }

  private combineApiErrors(apiResults: { result: ApiResult, documentName: string }[]): ApiResult {
    let combinedResult = apiResults.reduce((combinedResult, currentResult) => {
      let error = combinedResult.result.apiError.error;
      if (typeof error === 'string') {
        error = combinedResult.documentName + ': ' + error;
        error += '\n' + currentResult.result.apiError.error;
      }
      else if (typeof error === 'object' && error.hasOwnProperty('detail')) {
        error.detail = combinedResult.documentName + ': ' + error.detail;
        error.detail += '\n' + currentResult.documentName + ': ' + currentResult.result.apiError.error.detail;
      }
      // Else show the first error
      return combinedResult;
    });
    return combinedResult.result;
  }


  public MSOnClickUpdateAll() {
    this._collaboratorRouter.ToDocumentUpdateAll(this.MSEnvironmentName);
  }

  public MSOnClickDocumentCategory() {
    this._collaboratorRouter.ToDocumentCategory(this.MSEnvironmentName);
  }

  public MSOnClickUploadDocument(): void {
    this._collaboratorRouter.ToDocumentUpload(this.MSEnvironmentName);
  }

  private get selectedDocumentItems(): DocumentGet[] {
    return this.MSDocumentTable?.MSSelectedDocuments;
  }

  public get MSDoDisableActionButton(): boolean {
    return (
      !(this.selectedDocumentItems && this.selectedDocumentItems.length > 0) ||
      this.MSIsProcessInProgress
    );
  }


  private getActions(environmentName: string, document: DocumentFind) {
    return [
      {
        icon: PrimeIcons.PENCIL,
        label: 'Document Chunk',
        command: () => {
          this._collaboratorRouter.ToDocumentEdit(environmentName, document.documentID);
        }
      },
      {
        icon: PrimeIcons.TRASH,
        label: 'Delete Item',
        command: () => {
          this._confirmationService.confirm({
            message: `Are you sure you want to remove ${document.documentName}`,
            accept: () => {
              this.doRemove(environmentName, document.documentID);
            },
          });

        }
      }
    ];
  }

  private async doRemove(environmentName: string, documentCategoryID: number) {
    const apiResult: ApiResult = await this._documentController.Delete(environmentName, documentCategoryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Removed Successfully.")
      this.loadDocumentList();
    }
  }
}
