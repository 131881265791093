<moon-loading *ngIf="MSLoading"></moon-loading>
<div
    class="card moon-card-div"
    *ngIf="!MSLoading"
>
    <div styleClass="p-card-shadow">
        <document-batch-operation-table
            #MSTableComponent
            [MSEnvironmentName]="MSEnvironmentName"
            [MSItems]="MSItems"
            [ngClass]="{
                'update-precedence': MSDisableTable
            }"
        ></document-batch-operation-table>
        <div class="moon-button-div">
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                icon="pi pi-pencil"
                [disabled]="MSIsProcessInProgress"
                class="p-button-raised mr-2 mb-2"
                (click)="MSOnClickEdit()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Download Word"
                icon="pi pi-file-word"
                [disabled]="MSIsProcessInProgress"
                (click)="MSOnClickDownloadWord()"
                class="p-button-raised mr-2 mb-2"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Remove"
                icon="pi pi-trash"
                [disabled]="MSIsProcessInProgress"
                class="p-button-raised mr-2 mb-2"
                (click)="MSOnClickRemove($event)"
            ></button>
            <p-toast></p-toast>
            <p-confirmPopup
                key="MSDeleteConfirm"
                [style]="{width: '25vw'}"
            ></p-confirmPopup>
        </div>
    </div>
</div>
