import { Injectable }   from "@angular/core";
import { Router }       from "@angular/router";
// Moon Imports
import { RoutePath }    from "@moon-shared/constants/route-path";

@Injectable()
export class CollaboratorRouter {
    constructor(private _router: Router) { }
    
    // Environment Start
    public EnvironmentRouteLink() {
        return [RoutePath.Environment];
    }

    public ToEnvironment() {
        this._router.navigate(this.EnvironmentRouteLink());
    }
    // Environment End 

    // Document Start
    public DocumentRouteLink(environmentName: string): (string | number)[] {
        return [RoutePath.Environment, environmentName, RoutePath.Documents];
    }

    public ToDocument(environmentName: string, ...command: (string | number)[]) {
        this._router.navigate([RoutePath.Environment, environmentName, RoutePath.Documents, ...command]);
    }
    
    public ToDocumentUpload(environmentName: string): void {
        this.ToDocument(environmentName, RoutePath.Upload);
    }

    public ToDocumentUpdateAll(environmentName: string): void {
        this.ToDocument(environmentName, RoutePath.UpdateAll);
    }

    public ToDocumentEdit(environmentName: string, documentID: number) {
        this.ToDocument(environmentName, documentID, RoutePath.Chunk);
    }
    // Document End
    
    // DocumentCategory Start
    public DocumentCategoryRouteLink(environmentName: string): (string | number)[] {
        return [RoutePath.Environment, environmentName, RoutePath.DocumentCategory];
    }

    public ToDocumentCategory(environmentName: string, ...command: (string | number)[]) {
        this._router.navigate([RoutePath.Environment, environmentName, RoutePath.DocumentCategory, ...command]);
    }
    
    public ToDocumentCategoryAdd(environmentName: string): void {
        this.ToDocumentCategory(environmentName, RoutePath.Add);
    }

    public ToDocumentCategoryEdit(environmentName: string, documentCategoryID: number) {
        this.ToDocumentCategory(environmentName, documentCategoryID, RoutePath.Edit);
    }
    // DocumentCategory End
    
}
