<!-- Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSMezzLoanChunkList"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplayFindButton]="MSDisplayFind"
    [MSDisplayRouteBackButton]="true"
    (MSEventOnClickRouteBack)="MSOnClickGoBack()"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [rowHover]="true"
        [value]="MSMezzLoanChunkList"
        selectionMode="single"
        [loading]="MSLoading"
        styleClass="p-datatable-gridlines p-datatable-striped"
        [scrollable]="true"
        [scrollHeight]="MSScrollHeight"
        [(selection)]="MSSelectedItems"
    >
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="MSDisplayCreate"
                [MSDisplayFindButton]="MSDisplayFind"
                (MSEventOnSearchInput)="OnSearchInput($event)"
                [MSDisplayRouteBackButton]="MSDisplayRouteBack"
                (MSEventOnClickRouteBack)="MSOnClickGoBack()"
            >
                <ng-template moonTemplate="filter">
                    <div class="flex align-items-center gap-2 mr-2">
                        <label for="row-selected">Select Rows: </label>
                        <p-dropdown
                            class="align-self-baseline"
                            inputId="row-selected"
                            [options]="MSSelectRowNumberOptions"
                            (onChange)="MSOnSelectRowNumberChanged($event)"
                        ></p-dropdown>
                    </div>
                </ng-template>
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    [style.flex-basis]="'auto'"
                    class="moon-col-checkbox"
                >
                    <p-tableHeaderCheckbox [disabled]="MSIsProcessInProgress">
                    </p-tableHeaderCheckbox>
                </th>

                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                    [pSortableColumn]="col.field"
                >
                    <div [ngSwitch]="col.field">
                        <span *ngSwitchDefault>
                            {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                        </span>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td
                    [style.flex-basis]="'auto'"
                    class="moon-col-checkbox"
                >
                    <p-tableCheckbox
                        [disabled]="MSIsProcessInProgress"
                        [value]="rowData"
                    ></p-tableCheckbox>
                </td>
                <td
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions'"
                >
                    <div [ngSwitch]="col.field">
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>