// Moon Auto Generated (1.0.0) at 11/29/2023 2:05:56 PM
import { NgIf }                             from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }                from '@angular/core';
import { ActivatedRoute }                   from '@angular/router';
// Moon Imports
import { AppBreadcrumbService }             from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }      from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { NodeEnvironmentGet }               from '@moon-maintainer/api/response/node-environment-get.response';
import { NodeEnvironmentController }        from '@moon-maintainer/api/node-environment.controller';
import { NodeEnvironmentFormComponent }     from '@moon-maintainer/node-environment/node-environment-form/node-environment-form.component';
import { NodeEnvironmentTableComponent }    from '@moon-maintainer/node-environment/node-environment-table/node-environment-table.component';
import { MoonLoadingComponent }             from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { MaintainerRouter }                 from '@moon-maintainer/maintainer.router';
import { RouteParameter }                   from '@app/moon-shared/constants/route-parameter';

@Component({
    selector: 'node-environment-add-page',
    templateUrl: './node-environment-edit-page.component.html',
    styleUrls: ['./node-environment-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, NodeEnvironmentTableComponent, NodeEnvironmentFormComponent],
    providers: [NodeEnvironmentController, MaintainerRouter]
})
export class NodeEnvironmentEditPageComponent implements OnInit {
  public MSNodeEnvironmentID: number | null;
  public MSNodeEnvironmentGet: NodeEnvironmentGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.NodeEnvironment.getDisplayName();
  public MSEnvironmentName = String.empty;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _nodeEnvironmentController: NodeEnvironmentController,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSNodeEnvironmentID){
      await this.getNodeEnvironmentByID(this.MSNodeEnvironmentID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public MSOnClickRemove(): void {
    if (this.MSNodeEnvironmentID){
      this.doRemove(this.MSNodeEnvironmentID);
    }
  }

  public MSOnClickRouteBack() {
    this._maintainerRouter.ToNodeEnvironment();
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSNodeEnvironmentID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(this.MSPageTitle, ConstantString.NodeEnvironment, this.MSNodeEnvironmentID);
  }

  private readRouteParameters() {
    const nodeEnvironmentID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.NodeEnvironmentID);
    this.MSNodeEnvironmentID = nodeEnvironmentID ? +nodeEnvironmentID : null;
  }

  private async getNodeEnvironmentByID(nodeEnvironmentID: number) {
    if (nodeEnvironmentID) {
      const apiResult: ApiContentResult<NodeEnvironmentGet> = await this._nodeEnvironmentController.GetByID(nodeEnvironmentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSNodeEnvironmentGet = apiResult.content;
      }
    }
  }

  private async doRemove(nodeEnvironmentID: number) {
    const apiResult: ApiResult = await this._nodeEnvironmentController.Delete(nodeEnvironmentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToNodeEnvironment();
    }
  }
}
