// Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM
import { Component, Input, OnInit }         from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule, Validators }  from '@angular/forms';
// Third Party Imports
import { SharedModule }                     from 'primeng/api';
import {InputTextModule}                    from 'primeng/inputtext';
// Moon Imports
import { IApiResult }                       from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { UserGroup }                        from '@moon-maintainer/api/request/user-group.request';
import { UserGroupGet }                     from "@moon-maintainer/api/response/user-group-get.response";
import { UserGroupController }              from '@moon-maintainer/api/user-group.controller';
import { MaintainerRouter }                 from "@moon-maintainer/maintainer.router";
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';

@Component({
    selector: 'user-group-form',
    templateUrl: './user-group-form.component.html',
    styleUrls: ['./user-group-form.component.css'],
    standalone: true,
    imports: [ FormsModule, ReactiveFormsModule, SharedModule,
               MoonFormPageComponent, MoonFormControlComponent, InputTextModule],
    providers: [UserGroupController]
})
export class UserGroupFormComponent implements OnInit {
    @Input() public MSUserGroupGet: UserGroupGet;
    @Input() public MSUserGroupID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<UserGroup>> = new FormGroup<FormGroupOf<UserGroup>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		userGroupName: new FormControl('', {nonNullable: true }),
		externalGroupID: new FormControl(null, [Validators.required]),

    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _userGroupController: UserGroupController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSUserGroupGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        this.MSParentForm.markAllAsTouched();
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {
    }

    private doMapGetDataToFormControl(userGroup: UserGroupGet) {
        if (!userGroup) {
            return;
        }        
        this.MSUserGroupGet = userGroup;
        this.MSParentForm.patchValue(userGroup);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSUserGroupID
            ? await this._userGroupController.Update(this.MSUserGroupID, this.MSParentForm.getRawValue())
            : await this._userGroupController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        };
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToUserGroup();
    }
}
