import { MoonApiErrorResponse } from "../moon-errors/moon-api-error-response";

export interface IApiResult {
    success: boolean;
    message: string;
    apiError: MoonApiErrorResponse | Promise<MoonApiErrorResponse>;
}

export class ApiResult implements IApiResult {
    success: boolean = false;
    message: string;
    apiError: MoonApiErrorResponse;
}

export class ApiContentResult<T> extends ApiResult {
    content: T;
}

export class ApiBlobResult implements IApiResult {
    success: boolean = false;
    message: string;
    apiError: Promise<MoonApiErrorResponse>;
    content: Blob;
    contentDisposition: string;
}
