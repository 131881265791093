// MS Imports
import { MoonAppConfigService }     from '@moon-core/services/moon-app-config.service';
import { MoonApplicationInsight }   from '@moon-core/services/moon-application-insight.service';

export function appInitializerFn(
    moonAppConfigService: MoonAppConfigService,
    moonApplicationInsight: MoonApplicationInsight
    ) {
    
    const promise = moonAppConfigService.LoadAppConfig().then(() => {
        moonApplicationInsight.Initialize(moonAppConfigService.GetAppInsightsInstrumentationKey());
    });
    return () => promise;
}