// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import {
    DatePipe,
    DecimalPipe,
    NgClass, NgFor, NgIf, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                           from "@angular/common";
import {
    AfterContentInit,
    Component, EventEmitter, Input, OnInit,
    Output,
    ViewChild, ViewEncapsulation
}                                           from "@angular/core";
// Third Party imports
import { ButtonModule }                     from "primeng/button";
import { DropdownChangeEvent, DropdownModule } from "primeng/dropdown";
import { InputTextModule }                  from "primeng/inputtext";
import { Table, TableModule }               from "primeng/table";
// Moon imports
import { UserSessionService }               from "@moon-core/services/user-session.service";
import { TitleSummaryFind }                 from "@moon-public/api/response/title-summary-find.response";
import { TitleSummaryGet }                  from "@moon-public/api/response/title-summary-get.response";
import { PublicRouter }                     from "@moon-public/public.router";
import { TitleSummaryStatus }               from "@moon-public/title-summary/models/processing-status";
import { MoonCustomDropdownComponent }      from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }             from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }         from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }           from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { MoonTemplateDirective }            from "@moon-shared/directives/moon-template.directive";
import { TableColumn }                      from '@moon-shared/types/table-column.type';

@Component({
    selector: "title-summary-table",
    templateUrl: "./title-summary-table.component.html",
    styleUrls: ['./title-summary-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, DatePipe, DecimalPipe,
        TableModule, ButtonModule, InputTextModule, DropdownModule,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonLoadingComponent, MoonTemplateDirective],
    providers: [PublicRouter]
})
export class TitleSummaryTableComponent implements OnInit, AfterContentInit {
    @Input() public MSPageTitle: string;
    @Input() public MSTitleSummaryList: TitleSummaryFind[];
    @Input() public MSLoading = false;
    @Output() public MSOnEmitReload = new EventEmitter();
    public MSStatusFilterOptions: string[] = [TitleSummaryStatus.OPEN, TitleSummaryStatus.CLOSED, "ALL"];
    public MSTableColumns: TableColumn[] = [
        { field: FieldNames.MatterName.toCamelCase(), header: FieldNames.MatterName.getDisplayName(), class: 'w-20per', },
        { field: FieldNames.SummaryType.toCamelCase(), header: FieldNames.SummaryType.getDisplayName(), class: 'w-15per' },
        { field: FieldNames.Status.toCamelCase(), header: FieldNames.Status.getDisplayName(), class: 'w-5per' },
        { field: FieldNames.FileName.toCamelCase(), header: FieldNames.FileName.getDisplayName(), class: 'w-15per' },
        { field: FieldNames.FileSize.toCamelCase(), header: FieldNames.FileSize.getDisplayName(), class: 'w-5per', sortable: true },
        { field: FieldNames.TotalCost.toCamelCase(), header: FieldNames.TotalCost.getDisplayName(), class: 'w-5per' },
        { field: FieldNames.SystemMessage.toCamelCase(), header: FieldNames.SystemMessage.getDisplayName(), class: 'w-20per' },
        {
            field: FieldNames.ExceptionPagesCsv.toCamelCase(),
            header: FieldNames.ExceptionPagesCsv.replace("Csv", String.empty).getDisplayName(),
            class: 'w-10per'
        },
        { field: FieldNames.LastUpdatedBy.toCamelCase(), header: FieldNames.LastUpdatedBy.getDisplayName(), class: 'w-10per' },
        //{ field: FieldNames.LastUpdatedAtUtc.toCamelCase(), header: FieldNames.LastUpdatedAtUtc.getDisplayName(), class: 'w-15per' },
        {
            field: FieldNames.LastUpdatedAt.toCamelCase(),
            header: FieldNames.LastUpdatedAt.replace("Local", String.empty).getDisplayName(),
            class: 'w-10per',
            sortable: true
        },

        // Column PlaceHolder - DO NOT REMOVE
        { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-5per' }
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplaySecondary: boolean;

    @ViewChild("MSTable") MSTable: Table;

    constructor(
        private _publicRouter: PublicRouter,
        private _userSessionService: UserSessionService
    ) {
        this.removeColumnsForNormalUser();
    }
    private removeColumnsForNormalUser() {
        if (this._userSessionService.IsMaintainer() === false) {
            const excludedFields = [
                FieldNames.FileName.toCamelCase(),
                FieldNames.FileSize.toCamelCase(),
                FieldNames.TotalCost.toCamelCase(),
                FieldNames.SystemMessage.toCamelCase()
            ];
            this.MSTableColumns = this.MSTableColumns.filter(a => !excludedFields.includes(a.field));
        }
    }

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
        this.MSDisplaySecondary = true;    
    }

    ngAfterContentInit (): void {
        this.applyDefaultStatusFilter();
    }

    public MSOnStatusFilterChanged($event: DropdownChangeEvent) {
        let filterStatusValue = $event.value;
        if (TitleSummaryStatus.CLOSED.equalsIgnoreCase(filterStatusValue)) {
            this.MSTable.filteredValue = this.MSTitleSummaryList.filter(a => TitleSummaryStatus.CLOSED.equalsIgnoreCase(a.status));
        }
        else if ("All".equalsIgnoreCase(filterStatusValue)) {
            this.MSTable.filteredValue = this.MSTitleSummaryList;
        }
        else {
            this.applyDefaultStatusFilter();
        }
    }
    public MSOnClickSecondaryButton() {
        this.MSOnEmitReload.emit();
    }
    public OnClickItem(item: TitleSummaryGet): void {
        this._publicRouter.RouteToTitleSummaryDetail(item.titleSummaryID);
    }

    public OnClickCreate(): void {
        this._publicRouter.RouteToTitleSummaryAdd();
    }

    public OnClickFind(): void {
        this._publicRouter.RouteToTitleSummary();
    }
    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }

    public MSIsTitleSummaryProcessing(status: string): any {
       return TitleSummaryStatus.PROCESSING.equalsIgnoreCase(status);
    } 
    private applyDefaultStatusFilter() {
        if(!this.MSTable) return;
        this.MSTable.filteredValue = this.MSTitleSummaryList.filter(a => !TitleSummaryStatus.CLOSED.equalsIgnoreCase(a.status));
    }
}
