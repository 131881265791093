// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import {
    NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                       from "@angular/common";
import {
    Component, Input, OnInit,
    ViewEncapsulation
}                                       from "@angular/core";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { UserInfoFind }                 from "@moon-maintainer/api/response/user-info-find.response";
import { UserInfoGet }                  from "@moon-maintainer/api/response/user-info-get.response";
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type';

@Component({
    selector: "user-info-table",
    templateUrl: "./user-info-table.component.html",
    styleUrls: ['./user-info-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
              TableModule, ButtonModule, InputTextModule,
              MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter]
})
export class UserInfoTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSUserInfoList: UserInfoFind[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.ObjectIdentifier.toCamelCase(), header: FieldNames.ObjectIdentifier.getDisplayName(), class: 'w-20per'},
		{field: FieldNames.PreferredUserName.toCamelCase(), header: FieldNames.PreferredUserName.getDisplayName(), class: 'w-20per'},
		{field: FieldNames.UserStatus.toCamelCase(), header: FieldNames.UserStatus.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.RoleCode.toCamelCase(), header: FieldNames.RoleCode.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.FirstName.toCamelCase(), header: FieldNames.FirstName.getDisplayName(), class: 'w-13per'},
		{field: FieldNames.LastName.toCamelCase(), header: FieldNames.LastName.getDisplayName(), class: 'w-13per'},
		{field: FieldNames.EmailAddress.toCamelCase(), header: FieldNames.EmailAddress.getDisplayName(), class: 'w-16per'},
		{field: FieldNames.UserType.toCamelCase(), header: FieldNames.UserType.getDisplayName(), class: 'w-13per'},
		{field: FieldNames.AllowFavoriteSearchResult.toCamelCase(), header: FieldNames.AllowFavoriteSearchResult.getDisplayName(), class: 'w-10per'},

        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-10per'}
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;

    constructor(private _maintainerRouter: MaintainerRouter) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: UserInfoGet): void {
        this._maintainerRouter.ToUserInfoEdit(item.userInfoID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.ToUserInfoAdd();
    }

    public OnClickFind(): void {
        this._maintainerRouter.ToUserInfo();
    }

    public OnSearchInput(UserInfoTable: Table, searchInput: string): void {
        UserInfoTable.filterGlobal(searchInput, "contains");
    }
}
