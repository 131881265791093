<div class="grid">
    <div class="col-8">
        
        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-arrow-left"
            (click)="MSOnClickRouteBack()"
            class="p-button-rounded p-button-text mr-2 mb-2 absolute z-1"
            title="Go Back"
        ></button>

        <app-pdf-display
            [MSEntityType]="MSEntityType"
            [MSEntityID]="MSTitleDocumentID"
        >
        </app-pdf-display>
    </div>
    <div class="col-4">
        <moon-document-result [MSTitleDocumentID]="MSTitleDocumentID">
        </moon-document-result>
    </div>
</div>