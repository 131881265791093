export class TitleSummaryStatus {
  public static readonly OPEN = "OPEN" as const;
  public static readonly PROCESSING = "PROCESSING" as const;
  public static readonly CLOSED = "CLOSED" as const;
}
export class ProcessingStatus {
  // Common Status
  public static readonly Created = "Created" as const;
  public static readonly TitleDocumentDownloaded = "TitleDocumentDownloaded" as const;

  // Title Summary Status
  public static readonly Complete = "Complete" as const;
  public static readonly Ocr = "Ocr" as const;
  public static readonly PromptTitleSummary = "PromptTitleSummary" as const;
  public static readonly PromptTitleDocument = "PromptTitleDocument" as const;
  public static readonly PromptTitleException = "PromptTitleException" as const;

  // Title Document Status
  public static readonly PromptExceptionNumber = "PromptExceptionNumber" as const;
  public static readonly PromptSummary = "PromptSummary" as const;
  public static readonly PromptTypeAndAction = "PromptTypeAndAction" as const;
}