<p-dialog
    [(visible)]="MSDisplayDialog"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
>
    <div class="moon-disclaimer-header">
        <h2>
            PORTAL ACCESS TERMS & CONDITIONS <br />
            MOONSTONE SOFTWARE PLATFORM
        </h2>
    </div>
    <p class="moon-disclaimer-message">{{ MSMessage }}</p>
    <p-footer>
        <div class="moon-disclaimer-footer">
            <button
                pButton
                pRipple
                type="button"
                [disabled]="MSIsProcessInProgress"
                icon="pi pi-check"
                (click)="MSOnConfirmClick()"
                label="Accept"
                class="p-button-raised p-button-text p-button-plain"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                [disabled]="MSIsProcessInProgress"
                icon="pi pi-times"
                (click)="MSOnRejectClick()"
                label="Reject"
                class="p-button-raised p-button-text p-button-plain"
            ></button>
        </div>
    </p-footer>
</p-dialog>
