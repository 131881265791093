<!-- Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
  <user-group-table
    [MSPageTitle]="MSPageTitle"
    [MSUserGroupList]="MSUserGroupList"
    [MSLoading]="MSLoading"
  ></user-group-table>
</div>
