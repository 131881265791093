<div class="moon-table-page">
    <ng-container
        #Container
        *ngIf="MSHasItems"
    >
        <ng-content></ng-content>
        <div class="moon-item-count">
            {{ MSItemCount }}
        </div>
    </ng-container>
    <ng-container *ngIf="!MSHasItems">
        <div class="grid">
            <button *ngIf="MSDisplayRouteBackButton"
                pButton
                pRipple
                type="button"
                icon="pi pi-arrow-left"
                (click)="OnClickRouteBack()"
                class="p-button-rounded p-button-raised mr-2 mb-2 moon-btn-back"
                pTooltip="Go Back"
                tooltipPosition="bottom"
            ></button>
            <h5>{{ MSPageTitle }}</h5>
        </div>
        <moon-empty-state>
            <button
                *ngIf="MSDisplayCreateButton"
                pButton
                pRipple
                type="button"
                (click)="OnClickCreate()"
                icon="pi pi-plus"
                class="p-button-rounded p-button-raised mr-2 mb-2"
                pTooltip="Create New {{ MSPageTitle }}"
                tooltipPosition="bottom"
            ></button>
            <button
                *ngIf="MSDisplayFindButton"
                pButton
                pRipple
                type="button"
                (click)="OnClickFind()"
                icon="pi pi-search"
                class="p-button-rounded p-button-raised mr-2 mb-2"
                pTooltip="Find {{ MSPageTitle }}"
                tooltipPosition="bottom"
            ></button>
            <button
                *ngIf="MSDisplaySecondaryButton"
                pButton
                pRipple
                type="button"
                (click)="OnClickSecondary()"
                icon="{{ MSSecondaryButtonIcon }}"
                class="p-button-rounded p-button-raised mr-2 mb-2"
                pTooltip="{{ MSSecondaryButtonToolTip }}"
                tooltipPosition="bottom"
            ></button>

            <p-fileUpload
                *ngIf="MSDisplayUploadButton"
                class="mr-2 mb-2 inline-block"
                styleClass="border-circle p-button-raised"
                #MSFileUpload
                mode="basic"
                [accept]="MSAcceptedFileExtension"
                [maxFileSize]="1000000"
                [auto]="true"
                (uploadHandler)="OnUploadFile($event)"
                [customUpload]="true"
                chooseIcon="pi pi-upload"
                pTooltip="Import {{MSPageTitle}}"
            ></p-fileUpload>

        </moon-empty-state>

        <moon-empty-state
            *ngIf="
                !MSHasItems &&
                !MSDisplayCreateButton &&
                !MSDisplayFindButton &&
                !MSDisplaySecondaryButton
            "
            label="No Items To Show"
        >
        </moon-empty-state>
    </ng-container>
</div>