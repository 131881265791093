import { Component, Input }                from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { FormsModule }                     from '@angular/forms';
import { Clipboard }                       from '@angular/cdk/clipboard';
//Third Party Imports
import { SharedModule }                    from 'primeng/api';
import { TabViewModule }                   from 'primeng/tabview';
import { InputSwitchModule }               from 'primeng/inputswitch';
import { ButtonModule }                    from 'primeng/button';
import { TooltipModule }                   from 'primeng/tooltip';
//Naya Imports
import { ApiContentResult }                from '@moon-core/models/api-result';
import { TitleDocumentController }         from '@moon-public/api/title-document.controller';
import { DocumentOcr }                     from '@moon-public/api/response/document-ocr.response';
import { TitleExceptionGet }               from '@moon-public/api/response/title-exception.response';
import { TitleDocumentGet }                from '@moon-public/api/response/title-document.response';
import { ChatResult }                      from '@moon-public/api/response/chat-result.response';
import { ComponentUtilityService }         from '@moon-core/services/component-utility.service';


@Component({
  selector: 'moon-document-result',
  standalone: true,
  templateUrl: './document-result.component.html',
  styleUrls: ['./document-result.component.scss'],
  imports: [CommonModule, SharedModule, TabViewModule, InputSwitchModule, FormsModule, ButtonModule, TooltipModule  ],
})
export class DocumentResultComponent {

  @Input() public MSTitleDocumentID: number;

  public MSTitleExceptions: TitleExceptionGet[] = [];
  public MSSummaryContent: string;
  public MSSummaryContentParsed: any;
  public MSOcrContent: string;
  public MSDisplayJson: boolean = false;
  public MSOcrInProgress: boolean = false;
  public MSOcrSkillResult: ChatResult;

  constructor(
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
    private _clipboard: Clipboard
  ) { }
  
  async ngOnInit() {
    
    await this.loadSummary(this.MSTitleDocumentID);
    await this.loadOcr(this.MSTitleDocumentID);
  }
  
  public async loadSummary(titleDocumentID: number) {

    const apiResult: ApiContentResult<TitleDocumentGet> = await this._titleDocumentController.GetByID(titleDocumentID);
    if (apiResult.success) {
      this.MSSummaryContent = apiResult.content.summaryText;
      this.MSSummaryContentParsed = String.isNullOrWhiteSpace(this.MSSummaryContent) ? null : JSON.parse(this.MSSummaryContent);
    }
    else {
      this.MSOcrContent = "Summary data unavailable.";
    }
  }

  public async loadOcr(titleDocumentID: number) {

    const apiResult: ApiContentResult<DocumentOcr> = await this._titleDocumentController.GetOcr(titleDocumentID);
    if (apiResult.success) {
      this.MSOcrContent = this.extractOcrContent(apiResult.content);
    }
    else {
      this.MSOcrContent = "Ocr data unavailable.";
    }
  }

  public async OnClickOCRSkill(): Promise<void>{
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiContentResult<ChatResult> = await this._titleDocumentController.GetTitleDocumentOcrText(this.MSTitleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSOcrSkillResult = apiResult.content;
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }
  
  public OnClickCopy(e: MouseEvent): void {
    let systemMessage = this.MSOcrSkillResult.content;
    if (systemMessage)
        this._clipboard.copy(systemMessage);
    e.stopPropagation();
  }

  private extractOcrContent(documentOcr: DocumentOcr): string{
    const ocrLines: string[] = documentOcr.paragraphs.flatMap(a => a.content);
    return ocrLines?.join('\n\n');
  }

}
