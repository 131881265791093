// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import { NgIf }                         from '@angular/common';
import {
  Component, ElementRef,
  OnInit, ViewChild
}                                       from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Third Party IMports
import { MenuItem }                     from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }         from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { UserInfoGet }                  from '@moon-maintainer/api/response/user-info-get.response';
import { UserInfoController }           from '@moon-maintainer/api/user-info.controller';
import { MaintainerRouter }             from '@moon-maintainer/maintainer.router';
import { UserInfoFormComponent }        from '@moon-maintainer/user-info/user-info-form/user-info-form.component';
import { UserInfoTableComponent }       from '@moon-maintainer/user-info/user-info-table/user-info-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { RoutePath }                    from '@moon-shared/constants/route-path';


@Component({
    selector: 'user-info-add-page',
    templateUrl: './user-info-edit-page.component.html',
    styleUrls: ['./user-info-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, UserInfoTableComponent, UserInfoFormComponent],
    providers: [UserInfoController, MaintainerRouter]
})
export class UserInfoEditPageComponent implements OnInit {

  public MSUserInfoID: number | null;
  public MSUserInfoGet: UserInfoGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.UserInfo.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDisplayRouteBackButton: boolean = true;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _userInfoController: UserInfoController,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSUserInfoID){
      await this.getUserInfoByID(this.MSUserInfoID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers
  public OnClickRouteBack(): void {
    this._maintainerRouter.ToUserInfo();
  }

  public OnClickRemove(): void {
    if (this.MSUserInfoID){
      this.doRemove(this.MSUserInfoID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSUserInfoID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    const editPageBreadcrumb: MenuItem[] = [
      { label: this.MSPageTitle, routerLink: [`${this._maintainerRouter.UserInfoRouteLink()}`] }, 
      { label: this.MSUserInfoID ? RoutePath.Edit : RoutePath.Add, routerLink: null }
    ];
    this._appBreadcrumbService.setItems(editPageBreadcrumb);
  }

  private readRouteParameters() {
    const userInfoID = this._activatedRoute.snapshot.paramMap.get(FieldNames.UserInfoID);
    this.MSUserInfoID = userInfoID ? +userInfoID : null;
  }

  private async getUserInfoByID(userInfoID: number) {
    if (userInfoID) {
      const apiResult: ApiContentResult<UserInfoGet> = await this._userInfoController.GetByID(userInfoID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSUserInfoGet = apiResult.content;
      }
    }
  }

  private async doRemove(userInfoID: number) {
    const apiResult: ApiResult = await this._userInfoController.Delete(userInfoID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToUserInfo();
    }
  }
}
