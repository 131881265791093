import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { Facet } from '@app/moon-public/clause-search/models/search-result-filter';

@Component({
  selector: 'moon-filter-item',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    FormsModule,
    KeyValuePipe,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RippleModule,
    SliderModule,
  ],
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss']
})
export class FilterItemComponent {
  @Input() MSFilterMap: Facet;
  @Output() MSFilterMapChange:  EventEmitter<Facet> = new EventEmitter();
  @Input() MSFilterLabel: string;
  @Output() MSOnFilterChanged: EventEmitter<undefined> = new EventEmitter();
  @Output() MSOnFilterCleared: EventEmitter<undefined> = new EventEmitter();

  public MSShowClearButton(): boolean {
    return Array.from(this.MSFilterMap.values()).some(value => value.filterStatus === true);
  }
  public MSOnClickClearFilter() {

    this.MSFilterMap.forEach((value, key, map) => map.set(key, {filterStatus: false, count: value.count}));
    this.MSOnFilterCleared.emit();
  }
  public MSOnFilterChange() {
    this.MSOnFilterChanged.emit();
  }
}
