<form [formGroup]="MSParentForm" (ngSubmit)="OnClickSubmit()">
    <div class="p-fluid p-formgrid">
        <ng-content></ng-content>
    </div>
    <moon-form-sticky-footer *ngIf="MSDisplaySaveButton">
        <button
            type="submit"
            pButton
            pRipple
            label="Save"
            class="p-button-raised mr-2 mb-2"
            *ngIf="MSParentForm?.dirty"
            [disabled]="MSIsFormSaving"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Cancel"
            [disabled]="MSIsFormSaving"
            class="p-button-raised p-button-secondary mr-2 mb-2"
            (click)="OnClickCancel()"
        ></button>
    </moon-form-sticky-footer>
</form>
