import { Component, OnInit }    from '@angular/core';
import { NgIf }                 from '@angular/common';
// Third Party Imports
import { DialogModule }         from 'primeng/dialog';
import { ButtonModule }         from 'primeng/button';
import { SidebarModule }        from 'primeng/sidebar';
// Moon Imports
import { MoonDialogService }    from '@moon-shared/services/moon-dialog.service';

@Component({
    selector: 'moon-dialog',
    templateUrl: './moon-dialog.component.html',
    styleUrls: ["./moon-dialog.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        ButtonModule,
        DialogModule,
        SidebarModule,
    ]
})
export class MoonDialogComponent implements OnInit {
    constructor(
        private _moonDialogService: MoonDialogService
    ) { }

    ngOnInit() {
    }

    public get Message() {
        return this._moonDialogService.DialogSetting.Message;
    }

    public get Header() {
        return this._moonDialogService.DialogSetting.Header;
    }

    public get Footer(){
        return this._moonDialogService.DialogSetting.Footer;
    }
    public get ConfirmButtonLabel() {
        return this._moonDialogService.DialogSetting.ConfirmButtonLabel;
    }

    public get RejectButtonLabel() {
        return this._moonDialogService.DialogSetting.RejectButtonLabel;
    }

    public get DisplayRejectButton() {
        return this._moonDialogService.DialogSetting.DisplayRejectButton;
    }

    public get DisplayConfirmButton() {
        return this._moonDialogService.DialogSetting.DisplayConfirmButton;
    }

    public get DisplayDialog() {
        return this._moonDialogService.DisplayDialog;
    }

    public set DisplayDialog(value: boolean) {
        this._moonDialogService.DisplayDialog = value;
    }

    public get DisplaySideBar() {
        return this._moonDialogService.DisplaySideBar;
    }

    public set DisplaySideBar(value: boolean) {
        this._moonDialogService.DisplaySideBar = value;
    }

    public OnConfirmClick() {
    }

    public OnRejectClick() {
        this._moonDialogService.OnRejectClick();
    }

}
