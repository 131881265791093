// Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM
import { NgIf }                           from '@angular/common';
import {
  Component, ElementRef,
  OnInit, ViewChild
}                                         from '@angular/core';
import { ActivatedRoute }                 from '@angular/router';
// Third party imports
import { ConfirmationService, MenuItem }  from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }           from '@app-layout/services/app.breadcrumb.service';
import { DocumentCategoryController }     from '@moon-collaborator/api/document-category.controller';
import { DocumentCategoryGet }            from '@moon-collaborator/api/response/document-category-get.response';
import { CollaboratorRouter }             from '@moon-collaborator/collaborator.router';
import { DocumentCategoryFormComponent }  from '@moon-collaborator/document/document-category/document-category-form/document-category-form.component';
import { ApiContentResult, ApiResult }    from '@moon-core/models/api-result';
import { ComponentUtilityService }        from '@moon-core/services/component-utility.service';
import { MoonLoadingComponent }           from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }          from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }                 from '@moon-shared/constants/constant-string';
import { RouteParameter }                 from '@moon-shared/constants/route-parameter';
import { RoutePath }                      from '@moon-shared/constants/route-path';

@Component({
    selector: 'document-category-add-page',
    templateUrl: './document-category-edit-page.component.html',
    styleUrls: ['./document-category-edit-page.component.css'],
    standalone: true,
    imports: [
      NgIf,
      MoonLoadingComponent, 
      MoonEditPageComponent, 
      DocumentCategoryFormComponent,
    ],
    providers: [ 
      DocumentCategoryController,
      CollaboratorRouter,
    ]
})
export class DocumentCategoryEditPageComponent implements OnInit {

  public MSDocumentCategoryID: number | null;
  public MSDocumentCategoryGet: DocumentCategoryGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.DocumentCategory.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDisplayRouteBackButton: boolean = true;
  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _confirmationService: ConfirmationService,
    private _componentUtilityService: ComponentUtilityService,
    private _documentCategoryController: DocumentCategoryController,
    private _collaboratorRouter: CollaboratorRouter,
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSDocumentCategoryID){
      await this.getDocumentCategoryByID(this.MSDocumentCategoryID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSDocumentCategoryID){
      this._confirmationService.confirm({
        message: `Are you sure you want to remove ${this.MSDocumentCategoryGet.categoryName}`,
        accept: () => {
            this.doRemove();
        },
      });
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSDocumentCategoryID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    const editPageBreadcrumb: MenuItem[] = [
      { label: this.MSEnvironmentName, routerLink: this._collaboratorRouter.EnvironmentRouteLink() }, 
      { label: RoutePath.Documents, routerLink: this._collaboratorRouter.DocumentRouteLink(this.MSEnvironmentName) },
      { label: RoutePath.DocumentCategory.getDisplayName(), routerLink: this._collaboratorRouter.DocumentCategoryRouteLink(this.MSEnvironmentName) },
      { label: this.MSDocumentCategoryID ? RoutePath.Edit : RoutePath.Add, routerLink: null }
    ];
    
    this._appBreadcrumbService.setItems(editPageBreadcrumb);
  }
  
  private readRouteParameters() {
    this.MSEnvironmentName = this._activatedRoute.snapshot.paramMap.get(RouteParameter.EnvironmentName) ?? String.empty;
    const documentCategoryID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.DocumentCategoryID);
    this.MSDocumentCategoryID = documentCategoryID ? +documentCategoryID : null;
  }

  private async getDocumentCategoryByID(documentCategoryID: number | null) {
    if (documentCategoryID) {
      const apiResult: ApiContentResult<DocumentCategoryGet> = await this._documentCategoryController.GetByID(this.MSEnvironmentName, documentCategoryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSDocumentCategoryGet = apiResult.content;
      }
    }
  }

  private async doRemove() {
    const apiResult: ApiResult = await this._documentCategoryController.Delete(this.MSEnvironmentName, this.MSDocumentCategoryID ?? 0);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._collaboratorRouter.ToDocumentCategory(this.MSEnvironmentName);
    }
  }

  public OnClickRouteBack() {
    this._collaboratorRouter.ToDocumentCategory(this.MSEnvironmentName);
  }
}
