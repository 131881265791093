// Moon Auto Generated (1.0.0) at 11/26/2023 11:22:45 PM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
import { FormsModule }              from '@angular/forms';
// Moon Imports
import { AppBreadcrumbService }     from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { ChatLogController }           from '@moon-maintainer/api/chat-log.controller';
import { FindChatLogParameter }                 from "@moon-maintainer/api/request/find-chat-log.parameter";
import { ChatLogFind }                 from "@moon-maintainer/api/response/chat-log-find.response";
import { ChatLogTableComponent }       from '@moon-maintainer/chat-log/chat-log-table/chat-log-table.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'chat-log',
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, ChatLogTableComponent, FormsModule],
  providers: [ChatLogController]
})
export class ChatLogComponent implements OnInit {
  public MSChatLogList: ChatLogFind[] = [];
  public MSLoading: boolean = true;
  public MSPageTitle: string = ConstantString.ChatLog;
  public MSEnvironmentName = String.empty;
  public MSFindChatLogParameter: FindChatLogParameter = new FindChatLogParameter();
  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _chatLogController: ChatLogController
  ) { }

  ngOnInit() {
    this.setInitialDate();
    this.setBreadcrumb();
    this.loadChatLogList();
  }

  private setInitialDate(): void {
    this.MSFindChatLogParameter.dateTo = new Date();
    this.MSFindChatLogParameter.dateFrom.setDate(this.MSFindChatLogParameter.dateTo.getDate() - 7);
  }
  public MSOnDateChanged() {
    this.loadChatLogList();
  }
  private convertToUtc(date: Date): Date {
    return (new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)));
  }
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  private async loadChatLogList() {

    this.MSFindChatLogParameter.dateFrom = this.convertToUtc(this.MSFindChatLogParameter.dateFrom);
    this.MSFindChatLogParameter.dateTo = this.convertToUtc(this.MSFindChatLogParameter.dateTo);

    const apiResult: ApiContentResult<ChatLogFind[]> = await this._chatLogController.Find(this.MSFindChatLogParameter);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSChatLogList = apiResult.content;
    };
    this.MSLoading = false;
  }
}
