import { HttpHeaders }              from "@angular/common/http";
import { MoonApiErrorResponse } from "./moon-api-error-response";

export class MoonSessionErrorResponse extends MoonApiErrorResponse {
    
    sessionError: string;

    constructor(init: {
        error?: any;
        headers?: HttpHeaders;
        status?: number;
        statusText?: string;
        url?: string;
    }) {
        super(init);
    }
}
