<div id="document-search">

    <div
        [hidden]="MSLoading"
        class="card"
    >
        <form
            [formGroup]="MSSearchForm"
            (ngSubmit)="MSOnClickSearch()"
        >
            <div class="moon-card-body">
                <div class="flex flex-wrap">
                    <div class="col-12 md:col-9 inline-block p-0">
                        <moon-form-control
                            [MSInputSize]="12"
                            [MSFormControl]="MSSearchForm.controls.content"
                            [MSDisplayLabel]="false"
                            [MSLabel]="'Search Clause'"
                        >
                            <span class="p-fluid">
                                <textarea
                                    rows="4"
                                    cols="30"
                                    pInputTextarea
                                    placeholder="Search Clause"
                                    [formControl]="MSSearchForm.controls.content"
                                ></textarea>
                            </span>
                        </moon-form-control>
                    </div>
                    <div class="col-12 md:col-3 p-0">
                        <div class="col-12 p-0 inline-block p-fluid">
                            <moon-form-control
                                *ngIf="MSEnvironmentList;"
                                [MSInputSize]="8"
                                [MSLabelSize]="4"
                                [MSFormControl]="MSSearchForm.controls.environmentList"
                                [MSLabel]="'Environment'"
                            >
                                <ng-container>
                                    <p-multiSelect *ngIf="MSEnvironmentList.length > 1"
                                        [options]="MSEnvironmentList"
                                        [formControl]="MSSearchForm.controls.environmentList"
                                        optionLabel="environmentName"
                                        optionValue="environmentName"
                                        [filter]="true"
                                        filterBy="environmentName"
                                        placeholder="Select Environment"
                                        (onChange)="MSOnEnvironmentsChanged()"
                                    ></p-multiSelect>
                                    <span *ngIf="MSEnvironmentList.length == 1">{{ MSEnvironmentList[0]?.environmentName }}</span>
                                    <span *ngIf="!MSEnvironmentList.length">No Environment To Show</span>
                                </ng-container>
                            </moon-form-control>
                        </div>
                        <div class="col-12 p-0 inline-block p-fluid">
                            <moon-form-control
                                *ngIf="MSDocumentTypeCategoryListSelectItems"
                                [MSInputSize]="8"
                                [MSLabelSize]="4"
                                [MSFormControl]="MSSearchForm.controls.documentCategoryIDList"
                                [MSLabel]="'Document Type'"
                            >
                                <ng-container>
                                    <p-multiSelect
                                        [options]="MSDocumentTypeCategoryListSelectItems"
                                        [formControl]="MSSearchForm.controls.documentCategoryIDList"
                                        [disabled]="MSDisableDocumentCategory"
                                        optionLabel="label"
                                        optionValue="value"
                                        [filter]="true"
                                        filterBy="label"
                                        placeholder="Select Document Type"
                                    ></p-multiSelect>
                                </ng-container>
                            </moon-form-control>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="MSShowAdvanceSettings"
                    class="col-12"
                >
                    <p-divider>
                        <div class="inline-flex align-items-center">
                            <i class="pi pi-cog mr-2"></i>
                            <b>Advanced Settings</b>
                        </div>
                    </p-divider>
                    <ng-container>
                        <div
                            id="moon-document-search-template"
                            class="grid fadein animation-duration-200"
                        >
                            <div class="col-12 md:col-6">
                                <moon-form-control
                                    [MSLabel]="'Relevance'"
                                    [MSLabelSize]="4"
                                    [MSHint]="'Ranges from 0 to 1'"
                                    [MSInputSize]="8"
                                    [MSFormControl]="MSSearchForm.controls.minimumRelevance"
                                >
                                    <p-inputNumber
                                        class="col-6"
                                        placeholder="Min"
                                        [formControl]="MSSearchForm.controls.minimumRelevance"
                                        [useGrouping]="false"
                                        [required]="true"
                                        [step]="0.1"
                                        [min]="0"
                                        [max]="1"
                                        [minFractionDigits]="0"
                                        [maxFractionDigits]="2"
                                    ></p-inputNumber>

                                    <p-inputNumber
                                        class="col-6"
                                        placeholder="Max"
                                        [formControl]="MSSearchForm.controls.maximumRelevance"
                                        [useGrouping]="false"
                                        [required]="true"
                                        [step]="0.1"
                                        [min]="0"
                                        [max]="1.5"
                                        [minFractionDigits]="0"
                                        [maxFractionDigits]="2"
                                    ></p-inputNumber>
                                </moon-form-control>
                            </div>
                            <div class="col-12 md:col-4">
                                <moon-form-control
                                    [MSLabel]="'Include Exact Matches'"
                                    [MSLabelSize]="8"
                                    [MSInputSize]="4"
                                    [MSFormControl]="MSSearchForm.controls.includeExactMatches"
                                >
                                    <p-checkbox
                                        class="h-3rem"
                                        inputId="Include Exact Matches"
                                        [formControl]="MSSearchForm.controls.includeExactMatches"
                                        [binary]="true"
                                    ></p-checkbox>
                                </moon-form-control>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="grid align-items-center gap-2 col-12 moon-card-footer">
                <button
                    pButton
                    pRipple
                    icon="pi pi-search"
                    class="p-button-raised moon-warning-message-action-button"
                    (click)="MSOnClickSearch()"
                    [disabled]="MSLoading"
                    label="Search"
                ></button>

                <p-inputSwitch
                    inputID="togglefilter"
                    [(ngModel)]="MSShowAdvanceSettings"
                    [ngModelOptions]="{standalone: true}"
                ></p-inputSwitch>
                <label for="togglefilter">{{MSShowAdvanceSettings ? 'Hide ' : 'Show '}}Advanced Settings</label>
            </div>


        </form>
    </div>


    <div
        #resultView
        *ngIf="MSShowDocumentResultSection && !MSLoading"
        class="flex"
    >
        <!-- Data view component -->
        <div
            id="moon-doc-result"
            class="card col-12 relative"
        >
            <ms-search-data-view
                [MSOriginalSearchText]="MSSearchForm.value.content ?? ''"
                [MSSearchChunkList]="MSSearchChunkList"
                [MSTimeElapsedForResult]="MSTimeElapsedForResult"
                [MSSearchResultFilter]="MSSearchResultFilter"
                [MSTotalExactMatch]="MSTotalExactMatch"
                [MSIncludeExactMatches]="MSIncludeExactMatches"
            >
            </ms-search-data-view>
        </div>
    </div>
</div>


<moon-loading *ngIf="MSLoading" [MSLoadingMessage]="MSLoadingMessage"></moon-loading>
