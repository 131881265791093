<!-- Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM -->
<moon-form-page [MSParentForm]="MSParentForm"
                [MSIsFormSaving]="MSIsFormSubmitting"
                (MSOnClickEventSubmit)="OnClickSubmit()"
                (MSOnClickEventCancel)="OnClickCancel()">
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'User Group Name'"
            [MSFormControl]="MSParentForm.controls.userGroupName"
        >
            <input
                type="text"
                formControlName="userGroupName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'External Group ID'"
            [MSFormControl]="MSParentForm.controls.externalGroupID"
        >
            <input
                type="text"
                formControlName="externalGroupID"
                pInputText
            />
        </moon-form-control>

    </div>
</moon-form-page>
