// Moon Auto Generated (1.0.0) at 11/27/2023 10:27:34 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { FindSearchLogParameter }        from "@moon-maintainer/api/request/find-search-log.parameter";
import { SearchLogFind }             from "@moon-maintainer/api/response/search-log-find.response";
import { SearchLogGet }              from "@moon-maintainer/api/response/search-log-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";

@Injectable()
export class SearchLogController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.SearchLog);
  };

  constructor(private _moonApi: MoonApiClient) { }
    
/**
 *   POST: Maintainer/v1/SearchLog/find
 */
  public async Find(findParameter: FindSearchLogParameter
    ): Promise<ApiContentResult<SearchLogFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Find);
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/SearchLog/{documentID}
 */
  public async GetByID(searchLogID: number
    ): Promise<ApiContentResult<SearchLogGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), searchLogID);
    return this._moonApi.getAsync<SearchLogGet>(url);
  }
  
}
