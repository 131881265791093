<!-- Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM -->
<moon-form-page
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    (MSOnClickEventSubmit)="OnClickSubmit()"
    (MSOnClickEventCancel)="OnClickCancel()"
>
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->

        <moon-form-control
            [MSLabel]="'User Message'"
            [MSFormControl]="MSParentForm.controls.userMessage"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="userMsg.value"
            >
                <textarea
                    #userMsg
                    [formControl]="MSParentForm.controls.userMessage"
                    pInputTextarea
                >
                </textarea>
            </div>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Assistant Message'"
            [MSFormControl]="MSParentForm.controls.assistantMessage"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="assistantMsg.value"
            >
                <textarea
                    #assistantMsg
                    [formControl]="MSParentForm.controls.assistantMessage"
                    pInputTextarea
                    (input)="ValidateAssistantMessageInputJSON()"
                >
                </textarea>
                <p-checkbox
                    [(ngModel)]="MSValidateJSON"
                    [ngModelOptions]="{standalone: true}"
                    class="h-3rem"
                    [binary]="true"
                    label="Validate JSON format"
                    (onChange)="ValidateAssistantMessageInputJSON()"
                ></p-checkbox>

            </div>

        </moon-form-control>
    </div>
</moon-form-page>