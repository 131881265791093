import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SharedModule } from "primeng/api";
import { CardModule } from "primeng/card";
//
import { MezzLoanChunkRevisionGet } from '@app/moon-public/api/response/mezz-loan-chunk-revision-get.response';
import { MezzLoanController } from '@app/moon-public/api/mezz-loan.controller';
import { ComponentUtilityService } from '@app/moon-core/services/component-utility.service';
import { AppBreadcrumbService } from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult } from '@app/moon-core/models/api-result';
import { FieldNames } from '@app/moon-shared/constants/field-names';
import { ConstantString } from '@app/moon-shared/constants/constant-string';

@Component({
  selector: 'app-mezz-loan-revision',
  templateUrl: './mezz-loan-revision.component.html',
  styleUrls: ['./mezz-loan-revision.component.scss'],
  standalone: true,
  imports: [CommonModule, 
    CardModule, SharedModule],
  providers: [MezzLoanController]
})
export class MezzLoanRevisionComponent implements OnInit {

  public MSMezzLoanID: number | null;
  public MSMezzLoanRevisionGet: MezzLoanChunkRevisionGet | null;
  public MSLoading = true;
  
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _mezzLoanController: MezzLoanController,
  ) { }
  
  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSMezzLoanID){
      await this.getMezzLoanByID(this.MSMezzLoanID);
    }
    this.MSLoading = false;
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetSimple([ConstantString.MezzLoan, ConstantString.Revision]);
  }

  private readRouteParameters() {
    const mezzLoanID = this._activatedRoute.snapshot.paramMap.get(FieldNames.MezzLoanID);
    this.MSMezzLoanID = mezzLoanID ? +mezzLoanID : null;
  }

  private async getMezzLoanByID(mezzLoanID: number) {
    if (mezzLoanID) {
      const apiResult: ApiContentResult<MezzLoanChunkRevisionGet[]> = await this._mezzLoanController.GetListRevisionAsync(mezzLoanID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        //this.MSMezzLoanRevisionGet = apiResult.content;
      }
    }
  }
}
