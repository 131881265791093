<div
    class="p-2 card"
    [formGroup]="MSBlackLineForm"
>
    <div class="grid moon-black-line-text-area">
        <div class="col">
            <moon-form-control
                [MSFormControl]="MSBlackLineForm.controls.originalText"
                [MSLabel]="'Original text'"
                [MSLabelSize]="12"
                [MSInputSize]="12"
            >
                <span class="p-fluid">
                    <textarea
                        pInputTextarea
                        placeholder="Write text"
                        [formControl]="MSBlackLineForm.controls.originalText"
                    ></textarea>
                </span>
            </moon-form-control>
        </div>
        <div class="col">
            <moon-form-control
                [MSFormControl]="MSBlackLineForm.controls.modifiedText"
                [MSLabel]="'Modified text'"
                [MSLabelSize]="12"
                [MSInputSize]="12"
            >
                <span class="p-fluid">
                    <textarea
                        pInputTextarea
                        placeholder="Write text"
                        [formControl]="MSBlackLineForm.controls.modifiedText"
                    ></textarea>
                </span>
            </moon-form-control>
        </div>
    </div>
    <div class="flex align-items-start">
        <button
            pButton
            pRipple
            class="p-button-raised h-min"
            label="Submit"
            (click)="MSApplyBlackline()"
        >
        </button>

        <moon-form-control
            [MSLabel]="'Blackline Algorithm'"
            [MSLabelSize]="0"
            [MSDisplayLabel]="false"
            [MSInputSize]="12"
            [MSFormControl]="MSBlackLineForm.controls.blackLineMode"
        >
            <span class="p-fluid">
                <p-dropdown
                    [formControl]="MSBlackLineForm.controls.blackLineMode"
                    [options]="MSBlackLineModes"
                ></p-dropdown>
            </span>
        </moon-form-control>

    </div>
    <div
        *ngIf="MSShowBlackLine"
        class="col-12 moon-black-lines-output"
    >
        <span class="font-bold">Output</span>:
        <ms-black-lines
            #blackLinesComponent
            [MSOriginalText]="MSBlackLineForm.value.originalText ?? ''"
            [MSModifiedText]="MSBlackLineForm.value.modifiedText ?? ''"
            [MSBlackLineAlgorithm]="MSBlackLineForm.value.blackLineMode ?? 'Semantic Search'"
        ></ms-black-lines>
    </div>
</div>