import { Injectable }                                 from '@angular/core';
// MS Imports
import { ApiBlobResult, ApiContentResult, ApiResult } from '@moon-core/models/api-result';
import { MoonApiClient }                              from '@moon-core/services/moon-api.client';
import { DocumentOcr }                                from '@moon-public/api/response/document-ocr.response';
import { TitleDocumentGet }                           from '@moon-public/api/response/title-document.response';
import { RoutePath }                                  from '@moon-shared/constants/route-path';
import { ChatResult }                                 from '@moon-public/api/response/chat-result.response';

@Injectable()
export class TitleDocumentController {

  private getBaseUrl(): string {
    return this._moonApi.getPublicUrl(RoutePath.TitleDocument);
  };

  constructor(private _moonApi: MoonApiClient) { }

/**
 *   GET: Public/v1/TitleDocument/{titleDocumentID}
 */
public async GetByID(titleDocumentID: number
  ): Promise<ApiContentResult<TitleDocumentGet>> {
  
  const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID);
  return this._moonApi.getAsync<TitleDocumentGet>(url);
}

/**
 *   GET: Public/v1/TitleDocument/{titleSummaryID}/Blob
 */
  public async GetBlob(titleDocumentID: number
    ): Promise<ApiBlobResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.Blob);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   PUT: Public/v1/TitleDocument/{titleDocumentID}/Blob
   */
  public async PutBlob(titleDocumentID: number, file: File
  ): Promise<ApiResult> {

    let formData = new FormData();
    formData.set('inputFile', file);
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.Blob);
    return this._moonApi.putAsync(url, formData);
  }


  public async Process(titleDocumentID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.Process);
    return this._moonApi.postAsync(url, null);
  }  

/**
 *   GET: Public/v1/TitleDocument/{titleDocumentID}/Ocr
 */
  public async GetOcr(titleDocumentID: number
    ): Promise<ApiContentResult<DocumentOcr>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.Ocr);
    return this._moonApi.getAsync<DocumentOcr>(url);
  }
  
  public async PostOcr(titleDocumentID: number, testMode: boolean
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.Ocr, testMode.toString());
    return this._moonApi.postAsync(url, null);
  }

/**
  *   GET: Public/v1/TitleSummary/{titleSummaryID}/PromptTitleDocumentOcrText
  */
  public async GetTitleDocumentOcrText(
    titleDocumentID: number
  ): Promise<ApiContentResult<ChatResult>> {
    const url = this._moonApi.combineUrl(
      this.getBaseUrl(),
      titleDocumentID,
      RoutePath.PromptTitleDocumentOcrText
    );
    return this._moonApi.getAsync<ChatResult>(url);
  }
/**
 *   POST: Public/v1/TitleDocument/{titleDocumentID}/PromptExceptionNumber/{testMode}
 */
  public async PromptExceptionNumber(titleDocumentID: number, testMode: boolean
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.PromptExceptionNumber, testMode.toString());
    return this._moonApi.postAsync(url, null);
  }

/**
 *   POST: Public/v1/TitleDocument/{titleDocumentID}/PromptSummary/{testMode}
 */
  public async PromptSummary(titleDocumentID: number, testMode: boolean
    ): Promise<ApiResult> {
    
      const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.PromptSummary, testMode.toString());
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   PUT: Public/v1/TitleDocument/{titleDocumentID}/OverrideExceptionNumber
   */
  public async OverrideExceptionNumber(titleDocumentID: number, exceptionType: string
  ): Promise<ApiResult> {
    let form = new FormData();
    form.set('exceptionNumberJson', exceptionType);
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.OverrideExceptionNumber);
    return this._moonApi.putAsync(url, form);
  }
  /**
   *   PUT: Public/v1/TitleDocument/{titleDocumentID}/OverrideExceptionNumber
   */
  public async OverrideExceptionType(titleDocumentID: number, exceptionType: string
  ): Promise<ApiResult> {
    let form = new FormData();
    form.set('exceptionType', exceptionType);
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.OverrideExceptionType);
    return this._moonApi.putAsync(url, form);
  }
  /**
   *   PUT: Public/v1/TitleDocument/{titleDocumentID}/OverrideRecommendedAction
   */
  public async OverrideRecommendedAction(titleDocumentID: number, recommendedAction: string
  ): Promise<ApiResult> {
    let form = new FormData();
    form.set('recommendedAction', recommendedAction);
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.OverrideRecommendedAction);
    return this._moonApi.putAsync(url, form);
  }

}