import { CommonModule }                             from '@angular/common';
import { Component }                                from '@angular/core';
// Third party imports
import { DataViewModule }                           from 'primeng/dataview';
import { DynamicDialogConfig, DynamicDialogRef }    from 'primeng/dynamicdialog';
import { FieldsetModule }                           from 'primeng/fieldset';
import { TagModule }                                from 'primeng/tag';
// Moon Imports
import { BlackLinesComponent }                      from '@moon-shared/components/black-lines/black-lines.component';
import { MessageSeverity }                          from '@moon-shared/constants/message-severity';
import { MoonMessageService }                       from '@moon-shared/services/moon-message.service';
import { SearchMatchTextComponent }                 from '@app/moon-shared/components/search-match-text/search-match-text.component';
import { DocumentChunk }                            from '@app/moon-collaborator/api/request/document-chunk.request';

export type ChunkFromSameDocumentDynamicDialogData = {
    MSFreeTextSearch: string,
    MSOriginalChunkList: any,
    MSContentSearchList: string[]
};

@Component({
    standalone: true,
    selector: 'moon-full-text-document-chunk-modal',
    templateUrl: './full-text-document-chunk-modal.component.html',
    styleUrls: ['./full-text-document-chunk-modal.component.scss'],
    imports: [
        CommonModule, DataViewModule, TagModule, FieldsetModule, BlackLinesComponent,
        SearchMatchTextComponent,
    ],
    providers: []
})
export class FullTextDocumentChunkModalComponent {
    public MSOriginalSearchText: string;
    public MSOriginalChunkList: DocumentChunk[];
    public MSContentSearchList: string[];
    constructor(
        private _dynamicDialogConfig: DynamicDialogConfig<ChunkFromSameDocumentDynamicDialogData>,
        private _dynamicDialogRef: DynamicDialogRef,
        private _moonMessageService: MoonMessageService
    ) { }

    ngOnInit() {
        const data: ChunkFromSameDocumentDynamicDialogData | undefined = this._dynamicDialogConfig.data;

        if (data == null) {
            this._moonMessageService.showToastMessage(MessageSeverity.Info, "No documents found.");
            this._dynamicDialogRef.close();
            return;
        }
        this.MSOriginalChunkList = data.MSOriginalChunkList;
        this.MSOriginalSearchText = data.MSFreeTextSearch;
        this.MSContentSearchList = [...data.MSContentSearchList];
    }
}