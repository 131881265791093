<!-- Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <skill-example-table
        [MSPageTitle]="MSPageTitle"
        [MSSkillExampleList]="MSSkillExampleList"
        [MSLoading]="MSLoading"
        [MSSkillID]="MSSkillID"
        [MSNavigatedFromEdit]="MSNavigatedFromEdit"
    ></skill-example-table>
</div>