<!-- Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM -->
<moon-edit-page
    [MSLoading]="MSLoading"
    [MSPageTitle]="MSPageTitle"
    [MSDisplayRemoveButton]="MSDisplayRemoveButton"
    [MSDisplayRouteBackButton]="MSDisplayRouteBackButton"
    (MSOnClickEventRemove)="OnClickRemove()"
    (MSOnClickEventRouteBack)="OnClickRouteBack()"
>
    <ng-container *ngIf="!MSLoading">
        <mezz-loan-form
            #MSForm
            [MSMezzLoanID]="MSMezzLoanID"
            [MSMezzLoanGet]="MSMezzLoanGet"
        >
        </mezz-loan-form>
    </ng-container>
</moon-edit-page>