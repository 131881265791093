import {
    DecimalPipe,
    KeyValuePipe, NgClass, NgFor, NgIf, TitleCasePipe
}                                                       from '@angular/common';
import { Component, Input }                             from '@angular/core';
import { FormsModule, ReactiveFormsModule }             from '@angular/forms';
// Third party imports
import { SharedModule }                                 from 'primeng/api';
import { BadgeModule }                                  from 'primeng/badge';
import { ButtonModule }                                 from 'primeng/button';
import { DataViewModule }                               from 'primeng/dataview';
import { DropdownModule }                               from 'primeng/dropdown';
import { 
    DialogService, DynamicDialogConfig, 
    DynamicDialogModule, DynamicDialogRef 
}                                                       from 'primeng/dynamicdialog';
import { FieldsetBeforeToggleEvent, FieldsetModule }    from 'primeng/fieldset';
import { InputSwitchModule }                            from 'primeng/inputswitch';
import { RatingModule }                                 from 'primeng/rating';
import { RippleModule }                                 from 'primeng/ripple';
import { SkeletonModule }                               from 'primeng/skeleton';
import { TagModule }                                    from 'primeng/tag';
import { TooltipModule }                                from 'primeng/tooltip';
// Moon Imports
import { DocumentController }                           from '@moon-collaborator/api/document.controller';
import { DocumentChunkGet }                             from '@moon-collaborator/api/response/document-chunk-get.response';
import { SearchRatingController }                       from '@moon-collaborator/api/search-rating.controller';
import { ApiBlobResult, ApiContentResult }              from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';
import { UserSessionService }                           from '@moon-core/services/user-session.service';
import { FullTextFilterResultComponent }                from '@moon-public/full-text-search/full-text-filter-result/full-text-filter-result.component';
import { 
    ChunkFromSameDocumentDynamicDialogData, 
    FullTextDocumentChunkModalComponent }               from '@app/moon-public/full-text-search/modals/full-text-document-chunk/full-text-document-chunk-modal.component';
import { FullTextSearchResultFilter }                   from '@moon-public/full-text-search/models/full-text-search-result-filter';
import { SimilarChunk }                                 from '@moon-public/clause-search/models/similar-chunk';
import { SearchMatchTextComponent }                     from '@moon-shared/components/search-match-text/search-match-text.component';
import { MoonFormControlComponent }                     from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { DefaultValues }                                from '@moon-shared/constants/default-values';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';
import { SameDocumentChunk }                            from '@app/moon-public/full-text-search/models/same-document-chunk';

@Component({
    selector: 'ms-full-text-search-data-view',
    templateUrl: './full-text-search-data-view.component.html',
    styleUrls: ['./full-text-search-data-view.component.scss'],
    standalone: true,
    imports: [
        BadgeModule, SearchMatchTextComponent, ButtonModule, DataViewModule, DecimalPipe, TitleCasePipe,
        DropdownModule, FieldsetModule, FullTextFilterResultComponent, FormsModule, KeyValuePipe,
        MoonFormControlComponent, NgFor, NgIf, RatingModule, ReactiveFormsModule, RippleModule, SharedModule,
        SkeletonModule, TooltipModule, InputSwitchModule, NgClass, TagModule, DynamicDialogModule
    ],
    providers: [SearchRatingController, DialogService]
})
export class FullTextSearchDataViewComponent {
    @Input() public MSFreeTextSearch: string = String.empty;
    @Input() public MSSearchDocumentList: SameDocumentChunk[] = [];
    @Input() public MSContentSearchList: string[] = [];
    @Input() public MSSearchResultFilter: FullTextSearchResultFilter;
    @Input() public MSTimeElapsedForResult: number | null;
    @Input() public MSIncludeExactMatches: boolean = false;

    public MSFilteredSearchChunkList: SameDocumentChunk[] = [];
    public MSShowFilter: boolean = true;
    public MSDynamicDialogRef?: DynamicDialogRef;
    public MSIsCollaborator: boolean = false;
    public MSIsUserFavoritesAllowed: boolean = false;

    public get MSShowingOutputContent(): string {
        let showingCount = this.MSFilteredSearchChunkList.reduce((sum, currentArray) => sum + currentArray.documentChunkList.length, 0);
        const totalChunks = this.MSSearchDocumentList.reduce((sum, currentArray) => sum + currentArray.documentChunkList.length, 0);

        if (showingCount !== totalChunks) {
            return `Showing ${showingCount} out of ${totalChunks} results`;
        }

        return `Showing ${totalChunks} results`;
    };

    constructor(
        private _userSessionService: UserSessionService,
        private _documentController: DocumentController,
        private _componentUtilityService: ComponentUtilityService,
        private _moonMessageService: MoonMessageService,
        private _dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this.MSFilteredSearchChunkList = this.MSSearchDocumentList;
        this.MSIsCollaborator = this._userSessionService.IsCollaborator();

        this.MSIsUserFavoritesAllowed = this.getAllowFavoriteUserOption();
    }

    private getAllowFavoriteUserOption() {
        const allowFavoriteUserOptionValue = this._userSessionService.GetCurrentUserSession.allowFavoriteSearchResult;
        return DefaultValues.Y.equalsIgnoreCase(allowFavoriteUserOptionValue);
    }

    public MSGetBadgeSeverityForRelevance(relevance: number): string {
        if (relevance > 0.7) return "";
        if (relevance > 0.5) return "info";
        if (relevance > 0.3) return "warning";
        else return "danger";
    }

    public MSOnResultFilterChangedEvent(filterMap: FullTextSearchResultFilter) {
        this.MSFilteredSearchChunkList = filterMap.MSApplyFilter(this.MSSearchDocumentList);
    }

    public MSOnResultFilterClearedEvent() {
        this.MSFilteredSearchChunkList = this.MSSearchDocumentList;
    }

    public MSGetLabel(similarChunk: SameDocumentChunk): string {
        return `View Document ${similarChunk.noOfMatchingSearchText ? '(' + similarChunk.noOfMatchingSearchText + ')' : ''}`;
    }

    public async MSOnAdditionInfoBeforeToggle(event: FieldsetBeforeToggleEvent, similarResult: SimilarChunk) {
        if (event.collapsed) {
            if (!similarResult.neighborChunkList) {

                const apiResult: ApiContentResult<DocumentChunkGet[]> =
                    await this._documentController.GetChunkListOrdinalAsync(similarResult.environmentName, similarResult.documentID, similarResult.ordinal);
                if (this._componentUtilityService.WasSuccessful(apiResult)) {
                    similarResult.neighborChunkList = apiResult.content;
                };
            }
        }
    }

    public async MSOnClickDownloadDocument(similarChunk: SimilarChunk): Promise<void> {

        const apiResult: ApiBlobResult = await this._documentController.GetBlobAsync(similarChunk.environmentName, similarChunk.documentID);
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess("Download completed.");
        };
    }

    public async MSOnClickViewAllChunks(sameDocument: SameDocumentChunk): Promise<void> {
        const dynamicDialogConfig: DynamicDialogConfig<ChunkFromSameDocumentDynamicDialogData> = {
            styleClass: 'w-80per',
            header: `${sameDocument.documentName} (${sameDocument.documentChunkList.length})`,
            data: {
                MSFreeTextSearch: this.MSFreeTextSearch,
                MSOriginalChunkList: sameDocument.documentChunkList,
                MSContentSearchList: this.MSContentSearchList
            }
        };
        this.MSDynamicDialogRef = this._dialogService.open(FullTextDocumentChunkModalComponent, dynamicDialogConfig);
    }

    ngOnDestroy() {
        this.MSDynamicDialogRef?.close();
    }
}