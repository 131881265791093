import { Injectable }                                           from "@angular/core";
// MS Imports
import { MoonConfig, MoonEnvironmentConfig, MoonMsalConfig, }   from "@moon-core/api/response/moon-environment-config.response";
import { environment }                                          from 'src/environments/environment';

@Injectable()
export class MoonAppConfigService {
    private readonly _prodConfigFilePath = "moon-config/moon-config.json";
    private readonly _debugRunConfigFilePath = "../../../moon-config/staging/moon-config.json";
    private _moonEnvironmentConfig: MoonEnvironmentConfig;

    constructor() { }

    public LoadAppConfig(): Promise<void> {
        const moonConfigFilePath = environment.production ? this._prodConfigFilePath : this._debugRunConfigFilePath;

        return fetch(moonConfigFilePath)
            .then((response: Response) => response.json())
            .then((config) => {
                this._moonEnvironmentConfig = config;
            })
            .catch((error) => {
                console.log(error)
            });
    }

    public GetMoonConfig(): MoonConfig {
        return this._moonEnvironmentConfig.moon;
    }

    public GetMsalConfig(): MoonMsalConfig {
        return this._moonEnvironmentConfig.msal;
    }

    public GetAppInsightsInstrumentationKey(): string {
        return this._moonEnvironmentConfig.appInsightsInstrumentationKey;
    }
}