import { HttpHeaders }              from "@angular/common/http";
import { MoonHttpErrorResponse }    from "@moon-core/moon-errors/moon-http-error-response";
import { ProblemDetail }            from "@moon-core/moon-errors/problem-detail";

export class MoonApiErrorResponse extends MoonHttpErrorResponse {
    
    public problemDetail: ProblemDetail;

    constructor(init: {
        error?: any;
        headers?: HttpHeaders;
        status?: number;
        statusText?: string;
        url?: string;
    }) {
        super(init);
    }
}
