import {
    NgClass, NgFor, NgIf, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                           from "@angular/common";
import { Component, Input, OnInit }         from "@angular/core";
import { FormsModule }                      from "@angular/forms";
// Third Party Imports
import { FilterService, SelectItem }        from "primeng/api";
import { ButtonModule }                     from "primeng/button";
import { DropdownModule }                   from "primeng/dropdown";
import { InputNumberModule }                from "primeng/inputnumber";
import { InputTextModule }                  from "primeng/inputtext";
import { MultiSelectModule }                from "primeng/multiselect";
import { ProgressBarModule }                from "primeng/progressbar";
import { TableModule }                      from "primeng/table";
// Moon Imports
import { DocumentFind }                     from "@moon-collaborator/api/response/document-find.response";
import { CollaboratorRouter }               from "@moon-collaborator/collaborator.router";
import { DocumentBatchProcessService }      from "@moon-collaborator/document/document-batch-operation/services/document-batch-process.service";
import { MoonCustomDropdownComponent }      from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }             from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }         from "@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component";
import { MoonTablePageComponent }           from "@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component";
import { FieldNames }                       from "@moon-shared/constants/field-names";
import { TableColumn }                      from "@moon-shared/types/table-column.type";

@Component({
    selector: "document-batch-operation-table",
    templateUrl:"./document-batch-operation-table.component.html",
    styleUrls: ["./document-batch-operation-table.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        MoonLoadingComponent,
        DropdownModule, InputTextModule,
        TableModule, ButtonModule, InputNumberModule,
        MoonTablePageComponent,
        MoonTableHeaderComponent,
        MoonCustomDropdownComponent, FormsModule,
        ProgressBarModule, MultiSelectModule,
      ],
    providers: [
        FilterService
    ]
})
export class DocumentBatchOperationTableComponent
    implements OnInit
{
    @Input() public MSEnvironmentName: string;
    public MSPageTitle: string = `Update Documents`;
    public MSSelectedItems: DocumentFind[];
    public MSFilteredItems: DocumentFind[];

    public MSStatusList: SelectItem[] = [];
    public MSResponsiblePartyList: SelectItem[] = [];
    public MSCategorySelectItemList: SelectItem[] = [];
    public MSScrollHeight = "calc(100vh - 280px)";
    public MSIsChunkedSelectItemList: SelectItem[] = [
        {label: 'Yes', value: "Y"},
        {label: 'No', value: "N"},
    ]

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.DocumentName.toCamelCase(), header: FieldNames.Document.getDisplayName(), class: 'w-30per'},
		{field: FieldNames.CategoryName.toCamelCase(), header: FieldNames.CategoryName.getDisplayName(), class: 'w-10per'}, 
		{field: FieldNames.IsChunked.toCamelCase(), header: FieldNames.IsChunked.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.TotalTokens.toCamelCase(), header: FieldNames.TotalTokens.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.TotalVectors.toCamelCase(), header: FieldNames.TotalVectors.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.LawFirmName.toCamelCase(), header: FieldNames.LawFirmName.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.Author.toCamelCase(), header: FieldNames.Author.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.PropertyState.toCamelCase(), header: FieldNames.PropertyState.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.MatterName.toCamelCase(), header: FieldNames.MatterName.getDisplayName(), class: 'w-10per'},

    ];
    private _items: DocumentFind[];

    constructor(
        private _collaboratorRouter: CollaboratorRouter,
        private _documentBatchProcessService: DocumentBatchProcessService,
    ) {}

    ngOnInit() { }

    @Input("MSItems") 
    public set MSItems(items: DocumentFind[]) {
        this.MSSelectedItems = [];
        this._items = items?.slice();
        this.MSFilteredItems = this._items?.slice();
    }

    public get MSProcessMessage(): string {
        return this._documentBatchProcessService.ProcessMessage;
    }

    public get MSIsProcessInProgress(): boolean {
        return this._documentBatchProcessService.DisplayProgressBar;
    }

    public MSOnClickGoBack() {
        this._collaboratorRouter.ToDocument(this.MSEnvironmentName);
    }

    public GetSelectedItems() {
        return this.MSSelectedItems;
    }
}
