<div
    id="add-title-summary"
    [formGroup]="MSTitleSummaryForm"
    class="mb-1"
>
    <div
        id="summarize-input-container"
        class="col p-0"
    >
        <div class="card mb-1 flex flex-wrap align-items-center">
            <button
                pButton
                pRipple
                type="button"
                icon="pi pi-arrow-left"
                (click)="MSOnClickGoBack()"
                class="p-button-rounded p-button-text"
                pTooltip="Go Back"
                tooltipPosition="bottom"
            ></button>

            <moon-form-control
                class="p-fluid col-4 p-0"
                [MSFormControl]="MSTitleSummaryForm.controls.summaryType"
                [MSLabel]="'Type'"
                [MSLabelSize]="3"
                [MSInputSize]="9"
                [MSErrorMessageSize]="12"
            >
                <p-dropdown
                    [options]="MSTitleReviewSummaryTypes"
                    [formControl]="MSTitleSummaryForm.controls.summaryType"
                ></p-dropdown>
            </moon-form-control>

            <moon-form-control
                class="p-fluid col-4 p-0"
                [MSFormControl]="MSTitleSummaryForm.controls.matterName"
                [MSLabel]="'Matter Name'"
                [MSLabelSize]="3"
                [MSInputSize]="6"
                [MSErrorMessageSize]="3"
            >
                <input
                    pInputText
                    type="text"
                    [formControl]="MSTitleSummaryForm.controls.matterName"
                />
            </moon-form-control>
            <!-- Clear button to clear the file -->
            <div class="ml-auto">
                <button
                    *ngIf="MSTitleSummaryForm.controls.inputFile.value"
                    pButton
                    pRipple
                    class="p-button-raised moon-apply-extract-button"
                    label="Clear File"
                    title="Clear File"
                    (click)="MSClearSelectedFile()"
                >
                </button>
                <button
                    pButton
                    pRipple
                    class="p-button-raised moon-apply-summarize-button"
                    label="Process Exceptions"
                    (click)="MSOnClickProcessExceptions()"
                >
                </button>
            </div>
        </div>
        <div
            *ngIf="MSTitleSummaryPdfPage.HasExceptionPages()"
            class="text-right"
        >
            <i class="text-sm">{{ MSTitleSummaryPdfPage.GetExceptionPagesList() }} marked as exception page.</i>
        </div>
    </div>
</div>
<!-- File Input -->
<ng-template #fileInput>
    <div
        class="moon-file-input"
        moonDragDrop
        [allowDrop]="true"
        allowMultiple="false"
        [fileType]="['text/plain','application/pdf']"
        (outputFileDropped)="MSOnFileDrop($event)"
    >
        <!-- Show drag drop when file is not yet uploaded -->
        <ng-template #fileNotUploaded>
            <div class="flex flex-column gap-2 text-left moon-file-drop-container">
                <div>
                    <span class="pi pi-download mr-2"></span> Drop your file here or
                </div>
                <div>
                    <moon-form-control
                        [MSFormControl]="MSTitleSummaryForm.controls.inputFile"
                        [MSLabel]="'Input file'"
                        [MSInputSize]="12"
                        [MSDisplayLabel]="false"
                    >
                        <input
                            #fileInput
                            type="file"
                            (change)="MSHandleFileInput(fileInput.files)"
                            accept="text/plain, application/pdf"
                        />
                    </moon-form-control>
                </div>
            </div>
        </ng-template>
        <!-- Show name of the file and size when file is uploaded -->
        <ng-container *ngIf="MSTitleSummaryForm.controls.inputFile.value; else fileNotUploaded">
            <div class="h-full flex flex-column xl:flex-row gap-2 justify-content-evenly align-items-start">
                <div class="line-break-anywhere align-self-center">
                    {{MSTitleSummaryForm.controls.inputFile.value.name | slice: 0:50}} -
                    {{ MSTitleSummaryForm.controls.inputFile.value.size/1024 | number:'1.0-2'}} kb
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<div
    class="moon-pdf-viewer-container"
    *ngIf="MSTitleSummaryPdfPage.pdfSrc; else fileInput"
>

    <div
        id="moon-pdf-indicator"
        class="flex flex-column align-items-center py-2 px-4 gap-4 w-20rem"
        style="overflow: auto;"
    >
        <ng-container *ngFor="let item of MSTitleSummaryPdfPage.pdfThumbnails; let i=index">
            <div
                class="moon-thumnail-image-container relative"
                [ngClass]="{'active' : MSTitleSummaryPdfPage.activePdfPage == i+1, 'marked': MSTitleSummaryPdfPage.IsPageMarked(item.page) }"
                (click)="MSTitleSummaryPdfPage.activePdfPage = i+1"
                id="moon-thumbnail-page-{{item.page}}"
            >
                <img
                    src="{{item.url}}"
                    alt="item.page"
                    class="moon-thumbnail-image"
                />

                <button
                    pButton
                    pRipple
                    [title]="(MSTitleSummaryPdfPage.IsPageMarked(i+1) ? 'Unmark' : 'Mark') + ' as exception page'"
                    [label]="MSTitleSummaryPdfPage.IsPageMarked(i+1) ? 'Unmark' : 'Mark'"
                    class="w-min h-min absolute bottom-0 mb-1 moon-mark-unmark-btn "
                    [ngClass]="{'marked-page' : MSTitleSummaryPdfPage.IsPageMarked(item.page)}"
                    (click)="MSTitleSummaryPdfPage.MarkUnmarkAsExceptionPage(item.page)"
                ></button>
            </div>
            <div class="pagenumber pb-1">{{item.page}}</div>
        </ng-container>
    </div>
    <pdf-viewer
        id="moon-pdf-viewer"
        class="moon-pdf-viewer"
        [src]="MSTitleSummaryPdfPage.pdfSrc"
        [page]="MSTitleSummaryPdfPage.activePdfPage"
        [show-all]="true"
        [zoom-scale]="'page-height'"
        (pageChange)="MSTitleSummaryPdfPage.OnPdfPageChanged($event)"
        (after-load-complete)="MSTitleSummaryPdfPage.OnPdfLoadComplete($event)"
    ></pdf-viewer>
</div>


<moon-loading
    *ngIf="MSLoading"
    [MSLoadingMessage]="'Uploading file'"
></moon-loading>


<p-confirmDialog [closable]="false"></p-confirmDialog>