// Moon Auto Generated (1.2.0) at 12/20/2023 9:12:40 AM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Moon Imports
import { AppBreadcrumbService }     from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { UserGroupController }           from '@moon-maintainer/api/user-group.controller';
import { UserGroupFind }                 from "@moon-maintainer/api/response/user-group-find.response";
import { UserGroupTableComponent }       from '@moon-maintainer/user-group/user-group-table/user-group-table.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, UserGroupTableComponent],
  providers: [UserGroupController]
})
export class UserGroupComponent implements OnInit {

  public MSUserGroupList: UserGroupFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.UserGroup.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _userGroupController: UserGroupController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadUserGroupList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadUserGroupList() {    
    try {
      this.MSLoading = true;
      const apiResult: ApiContentResult<UserGroupFind[]> = await this._userGroupController.GetList();
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSUserGroupList = apiResult.content;
      };
    } finally {
      this.MSLoading = false;
    }
  }
}
