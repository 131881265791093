// Third party imports
import { MenuItem } from "primeng/api";
// Moon Imports
import { RoutePath } from "@moon-shared/constants/route-path";


export const PreviewMenuItems: MenuItem = {
    label: "Preview",
    items: [
        { label: RoutePath.TitleReview.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.TitleReview] },
        { label: RoutePath.MezzLoan.getDisplayName(), icon: "pi pi-file", routerLink: [RoutePath.MezzLoan] },
        { label: RoutePath.Summarize.getDisplayName(), icon: "pi pi-file-edit", routerLink: [RoutePath.Summarize] },
        { label: RoutePath.Revise.getDisplayName(), icon: "pi pi-file-edit", routerLink: [RoutePath.Revise] },
    ]
};
