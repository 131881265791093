<moon-loading
    *ngIf="MSLoading"
    [MSLoadingMessage]="MSLoadingMessage"
></moon-loading>

<div
    id="full-text-search"
    *ngIf="!MSLoading"
>
    <div
        class="card"
        [formGroup]="MSSearchForm"
        (ngSubmit)="MSOnClickSearch()"
    >
        <p-panel
            [toggleable]="true"
            [collapsed]="MSCollapseSearch"
        >
            <ng-template pTemplate="icons">
                <button
                    class="p-panel-header-icon p-link mr-2"
                    (click)="MSTriggerExpandCollapse()"
                >
                    <span
                        *ngIf="!MSCollapseSearch"
                        class="pi pi-chevron-up"
                    ></span>
                    <span
                        *ngIf="MSCollapseSearch"
                        class="pi pi-chevron-down"
                    ></span>
                </button>
            </ng-template>
            <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2">
                    <span class="font-bold">Full Text Search: </span>
                </div>
            </ng-template>
            <div class="m-0">
                <div>
                    <div class="moon-card-body">
                        <div class="flex">
                            <div class="col-12 md:col-12 inline-block p-fluid">
                                <moon-form-control
                                    *ngIf="!MSSearchWithMeaning"
                                    [MSInputSize]="12"
                                    [MSFormControl]="MSSearchForm.controls.containsSearch"
                                    [MSDisplayLabel]="false"
                                    [MSLabel]="'Contains'"
                                >
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input
                                            type="text"
                                            pInputText
                                            id="Contains"
                                            [formControl]="MSSearchForm.controls.containsSearch"
                                            (keydown.enter)="MSOnEnterClickContainsSearch()"
                                        />
                                        <i
                                            class="pi pi-times cursor-pointer ms-clear-right"
                                            (click)="MSClearSearchTextbox()"
                                        ></i>
                                    </span>

                                    <!-- <span class="p-fluid p-float-label">
                                        <textarea
                                            rows="5"
                                            cols="30"
                                            pInputTextarea
                                            id="Contains"
                                            [formControl]="MSSearchForm.controls.containsSearch"
                                        ></textarea>
                                        <label for="Contains">Contains</label>
                                    </span> -->

                                    <!-- Show Searched Input Text -->
                                    <div *ngIf="!MSSearchWithMeaning" class="flex align-items-center flex-wrap mt-2">
                                        <ng-container *ngFor="let searchInputText of MSContentSearchList">
                                            <span>
                                                <p-tag value="{{searchInputText}}"></p-tag>
                                            </span> &nbsp;
                                            <i
                                                class="pi pi-times cursor-pointer"
                                                (click)="MSClearSearchText(searchInputText)"
                                            ></i> &nbsp;&nbsp;
                                        </ng-container>
                                    </div>
                                </moon-form-control>

                                <moon-form-control
                                    *ngIf="MSSearchWithMeaning"
                                    [MSInputSize]="12"
                                    [MSFormControl]="MSSearchForm.controls.freeTextSearch"
                                    [MSDisplayLabel]="false"
                                    [MSLabel]="'Search With Meaning'"
                                >
                                    <span class="p-fluid p-float-label">
                                        <textarea
                                            rows="5"
                                            cols="30"
                                            pInputTextarea
                                            id="searchMeaning"
                                            [formControl]="MSSearchForm.controls.freeTextSearch"
                                        ></textarea>
                                        <label for="searchMeaning">Search With Meaning</label>
                                    </span>
                                </moon-form-control>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <moon-form-control
                                    *ngIf="MSDisplayUseMeaning"
                                    class="ms-float-left"
                                    [MSFormControl]="MSSearchForm.controls.searchWithMeaning"
                                    MSLabel="Use Meaning"
                                    [MSLabelSize]="10"
                                    [MSInputSize]="2"
                                >
                                    <p-checkbox
                                        [ngModel]="MSSearchForm.value.searchWithMeaning"
                                        [ngModelOptions]="{standalone: true}"
                                        [binary]="true"
                                        (onChange)="MSOnSearchWithMeaningChanged($event)"
                                    ></p-checkbox>
                                </moon-form-control>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <moon-form-control
                                    *ngIf="MSEnvironmentList;"
                                    [MSInputSize]="11"
                                    [MSLabelSize]="0"
                                    [MSDisplayLabel]="false"
                                    [MSFormControl]="MSSearchForm.controls.environmentList"
                                    [MSLabel]="'Environment'"
                                >
                                    <ng-container>
                                        <p-multiSelect
                                            *ngIf="MSEnvironmentList.length > 1"
                                            [options]="MSEnvironmentList"
                                            [formControl]="MSSearchForm.controls.environmentList"
                                            optionLabel="environmentName"
                                            optionValue="environmentName"
                                            [filter]="true"
                                            filterBy="environmentName"
                                            placeholder="Environment"
                                        ></p-multiSelect>
                                        <!-- (onChange)="MSOnEnvironmentsChanged()"
                                        (onSelectAllChange)="MSOnEnvironmentsChanged()" -->
                                        <span *ngIf="MSEnvironmentList.length == 1">
                                            <label for="environmentName">Environment &nbsp;&nbsp;</label>{{
                                            MSEnvironmentList[0]?.environmentName }}
                                        </span>
                                        <span *ngIf="!MSEnvironmentList.length">
                                            <label for="environmentName">Environment &nbsp;&nbsp;</label>No Environment
                                            To Show
                                        </span>
                                    </ng-container>
                                </moon-form-control>
                            </div>

                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <moon-form-control
                                    [MSInputSize]="11"
                                    [MSLabelSize]="0"
                                    [MSDisplayLabel]="false"
                                    [MSFormControl]="MSSearchForm.controls.matterName"
                                    [MSLabel]="'Matter Name'"
                                >
                                    <ng-container>
                                        <span class="p-float-label">
                                            <input
                                                type="text"
                                                pInputText
                                                [formControl]="MSSearchForm.controls.matterName"
                                            >

                                            <label for="matterName">Matter Name</label>
                                        </span>
                                    </ng-container>
                                </moon-form-control>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <moon-form-control
                                    [MSInputSize]="11"
                                    [MSLabelSize]="0"
                                    [MSDisplayLabel]="false"
                                    [MSFormControl]="MSSearchForm.controls.author"
                                    [MSLabel]="'Author'"
                                >
                                    <ng-container>
                                        <span class="p-float-label">
                                            <input
                                                type="text"
                                                pInputText
                                                [formControl]="MSSearchForm.controls.author"
                                            >

                                            <label for="author">Author</label>
                                        </span>
                                    </ng-container>
                                </moon-form-control>
                            </div>

                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <div>
                                    <moon-form-control
                                        [MSInputSize]="11"
                                        [MSLabelSize]="0"
                                        [MSDisplayLabel]="false"
                                        [MSFormControl]="MSSearchForm.controls.documentName"
                                        [MSLabel]="'Document Name'"
                                    >
                                        <ng-container>
                                            <span class="p-float-label">
                                                <input
                                                    type="text"
                                                    pInputText
                                                    [formControl]="MSSearchForm.controls.documentName"
                                                >
                                                <!-- <p-autoComplete
                                                    [size]="6"
                                                    [dropdown]="true"
                                                    formControlName="document"
                                                    (onSelect)="MSSetCategory()"
                                                    (onKeyUp)="MSSetCategory()"
                                                    (onBlur)="MSSetCategory()"
                                                    (onClear)="MSSetCategory()"
                                                    field="label"
                                                    dataKey="value"
                                                    [suggestions]="MSFilterDocumentNameList"
                                                    (completeMethod)="MSFindCategory($event)"
                                                >
                                                </p-autoComplete> -->
                                                <label for="document">Document Name</label>
                                            </span>
                                        </ng-container>
                                    </moon-form-control>
                                </div>
                                <!-- <div class="ms-spinner">
                                    <p-progressSpinner
                                        *ngIf="MSLoadingDocumentName"
                                        styleClass="w-2rem h-2rem"
                                        strokeWidth="4"
                                        fill="var(--surface-ground)"
                                        animationDuration="2s"
                                    ></p-progressSpinner>
                                </div> -->
                            </div>
                        </div>

                        <div class="flex">
                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <moon-form-control
                                    [MSInputSize]="11"
                                    [MSLabelSize]="0"
                                    [MSDisplayLabel]="false"
                                    [MSFormControl]="MSSearchForm.controls.propertyState"
                                    [MSLabel]="'Property State'"
                                >
                                    <ng-container>
                                        <span class="p-float-label">
                                            <input
                                                type="text"
                                                pInputText
                                                [formControl]="MSSearchForm.controls.propertyState"
                                            >

                                            <label for="propertyState">Property State</label>
                                        </span>
                                    </ng-container>
                                </moon-form-control>
                            </div>

                            <div class="col-12 md:col-5 inline-block p-fluid">
                                <moon-form-control
                                    [MSInputSize]="11"
                                    [MSLabelSize]="0"
                                    [MSDisplayLabel]="false"
                                    [MSFormControl]="MSSearchForm.controls.lawFirmName"
                                    [MSLabel]="'Law Firm Name'"
                                >
                                    <ng-container>
                                        <span class="p-float-label">
                                            <input
                                                type="text"
                                                pInputText
                                                [formControl]="MSSearchForm.controls.lawFirmName"
                                            >
                                            <label for="lawFirmName">Law Firm Name</label>
                                        </span>
                                    </ng-container>
                                </moon-form-control>
                            </div>
                        </div>
                    </div>

                    <div class="grid align-items-center gap-2 col-12 moon-card-footer">
                        <button
                            (click)="MSOnClickSearch()"
                            pButton
                            pRipple
                            icon="pi pi-search"
                            class="p-button-raised moon-warning-message-action-button"
                            label="Search"
                            [disabled]="MSLoading"
                        ></button>
                    </div>
                </div>
            </div>
        </p-panel>
    </div>

    <div
        #resultView
        *ngIf="MSShowDocumentResultSection"
        class="flex"
    >
        <!-- Data view component -->
        <div
            id="moon-doc-result"
            class="card col-12 relative"
        >
            <ms-full-text-search-data-view
                [MSFreeTextSearch]="MSFreeTextSearched"
                [MSSearchDocumentList]="MSSearchDocumentList"
                [MSContentSearchList]="MSContentSearchList"
                [MSTimeElapsedForResult]="MSTimeElapsedForResult"
                [MSSearchResultFilter]="MSSearchResultFilter"
                [MSIncludeExactMatches]="MSIncludeExactMatches"
            >
            </ms-full-text-search-data-view>
        </div>
    </div>
</div>