<ng-container *ngIf="MSResultFilter">
    <moon-filter-item
        [MSFilterLabel]="'Year'"
        [(MSFilterMap)]="MSResultFilter.year"
        (MSOnFilterChanged)="MSOnFilterChanged()"
        (MSOnFilterCleared)="MSOnFilterCleared()"
    ></moon-filter-item>
    
    <moon-filter-item
        [MSFilterLabel]="'Property State'"
        [(MSFilterMap)]="MSResultFilter.propertyState"
        (MSOnFilterChanged)="MSOnFilterChanged()"
        (MSOnFilterCleared)="MSOnFilterCleared()"
    ></moon-filter-item>

    <moon-filter-item
        [MSFilterLabel]="'Property Type'"
        [(MSFilterMap)]="MSResultFilter.propertyType"
        (MSOnFilterChanged)="MSOnFilterChanged()"
        (MSOnFilterCleared)="MSOnFilterCleared()"
    ></moon-filter-item>
    
    <moon-filter-item
        [MSFilterLabel]="'Law Firm Name'"
        [(MSFilterMap)]="MSResultFilter.lawFirmName"
        (MSOnFilterChanged)="MSOnFilterChanged()"
        (MSOnFilterCleared)="MSOnFilterCleared()"
    ></moon-filter-item>

    <moon-filter-item
        [MSFilterLabel]="'Author'"
        [(MSFilterMap)]="MSResultFilter.author"
        (MSOnFilterChanged)="MSOnFilterChanged()"
        (MSOnFilterCleared)="MSOnFilterCleared()"
    ></moon-filter-item>

    <moon-filter-item
        [MSFilterLabel]="'Matter'"
        [(MSFilterMap)]="MSResultFilter.matterName"
        (MSOnFilterChanged)="MSOnFilterChanged()"
        (MSOnFilterCleared)="MSOnFilterCleared()"
    ></moon-filter-item>

</ng-container>