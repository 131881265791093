<moon-form-page
    [MSParentForm]="MSFavoriteChunkForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    [MSDisplaySaveButton]="false"
    (MSOnClickEventSubmit)="MSOnClickSubmitFavoriteClause()"
    (MSOnClickEventCancel)="MSOnClickCancelFavoriteChunk()"
>
    <div [formGroup]="MSFavoriteChunkForm">
        <moon-form-control
            [MSLabel]="'Favorite Notes'"
            [MSFormControl]="MSFavoriteChunkForm.controls.favoriteNotes"
            [MSInputSize]="6"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="favoriteNotes.value"
            >
                <textarea
                    #favoriteNotes
                    pInputTextarea
                    [formControl]="MSFavoriteChunkForm.controls.favoriteNotes"
                ></textarea>
            </div>
        </moon-form-control>
    </div>

    <div>
        <div
            class="progress-bar"
            *ngIf="MSIsFormSubmitting"
        >
            Please wait...
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <button
            type="submit"
            pButton
            pRipple
            label="Save"
            class="w-init p-button-raised mr-2"
            [disabled]="MSIsFormSubmitting"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Cancel"
            [disabled]="MSIsFormSubmitting"
            class="w-init p-button-raised p-button-secondary"
            (click)="MSOnClickCancelFavoriteChunk()"
        ></button>
        <button
            *ngIf="MSShowRemoveButton"
            pButton
            pRipple
            type="button"
            label="Remove from favorites"
            icon="pi pi-trash"
            class="w-init p-button-raised p-button-danger float-right"
            (click)="MSOnClickRemoveFromFavorite()"
        ></button>
    </div>
</moon-form-page>