<!-- Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                [MSDisplayRouteBackButton]="MSDisplayRouteBackButton"
                (MSOnClickEventRemove)="OnClickRemove()"
                (MSOnClickEventRouteBack)="OnClickRouteBack()">
    <ng-container *ngIf="!MSLoading">
        <skill-example-form #MSForm
                        [MSSkillID]="MSSkillID"
                       [MSSkillExampleID]="MSSkillExampleID"
                       [MSSkillExampleGet]="MSSkillExampleGet">
        </skill-example-form>
    </ng-container>
</moon-edit-page>
